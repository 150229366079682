import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Web3 from "web3";
import "@metamask/legacy-web3";

// import EXCHANGE from '../../ABI/EXCHANGE.json'
import DETH_ABI from "../../ABI/DETH_ABI.json";
import config from "../../lib/config";

import {
  PurchaseNow_Complete_Action,
  checkOtherPlatformDetais1155,
  PurchaseNow_Complete_Action_Meta,
  PurchaseNow_Price_Action,
  IpfsUpdate,
  LazyBuyAccept,
} from "../../actions/v1/token";
import Convert from "./Convert";
import { UserCheck, toFixedNumber } from "../../actions/v1/user";
import SINGLE from "../../ABI/SINGLE.json";
import MULTIPLE from "../../ABI/MULTIPLE.json";
import isEmpty from "../../lib/isEmpty";
import { toast } from "react-toastify";
// import { GetItemList } from 'actions/items';
import Trade from "../../ABI/Trade.json";
import { getReceipt } from "../../actions/v1/getReceiptFunc";
import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network";
import { connectWallet, WalletConnect } from "../../views/hooks/useWallet";
import {
  Account_Connect,
  Account_disConnect,
} from "../../actions/redux/action";
import Market from "../../ABI/market.json";
import Web3Utils from "web3-utils";
import { GetGasFees } from "../../actions/contractProvider";
import { NewGetGasFees } from "../../actions/contractProvider";
import { GetServiceFee } from "../../actions/contractProvider";
import CommonFunction from "../../views/hooks/commonhookfunction";
import { NumberChange } from "../../lib/common";

// toast.configure();
let toasterOption = config.toasterOption;

export const PurchaseNowRef = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  var {
    UserAccountAddr,
    UserAccountBal,
    TokenBalance,
    Wen_Bln,

    MyItemAccountAddr_Details,
  } = props;
  const location = useLocation();
  var location_name = location.pathname;

  const useCommonFunction = CommonFunction();

  const [ApproveCallStatus, setApproveCallStatus] = React.useState("init");
  const [PurchaseCallStatus, setPurchaseCallStatus] = React.useState("init");
  const [MultipleWei, Set_MultipleWei] = React.useState(0);
  const [NoOfToken, Set_NoOfToken] = React.useState(1);
  const [FormSubmitLoadings, Set_FormSubmitLoading] = React.useState("start");
  const [ValidateError, Set_ValidateError] = React.useState({});
  const [YouWillPay, Set_YouWillPay] = React.useState(0);
  const [TokenPrice, Set_TokenPrice] = React.useState(0);
  const [CoinName, set_CoinName] = React.useState(0);
  const [BuyOwnerDetails, set_BuyOwnerDetail] = React.useState({});
  const [Mul_YouWillPay, Set_Mul_YouWillPay] = React.useState(0);
  const [NewTokenUSValue, setNewTokenUSValue] = React.useState(0);
  const [NewTokenAddress, setNewTokenAddresss] = React.useState("");
  const [item, Set_item] = React.useState(props.item);
  const [NewTokenDecimal, setNewTokenDecimal] = React.useState(0);
  const [PurchaseBalance, Set_PurchaseBalance] = React.useState(0);
  const [currentFees, SetCurrentFees] = useState({});

  useEffect(() => {
    Set_ValidateError({});
    TokenValue();
    ItemValidation({ NoOfToken: NoOfToken, TokenPrice: TokenPrice });
  }, [
    NoOfToken,
    TokenPrice,
    BuyOwnerDetails.CoinName,
    Wallet_Details.currency_convertion,
  ]);

  useEffect(() => {
    PriceCalculate();
    TokenValue();
  }, [
    NewTokenDecimal,
    NewTokenAddress,
    currentFees,
    Wallet_Details.currency_convertion,
  ]);

  async function FormSubmit() {
    window.$("#PurchaseNow_modal").modal("hide");
    window.$("#PurchaseStep_modal").modal("show");
  }

  const TokenValue = () => {
    if (typeof Wallet_Details.currency_convertion === "object") {
      if (!isEmpty(item)) {
        let check = Wallet_Details.Token_convertion.filter(
          (items) => items.label == item.tokenowners_current.CoinName
        );
        let Pricecal =
          Wallet_Details.currency_convertion.currency ===
          item.tokenowners_current.CoinName
            ? Wallet_Details.currency_convertion.USD *
              item.tokenowners_current.tokenPrice *
              NoOfToken
            : check[0].USD * item.tokenowners_current.tokenPrice * NoOfToken;
        setNewTokenUSValue(Number(Pricecal));
      }
    }
  };

  const inputChange = (e) => {
    Set_FormSubmitLoading("start");
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      let value = e.target.value;
      switch (e.target.name) {
        case "NoOfToken":
          Set_NoOfToken(value);
          PriceCalculate({ quantity: Number(value) });
          break;
        case "TokenPrice":
          Set_TokenPrice(value);
          if (value !== "" && isNaN(value) === false && value > 0) {
            PriceCalculate({ price: Number(value) });
          }
          break;
        default:
      }
    }
  };

  const PriceCalculate = async (data = {}) => {
    let web3 = new Web3(Wallet_Details.providerss);
    let price = typeof data.price != "undefined" ? data.price : TokenPrice;
    let quantity =
      typeof data.quantity != "undefined" ? data.quantity : NoOfToken;
    let royalty =
      typeof data.tokenRoyality != "undefined"
        ? data.tokenRoyality
        : item.tokenRoyality;
    let newPrice =
      item.type === 721 ? price * 1000000 : quantity * (price * 1000000);
    let toMid = newPrice;
    let currfees = data?.Service_Fee_buyer
      ? data?.Service_Fee_buyer
      : currentFees?.Service_Fee_buyer;
    let serfee =
      (toMid / 100000000) *
      (((currfees || currfees == 0
        ? currfees
        : Wallet_Details?.Service_Fee_buyer) /
        config.decimalvalues) *
        1000000);
    let totfee = serfee + toMid;
    let tot2cont = null;
    let aprrove = null;
    let youwillpay = totfee / 1e6;
    if (NewTokenDecimal == 18) {
      tot2cont = web3.utils.toWei(String(Number(Number(totfee) / 1000000)));
      aprrove = web3.utils.toWei(String(Number(Number(totfee) / 1000000)));
    } else {
      tot2cont = web3.utils.toWei(String(Number(Number(totfee) / 1000000)));
      let dec = 18 - NewTokenDecimal;
      aprrove = tot2cont / 10 ** dec;
    }
    Set_YouWillPay(youwillpay);
    Set_Mul_YouWillPay(aprrove);
    Set_MultipleWei(String(tot2cont));
  };

  const ItemValidation = async (data = {}) => {
    let ValidateError = {};
    let Chk_TokenPrice =
      typeof data.TokenPrice != "undefined" ? data.TokenPrice : TokenPrice;
    let quantity =
      typeof data.quantity != "undefined" ? data.quantity : NoOfToken;
    let Collectible_balance = 0;
    if (BuyOwnerDetails && BuyOwnerDetails.balance) {
      Collectible_balance = BuyOwnerDetails.balance;
    }
    if (quantity === "") {
      ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
    } else if (quantity === 0) {
      ValidateError.NoOfToken = '"Quantity" must be greater than 0';
    } else if (isNaN(quantity) === true) {
      ValidateError.NoOfToken = '"Quantity" must be a number';
    }
    if (
      quantity >
      (
        // BuyOwnerDetails.putonsalequantity > 1
        // ? 
        BuyOwnerDetails.putonsalequantity
        // : BuyOwnerDetails.balance
    )
    ) {
      ValidateError.NoOfToken =
        '"Quantity" must be below on ' + BuyOwnerDetails.putonsalequantity;
    }
    if (Chk_TokenPrice === "") {
      ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
    } else if (Chk_TokenPrice === 0) {
      ValidateError.TokenPrice = '"Token Price" must be greater than 0';
    } else if (isNaN(Chk_TokenPrice) === true) {
      ValidateError.TokenPrice = '"Token Price" must be a number';
    } else if (
      String(BuyOwnerDetails.CoinName).toLowerCase() ===
      String(Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase()
    ) {
      if (Wallet_Details.Wen_Bln === 0) {
        Set_FormSubmitLoading("error");
      } else if (Wallet_Details.Wen_Bln < Chk_TokenPrice) {
        Set_FormSubmitLoading("error");
      }
    } else if (
      String(BuyOwnerDetails.CoinName).toLowerCase() !==
      String(Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase()
    ) {
      if (Wallet_Details.UserAccountBal === 0) {
        Set_FormSubmitLoading("errors");
      } else if (Wallet_Details.UserAccountBal < Chk_TokenPrice) {
        Set_FormSubmitLoading("errors");
      }
    } else {
      if (Chk_TokenPrice > Wallet_Details.UserAccountBal) {
        ValidateError.TokenPrice =
          "Insufficient balance, Check your wallet balance";
      } else {
        delete ValidateError.TokenPrice;
      }
    }
    if (!isEmpty(ValidateError)) {
      Set_FormSubmitLoading("errors1");
    } else if (NoOfToken > 0) {
      Set_FormSubmitLoading("start");
    }
    Set_ValidateError(ValidateError);
    return ValidateError;
  };

  async function BalanceCalculation(Coin) {
    try {
      if (Wallet_Details.providerss && Coin) {
        let web3 = new Web3(Wallet_Details.providerss);
        if (web3) {
          let web3 = new Web3(Wallet_Details.providerss);
          if (Coin !== Wallet_Details.networkConfiguration.currencySymbol) {
            const MultiContract = new web3.eth.Contract(
              Market,
              Wallet_Details.networkConfiguration.TradeContract
            );
            let tokenAddress = await MultiContract.methods
              .tokentype(Coin)
              .call();
            const TokenObj = new web3.eth.Contract(DETH_ABI, tokenAddress);
            let TokenBalance = Number(
              await TokenObj.methods
                .balanceOf(Wallet_Details.UserAccountAddr)
                .call()
            );
            let TokenDecimal = Number(await TokenObj.methods.decimals().call());
            Set_PurchaseBalance(TokenBalance / 10 ** TokenDecimal);
            setNewTokenDecimal(TokenDecimal);
            setNewTokenAddresss(tokenAddress);
          } else {
            Set_PurchaseBalance(Wallet_Details.UserAccountBal);
          }
        }
      }
    } catch (error) {
      console.log("BalanceCalculation error", error);
    }
  }

  useImperativeHandle(ref, () => ({
    async PurchaseNow_Click(item, BuyOwnerDetail = {}) {
      let useractive = await UserCheck({
        currAddr: Wallet_Details?.UserAccountAddr,
      });
      if (useractive?.userValue?.activate) {
      } else {
        toast.error("User banned by the admin");
        return false;
      }
      if (
        Wallet_Details.UserAccountAddr !== "" ||
        Wallet_Details.UserAccountAddr !== null
      ) {
        let chainid =
          item?.tokenowners_current?.[0]?.SelectedNetwork ||
          item?.tokenowners_current?.SelectedNetwork;
        console.log(
          "fgndfgnfdssd",
          item,
          Wallet_Details,
          Wallet_Details?.networkConfiguration?.Chainid ==
            item?.tokenowners_current?.SelectedNetwork
        );
        let accountDetailsss = "";
        if (
          (Wallet_Details?.networkConfiguration?.Chainid ==
            item?.tokenowners_current?.[0]?.SelectedNetwork ||
            Wallet_Details?.networkConfiguration?.Chainid ==
              item?.tokenowners_current?.SelectedNetwork,
          chainid)
        ) {
          accountDetailsss = true;
        } else {
          let accountDetailsss = await switchNetwork(chainid);
        }
        if (item?.hashValue == "notminted" && item?.ipfsimage == "") {
          let ipfsdata = await IpfsUpdate({ tokenCounts: item.tokenCounts });
        }
        if (chainid == Wallet_Details.networkConfiguration.Chainid) {
          let web3 = new Web3(Wallet_Details.providerss);

          if (
            BuyOwnerDetail &&
            typeof BuyOwnerDetail.tokenOwner != "undefined"
          ) {
            item.tokenowners_current = {};
            item.tokenowners_current = BuyOwnerDetail;
          }
          if (item) {
            let data = {
              Singleaddress: Wallet_Details.networkConfiguration.singleContract,
              multipleContract:
                Wallet_Details.networkConfiguration.multipleContract,
            };
            let CoursetroContract = new web3.eth.Contract(
              Market,
              Wallet_Details.networkConfiguration.TradeContract
            );
            let balance = await checkOtherPlatformDetais1155(
              item,
              BuyOwnerDetail,
              item.type,
              web3,
              data,
              CoursetroContract
            );
            if (balance == 0) {
              toast.warning(
                "You won't buy at this moment please refresh you data",
                toasterOption
              );
              return false;
            } else {
              Set_item(item);
              if (BuyOwnerDetail && BuyOwnerDetail.tokenPrice) {
                Set_TokenPrice(BuyOwnerDetail.tokenPrice);
              }
              set_BuyOwnerDetail(BuyOwnerDetail);
              let CurrServiceFee = await GetServiceFee(
                item?.ServiceType,
                CoursetroContract
              );
              SetCurrentFees(CurrServiceFee);

              if (BuyOwnerDetail && BuyOwnerDetail.CoinName) {
                set_CoinName(BuyOwnerDetail.CoinName);
                BalanceCalculation(BuyOwnerDetail.CoinName);
              }

              Set_NoOfToken(1);
              PriceCalculate({
                quantity: 1,
                price: BuyOwnerDetail.tokenPrice,
                tokenRoyality: item.tokenRoyality,
                Service_Fee_buyer: CurrServiceFee?.Service_Fee_buyer,
              });
              window.$("#PurchaseNow_modal").modal("show");
            }
          }
        } else {
          if (Wallet_Details.UserAccountAddr == "") {
            window.$("#connect_modal").modal("show");
          }
        }
      }
    },
  }));

  async function switchNetwork(configdata) {
    let type = "";
    let networkConfiguration = {};
    let accountDetails;
    if (configdata) {
      if (
        localStorage.walletConnectType &&
        localStorage.walletConnectType != null &&
        localStorage.walletConnectType == "MetaMask"
      ) {
        type = "MetaMask";
      } else if (
        localStorage.walletConnectType &&
        localStorage.walletConnectType == "WalletConnect" &&
        localStorage.walletConnectType != null
      ) {
        type = "WalletConnect";
      }

      window.$("#connect_modal").modal("hide");
      accountDetails = await connectWallet(type, configdata);
      let web3 = new Web3(window.ethereum);
      if (window.ethereum.isMetaMask == true) {
        const chainId = await web3.eth.getChainId();
        if (chainId === network.ETH.Chainid) {
          networkConfiguration = network.ETH;
        } else if (chainId === network.BSC.Chainid) {
          networkConfiguration = network.BSC;
        }
      }
      if (accountDetails != "") {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: accountDetails.accountAddress,
            UserAccountBal: accountDetails.coinBalance,
            WalletConnected: "true",
            Wen_Bln: accountDetails.tokenBalance,
            Accounts: accountDetails.accountAddress,
            providerss: accountDetails.web3._provider,
            networkConfiguration: networkConfiguration,
          },
        });
      }
    }
    return accountDetails;
  }

  async function FormSubmit_StepTwo() {
    setPurchaseCallStatus("start");

    setApproveCallStatus("process");
    if (Wallet_Details.providerss) {
      let web3 = new Web3(Wallet_Details.providerss);
      if (web3) {
        let web3 = new Web3(Wallet_Details.providerss);
        let Approves = null;
        let handle = null;
        let receipt = null;
        let senfCon = Number(Mul_YouWillPay) / 1e6;
        let SendMntCon = null;
        let tokenAdd =
          Wallet_Details.networkConfiguration.tokenAddr[
            Wallet_Details.networkConfiguration.tokenSymbol
          ];
        let bidvalue;
        try {
          bidvalue = new web3.eth.Contract(DETH_ABI, NewTokenAddress);
          let getAllowance = await bidvalue.methods
            .allowance(
              Wallet_Details.UserAccountAddr,
              Wallet_Details.networkConfiguration.TradeContract
            )
            .call();
          if (NewTokenDecimal == 18) {
            SendMntCon = web3.utils.toWei(
              String(
                Number(web3.utils.fromWei(String(Mul_YouWillPay))) +
                  Number(web3.utils.fromWei(String(getAllowance)))
              )
            );
          } else {
            SendMntCon =
              (Number(Mul_YouWillPay) / 10 ** NewTokenDecimal +
                Number(getAllowance) / 10 ** NewTokenDecimal) *
              10 ** NewTokenDecimal;
          }
        } catch (err) {
          console.log("dfbafsgnh", err);
        }
        try {
          console.log("SendMntCon-->", SendMntCon);
          let encoded = await bidvalue.methods.approve(
            Wallet_Details.networkConfiguration.TradeContract,
            SendMntCon
          );
          let gasfeecalculated = await NewGetGasFees({
            web3: web3,
            contractcall: encoded,
            accountAddress: Wallet_Details.UserAccountAddr,
          });

          await bidvalue.methods
            .approve(
              Wallet_Details.networkConfiguration.TradeContract,
              SendMntCon
            )
            .send({
              from: Wallet_Details.UserAccountAddr,
              gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10),
              gasPrice: gasfeecalculated?.gasPrice,
            })
            .on("transactionHash", async (transactionHash) => {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash);
                clr1();
              }, 8000);
            });
        } catch (e) {
          setApproveCallStatus("try");
          console.log("dskljdlasd", e);
        }
        async function clr1() {
          if (receipt != null) {
            clearInterval(handle);
            if (receipt.status === true) {
              setApproveCallStatus("done");
              setPurchaseCallStatus("init");
              toast.success("Token Approved Successfully", 1000);
            }
          }
        }
      } else {
        window.$("#connect_modal").modal("show");
      }
    }
  }

  async function FormSubmit_StepOne() {
    if (Wallet_Details.providerss) {
      let web3 = new Web3(Wallet_Details.providerss);
      if (web3) {
        let handle = null;
        let receipt = null;
        let CoursetroContract = null;
        let contractCall = null;
        let tokenCounts;
        let ipfsdata = null;
        let totalsendAmount = web3.utils.toWei(
          String(
            Number(Number(BuyOwnerDetails.tokenPrice * 1e6) * NoOfToken) / 1e6
          )
        );
        let sendAmount = web3.utils.toWei(
          String(Number(BuyOwnerDetails.tokenPrice))
        );
        web3 = new Web3(Wallet_Details.providerss);
        try {
          setPurchaseCallStatus("processing");
          if (item?.hashValue == "notminted" && item?.ipfsimage == "") {
            let ipfsdata = await IpfsUpdate({ tokenCounts: item.tokenCounts });
            if (ipfsdata?.success == "success") {
            } else {
              setPurchaseCallStatus("tryagain");
              toast.error("Try-Again");
              return false;
            }
          }

          CoursetroContract = new web3.eth.Contract(
            Market,
            Wallet_Details.networkConfiguration.TradeContract
          );

          let CurrServiceFee = await GetServiceFee(
            item?.ServiceType,
            CoursetroContract
          );

          if (item?.hashValue == "notminted") {
            try {
              let sendcontract = {
                from: Wallet_Details.UserAccountAddr,
              };
              let encoded = await CoursetroContract.methods.lazyMinting(
                [
                  `${config.IPFS_IMG}/${item?.metadataipfs}`,
                  item.tokenowners_current?.Randomname,
                ],
                [
                  item.tokenowners_current.tokenOwner,
                  Wallet_Details?.UserAccountAddr,
                  item?.contractAddress,
                ],
                [
                  NoOfToken,
                  item?.type,
                  window.web3.toWei(String(item?.tokenRoyality)),
                  String(totalsendAmount),
                  item?.tokenQuantity,
                  item?.tokenowners_current?._nonce,
                  String(sendAmount),
                  item?.tokenowners_current?.ListedQuantity,
                  currentFees?.key,
                ],
                coinname,
                item?.tokenowners_current?.signature,
                item?.tokenowners_current?.ListNFT
              );

              let coinname = "";
              if (
                String(CoinName).toLowerCase() !==
                String(
                  Wallet_Details.networkConfiguration.currencySymbol
                ).toLowerCase()
              ) {
                coinname = String(BuyOwnerDetails?.CoinName);
                // sendcontract.value = totalsendAmount;
              } else {
                sendcontract.value = MultipleWei;
                coinname = "coin";
              }
              let gasPrice = await web3.eth.getGasPrice();
              sendcontract.gasPrice = gasPrice;
              contractCall = await CoursetroContract.methods
                .lazyMinting(
                  [
                    `${config.IPFS_IMG}/${item?.metadataipfs}`,
                    item.tokenowners_current?.Randomname,
                  ],
                  [
                    item.tokenowners_current.tokenOwner,
                    Wallet_Details?.UserAccountAddr,
                    item?.contractAddress,
                  ],
                  [
                    NoOfToken,
                    item?.type,
                    window.web3.toWei(String(item?.tokenRoyality)),
                    String(totalsendAmount),
                    item?.tokenQuantity,
                    item?.tokenowners_current?._nonce,
                    String(sendAmount),
                    String(
                      Number(item?.tokenowners_current?.ListedQuantity) -
                        Number(NoOfToken)
                    ),
                    currentFees?.key,
                  ],
                  coinname,
                  item?.tokenowners_current?.signature,
                  item?.tokenowners_current?.ListNFT
                )
                .send(sendcontract)
                .on("transactionHash", async (transactionHash) => {
                  handle = setInterval(async () => {
                    receipt = await getReceipt(web3, transactionHash);
                    clr1();
                  }, 8000);
                });
            } catch (err) {
              setPurchaseCallStatus("tryagain");
              toast.error("Order not placed", toasterOption);
            }
          } else {
            if (
              String(CoinName).toLowerCase() !=
              String(
                Wallet_Details.networkConfiguration.currencySymbol
              ).toLowerCase()
            ) {
              //ids[0] - tokenId, ids[1] - amount, ids[2] -  nooftoken, ids[3] - nftType

              let ids = [
                item.tokenCounts,
                totalsendAmount.toString(),
                NoOfToken,
                item.type,
                item?.tokenowners_current?._nonce,
                sendAmount.toString(),
                currentFees?.key,
              ];
              console.log("🚀 ~ FormSubmit_StepOne ~ ids:", ids);
            //   debugger;
              try {
                let encoded = await CoursetroContract.methods.saleWithToken(
                  BuyOwnerDetails.CoinName.toString(),
                  BuyOwnerDetails.tokenOwner,
                  ids,
                  item.tokenowners_current.contractAddress,
                  item?.tokenowners_current?.Randomname,
                  item?.tokenowners_current?.signature
                );
                let gasfeecalculated = await NewGetGasFees({
                  web3: web3,
                  contractcall: encoded,
                  accountAddress: Wallet_Details.UserAccountAddr,
                });
                contractCall = await CoursetroContract.methods
                  .saleWithToken(
                    BuyOwnerDetails.CoinName.toString(),
                    BuyOwnerDetails.tokenOwner,
                    ids,
                    // sendAmount.toString(),
                    item.tokenowners_current.contractAddress,
                    item?.tokenowners_current?.Randomname,
                    item?.tokenowners_current?.signature
                  )
                  .send({
                    from: Wallet_Details.UserAccountAddr,
                    // gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10),
                    // gasPrice: gasfeecalculated?.gasPrice,
                  })
                  .on("transactionHash", async (transactionHash) => {
                    handle = setInterval(async () => {
                      receipt = await getReceipt(web3, transactionHash);
                      clr1();
                    }, 8000);
                  });
              } catch (err) {
                //console.log("fgaehrthr",err);
                console.log("contract saleWithToken error", err);
                setPurchaseCallStatus("tryagain");
                toast.error("Order not placed", toasterOption);
              }
            } else {
              try {
                let ids = [
                  item.tokenCounts,
                  totalsendAmount.toString(),
                  NoOfToken,
                  item.type,
                  item?.tokenowners_current?._nonce,
                  sendAmount.toString(),
                  currentFees?.key,
                ];
                // ids[0] - tokenId, ids[1] - amount, ids[2] -  nooftoken, ids[3] - nftType, isd[4] - _nonce, ids[5] - signatureValue

                let encoded = await CoursetroContract.methods.saleToken(
                  BuyOwnerDetails.tokenOwner,
                  ids,
                  item.tokenowners_current.contractAddress,
                  item?.tokenowners_current?.Randomname,
                  item?.tokenowners_current?.signature
                );
                let gasfeecalculated = await NewGetGasFees({
                  web3: web3,
                  contractcall: encoded,
                  accountAddress: Wallet_Details.UserAccountAddr,
                  value: MultipleWei,
                });
                contractCall = await CoursetroContract.methods
                  .saleToken(
                    // sendAmount.toString(),
                    BuyOwnerDetails.tokenOwner,
                    ids,
                    item.tokenowners_current.contractAddress,
                    item?.tokenowners_current?.Randomname,
                    item?.tokenowners_current?.signature
                  )
                  .send({
                    from: Wallet_Details.UserAccountAddr,
                    value: MultipleWei,
                    // gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10),
                    // gasPrice: gasfeecalculated?.gasPrice,
                  })
                  .on("transactionHash", async (transactionHash) => {
                    handle = setInterval(async () => {
                      receipt = await getReceipt(web3, transactionHash);
                      clr1();
                    }, 8000);
                  });
              } catch (err) {
                console.log("contract saleToken error", err);
                setPurchaseCallStatus("tryagain");
                toast.error("Order not placed", toasterOption);
              }
            }
          }
        } catch (error) {
          console.log("Order not placed error : ", error);
          setPurchaseCallStatus("tryagain");
          toast.error("Order not placed", toasterOption);
        }

        async function clr1() {
          try {
            if (receipt !== null) {
              clearInterval(handle);
              if (receipt?.status === true) {
                let paidamount =
                  NumberChange(BuyOwnerDetails.tokenPrice) *
                  NumberChange(NoOfToken);
                let usdval = useCommonFunction.CurrencyUSDConversion({
                  Price: paidamount,
                  CoinName: item?.tokenowners_current?.CoinName,
                  ChainId: item?.tokenowners_current?.SelectedNetwork,
                });
                console.log("paidamountpaidamount", paidamount, usdval);
                if (item?.hashValue == "notminted") {
                  let tokenid;
                  // if()
                  console.log("data topics console", receipt?.logs[0].data);
                  // amount =  web3.utils.toBN(data.logs[0].data).toString()
                  let someString;

                  if (item.type == 721) {
                    tokenid = receipt?.logs[0]?.topics[3];
                    someString = Web3Utils.hexToNumber(tokenid);
                  } else {
                    let sliceee = String(receipt?.logs?.[0]?.data).split(
                      "0x"
                    )[1];
                    console.log(
                      "qwewqeqweqweqweqw",
                      sliceee,
                      sliceee.length / 2,
                      sliceee.length
                    );
                    let lengthuh = sliceee.length / 2;
                    tokenid = "0x" + sliceee.slice(0, lengthuh);
                    console.log("lsjlfksjfs", tokenid);
                    someString = Web3Utils.hexToNumber(tokenid);
                  }
                  let postData = {
                    oldtokenCounts: item.tokenCounts,
                    oldtokenOwner: BuyOwnerDetails.tokenOwner, // old owner
                    newOwner: Wallet_Details.UserAccountAddr, // new owner
                    newtokenCounts: someString,
                    tokenType: item.type,
                    NoOfToken: item.type === 721 ? 1 : NoOfToken,
                    transactionHash: receipt.transactionHash,
                    tokenBid: true,
                    CoinName: CoinName,
                    USDvalue: usdval,
                    contractAddress: item.contractAddress,
                    activity: "purchase",
                  };
                  if (
                    String(item.contractAddress).toLowerCase() !==
                      Wallet_Details.networkConfiguration.singleContract ||
                    String(item.contractAddress).toLowerCase() !==
                      Wallet_Details.networkConfiguration.multipleContract
                  ) {
                    postData.PurchaseNow_meta = true;
                  }
                  let Resp = await LazyBuyAccept(postData);
                  if (
                    Resp.data &&
                    Resp.data.toast &&
                    Resp.data.toast.type == "success"
                  ) {
                    toast.success(
                      "Collectible purchase successfully",
                      toasterOption
                    );
                    setPurchaseCallStatus("done");
                    window.$(".modal").modal("hide");
                    navigate("/my-items");
                  } else {
                    toast.error(Resp?.msg);
                    setPurchaseCallStatus("tryagain");
                  }
                } else {
                  let postData = {
                    tokenOwner: BuyOwnerDetails.tokenOwner, // old owner
                    UserAccountAddr: Wallet_Details.UserAccountAddr, // new owner
                    tokenCounts: item.tokenCounts,
                    tokenType: item.type,
                    NoOfToken: item.type === 721 ? 1 : NoOfToken,
                    transactionHash: receipt.transactionHash,
                    tokenBid: true,
                    CoinName: CoinName,
                    USDvalue: usdval,
                  };
                  if (
                    String(item.contractAddress).toLowerCase() !==
                      Wallet_Details.networkConfiguration.singleContract ||
                    String(item.contractAddress).toLowerCase() !==
                      Wallet_Details.networkConfiguration.multipleContract
                  ) {
                    let postMetaData = {
                      tokenOwner: BuyOwnerDetails.tokenOwner, // old owner
                      tokenCounts: item.tokenCounts,
                      NoOfToken: item.type === 721 ? 1 : NoOfToken,
                      contractAddress: item.contractAddress,
                    };
                    let result = await PurchaseNow_Complete_Action_Meta(
                      postMetaData
                    );
                  }
                  let postPrice = {
                    tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                    UserAccountAddr: Wallet_Details.UserAccountAddr, // new owner
                    tokenPrice: NewTokenUSValue,
                  };
                  let Resp = await PurchaseNow_Complete_Action(postData);
                  let Responce = await PurchaseNow_Price_Action(postPrice);
                  if (
                    Resp.data &&
                    Resp.data.toast &&
                    Resp.data.toast.type == "success"
                  ) {
                    toast.success(
                      "Collectible purchase successfully",
                      toasterOption
                    );
                    setPurchaseCallStatus("done");
                    window.$(".modal").modal("hide");
                    navigate("/my-items");
                  } else {
                    toast.error(Resp?.msg);
                    setPurchaseCallStatus("tryagain");
                  }
                }
              }
            }
          } catch (err) {
            console.log("clr1 error", err);
            setPurchaseCallStatus("tryagain");
          }
        }
      }
    } else {
      window.$("#connect_modal").modal("show");
    }
  }

  const SettoInitalValueFuc = () => {
    setApproveCallStatus("init");
    setPurchaseCallStatus("init");
    Set_MultipleWei(0);
    Set_NoOfToken(1);
    Set_FormSubmitLoading("start");
    Set_ValidateError({});
    Set_YouWillPay(0);
    Set_TokenPrice(0);
    set_CoinName(0);
    set_BuyOwnerDetail({});
    Set_Mul_YouWillPay(0);
    setNewTokenUSValue(0);
    setNewTokenAddresss("");
    setNewTokenDecimal(0);
    Set_PurchaseBalance(0);
  };

  return (
    <div>
      <div
        className="modal fade primary_modal PurchaseNow_modal"
        id="PurchaseNow_modal"
        tabIndex="-1"
        role="dialog"
        data-bs-backdrop="static"
        data-keyboard="false"
        aria-labelledby="accept_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Checkout
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close9"
                onClick={() => SettoInitalValueFuc()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="row mx-0">
                <div className="col-12 col-sm-3 px-4">
                  <p className="buy_title_sm">Seller</p>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <p className="buy_title_md text-md-right word_brak_text">
                    <span
                      title={
                        "Seller : " +
                        (item && item.userprofile && item.userprofile.name
                          ? item.userprofile && item.userprofile.name
                          : String(
                              BuyOwnerDetails &&
                                (BuyOwnerDetails.tokenOwner !== ""
                                  ? BuyOwnerDetails.tokenOwner
                                  : item.tokenOwner)
                            ))
                      }
                    >
                      {item.userprofile && item.userprofile.name
                        ? item.userprofile && item.userprofile.name
                        : String(
                            BuyOwnerDetails &&
                              (BuyOwnerDetails.tokenOwner !== ""
                                ? BuyOwnerDetails.tokenOwner
                                : item.tokenOwner)
                          )
                            .slice(0, 12)
                            .concat("....")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-12 col-md-3 px-4">
                  <p className="buy_title_sm">Buyer</p>
                </div>
                <div className="col-12 col-md-6 px-4">
                  <p className="buy_title_md text-md-right word_brak_text">
                    <span
                      title={
                        "Buyer : " +
                        (!isEmpty(MyItemAccountAddr_Details)
                          ? MyItemAccountAddr_Details.name !== ""
                            ? MyItemAccountAddr_Details.name
                            : Wallet_Details.UserAccountAddr
                          : Wallet_Details.UserAccountAddr)
                      }
                    >
                      {" "}
                      {!isEmpty(MyItemAccountAddr_Details) &&
                      MyItemAccountAddr_Details.name !== ""
                        ? MyItemAccountAddr_Details.name
                        : String(Wallet_Details.UserAccountAddr)
                            .slice(0, 12)
                            .concat("....")}
                    </span>
                  </p>
                </div>
              </div>
              {console.log('BuyOwnerDetails-->',BuyOwnerDetails)}
              <form className="bid_form" action="#">
                {item.type === 721 ? (
                  ""
                ) : (
                  <div className="mb-3 px-4 ">
                    <label htmlFor="qty">
                      Quantity
                      <span>
                        (
                        {BuyOwnerDetails.putonsalequantity > 1
                          ? BuyOwnerDetails.putonsalequantity
                          : item &&
                            BuyOwnerDetails &&
                            BuyOwnerDetails.balance &&
                            BuyOwnerDetails.ListNFT && 
                            BuyOwnerDetails.putonsalequantity}{" "}
                        is available)
                      </span>
                    </label>

                    <div className="mb-3 input_grp_style_1">
                      <input
                        type="text"
                        maxLength={config.maxLength}
                        className="form-control primary_inp text-center"
                        name="NoOfToken"
                        id="NoOfToken"
                        onChange={inputChange}
                        placeholder="e.g. 2"
                        autoComplete="off"
                        value={NoOfToken}
                      />
                      {ValidateError.NoOfToken && (
                        <span className="text-danger">
                          {ValidateError.NoOfToken}
                        </span>
                      )}
                      {!ValidateError.NoOfToken && ValidateError.TokenPrice && (
                        <span className="text-danger">
                          {ValidateError.TokenPrice}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </form>

              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    <Convert
                      item={Number(Wallet_Details.UserAccountBal / 1e18)}
                      coinName={BuyOwnerDetails.CoinName}
                      convertVal={1}
                    />
                    {Wallet_Details.networkConfiguration.currencySymbol}
                  </p>
                </div>
              </div>
              {String(BuyOwnerDetails.CoinName).toLowerCase() ===
                String(
                  Wallet_Details.networkConfiguration.tokenSymbol
                ).toLowerCase() && (
                <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">Your Token balance</p>
                  </div>
                  <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">
                      {PurchaseBalance} {BuyOwnerDetails.CoinName}
                    </p>
                  </div>
                </div>
              )}
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Price</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    <Convert
                      item={Number(TokenPrice)}
                      coinName={BuyOwnerDetails.CoinName}
                      convertVal={1}
                    />
                    {BuyOwnerDetails.CoinName}
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {String(BuyOwnerDetails.CoinName).toLowerCase() ===
                    String(
                      Wallet_Details.networkConfiguration.tokenSymbol
                    ).toLowerCase()
                      ? (Number(
                          currentFees?.Service_Fee_buyer ||
                            currentFees?.Service_Fee_buyer == 0
                            ? currentFees?.Service_Fee_buyer
                            : Wallet_Details?.Service_Fee_buyer
                        ) +
                          Number(0)) /
                        config.decimalvalues
                      : (currentFees?.Service_Fee_buyer ||
                        currentFees?.Service_Fee_buyer == 0
                          ? currentFees?.Service_Fee_buyer
                          : Wallet_Details?.Service_Fee_buyer) /
                        config.decimalvalues}
                    % <span>{BuyOwnerDetails.CoinName}</span>
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Royalty fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {item.tokenRoyality}%{" "}
                    <span>{BuyOwnerDetails.CoinName}</span>
                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will pay</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {YouWillPay}

                    <span>{BuyOwnerDetails.CoinName}</span>
                  </p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <button
                    type="button"
                    className="create_btn btn-block"
                    onClick={
                      (FormSubmitLoadings === "start" ||
                        isEmpty(ValidateError)) &&
                      (() => FormSubmit())
                    }
                    disabled={
                      FormSubmitLoadings === "processing" ||
                      FormSubmitLoadings === "error" ||
                      FormSubmitLoadings === "errors" ||
                      FormSubmitLoadings === "errors1" ||
                      !isEmpty(ValidateError)
                    }
                  >
                    {FormSubmitLoadings === "processing" && (
                      <>
                        {" "}
                        <i
                          className="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                          id="circle1"
                        ></i>{" "}
                        <>In-Progress</>
                      </>
                    )}
                    {FormSubmitLoadings === "error" && "Check Wenlambo Balance"}
                    {FormSubmitLoadings === "start" && "Proceed to payment"}
                    {FormSubmitLoadings === "errors" && "Check Balance"}
                    {FormSubmitLoadings === "errors1" && "Error in Field"}
                  </button>

                  <button
                    className="btn cancel btn-block"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => SettoInitalValueFuc()}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade primary_modal PurchaseStep_modal"
        id="PurchaseStep_modal"
        tabIndex="-1"
        role="dialog"
        data-bs-backdrop="static"
        data-keyboard="false"
        aria-labelledby="PurchaseStepCenteredLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="PurchaseStepLabel">
                Follow Steps
              </h5>

              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                disabled={
                  ApproveCallStatus === "process" ||
                  PurchaseCallStatus === "done"
                }
                onClick={() => SettoInitalValueFuc()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {String(CoinName).toLowerCase() !==
                  String(
                    Wallet_Details.networkConfiguration.currencySymbol
                  ).toLowerCase() && (
                  <div className="text-center">
                    <p className="mt-3 purchase_desc text-center">
                      Approve the transaction
                    </p>
                    <button
                      type="button"
                      onClick={() => FormSubmit_StepTwo()}
                      className={"create_btn btn-block"}
                      disabled={
                        ApproveCallStatus === "process" ||
                        ApproveCallStatus === "done"
                      }
                    >
                      {ApproveCallStatus === "process" && (
                        <i
                          className="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                          id="circle1"
                        ></i>
                      )}
                      {ApproveCallStatus === "init" && "Approve"}
                      {ApproveCallStatus === "process" && "In-progress..."}
                      {ApproveCallStatus === "done" && "Done"}
                      {ApproveCallStatus === "try" && "Try Again"}
                    </button>
                  </div>
                )}
                <div className="text-center my-3">
                  <p className="mt-3 purchase_desc text-center">
                    Send transaction with your wallet
                  </p>
                  <button
                    type="button"
                    onClick={() => FormSubmit_StepOne()}
                    disabled={
                      PurchaseCallStatus === "processing" ||
                      PurchaseCallStatus === "start" ||
                      PurchaseCallStatus === "done"
                    }
                    className={"create_btn btn-block"}
                  >
                    {PurchaseCallStatus === "processing" && (
                      <i
                        className="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                        id="circle1"
                      ></i>
                    )}
                    {PurchaseCallStatus === "init" && "Purchase"}
                    {PurchaseCallStatus === "start" && "Purchase"}

                    {PurchaseCallStatus === "processing" && "In-progress..."}
                    {PurchaseCallStatus === "done" && "Done"}
                    {PurchaseCallStatus === "tryagain" && "Try Again"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
