import * as CryptoJs from 'crypto-js'
import Config from '../lib/config'
import axios from 'axios';
import { GetToken } from '../lib/common';

export const FirstLetterCaps = (data) => {
  try {
    data = data[0].toUpperCase() + data.substring(1, data.length);
    return data;
  }
  catch (err) {
    console.log('FirstLetterCaps error', err)
    return data;
  }
}

export const isEmpty = value =>
  value === undefined ||
  value == "undefined" ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0) ||
  (typeof value === 'string' && value === '0') ||
  (typeof value === 'number' && value === 0);

  export const axiosFunc    =   async   (data)  =>  {
    try{
      let Resp    =   {};
      // if(false){
        if(data?.params){
          data.params = { data: EncryptData(data.params)  }
        }
        else if(data?.data){
          if(!(data?.data instanceof FormData)){
            data.data = { data: EncryptData(data.data)  }
          }
        }
        if(data?.headers){
          data.headers.Authorization = "Bearer "+GetToken(Config.userCookieName)
        }
        else{
          data.headers = {
          Authorization: "Bearer "+GetToken(Config.userCookieName)
          }
        }
      // }
        Resp    =   await axios(data)
        if(Resp?.data){
          Resp.data = ( typeof(DecryptData(Resp.data)) == 'string' && DecryptData(Resp.data) == '') ? Resp.data : DecryptData(Resp.data)
        }
        return Resp
    }
    catch (e) {
      return { success: 'error', msg: null }
    }
  }

export const EncryptData = (data) => {
  if (typeof (data) == 'string') {
    return CryptoJs.AES.encrypt(data, Config.KEY).toString()
  }
  else {
    return CryptoJs.AES.encrypt(JSON.stringify(data), Config.KEY).toString()
  }
}

export const DecryptData = (data) => {
  try {
    var bytes = CryptoJs.AES.decrypt(data, Config.KEY);

    var decryptedData;
    try {
      decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
    }
    catch (err) {

      try {
        decryptedData = bytes.toString(CryptoJs.enc.Utf8);


      }
      catch (dcpterr) {

        return '';
      }
    }
    return decryptedData;
  }
  catch (error) {
    return '';
  }
}

export const handleCallback = (callback) => {
  return async (data) => {
    console.log('kdsjlsjdlsjd', callback)
    try {
      return await callback(data);
    } catch (error) {
      console.error("handleCallback error", error)
      return ({
        error: error,
        Success: false,
        data: [],
        msg: `${'From user' + error.toString()}`
      })
    }
  };
};

export const ImgUrl = (data) => {
  try{
    return URL.createObjectURL(data);
  }
  catch(err){
    console.log('ImgUrl_error',err)
    return ''
  }
}

export const ImgEmpty = (data) => {
  try {
    return (
      (typeof (data) == 'object' && !data?.type) ||
      data === undefined ||
      data == "undefined" ||
      data === null ||
      (typeof data === 'string' && data.trim().length === 0) ||
      (typeof data === 'string' && data === '0') ||
      (typeof data === 'number' && data === 0)
    )
  }
  catch(err){
    console.log('ImgUrl_error',err)
    return false
  }
}
