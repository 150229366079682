import React, { useEffect, useState } from "react";

// import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import { Accordion, Container, Row, Col } from "react-bootstrap";

// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'


// const Icon = styled(props => (
//   <div {...props}>
//     <div className="minus">-</div>
//     <div className="plus">+</div>
//   </div>
// ))`
//   & > .plus {
//     display: block;
//     color: #a30726;
//     font-size: 24px;
//   }
//   & > .minus {
//     display: none;
//     color: #a30726;
//     font-size: 24px;
//   }
//   .Mui-expanded & > .minus {
//     display: flex;
//   }
//   .Mui-expanded & > .plus {
//     display: none;
//   }
// `;

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Faq(props) {

  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel100');
  const [faqlist,setfalist]=useState ([])

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(()=>{
    faqlistfunc()
  },[])

  const faqlistfunc=async()=>{
    let fql=await faqlists()
    //console.log("ksaldjjsadkls",fql.data.soci)
    if(fql&&fql.data&&fql.data.soci){
      setfalist(fql.data.soci)
    }
  }

  return (
    <div className="inner_header">
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader faq"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme container-lg">
            <div>
              <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center">
                  <h2 className="inner_title">Frequently Asked Questions</h2>
                </div>
              </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="container-fluid container-theme container-lg mt-3">
          <div>
            <Row>
            <Col xs={12} sm={6} md={6}>
              <div className="faq_panel">

              
{console.log('ldkjjfkldsjlkjfkldsjf',faqlist)}
<Accordion  className="mt-5">
      {faqlist.map((item, ind) => {
        if (ind % 2 === 0) {
          return (
            <Accordion.Item eventKey={ind.toString()} key={ind}>
              <Accordion.Header>
                <div className="accordian_head">
                  <h2>{item.question}</h2>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordian_para kr_accordian_para">
                  <p dangerouslySetInnerHTML={{__html:item.answer}}></p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        }
        return null;
      })}
    </Accordion>



            {/* {console.log('ldkjjfkldsjlkjfkldsjf',faqlist)}
                     {faqlist.map((item, ind) => {
                  if (ind % 2 === 0) {
                    return (
                      <Accordion expanded={expanded === 'panel' + (ind + 1)} onChange={handleChangeFaq('panel' + (ind + 1))}className="mt-5">
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head"><h2>{item.question}</h2></div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para kr_accordian_para">
                            <p>{ReactHTMLParser(item.answer)}</p>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                    )
                  }
                })} */}

                
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <div className="faq_panel">

                {/* {faqlist.map((item, ind) => {
                  if (ind % 2 !== 0) {
                    // //alert(ind%2)
                    return (

                      <Accordion expanded={expanded === 'panel' + (ind + 1)} onChange={handleChangeFaq('panel' + (ind + 1))}>
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel10bh-content" id="panel4bh-header">
                          <div className="accordian_head"><h2>{item.question}</h2></div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para kr_accordian_para">
                            <p>{ReactHTMLParser(item.answer)}</p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  }
                })} */}





<Accordion  className="mt-5">
      {faqlist.map((item, ind) => {
         if (ind % 2 !== 0) {
          return (
            <Accordion.Item eventKey={ind.toString()} key={ind}>
              <Accordion.Header>
                <div className="accordian_head">
                  <h2>{item.question}</h2>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordian_para kr_accordian_para">
                  <p dangerouslySetInnerHTML={{__html:item.answer}}></p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        }
        return null;
      })}
    </Accordion>




              </div>


            </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
