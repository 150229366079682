import React, { useEffect, useState } from "react";




// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { faqlists } from '../actions/v1/report'
import { Row, Col } from "react-bootstrap";


const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Howtobuy(props) {

  const { ...rest } = props;


  return (
    <div className="inner_header howItWorks">
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">How to Buy NFTs on EdaFace Marketplace</h2>
                </div>

              </Col>
            </Row>
          </div>
        </div>
        <div className="container mt-3">
          <div className="accordion" id="accordionExample">

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Purchase Options</button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>There are two options available for you to buy NFTs on EdaFace Marketplace:

                  </p>
                  <div className="accordionparalist">
                    <h2>(a). Fixed Price Purchase</h2>
                    <h2>(b). Auction Purchase</h2>
                  </div>


                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  How to Buy NFTs with Fixed Price Purchase
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>Step 1: Find an NFT you’d like to purchase by exploring any of the three market arenas of EdaFace
                    Marketplace.
                  </p>

                  <div className="accordionparalist">

                    <p>Step 2: Review the collected information about the NFT.</p>
                    <p>There are four basic information you should look out for in any NFT:
                    </p>
                    <h2>(a). The Current Price of the NFT</h2>
                    <h2>(b). The Properties of the NFT</h2>
                    <p>This information tells you about the uniqueness of the NFT. It includes:</p>
                    <ul>
                      <li> the Creator of the NFT</li>
                      <li> the Birthday of the NFT (the year it was created)</li>
                      <li> the current Owner of the NFT</li>
                      <li> the various breed types of the NFT</li>
                      <li> any special properties of the NFT</li>
                    </ul>
                    <h2>(c). Trading History</h2>
                    <p>This information tells you about the rarity of the NFT. It includes: </p>
                    <ul>
                      <li> the total number of the NFT that were minted</li>
                      <li> the volumes of trade that have occurred with the NFT</li>
                      <li> the various prices of transactions with the NFT that had taken place before</li>

                    </ul>
                    <h2>(d). Contract Details</h2>
                    <p>This information will help you to identify the NFT. It has four essential details to look out for:</p>
                    <ul>
                      <li> Contract Address – the address by which the NFT is stored</li>
                      <li> Token ID – the unique number that identifies the NFT</li>
                      <li> Blockchain: identifies the blockchain in which the NFT can be seen, e.g. Polygon, Ethereum,
                        Binance, etc</li>
                      <li>Token Standard – identifies the type of smartchain by which the NFT was created (e.g. Ethereum
                        NFTs are ERC-721 while Binance is BEP-721)
                      </li>

                    </ul>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 3: When you have reviewed the basic information of the NFT and you are satisfied with what
                      you see, you can go ahead with the purchase by simply clicking the “Buy Now” button.
                    </p>

                  </div>
                  <div className="accordionparalist">
                    <p>Step 4: You may be prompted by EdaFace Marketplace to review a few details about your purchase
                      before finalization.
                    </p>
                    <p>This is to ensure that you know exactly what you’re buying and also to prevent you from the scams
                      of buying an unauthentic version of the NFT.</p>


                  </div>
                  <div className="accordionparalist">
                    <p>Step 5: Once you have checked all details, proceed to a checkout pop-up where you can review the
                      final cost of your purchase.</p>
                    <p>You’ll be asked to agree to EdaFace terms of service, then you’re free to click the “Checkout” button
                      to finalize your purchase.
                    </p>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 6: You will now be directed to your crypto wallet where you will see the final cost, which
                      includes the blockchain gas fee. Click “Confirm” and the amount will be deducted from your wallet
                      to pay for the NFT.
                    </p>
                    <p>Congrats, you have bought your NFT.
                    </p>
                    <p>To see the NFT you have bought in your wallet, return to your “Profile,” and select “Collected” on
                      the left side of the page. There might be a slight delay until it shows up due to processing time, and
                      you might need to refresh the page.</p>
                  </div>

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  How to Buy NFTs in Auctions</button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>Step 1: Find an NFT you’d like to buy in an auction by exploring any of the three arenas of EdaFace
                    Marketplace</p>
                  <div className="accordionparalist">
                    <p>Step 2: Review the collected information about the NFT as stated in How to Buy NFTs with Fixed
                      Price Purchase <span>(hyperlink please)</span>.
                    </p>
                    <p>In addition, the information for review should include current offers to make sure you can place a
                      bid. EdaFace Marketplace requires that bids must be at least 5 percent higher than the previous bid.</p>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 3: If you are satisfied with the reviewed information, click the “Place Bid” button on the NFT
                      page.</p>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 4: You may be prompted by EdaFace Marketplace to review a few details about your purchase
                      before finalization.</p>
                    <p>This is to ensure that you know exactly what you’re buying and also to prevent you from the scams
                      of buying an unauthentic version of the NFT.</p>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 5: Select the currency you'd like to place a bid on. There may be once-off fees if you bid in a
                      currency you’ve never made a purchase with before.
                    </p>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 6: Enter the amount of your offer, accept EdaFace Terms of Service, and indicate an expiration
                      date.
                    </p>
                    <p>The expiration date allows you to pull your offer at that time, giving the seller a timeframe to make a
                      decision on accepting an offer on non-competitive NFT auctions.
                    </p>
                    <p>You can see a list of all offers made by returning to your profile and finding the “Offers” tab in the
                      left-hand sidebar.</p>
                    <p>After you have placed an offer, what you do is to wait for the seller to accept your offer.</p>
                    <p>Of course, if you’re making an offer on an NFT you really want and there’s a lot of bids, you’ll want
                      to monitor the bids and keep raising your offer. Like in traditional auctions, the highest bidder is the
                      winner.</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
