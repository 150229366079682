

import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useNavigate,
    useLocation
} from "react-router-dom";

import '@metamask/legacy-web3'


import config from '../../lib/config';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from "react-redux";
import isEmpty from '../../lib/isEmpty';
import PreviewCard from './PreviewCard';


// toast.configure();
let toasterOption = config.toasterOption;



export const ViewBulkFiles = forwardRef((props) => {


    const dispatch = useDispatch();

    const navigate = useNavigate()
    const Wallet_Details = useSelector(state => state.wallet_connect_context);


    const { Files, ThumbFiles, ChangeView, TokenQuantity, Pathname, Layertype, Popup, PreviewFile, NFTNames } = props;


    const [imagearray, SetImageArray] = useState([])


    useEffect(() => {
        if (typeof (ThumbFiles) == 'string') {
            let arr = [];
            for (let i = 0; i < TokenQuantity; i++) {
                arr.push(config.Back_URL + '/' + ThumbFiles + '/' + `Thumb${i}.png`)
            }
            SetImageArray(arr)
        }
        else if (typeof (Files) == 'string') {
            let arr = [];
            for (let i = 0; i < TokenQuantity; i++) {
                arr.push(Files + '/' + `Thumb${i}.png`)
            }
            SetImageArray(arr)
        }
    }, [Files, ThumbFiles])


    const GoBack = () => {
        ChangeView(false)
        if (Popup) {
            window.$('#create_item_modal2').modal('show');
        }
    }

    return (
        <div className={" inner_pageheader"}>
            <div className={"container"}>
                <div className="d-flex align-items-center">
                    <i class="fas fa-arrow-left arrow_back cursor" onClick={() => GoBack()} />
                </div>
                <div className="container mt-5">
                    <h1 className="primary_label uploaded_file">Uploaded Files:</h1>
                    {
                        (Files?.length > 0 && (Pathname != 'layer' || (Layertype != 'Image' && Pathname == 'layer' && imagearray?.length > 0))) ?
                            <>
                                <div className='row'>
                                    {(Object.keys(Files)).map((val, ind) =>
                                        <>
                                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                {(Pathname != 'image') ?
                                                    <PreviewCard
                                                        previewFile={Files[val]}
                                                        previewThumb={typeof (ThumbFiles) == 'string' ? imagearray[ind] : ThumbFiles[val]}
                                                        pathname={props?.Pathname}
                                                        NFTName={NFTNames?.[ind]}
                                                    />
                                                    :
                                                    <PreviewCard
                                                        previewFile={Files[val]}
                                                        pathname={props?.Pathname}
                                                        NFTName={NFTNames?.[ind]}
                                                    />}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                            :
                            (typeof (ThumbFiles) == 'string' && imagearray?.length > 0) ?
                                <>
                                    <div className='row'>
                                        {imagearray.map((val, ind) =>
                                            <>
                                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                    <PreviewCard
                                                        previewFile={val}
                                                        pathname={props?.Pathname}
                                                        NFTName={NFTNames?.[ind]}
                                                    // previewThumb={ThumbFiles[val]}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                                :
                                <>
                                    <p className="form_note">No Files Uploaded</p>
                                </>
                    }
                    {console.log('ksjshfkshfksfs', PreviewFile, !isEmpty(PreviewFile), PreviewFile?.length)}
                    {
                        (!isEmpty(PreviewFile) && PreviewFile?.length > 0 && PreviewFile?.some((test) => !isEmpty(test))) &&
                        <>
                            <h1 className="primary_label uploaded_file">Uploaded Preview Files:</h1>
                            {
                                (PreviewFile?.length > 0 ?
                                    <>
                                        <div className='row'>
                                            {(Object.keys(PreviewFile)).map((val, ind) =>
                                                <>
                                                    {
                                                        PreviewFile[val] &&
                                                        <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                            <PreviewCard
                                                                previewFile={PreviewFile[val]}
                                                                previewThumb={typeof (ThumbFiles) == 'string' ? imagearray[ind] : ThumbFiles[val]}
                                                                NFTName={NFTNames?.[ind]}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            )}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <p className="form_note">No Files Uploaded</p>
                                    </>)
                            }
                        </>
                    }
                </div>
            </div>
        </div>

    )
})

