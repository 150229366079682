import React, { useState, useEffect, useRef } from "react";
import DETH_ABI from "../ABI/DETH_ABI.json";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { UserCheck, getUserCollection } from "../actions/v1/user";
import { Link, useNavigate , useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import randomInteger from "random-int";
import { FaPlus } from "react-icons/fa";
import delete_png from "../assets/images/del.png";

import $ from "jquery";
import "@metamask/legacy-web3";
import Select from "react-select";
import CONFIG from "../lib/config";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../lib/isEmpty";
import Web3 from "web3";
import Web3Utils from "web3-utils";
import MULTIPLE from "../ABI/MULTIPLE.json";
import SINGLE from "../ABI/SINGLE.json";
import { getReceipt } from "../actions/v1/getReceiptFunc";
import {
  BulkNFTNameValidation,
  ExcelDataGet,
  IPFSUploadFunc,
  LazyMinting,
  ipfsImageHashGet,
} from "../actions/v1/token";
import { connectWallet, WalletConnect } from "../views/hooks/useWallet";
import {
  GetCategoryAction,
  CreateTokenValidationAction,
  TokenAddItemAction,
  TokenAddItemAction1,
  TokenAddOwnerAction,
  WenlamboConvert,
  validateNftQuantity,
  createNfts,
  ipfsImageHashGetBulk,
} from "../actions/v1/token";
import { Account_Connect, Account_disConnect } from "../actions/redux/action";

import { AddressUserDetails_GetOrSave_Action } from "../actions/v1/user";
import { network } from "./network";
import Market from "../ABI/market";
import { ViewBulkFiles } from "./separate/ViewBulkFiles";

import { AddCollectionRef } from "./separate/create_collection";
import { getcmslistinhome } from "../actions/v1/report";
import { GetServiceFee } from "../actions/contractProvider";
import { NumberOnly } from "../lib/common";
import { NumberChange } from "../lib/common";
import { Row, Col } from "react-bootstrap"



var initialformvalue = {
  currencySymbol: "",
  tokenSymbol: "",
  tokenAddr: {},
  tokenABI: {},
  singleContract: "",
  multipleContract: "",
  BNBPROVIDER: "",
  networkVersion: "",
  chainId: "",
  Chainid: 0,
  trade: "",
  Fronturl: "",
};

const mapdata = [
  { value: "Discord", label: "Discord", classname: "fab fa-discord" },
  { value: "Instagram", label: "Instagram", classname: "fab fa-instagram" },
  { value: "Linkedin", label: "Linkedin", classname: "fab fa-linkedin" },
  { value: "Whatsapp", label: "Whatsapp", classname: "fab fa-whatsapp" },
  { value: "Twitter", label: "Twitter", classname: "fab fa-twitter" },
  { value: "Slack", label: "Slack", classname: "fab fa-slack" },
  { value: "Telegram", label: "Telegram", classname: "fab fa-telegram" },
  { value: "Github", label: "Github", classname: "fab fa-github" },
  { value: "Facebook", label: "Facebook", classname: "fab fa-facebook-f" },
  { value: "Others", label: "Others", classname: "fas fa-hashtag" },
  // Add more data items here as needed
];

// toast.configure();
const dashboardRoutes = [];
let toasterOption = CONFIG.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {


  const dispatch = useDispatch();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const navigate = useNavigate();



  const { ...rest } = props;



  var pathVal = "";
  var new_pathVal = "";
  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split("/").length >= 2) {
      pathVal = location.pathname.split("/")[1];
      new_pathVal =
        location.pathname.split("/")[2] == "song"
          ? "audio"
          : location.pathname.split("/")[2];
    }
  }


  const [category, setCategory] = React.useState(false);
  const [location_pathname, Set_location_pathname] = useState(pathVal);
  const [newlocation_pathname, Set_new_location_pathname] = useState(
    new_pathVal
  );
  var CollectibleType_val = CONFIG.singleType;
  const [CollectibleType, Set_CollectibleType] = useState(location_pathname == 'literature' ? CONFIG.literatureType : CONFIG.multipleType);
  const [ValidateError, setValidateError] = useState({ TokenPrice: "" });
  const [PutOnSale, setPutOnSale] = useState(false);
  const [PutOnSaleType, setPutOnSaleType] = useState("UnLimitedAuction");
  const [TokenBid, setTokenBid] = useState(true);
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);
  const [TokenPrice, setTokenPrice] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [TokenName, setTokenName] = useState("");
  const [TokenDescription, setTokenDescription] = useState("");
  const [TokenRoyalities, setTokenRoyalities] = useState("");
  const [TokenProperties, setTokenProperties] = useState("");
  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [TokenQuantity, Set_TokenQuantity] = useState(2);
  const [CoinName, setCoinNames] = useState("");
  const [priceoption, setPriceoption] = React.useState([]);
  const [FormSubmitUserClicked, Set_FormSubmitUserClicked] = useState(false);
  const [TokenCategory, setTokenCategory] = useState({ label: "" });
  const [UserCollection, setUserCollection] = useState("");
  const [CollectionList, setCollectionList] = useState([]);
  const [additionalImage, setAdditionalImage] = useState("");
  const [MintCallStatus, setMintCallStatus] = useState("init");
  const [networkoption, setnetworkoption] = React.useState([]);
  const [NetworkID, setNetworkID] = React.useState("");
  const [NetworkName, SetNetworkName] = React.useState("");
  const [config, setconfig] = React.useState(initialformvalue);
  const [accountDetailssss, setaccountDetailssss] = useState({});
  const [decimal, setdecimal] = React.useState();
  const [approvecheck, setapprovecheck] = useState(false);
  const [fullcategory, setFullcategory] = useState({});
  const [CategorywithSubcategory, setCategorywithSubcategory] = useState({});
  const [NFTProbability, setNFTProbability] = useState(null);
  const [ListNFT, SetListNFT] = useState(false);
  const [LazyMint, SetLazyMint] = useState(false);
  const [ListedQuantity, SetListedQuantity] = useState(0);
  const [PutOnSaleQuantity, SetPutOnSaleQuantity] = useState(0);
  const [NFTProperties, setNFTProperties] = useState([]);
  const [Quantity, Set_Quantity] = useState(1);
  const [files, set_files] = useState([]);
  const [mp3File, set_mp3File] = useState([]);
  const [ipfsArray, setipfsArray] = useState([]);
  const [nftcreated, setnftcreated] = useState("");
  const [ipfsdone, setipfsdone] = useState("");
  const [mintdone, setmintdone] = useState("");
  const [placeordedone, setplaceordedone] = useState("");
  const [lazymintdone, setLazymintdone] = useState("");
  const [agree, SetAgree] = useState({
    original: false,
    termsandcondition: false,
  });
  const [LayerType, SetLayerType] = useState({
    label: "Image Only",
    value: "Image",
  });
  const [ViewMode, SetViewMode] = useState(false);
  const [MultipleImagePath, SetMultipleImagePath] = useState("");
  const [PopupOpen, SetPopupOpen] = useState(false);
  const [FileProbability, SetFileProbability] = useState("");
  const [RequiredNFT, SetRequiredNFT] = useState("");
  const [NFTIds, SetNFTIds] = useState([]);
  const [Randomname, setRandomName] = useState("");
  const [_nonce, setNonceHash] = useState("");
  const [signature, setSignatureHash] = useState("");
  const [socialLinks, SetSocialLinks] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [choosencollection, SetChoosenCollection] = useState({});
  const [previewfile, set_PreviewFile] = useState([]);
  const [Excel, set_Excel] = useState({ file: {}, data: [] });
  const [allfile, SetAllFiles] = useState([]);
  const [nftnames, SetNFTNames] = useState([]);
  const [WhiteListUser, SetWhiteListUser] = useState([]);
  const [WhiteListOptions, SetWhiteListOptions] = useState([]);
  const [admincheck, SetAdmincheck] = useState(false);

console.log('allfile-->',allfile)
  useEffect(() => {
    if (Array.isArray(Wallet_Details?.alldata?.WhiteListUser) && Wallet_Details?.alldata?.WhiteListUser?.length > 0) {
      let arr = [];
      Wallet_Details.alldata.WhiteListUser.map((val) => {
        if (!isEmpty(val)) {
          arr.push({
            label: String(val).toLowerCase(),
            value: String(val).toLowerCase(),
          })
        }
      })
      SetWhiteListOptions(arr);
      SetWhiteListUser([]);
    }
    else {
      SetWhiteListOptions([]);
      SetWhiteListUser([]);
    }
  }, [Wallet_Details?.alldata])

  const mappedData = mapdata.map((item) => ({
    value: item.value,
    label: item.label,
    classname: item.classname,
  }));

  const CreateCollectionForwardRef = useRef();

  var initvalue;

  if (newlocation_pathname == "audio") {
    initvalue = "Supported Audio NFT format is: wav, FLAC, mp3, WAV, flac, MP3";
  } else if (newlocation_pathname == "video") {
    initvalue = "Supported Video NFT format is: OGV, ogv, WEBM, webm, mp4";
  } else if (newlocation_pathname == "image") {
    initvalue = "Supported Image NFT format is: JPEG, png, PNG, jpeg, jpg, JPG";
  } else if (newlocation_pathname == "book") {
    initvalue = "Supported Book NFT format is: pdf , PDF";
  }

  const [Validfile, SetValidFile] = useState(initvalue);

  var ContractAddressUser_val =
    location_pathname === "create-single"
      ? Wallet_Details.networkConfiguration.singleContract
      : Wallet_Details.networkConfiguration.multipleContract;

  useEffect(() => {
    GetAdmin()
    //console.log("jhvhgchgf", Wallet_Details);
  }, [Wallet_Details.UserAccountAddr]);

  useEffect(()=>{
    getCollection();
  },[Wallet_Details.networkConfiguration])

  console.log('Wallet_Details.networkConfiguration',Wallet_Details.networkConfiguration)

  async function getCollection() {
    let Singlee = Wallet_Details.networkConfiguration.singleContract;
    let Multiplee = Wallet_Details.networkConfiguration.multipleContract;
    let payload = {
      userAddress: Wallet_Details.UserAccountAddr,
    };
    if (location_pathname === "bulkmint") {
      payload.NFTType = "MULTIPLE";
      payload.Type = Multiplee;
    }
    else if (location_pathname == "literature") {
      payload.NFTType = "LITERATURE";
      payload.Type = Wallet_Details?.networkConfiguration?.literatureContract;
    }
    console.log(' Wallet_Details?.networkConfiguration', Wallet_Details?.networkConfiguration)
    // else{
    //   payload.NFTType = "SINGLE";
    //   payload.Type = Singlee;
    // }
    let userCollectiondata = await getUserCollection(payload);
    console.log('userCollectiondatauserCollectiondata',userCollectiondata)
    let Collectionlist = [];
    if (
      userCollectiondata &&
      userCollectiondata.message &&
      userCollectiondata.message.data &&
      isEmpty(userCollectiondata.message.data.collections) === false
    ) {
      console.log("setUserCollection",userCollectiondata.message.data.collections);
      userCollectiondata.message.data.collections.map((item, index) => {
        console.log('itemmmmm',item);
        Collectionlist.push({
          ...item,
          ...{
            name: "userCollection",
            value: item._id,
            label: item.collectionName,
            // custon:item.customurl
          },
        });
      });
    }
    Collectionlist.push({
      name: "userCollection",
      value: "create",
      label: (
        <div className="drop_static_create">
          <Link
            onClick={() =>
              CreateCollectionForwardRef.current.AddCollection_Click()
            }
          >
            Create Collections
          </Link>
        </div>
      ),
    });
    console.log('CollectionlistCollectionlist',Collectionlist)
    setCollectionList(Collectionlist);
  }

  useEffect(() => {
    if (Wallet_Details && Wallet_Details.tokenAddress.length > 0) {
      setPriceoption(Wallet_Details.tokenAddress);
    }
  }, [Wallet_Details.tokenAddress]);

  useEffect(() => {
    if (Wallet_Details && Wallet_Details?.networkoption?.length > 0) {
      setnetworkoption(Wallet_Details.networkoption);
    }
  }, [Wallet_Details?.networkoption])

  useEffect(() => {
    GetCategoryCall();
    CreateItemValidation(FormSubmitUserClicked);
  }, [Wallet_Details.UserAccountAddr, CoinName]);

  useEffect(() => {
    setconfig(Wallet_Details?.networkConfiguration)
  }, [Wallet_Details?.networkConfiguration])

  async function GetCategoryCall() {
    let resp = await GetCategoryAction();
    if (resp && resp.data && isEmpty(resp.data.list) === false) {
      setFullcategory(resp.data.list);
    }
  }

  const PriceCalculate = async (data = {}) => {
    let price = typeof data.price != "undefined" ? data.price : TokenPrice;
    let weii = price * CONFIG.decimalvalues;
    let per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
    let mulWei = parseFloat(weii - per);
    let getVal = mulWei / CONFIG.decimalvalues;
    Set_YouWillGet(getVal.toFixed(4));
  };

  const priceoptionfunc = (e) => {
    //console.log("nfrymntfryju",e.label);
    setCoinNames(e.label);
    setdecimal(e.Decimal);
    // setiscurrency(e.currency)
  };

  async function switchNetwork(configdata) {
    let type = "";
    let networkConfiguration = {};
    let accountDetails;
    if (configdata) {
      if (
        localStorage?.walletConnectType &&
        localStorage?.walletConnectType != null &&
        localStorage?.walletConnectType == "MetaMask"
      ) {
        type = "MetaMask";
      } else if (
        localStorage?.walletConnectType &&
        localStorage?.walletConnectType == "WalletConnect" &&
        localStorage?.walletConnectType != null
      ) {
        type = "WalletConnect";
      }
      window.$("#connect_modal").modal("hide");
      accountDetails = await connectWallet(type, configdata.Chainid);
      let web3 = new Web3(window.ethereum);
      if (window.ethereum.isMetaMask == true) {
        const chainId = await web3.eth.getChainId();
        console.log('chainIdchainId', chainId, network.BSC.Chainid, network.ETH.chainId, accountDetails)
        if (chainId == network.ETH.Chainid) {
          networkConfiguration = network.ETH;
        } else if (chainId == network.BSC.Chainid) {
          networkConfiguration = network.BSC;
        }
      }
      if (accountDetails != "" && accountDetails?.web3?._provider != "") {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: accountDetails.accountAddress,
            UserAccountBal: accountDetails.coinBalance,
            WalletConnected: "true",
            Wen_Bln: accountDetails.tokenBalance,
            Accounts: accountDetails.accountAddress,
            providerss: accountDetails.web3._provider,
            networkConfiguration: networkConfiguration,
          },
        });
      } else {
        toast.warning("please connect your wallet");
      }
    }
    return accountDetails;
  }

  async function orderApprovecheck(item, tokenOwnerInfo) {
    if (Wallet_Details.providerss == null) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    let web3 = new Web3(Wallet_Details.providerss);
    try {
      let MultiContract = new web3.eth.Contract(
        item.type === 721 ? SINGLE : MULTIPLE,
        item.contractAddress
      );
      let status = await MultiContract.methods
        .isApprovedForAll(
          tokenOwnerInfo.tokenOwner,
          Wallet_Details.networkConfiguration.TradeContract
        )
        .call();
      return status;
    } catch (e) {
      console.log("OrderApprove Check", e);
      return false;
    }
  }

  const SettokenCategory = (cat, subcat) => {
    setCategorywithSubcategory({
      category: cat.name,
      subcategory: subcat,
      id: cat._id,
      display: cat.name + "-" + subcat,
    });
    setCategory(!category);
  };
  /////////////////////////////bulk mint ////////////////////////////////////////

  const selectFileChange = async (e) => {
    let validext = ["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"];
    let audioext = ["wav", "FLAC", "mp3", "WAV", "flac", "MP3"];
    console.log("dfbsfgbsfgbs",newlocation_pathname);//fileInputControl
    let validfile;
    if (newlocation_pathname == "audio" || newlocation_pathname == "song") {
      audioext = ["wav", "FLAC", "mp3", "WAV", "flac", "MP3"];
    } else if (newlocation_pathname == "video") {
      audioext = ["OGV", "ogv", "WEBM", "webm", "mp4"];
    } else if (newlocation_pathname == "image") {
      audioext = ["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"];
    } else if (newlocation_pathname == "book") {
      audioext = ["pdf", "PDF"];
    }
    if (newlocation_pathname !== "layer") {
      validfile = ["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"];
    }

    console.log('e.target.id-->',e.target.id)
    if (e.target.id === "fileInputControl") {
      try {
        if (Wallet_Details.UserAccountAddr == "") {
          toast.warning(" connect your wallet", toasterOption);
        } else {
          let filessss = [];
          let compfilepath = [];
          let folderlength = [];
          let files = e.target.files;
          //console.log("fnsfgndghvmn",files);
          //console.log("sfjnfdjndgfj",files.length > 0);

          if (files) {
            if (files.length > 0) {
              for (let i = 0; i < files.length; i++) {
                filessss.push(files[i]);
              }
            }
            if (newlocation_pathname == "layer") {
              let validatext = await Promise.all(
                filessss.map((file) => {
                  let ext = file.name.split(".").pop();
                  let extval = validext.includes(ext);
                  return extval;
                })
              );
              let checkfileext = validatext.includes(false);
              if (!checkfileext) {
                let data = {
                  files,
                  curradd: Wallet_Details.UserAccountAddr,
                };
                let check = await validateNftQuantity(data);
                //console.log("dfbssdfgnfsgn",check.data.data);
                setNFTProbability(check.data.data);

                set_files(filessss);
                toast.success("file uploaded successfully");
              } else {
                toast.warn(`only ${validext} extensions are allowed`);
              }
            }
            else {
              let validatext = await Promise.all(
                filessss.map((file) => {
                  let ext = file.name.split(".").pop();
                  let extval = validfile.includes(ext);
                  return extval;
                })
              );
              let checkfileext = validatext.includes(false);
              if (!checkfileext) {
                set_files(filessss);
                toast.success(
                  (newlocation_pathname == "layer" ? "File" : "Thumb file") +
                  " uploaded successfully"
                );
              } else {
                toast.warn(`only ${validfile} extensions are allowed`);
              }
            }
          }
        }
      } catch (err) {
        //console.log("vdfbvfdsbvfd",err);
      }
    } else if (e.target.id === "audio") {
      try {
        if (location_pathname == "literature") {
          let mp3filesss = [];
          let mp3files = e.target.files;
          if (mp3files) {
            if (mp3files.length > 0) {
              for (let i = 0; i < mp3files.length; i++) {
                mp3filesss.push(mp3files[i]);
              }
            }
            console.log('mp3filesss-->',mp3filesss)
            SetAllFiles([...mp3filesss]);
            toast.success("Files Uploaded Succcessfully");
          }
        } else {
          let mp3files = e.target.files;
          let mp3filesss = [];
          if (mp3files) {
            //console.log("fbhfngn",mp3files);

            if (mp3files.length > 0) {
              for (let i = 0; i < mp3files.length; i++) {
                mp3filesss.push(mp3files[i]);
              }
            }
            let validatextaudio = await Promise.all(
              mp3filesss.map((file) => {
                let ext = file.name.split(".").pop();
                let extval = audioext.includes(ext);
                return extval;
              })
            );
            //console.log("dgmjfhgmfhjm",validatextaudio);
            let audiocheckfileext = validatextaudio.includes(false);
            console.log("Dgfhjnfghjmfh",audiocheckfileext);
            if (!audiocheckfileext) {
              set_mp3File(mp3filesss);
              toast.success(
                (newlocation_pathname == "layer" ? "audios" : "File") +
                " upload successfully"
              );
            } else {
              toast.warn(`only ${audioext} extensions are allowed`);
            }
          }
        }
      } catch (err) {
        console.log("fdghmnghjmgh", err);
      }
      // setaudioname(audioname)
    } else if (e.target.id == "PreviewFile") {
      try {
        let mp3files = e.target.files;
        let mp3filesss = [];
        if (mp3files) {
          //console.log("fbhfngn",mp3files);

          if (mp3files.length > 0) {
            for (let i = 0; i < mp3files.length; i++) {
              mp3filesss.push(mp3files[i]);
            }
          }
          //console.log("fghnjdghmnfhgn",mp3filesss);
          let validatextaudio = await Promise.all(
            mp3filesss.map((file) => {
              let ext = file.name.split(".").pop();
              //console.log("Gdnhjsfegnjhd",ext);
              let extval = audioext.includes(ext);
              return extval;
            })
          );
          //console.log("dgmjfhgmfhjm",validatextaudio);
          let audiocheckfileext = validatextaudio.includes(false);
          //console.log("Dgfhjnfghjmfh",audiocheckfileext);
          if (!audiocheckfileext) {
            set_PreviewFile(mp3filesss);
            toast.success("Preview uploaded successfully");
          } else {
            toast.warn(`only ${audioext} extensions are allowed`);
          }
        }
      } catch (err) {
        //console.log("fdghmnghjmgh",err);
      }
    } else if (e.target.id == "excelfile") {
      let file = e?.target?.files?.[0];
      if (file) {
        if (file.name.split(".").pop() == "xlsx") {
          let exceldata = await ExcelDataGet({
            Excel: file,
            Address: Wallet_Details.UserAccountAddr.toLowerCase(),
          });
          console.log("jdsfhsfsjkfs", exceldata);
          let error = "";
          let checknftnames = [];
          if (exceldata?.data?.excel?.length > 0) {
            // error
            exceldata.data.excel.map((data, ind) => {
              if (
                [
                  "pdf",
                  "PDF",
                  "png",
                  "PNG",
                  "jpeg",
                  "JPEG",
                  "jpg",
                  "JPG",
                  "OGV",
                  "ogv",
                  "WEBM",
                  "webm",
                  "mp4",
                  "wav",
                  "FLAC",
                  "mp3",
                  "WAV",
                  "flac",
                  "MP3",
                ].includes(data?.File?.split(".").pop())
              ) {
                if (error == "") {
                  if (["pdf", "PDF"].includes(data?.File?.split(".").pop())) {
                    if (
                      newlocation_pathname == "all" ||
                      newlocation_pathname == "book"
                    ) {
                      if (isEmpty(data?.Preview)) {
                        error = "Preview file for PDF is required";
                      } else if (
                        !["pdf", "PDF"].includes(
                          data?.Preview?.split(".").pop()
                        )
                      ) {
                        console.log("klsfujskfksjkfhs", data?.Preview);
                        error = "Preview file for PDF Must be PDF";
                      } else if (isEmpty(data?.Thumb)) {
                        error = "Thumb File is Required for pdf";
                      } else if (
                        !["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"].includes(
                          data?.Thumb?.split(".").pop()
                        )
                      ) {
                        error =
                          "Thumb file mst be: JPEG , png , PNG, jpeg , jpg , JPG";
                      } else {
                        data.Type = "book";
                      }
                    } else {
                      error = Validfile;
                    }
                  }
                  else if (["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"].includes(data?.File?.split(".").pop())) {
                    if (newlocation_pathname == "all" || newlocation_pathname == "image") {
                      data.Type = "image";
                      if (!isEmpty(data.Preview)) {
                        data.Preview = "";
                      }
                      if (!isEmpty(data.Thumb)) {
                        data.Thumb = "";
                      }
                    }
                    else {
                      error = Validfile;
                    }
                  }
                  else if (["OGV", "ogv", "WEBM", "webm", "mp4"].includes(data?.File?.split(".").pop())) {
                    if (newlocation_pathname == "all" || newlocation_pathname == "video") {
                      data.Type = "video";
                      if (!isEmpty(data.Preview)) {
                        if (
                          ["OGV", "ogv", "WEBM", "webm", "mp4"].includes(
                            data?.Preview?.split(".").pop()
                          )
                        ) {
                        } else {
                          data.Preview = "";
                        }
                      }
                      if (!isEmpty(data.Thumb)) {
                        if (["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"].includes(data?.Thumb?.split(".").pop())) {
                        }
                        else {
                          data.Thumb = "";
                        }
                      }
                    }
                    else {
                      error = Validfile;
                    }
                  } else if (["wav", "FLAC", "mp3", "WAV", "flac", "MP3"].includes(data?.File?.split(".").pop())) {
                    if (newlocation_pathname == "all" || newlocation_pathname == "audio") {
                      data.Type = "audio";
                      if (!isEmpty(data.Preview)) {
                        if (
                          ["wav", "FLAC", "mp3", "WAV", "flac", "MP3"].includes(
                            data?.Preview?.split(".").pop()
                          )
                        ) {
                        } else {
                          data.Preview = "";
                        }
                      }
                      if (!isEmpty(data.Thumb)) {
                        if (["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"].includes(data?.Thumb?.split(".").pop())) {
                        }
                        else {
                          data.Thumb = "";
                        }
                      }
                    }
                    else {
                      error = Validfile;
                    }
                  }
                  if (isEmpty(data?.NFTName)) {
                    error = "Enter NFT Name in " + (ind + 1) + "th NFT";
                  }
                  else {
                    checknftnames.push(data?.NFTName);
                  }
                  if (!isEmpty(data?.Properties)) {
                    try {
                      let instprops = JSON.parse(data?.Properties);
                      console.log("sjflkskhfsf", instprops);
                      if (!isEmpty(instprops)) {
                        let propkeys = Object.keys(instprops);
                        let propvalues = Object.values(instprops);
                        propkeys.map((currkey, currkeyind) => {
                          if (isEmpty(currkey)) {
                            error =
                              "Enter key for Properties in row number " +
                              (ind + 1);
                          }
                          else if (typeof currkey != "string") {
                            error =
                              "Key for Properties in row number " +
                              (ind + 1) +
                              " must be a String";
                          }
                          if (isEmpty(propvalues[currkeyind])) {
                            error =
                              "Enter value for Properties in row number " +
                              (ind + 1);
                          }
                          else if (typeof propvalues[currkeyind] != "string"&&typeof propvalues[currkeyind] != "number") {
                            error =
                              "Value for Properties in row number " +
                              (ind + 1) +
                              " must be a String";
                          }
                        });
                      }
                    } catch (err) {
                      console.log('error',err)
                      error = "Properties Must be valid JSON";
                    }
                  }
                }
              }
              else if (error == "") {
                error =
                  "The File for NFT Should be in: pdf,PDF,png,PNG,jpeg,JPEG,jpg,JPG,OGV,ogv,WEBM,webm,mp4,wav,FLAC,mp3,WAV,flac,MP3";
              }
            });
            if (error) {
              return toast.error(error);
            }
            else {
              const namevalidation = await BulkNFTNameValidation({ NFTNames: JSON.stringify(checknftnames) })
              console.log('dsjgdgfgjsf', namevalidation)
              if (!namevalidation?.status) {
                return toast.error(namevalidation.msg, { autoClose: false });
              }
            }
          }
          set_Excel({
            ...Excel,
            ...{ file: file, data: exceldata?.data?.excel },
          });

          toast.success("Excel File uploaded successfully");
        } else {
          toast.warn(`only excel files are allowed`);
        }
      }
    } else if (e.target.id == "properties") {
      let fileread = new FileReader();
      fileread.readAsText(e.target.files[0], "UTF-8");
      fileread.onload = function (e) {
        try {
          console.log(
            "lksjflksjfs",
            typeof e.target.result,
            e.target.result,
            e.target,
            JSON.parse(e.target.result)
          );
        } catch (e) {
          console.log("filereadloaderror", e);
        }
      };
    }
  };

  console.log(
    "kshdsjkhfsfsf",
    files,
    mp3File,
    previewfile,
    Excel,
    allfile,
    NFTProperties
  );
  const selectChange = async (e) => {
    console.log("sdgsdrh", e.value, e.name, networkoption);
    let accountDetails;
    let configdata;
    if (e.name == "networkname") {
      setCoinNames("");
      if (e.name == "networkname" && e.value == CONFIG.binance) {
        configdata = network.BSC;
        accountDetails = await switchNetwork(configdata);
        configdata = {
          ...configdata,
          ...{ Fronturl: CONFIG.Front_URL, label: e.label, value: e.value },
        };
        setconfig(configdata);
      }
      else if (e.name == "networkname" && e.value == CONFIG.etherium) {
        configdata = network.ETH;
        accountDetails = await switchNetwork(configdata);
        configdata = {
          ...configdata,
          ...{ Fronturl: CONFIG.Front_URL, label: e.label, value: e.value },
        };
        setconfig(configdata);
      }
    }

    setaccountDetailssss(accountDetails);
    console.log("sdfvsdfsdf", e);
    if (e && e.name && e.label && e.value) {
      switch (e.name) {
        case "TokenCategory":
          setTokenCategory(e);
          break;
        case "networkname":
          setNetworkID(configdata?.Chainid);
          SetNetworkName(configdata.name);
          break;
        case "userCollection": {
          if (e.value == "create") {
            setUserCollection("");
          } else {
            setUserCollection(e.label);
            if (e?.SubCategory && e?.CategoryId && e?.Category) {
              setCategorywithSubcategory({
                category: e.Category,
                subcategory: e.SubCategory,
                id: e.CategoryId,
                display: e.Category + "-" + e.SubCategory,
                disabecategory: true,
                collection: e.label,
              });
            } else if (CategorywithSubcategory?.collection == UserCollection) {
              setCategorywithSubcategory({});
            }
          }
        }

        default:
      }
    } else {
      toast.warning("please connect your wallet", toasterOption);
    }
  };

  const inputChange = (e) => {
    //console.log("dfgndgfn",CoinName);
    setValidateError({});
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      let value = e.target.value;
      const re = /^[0-9]+([.][0-9]+)?$/; //with decimal
      const re1 = /^[0-9\b]+$/; // only [0-9]

      switch (e.target.name) {
        case "TokenPrice":
          if (value !== "" && isNaN(value) === false && value > 0) {
            setTokenPrice(value);
            PriceCalculate({ price: value });
          } else {
            setValidateError(ValidateError);
            setTokenPrice("0");
            PriceCalculate({ price: 0 });
          }
          break;
        case "TokenName":
          setTokenName(value);
          break;
        case "TokenDescription":
          setTokenDescription(value);
          break;
        case "TokenRoyalities":
          setTokenRoyalities(value);
          break;
        case "TokenProperties":
          setTokenProperties(value);
          break;
        case "UnLockcontent":
          Set_UnLockcontent(value);
          break;
        case "TokenQuantity":
          Set_TokenQuantity(value);
          break;
        case "TokenProperties":
          setNFTProperties(value);
          break;
        case "ListedQuantity":
          SetListedQuantity(value);
          break;
        case "PutOnSaleQuantity":
          SetPutOnSaleQuantity(value);
          break;
        case "Quantity":
          Set_Quantity(value);
          break;

        default:
        // code block
      }
    }
  };

  const NameOnly = (name) => {
    console.log("skljshfs", name);
    if (name) {
      let instnamearr = name.split(".");
      let crtname = "";
      instnamearr.map((val, ind) => {
        if (ind + 1 < instnamearr?.length) {
          crtname = crtname + val;
        } else if (crtname == "") {
          crtname = val;
        }
      });
      return crtname;
    } else return undefined;
  };

  const GetExtension = (ImageName) => {
    try {
      let ext = ImageName.split(".");
      let extension = ext[ext.length - 1];
      return extension;
    } catch (err) {
      return ImageName;
    }
  };

  const FileCategoryCheck = (file, error, ind) => {
    let retdata = {};
    if (file?.File) {
      let foundindex = allfile?.findIndex((val) => val?.name == file?.File);
      if (foundindex > -1) {
        retdata.File = allfile[foundindex];
        // crtfile[ind] = allfile[foundindex]
      } else {
        error = file?.File + " is missing upload this file";
      }
    }
    if (file?.Preview) {
      let foundindex = allfile?.findIndex((val) => val?.name == file?.Preview);
      if (foundindex > -1) {
        retdata.Preview = allfile[foundindex];
      } else {
        error = file?.Preview + " is missing upload this file";
      }
    } else {
      retdata.Preview = undefined;
    }
    if (file?.Thumb) {
      let foundindex = allfile?.findIndex((val) => val?.name == file?.Thumb);
      if (foundindex > -1) {
        retdata.Thumb = allfile[foundindex];
      } else {
        error = file?.Thumb + " is missing upload this file";
      }
    } else {
      retdata.Thumb = undefined;
    }
    if (!isEmpty(file.Properties)) {
      let keyval = [];
      let crtproprty = JSON.parse(file.Properties);
      if (!isEmpty(crtproprty)) {
        let propkeys = Object.keys(crtproprty);
        let propvalues = Object.values(crtproprty);
        propkeys.map((currkey, currkeyind) => {
          keyval.push({
            key: currkey,
            value: propvalues[currkeyind],
          });
        });
      }
      if (keyval?.length > 0) {
        retdata.Properties = keyval;
      }
    }
    retdata.NFTName = file?.NFTName ? file?.NFTName : undefined;
    retdata.error = error;
    return retdata;
  };

  useEffect(() => {
    if (
      newlocation_pathname != "layer" &&
      !isEmpty(Excel) &&
      allfile?.length > 0
    ) {
      NftCheckAssign();
    }
  }, [Excel, allfile]);

  const NftCheckAssign = () => {
    let crtfile = [];
    let crttmbfile = [];
    let crtpreviewfile = [];
    let crtnftname = [];
    let crtproperties = [];
    console.log("dfbgszdhbsf", files, mp3File, previewfile);

    let errorfilematch = { preview: false, thumb: false };
    if (allfile?.length > 0 && newlocation_pathname != "all") {
      //              NFT merge with name

      // mp3File.map((currfile, fileind) => {
      //   console.log('sldjsklfsfsfs', currfile)
      //   if (files?.length > 0) {
      //     let checkcompare = files.findIndex((val) =>NameOnly(val?.name) == NameOnly(currfile?.name))
      //     console.log('dfdsgdsgdsgsdmapfile',checkcompare)
      //     if (checkcompare > -1) {
      //       let tmp = files[checkcompare];
      //       crttmbfile[fileind] = files[checkcompare];
      //     }
      //     else {
      //       errorfilematch.thumb = true;
      //     }
      //   }
      //   if (previewfile?.length > 0) {
      //     let checkcompare = previewfile.findIndex((val) =>NameOnly(val?.name) == NameOnly(currfile?.name))
      //     console.log('dfdsgdsgdsgsdmapprev',checkcompare)
      //     if (checkcompare > -1) {
      //       let tmp = previewfile[checkcompare];
      //       crtpreviewfile[fileind] = previewfile[checkcompare];
      //     }
      //     else {
      //       errorfilematch.preview = true;
      //     }
      //   }
      //   if(!isEmpty(currfile.Properties)){
      //     crtproperties[fileind] = c
      //   }
      // });

      //              NFT merge with name

      let error = "";
      Excel.data.map((exceldata, ind) => {
        if (error == "") {
          let upddata = FileCategoryCheck(exceldata, error, ind);
          crtfile[ind] = upddata.File;
          crttmbfile[ind] = upddata.Thumb;
          crtpreviewfile[ind] = upddata.Preview;
          error = upddata?.error;
          crtnftname[ind] = upddata.NFTName;
          crtproperties[ind] = upddata.Properties;
        }
      });
      if (error) {
        return toast.error(error, 1000);
      }
    } else if (newlocation_pathname == "all" && allfile?.length > 0) {
      let error = "";
      Excel.data.map((exceldata, ind) => {
        if (error == "") {
          let upddata = FileCategoryCheck(exceldata, error, ind);
          crtfile[ind] = upddata.File;
          crttmbfile[ind] = upddata.Thumb;
          crtpreviewfile[ind] = upddata.Preview;
          error = upddata?.error;
          crtnftname[ind] = upddata.NFTName;
          crtproperties[ind] = upddata.Properties;
        }
      });
      if (error) {
        return toast.error(error, 1000);
      }
    }
    if (errorfilematch?.preview || errorfilematch?.thumb) {
      if (errorfilematch?.preview && errorfilematch?.thumb) {
        return toast.error(
          "Files Name Must be same for thumb,preview," + newlocation_pathname
        );
      } else if (errorfilematch?.preview) {
        return toast.error(
          "Files Name Must be same for preview," + newlocation_pathname
        );
      } else if (errorfilematch?.thumb) {
        return toast.error(
          "Files Name Must be same for thumb," + newlocation_pathname
        );
      }
    } else {
      if (crttmbfile?.length > 0) {
        set_files([...crttmbfile]);
      }
      if (crtpreviewfile?.length > 0) {
        set_PreviewFile([...crtpreviewfile]);
      }
      if (crtfile?.length > 0) {
        set_mp3File([...crtfile]);
      }
      if (crtnftname?.length > 0) {
        SetNFTNames([...crtnftname]);
      }
      if (crtproperties?.length > 0) {
        setNFTProperties(crtproperties);
      }
    }
  };

  async function CreateItem() {
    if (Wallet_Details.UserAccountAddr !== "") {
      let useractive = await UserCheck({
        currAddr: Wallet_Details?.UserAccountAddr,
      });
      if (useractive?.userValue?.activate) {
      } else {
        toast.error("User banned by the admin");
        return false;
      }
      let check = await orderApprovecheck();

      //console.log("Wallet_Details.providerss", Wallet_Details);
      Set_FormSubmitUserClicked(true);
      let errors = await CreateItemValidation(true);
      let errorsSize = Object.keys(errors).length;
      if (errorsSize !== 0) {
        toast.error(
          "Form validation error. Fix all mistakes and submit again",
          toasterOption
        );
        return false;
      }
      else if (Wallet_Details.providerss) {
        if (newlocation_pathname !== "layer") {
          let crtfile = [];
          let crttmbfile = [];
          let crtpreviewfile = [];
          let crtnftname = [];
          console.log("dfbgszdhbsf", files, mp3File, previewfile);

          let errorfilematch = { preview: false, thumb: false };
          if (
            (files?.length > 0 || previewfile?.length > 0) &&
            location_pathname != "literature"
          ) {
            mp3File.map((currfile, fileind) => {
              console.log("sldjsklfsfsfs", currfile);
              if (files?.length > 0) {
                let checkcompare = files.findIndex(
                  (val) => NameOnly(val?.name) == NameOnly(currfile?.name)
                );
                console.log("dfdsgdsgdsgsdmapfile", checkcompare);
                if (checkcompare > -1) {
                  let tmp = files[checkcompare];
                  crttmbfile[fileind] = files[checkcompare];
                } else {
                  errorfilematch.thumb = true;
                }
              }
              if (previewfile?.length > 0) {
                let checkcompare = previewfile.findIndex(
                  (val) => NameOnly(val?.name) == NameOnly(currfile?.name)
                );
                console.log("dfdsgdsgdsgsdmapprev", checkcompare);
                if (checkcompare > -1) {
                  let tmp = previewfile[checkcompare];
                  crtpreviewfile[fileind] = previewfile[checkcompare];
                } else {
                  errorfilematch.preview = true;
                }
              }
            });
          } else if (location_pathname == "all" && allfile?.length > 0) {
            let error = "";
            Excel.data.map((exceldata, ind) => {
              // FileCategoryCheck(exceldata);
              if (error == "") {
                let upddata = FileCategoryCheck(exceldata, error, ind);
                crtfile[ind] = upddata.File;
                crttmbfile[ind] = upddata.Thumb;
                crtpreviewfile[ind] = upddata.Preview;
                error = upddata?.error;
                crtnftname[ind] = upddata.NFTName;
              }
            });
            if (error) {
              return toast.error(error, 1000);
            }
          }
          if (errorfilematch?.preview || errorfilematch?.thumb) {
            if (errorfilematch?.preview && errorfilematch?.thumb) {
              return toast.error(
                "Files Name Must be same for thumb,preview," +
                newlocation_pathname
              );
            }
            else if (errorfilematch?.preview) {
              return toast.error(
                "Files Name Must be same for preview," + newlocation_pathname
              );
            }
            else if (errorfilematch?.thumb) {
              return toast.error(
                "Files Name Must be same for thumb," + newlocation_pathname
              );
            }
          }
          else {
            if (crttmbfile?.length > 0) {
              set_files([...crttmbfile]);
            }
            if (crtpreviewfile?.length > 0) {
              set_PreviewFile([...crtpreviewfile]);
            }
            if (crtfile?.length > 0) {
              set_mp3File([...crtfile]);
            }
            if (crtnftname?.length > 0) {
              SetNFTNames([...crtnftname]);
            }
          }
        }
        if (config.Chainid === Wallet_Details.networkConfiguration.Chainid) {
          if (Wallet_Details.providerss == null) {
            toast.error("Please Connect to BINANCE Network", toasterOption);
          }
          else {
            if (Wallet_Details.UserAccountBal === 0) {
              toast.error("Insufficient balance", toasterOption);
              return false;
            }
            let TokenCategory_label = TokenCategory.label;
            let payload = {
              TokenName,
              TokenRoyalities,
              image: files,
              TokenCategory_label,
              PutOnSaleType,
              TokenPrice,
            };
            //console.log("payload", payload);
            const resp = await CreateTokenValidationAction(payload);
            console.log('shfsljfskf', resp)
            if (resp && resp.data) {
              //console.log("iffff in createissue");
              if (resp.data.errors) {
                let errors = resp.data.errors;
                let errorsSize = Object.keys(errors).length;
                if (errorsSize !== 0) {
                  setValidateError(errors);
                  toast.error(
                    "Form validation error. Fix all mistakes and submit again",
                    toasterOption
                  );
                }
                else {
                  //console.log("else in createissue");
                  setValidateError({});

                  if (
                    Wallet_Details.networkConfiguration.Chainid ===
                    config.Chainid
                  ) {
                    if (newlocation_pathname != "layer") {
                      setnftcreated("true");
                    }
                    // if(PutOnSale){
                    let generator = require("generate-password");
                    let web3 = new Web3(Wallet_Details.providerss);
                    if (web3 && PutOnSale) {
                      // var web3Rpc = new Web3(config.RPC_URL)
                      // console.log("qweqwewqewqewqewqqwvbv", web3Rpc);
                      let randomNum = randomInteger(10000000, 100000000);
                      let password = generator.generate({
                        length: 10,
                        numbers: true,
                      });
                      if (web3) {
                        let web3RpcPro = new Web3(web3.providers);
                        const to = Wallet_Details.UserAccountAddr;
                        const _amount =
                          TokenPrice == "" || TokenPrice == undefined
                            ? 0
                            : web3RpcPro.utils.toWei(String(TokenPrice));
                        console.log("ajhghjas", _amount);
                        const _nonce = Date.now();
                        console.log("qwewqewqreqwrqrq", password);

                        setRandomName(password);
                        let tot = _nonce + Number(randomNum);
                        setNonceHash(tot);
                        const result = web3RpcPro.utils.soliditySha3(
                          to,
                          _amount,
                          password,
                          tot
                        );
                        console.log("asdsadasfdafaf", result);
                        const signhash = await web3.eth.personal.sign(
                          result,
                          to
                        );
                        if (signhash) {
                          setSignatureHash(signhash);
                        }
                      }
                    }
                    SetPopupOpen(true);
                    window.$("#create_item_modal2").modal("show");
                  }
                  else {
                    toast.warn("CoiName is not Valid");
                  }
                }
              }
            }
          }
        }
      }
      else {
        if (config.Chainid === 0) {
          toast.error("Please select network ");
        } else {
          toast.error("Please switch network", toasterOption);
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        }
      }
    }
    else {
      toast.warning("please connect your wallet");
    }
  }

  async function CreateItemValidation(chk) {
    let approvedata = {
      curradd: Wallet_Details.UserAccountAddr,
      tradeadd: Wallet_Details.networkConfiguration.TradeContract,
    };
    let check = await orderApprovecheck(approvedata);
    setapprovecheck(check);
    if (chk) {
      let ValidateError = {};
      if (
        isEmpty(files) === true &&
        ((newlocation_pathname != "image" && newlocation_pathname != "layer") ||
          (newlocation_pathname == "layer" &&
            LayerType?.value == "Imageaudio")) &&
        newlocation_pathname != "all"
      ) {
        ValidateError.layer =
          (newlocation_pathname == "layer" ? '"audio"' : '"Thumb Image"') +
          " is required";
      }
      if (
        isEmpty(mp3File) === true &&
        (newlocation_pathname != "layer" ||
          (newlocation_pathname == "layer" && LayerType?.value != "Image")) &&
        newlocation_pathname != "all"
      ) {
        ValidateError.photo = '"File" is required';
      }
      if (newlocation_pathname == "book" && previewfile?.length == 0) {
        ValidateError.Preview = "Preview File Required";
      }
      if (newlocation_pathname == "all" && allfile?.length == 0) {
        ValidateError.photo = '"File" is required';
      }
      if (
        Number(TokenQuantity) !== Number(mp3File.length) &&
        newlocation_pathname === "layer" &&
        LayerType.value !== "Image" &&
        newlocation_pathname != "all"
      ) {
        ValidateError.TokenQuantity = `length of audio file is ${mp3File.length} only ${mp3File.length}NFT is allowed `;
      }
      if (TokenQuantity > NFTProbability && newlocation_pathname == "layer") {
        ValidateError.TokenQuantity = `only ${NFTProbability} NFTs can be created "upload more traits for more NFTs"`;
      }
      if (
        newlocation_pathname !== "layer" &&
        newlocation_pathname !== "image" &&
        newlocation_pathname != "all"
      ) {
        if (
          files.length !== mp3File.length &&
          !ValidateError.photo &&
          !ValidateError.layer
        ) {
          ValidateError.layer =
            "Thump Image and " +
            FirstCaps(newlocation_pathname) +
            " File Count Must be Same";
          ValidateError.photo =
            "Thump Image and " +
            FirstCaps(newlocation_pathname) +
            " File Count Must be Same";
        } else if (
          files.length == mp3File.length &&
          !ValidateError.photo &&
          !ValidateError.layer
        ) {
        }
      }
      if (config.Chainid === 0) {
        ValidateError.network = '"network" is not allowed to be empty';
      }
      if (CoinName === "" && PutOnSaleType !== "UnLimitedAuction") {
        ValidateError.TokenPrice = 'Currency or Token" must be required';
      }
      if (TokenName === "") {
        ValidateError.TokenName = '"Name" is not allowed to be empty';
      }
      if (TokenName !== "") {
        if (CONFIG.nameFormat.test(TokenName)) {
          ValidateError.TokenName = '"Emoji" is not allowed';
        }
      }
      if (TokenRoyalities === "") {
        ValidateError.TokenRoyalities =
          '"Royalties" is not allowed to be empty';
      } else if (isNaN(TokenRoyalities) === true) {
        ValidateError.TokenRoyalities = '"Royalties" must be a number';
      } else if (TokenRoyalities > 20) {
        ValidateError.TokenRoyalities =
          '"Royalties" must be less than or equal to 20';
      }
      console.log("kjskfjsfs", UserCollection, isEmpty(UserCollection));
      if (isEmpty(UserCollection)) {
        ValidateError.userCollection = "Collection is not allowed to be Empty";
      }
      if (isEmpty(CategorywithSubcategory)) {
        ValidateError.TokenCategory = '"Category" is required';
      }

      if (Unlockoncepurchased === true && UnLockcontent === "") {
        ValidateError.UnLockcontent = '"Locked content" is required';
      }

      if (ListNFT) {
        if (isEmpty(ListedQuantity)) {
          ValidateError.ListedQuantity = "List Quantity must be greater than 0";
        } else if (Number(ListedQuantity) % 1 !== 0) {
          ValidateError.ListedQuantity = "List Quantity must be Whole Number";
        } else if (Number(ListedQuantity) > Quantity) {
          ValidateError.ListedQuantity =
            "List Quantity must be less than " + Quantity;
        }
      }
      if (PutOnSale === true && PutOnSaleType === "FixedPrice") {
        if (
          TokenPrice === "" ||
          (isNaN(TokenPrice) === true && Number(TokenPrice) === 0)
        ) {
          ValidateError.TokenPrice = '"Price" must be a number';
        }
        else if (Number(TokenPrice) == 0 && !admincheck) {
          ValidateError.TokenPrice = '"Price" must be greater than zero';
        } else if (CoinName === "") {
          ValidateError.TokenPrice = 'Currency or Token" must be required';
        }
        if (YouWillGet === 0) {
          ValidateError.TokenPrice = '"Price" must be Greater than 0';
        }
        if (Number(ListedQuantity) < 1) {
          ValidateError.ListedQuantity = "Quantity must be Greater than 0";
        }
      }
      if (PutOnSale) {
        if (isEmpty(PutOnSaleQuantity)) {
          ValidateError.PutOnSaleQuantity = "Quantity is Required";
        } else if (Number(PutOnSaleQuantity) > Number(Quantity)) {
          ValidateError.PutOnSaleQuantity = "Quantity must be less than Copies";
        } else if (Number(PutOnSaleQuantity) > Number(ListedQuantity)) {
          ValidateError.PutOnSaleQuantity =
            "Quantity must be less than Listed Quantity";
        } else if (Number(PutOnSaleQuantity) % 1 !== 0) {
          ValidateError.PutOnSaleQuantity = "Quantity must be whole number";
        }
      }
      if (TokenQuantity % 1 != 0) {
        ValidateError.TokenQuantity = "Quantity must be whole number";
      }
      if (TokenQuantity == "" || (isNaN(TokenQuantity) == true && TokenQuantity == 0)) {
        ValidateError.TokenQuantity = '"Number of copies" must be a number';
      }
      if (Quantity % 1 != 0) {
        ValidateError.Quantity = "Quantity must be whole number";
      }
      if (Quantity == "" || (isNaN(Quantity) == true && Quantity == 0)) {
        ValidateError.Quantity = '"Number of copies" must be a number';
      }
      if (!agree?.original) {
        ValidateError.original = "This field is required";
      }
      if (!agree?.termsandcondition) {
        ValidateError.termsandcondition = "This field is required";
      }
      if (socialLinks?.length > 0) {
        socialLinks.map((val, ind) => {
          if (isEmpty(val.url)) {
            ValidateError["url" + ind] = "Url cannot be Empty";
          }
          if (isEmpty(val.urlfor)) {
            ValidateError["urlfor" + ind] = "Url cannot be Empty";
          }
        });
      }
      if (WhiteListUser?.length > 0) {
        let whitelistquantity = 0;
        let errorquantityindex = [];
        WhiteListUser.map((val, ind) => {
          if (isEmpty(val?.Address)) {
            ValidateError["whitelistaddress" + ind] = "Wallet Address cannot be Empty";
          } else {
            try {
              Web3Utils.toChecksumAddress(val?.Address);
            } catch {
              ValidateError["whitelistaddress" + ind] = "Enter Valid Wallet Address";
            }
          }
          if (location_pathname == "bulkmint") {
            if (isEmpty(val?.Quantity)) {
              ValidateError["whitelistquantity" + ind] = "Enter Quantity"
            }
            else if (NumberChange(val?.Quantity) <= 0) {
              ValidateError["whitelistquantity" + ind] = "Quantity Must be Greater Than '0'"
            }
            else if (NumberChange(val?.Quantity) % 1 != 0) {
              ValidateError["whitelistquantity" + ind] = "Quantity Must be a Number not a Decimal"
            }
            else {
              if ((whitelistquantity + NumberChange(val?.Quantity)) > NumberChange(Quantity)) {
                errorquantityindex.push(ind);
              }
              else {
                whitelistquantity = whitelistquantity + NumberChange(val?.Quantity);
              }
            }
          }
        });
        if (errorquantityindex?.length > 0) {
          errorquantityindex.map((val) => {
            if (whitelistquantity < Quantity) {
              ValidateError["whitelistquantity" + val] = "Quantity Must be less than " + (NumberChange(Quantity) - whitelistquantity)
            }
            else {
              ValidateError["whitelistquantity" + val] = "No Quantity Available"
            }
          })
        }
      }
      setValidateError(ValidateError);
      return ValidateError;
    }
    else {
      return {};
    }
  }

  async function createnfts() {
    try {
      setnftcreated("processs");
      let data = {
        files,
        curradd: Wallet_Details.UserAccountAddr,
        TokenQuantity: TokenQuantity,
      };
      let multipleImage = await createNfts(data);
      if (multipleImage?.data?.data?.path != "") {
        SetMultipleImagePath(multipleImage.data.data.path.split("/public/")[1]);
        setnftcreated("true");
      }
    }
    catch (err) {
      setnftcreated("");
    }
  }

  const IpfsAndMint = async () => {
    try {
      setipfsdone("processs");
      let senddata;
      let ipfsdata;
      if (newlocation_pathname == "layer") {
        senddata = {
          name: TokenName,
          Image: mp3File,
          desc:
            TokenDescription != ""
              ? TokenDescription
              : "This NFT Token From " + config.Fronturl,
          Creator: Wallet_Details.UserAccountAddr.toLowerCase(),
          TokenQuantity: TokenQuantity,
          from: location_pathname == "literature" ? "preview" : "bulk",
        };
        if (NFTProperties?.length > 0) {
          senddata.Properties = NFTProperties;
        }
        ipfsdata = await ipfsImageHashGetBulk(senddata);
      } else {
        senddata = {
          name: TokenName,
          File: mp3File,
          desc:
            TokenDescription != ""
              ? TokenDescription
              : "This NFT Token From " + config.Fronturl,
          Creator: Wallet_Details.UserAccountAddr.toLowerCase(),
          TokenQuantity: TokenQuantity,
          from: location_pathname == "literature" ? "preview" : "bulk",
        };
        if (files?.length > 0) {
          senddata.Thumbfile = files;
        }
        if (previewfile?.length > 0) {
          senddata.PreviewFile = previewfile;
        }
        if (nftnames?.length > 0) {
          senddata.name = nftnames;
        }
        if (NFTProperties?.length > 0) {
          senddata.Properties = NFTProperties;
        }
        ipfsdata = await IPFSUploadFunc(senddata);
      }

      console.log("ipfsdata", ipfsdata);

      let ipfs = ipfsdata.data.ipfsfinal;
      let nullcheck = ipfs.map((item) => {
        console.log("lskflskflsf", item);
        if (item === null) {
          return true;
        } else {
          return false;
        }
      });
      let check = nullcheck.includes(true);
      let aerrcheck = ipfs.includes(null);
      if (check) {
        setipfsdone("try");
      } else {
        toast.success("IPFS Uploaded", { autoClose: 1000 });
        setipfsArray(ipfsdata.data.ipfsfinal);
        setipfsdone("true");
      }
    } catch (err) {
      setipfsdone("");

      console.log("IPFSMinst_error",err);
    }
  };

  var nftids = [];

  async function Mintcallfun(mintinc) {
    let mintCall = null;
    let receipt = null;
    let handle = null;

    try {
      setmintdone("processs");
      let mintindex = mintinc != undefined ? mintinc : 0;
      let ipfshashh = [];
      let supply1 = [];
      let royal1 = [];
      let sub1 = [];
      let val1 = [];
      let price1 = [];

      let size = 50;
      // if(ipfshashh.length > mintindex)
      // {
      let web3 = new Web3(Wallet_Details.providerss);

      //console.log("dfbsfdbsfdgb",web3);
      if (Wallet_Details.UserAccountAddr === "") {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
      let gas;
      await web3.eth.getGasPrice().then(async (result) => {
        gas = result;
      });

      console.log('ipfsArray-->',ipfsArray,mintindex)
      let CoursetroContract = null;
      let contractCall = null;
      let mintingfees = null;
      let ipfsurls = ipfsArray?.[mintindex]?.ipfsmetadata;
      let originalImage = ipfsArray?.[mintindex]?.image;
      let ipfsHashmp3 = ipfsArray?.[mintindex]?.ipfsval;
      let ipfsmetahash = `${CONFIG.IPFS_IMG}/${ipfsurls}`;
      let TokenPriceInStr = window.web3.toWei(TokenPrice).toString();
      
      CoursetroContract = new web3.eth.Contract(
        Market,
        Wallet_Details.networkConfiguration.TradeContract
      );
      //console.log("dfbdfbdfb",ipfsArray.length);
      let supply = new Array(ipfsArray.length).fill(Quantity);
      let royal = new Array(ipfsArray.length).fill(
        window.web3.toWei(TokenRoyalities).toString()
      );
      let price = new Array(ipfsArray.length).fill(TokenPriceInStr);
      let sub = new Array(ipfsArray.length).fill(TokenPriceInStr);
      let val = new Array(ipfsArray.length).fill(1);

      //console.log("dfbsdfbsfcgbnsf",supply);
      if (ipfsArray?.length > 0) {
        for (let i = 0, j = ipfsArray.length; i < j; i += size) {
          ipfshashh.push(ipfsArray.slice(i, i + size));
          supply1.push(supply.slice(i, i + size));
          royal1.push(royal.slice(i, i + size));
          price1.push(price.slice(i, i + size));
          val1.push(sub.slice(i, i + size));
          sub1.push(val.slice(i, i + size));
        }
      }

      if (ipfshashh?.length > mintindex) {
        try {

          let ipfsarr = [];
          let currlistedqty = [];
          ipfshashh[mintindex].map((val) => {
            ipfsarr.push(`${CONFIG.IPFS_IMG}/${val?.ipfsmetadata}`);
            currlistedqty.push(Number(ListedQuantity));
          });
          let CurrServiceFee = await GetServiceFee(location_pathname == "literature" ? (newlocation_pathname == 'all' ? 'default' : newlocation_pathname) : 'default', CoursetroContract)
          mintingfees = CurrServiceFee?.Minting_fee;
          contractCall = await CoursetroContract.methods.batchMinting(
            ipfsarr,
            Wallet_Details.UserAccountAddr,
            supply1[mintindex],
            currlistedqty,
            [
              window.web3.toWei(TokenRoyalities).toString(),
              supply1[mintindex].length,
              CollectibleType,
              CurrServiceFee?.key,
            ],
            ListNFT
          );
        }
        catch (err) {
          setMintCallStatus("try");
          setmintdone("try");
          toast.error("Try Again", toasterOption);
          console.log("batchMinting_error", err);
        }

        try {
          
          setMintCallStatus("processing");
          if (contractCall != null) {
            let gasPrice = await web3.eth.getGasPrice();
            let encoded = contractCall.encodeABI()
            let gasdata = await web3.eth.estimateGas({
              from: Wallet_Details.UserAccountAddr,
              value: Number(mintingfees) * supply1[mintindex].length,
              to: Wallet_Details.networkConfiguration.TradeContract,
              data: encoded,
            });
            await contractCall
              .send({
                from: Wallet_Details.UserAccountAddr,
                value:
                  Number(mintingfees) *
                  supply1[mintindex].length,
                gasLimit: parseInt(gasdata * 1.5, 10),
                gasPrice: gasPrice
              })
              .on("transactionHash", (transactionHash) => {
                console.log("XGFnbfgnfxdgn1111111", transactionHash)
                mintCall = transactionHash;
                if (mintCall) {
                  handle = setInterval(async () => {
                    receipt = await getReceipt(web3, transactionHash);
                    console.log("XGFnbfgnfxdgn", receipt);
                    clr1(mintindex);
                  }, 8000);
                }
              });
          }
        }
        catch (e) {
          console.log("contractCall_error", e);
          toast.error("Try Again", toasterOption);
          setMintCallStatus("tryagain");
          setmintdone("try");
        }
      } else {
        SetNFTIds(nftids);
        setmintdone("true");
        window.$("#create_item_modal2").modal("hide");
        toast.success("Minted Successfully");
        navigate("/my-items");
      }
      async function clr1(mintindex) {
        try {
          let tokenhex = [];
          let ipfshsah1 = ipfshashh[mintindex];
          if (receipt != null) {
            clearInterval(handle);
            if (receipt?.logs != undefined) {
              let Loglength = receipt.logs.length;
              let web3 = new Web3(Wallet_Details.providerss);
              let tokenname = [];
              if (ListNFT && ListedQuantity != Quantity) {
                for (let i = 2; i < Loglength; i += 3) {
                  let tokenid = receipt.logs[i].topics[2];
                  let someString = web3.utils.hexToNumber(tokenid);
                  let tokendata = {
                    tokenCounts: someString,
                  };
                  tokenhex.push(tokendata);
                  if (tokendata) {
                    nftids.push(someString);
                  }
                }
              }
              else {
                for (let i = 1; i < Loglength; i += 2) {
                  let tokenid = receipt.logs[i].topics[2];
                  let someString = web3.utils.hexToNumber(tokenid);
                  let tokendata = {
                    tokenCounts: someString,
                  };
                  tokenhex.push(tokendata);
                  if (tokendata) {
                    nftids.push(someString);
                  }
                }
              }
            }
            let finalarrofdata = tokenhex.map((obj, index) =>
              Object.assign({}, obj, ipfshsah1[index])
            );
            if (receipt.status === true && finalarrofdata) {
              let Status = "true";
              let TokenAddItemPayload = {
                Name: TokenName,
                Description: TokenDescription,
                Price: TokenPrice,
                Royalities: TokenRoyalities,
                Category_label: CategorywithSubcategory.category,
                Bid: TokenBid,
                tokenProperty: TokenProperties,
                Owner: Wallet_Details.UserAccountAddr,
                Creator: Wallet_Details.UserAccountAddr,
                CategoryId: CategorywithSubcategory.id,
                Quantity: Quantity,
                Balance: Quantity,
                // ContractAddress:Wallet_Details.networkConfiguration.TradeContract,
                ContractAddress: location_pathname == 'literature' ? Wallet_Details?.networkConfiguration?.literatureContract : Wallet_Details.networkConfiguration.multipleContract,
                Status: Status,
                HashValue: mintCall,
                Type: CollectibleType,
                MinimumBid: 0,
                Clocktime: "",
                EndClocktime: "",
                UnLockcontent: "",
                PutOnSale: PutOnSale,
                PutOnSaleType: PutOnSaleType,
                CoinName: CoinName,
                additionalImage: additionalImage,
                collection: UserCollection,
                SelectedNetwork: Wallet_Details?.networkConfiguration?.Chainid,
                decimal: decimal,
                Subcategory: CategorywithSubcategory.subcategory,
                ipfsarry: JSON.stringify(finalarrofdata),
                TermsandConditions: agree.termsandcondition,
                Randomname: Randomname,
                _nonce: _nonce,
                signature: signature,
                PutOnSaleQuantity: PutOnSaleQuantity,
                ListNFT: ListNFT,
                ListedQuantity: ListNFT ? ListedQuantity : 0,
                SocialLinks: JSON.stringify(socialLinks),
                SelectedNetworkName: Wallet_Details?.networkConfiguration?.label,
              };
              if (location_pathname == "literature") {
                TokenAddItemPayload.ServiceType = newlocation_pathname == "all" ? "default" : newlocation_pathname
              }

              if (WhiteListUser?.length > 0) {
                TokenAddItemPayload.WhiteListUser = JSON.stringify(WhiteListUser);
              }
              if (NFTProperties?.length > 0) {
                TokenAddItemPayload.Properties = JSON.stringify(NFTProperties);
              }
              console.log("dfnfrngjtygmn", TokenAddItemPayload);

              let resp = await TokenAddItemAction1(TokenAddItemPayload);
              //console.log('tokkkkkeeee',resp)

              if (resp) {
                let incr = mintindex + 1;
                Mintcallfun(incr);
              }
            }
          }
        } catch (err) {
          console.log("clr1_error", err);
          setmintdone("try");
        }
      }
    } catch (err) {
      console.log("Minting_error", err);
      setmintdone("try");
    }
  }

  const ChangeLayer = (e) => {
    console.log("kjsfffsfsfs", e);
    SetLayerType(e);
  };

  useEffect(() => {
    if (mp3File?.length > 0 && newlocation_pathname !== "layer") {
      Set_TokenQuantity(mp3File.length);
    }
  }, [mp3File]);

  const ChangeView = (val) => {
    SetViewMode(val);
  };

  const Oncancel = (from) => {
    if (from === "fileInputControl") {
      if (files?.length > 0) {
        set_files([]);
        toast.success("Files Removed Successfully", 1000);
      }
    } else if (from === "audio") {
      if (mp3File?.length > 0) {
        set_mp3File([]);
        toast.success("Files Removed Successfully", 1000);
      }
    } else if (from === "PreviewFile") {
      if (previewfile?.length > 0) {
        set_PreviewFile([]);
        toast.success("Preview Files Removed Successfully", 1000);
      }
    } else if (from == "excel") {
      if (Excel?.file?.length > 0) {
        set_Excel({ file: {}, data: [] });
        toast.success("Excel File Removed Successfully", 1000);
      }
    } else if (from == "all") {
      if (allfile?.length > 0) {
        SetAllFiles([]);
        toast.success("Files Removed Successfully", 1000);
      }
    }
  };

  const FirstCaps = (data) => {
    return data[0].toLocaleUpperCase() + data.slice(1);
  };

  const GetRequiredLayers = (e) => {
    if (!isNaN(e.target.value)) {
      SetRequiredNFT(e.target.value);
      if (Number(e.target.value) >= 2) {
        let num = Number(e.target.value);
        let guessnum = 2;
        let count = 0;
        let total = 0;
        let arr = [];
        let prime = true;
        let currnum = num;
        let quo = 0;
        let folder = 2;

        if (num < 16) {
          quo = Math.round(num / folder);
        } else {
          folder = 4;
          quo = Math.round(num / folder);
        }

        Loop(quo);

        function Loop(check) {
          console.log("checkcccccc", check, folder, quo, num);
          if (check ** folder > num) {
            Loop(check - 1);
          } else {
            quo = num > 2 ? check + 1 : check;
            return true;
          }
        }

        // let rem = Number(e.target.value)%2;
        console.log("lkslfkslks", quo, Math.round(quo));
        SetFileProbability(folder + " Folders Each of " + quo + " Layers");
      } else {
        if (!isEmpty(FileProbability)) {
          SetFileProbability("");
        }
      }
    }
  };

  const Viewfunction = () => {
    console.log('ooooooo-->',newlocation_pathname,files.length,mp3File.length,files,mp3File)
    if (newlocation_pathname !== "layer" && newlocation_pathname !== "image") {
      if (files.length !== mp3File.length) {
        toast.error("Thump Image and " + FirstCaps(newlocation_pathname) + " File Count Must be Same", 1000);
      }
      else if (files.length == 0 && mp3File.length == 0) {
        if (location_pathname == 'literature') {
          toast.error("Upload All File to See Preview", 1000);
        }
        else {
          toast.error("Upload Thump Image and " + FirstCaps(newlocation_pathname) + " File to See Preview", 1000);
        }

      } else {
        SetViewMode(true);
      }
    } else if (mp3File.length == 0 && newlocation_pathname == "image") {
      toast.error("Upload Images to See Preview", 1000);
    } else {
      SetViewMode(true);
    }
  };

  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name === "putonsale") {
        if (PutOnSale === false) {
          setPutOnSale(true);
          SetPutOnSaleQuantity(1);
          if (PutOnSaleType === "UnLimitedAuction") {
            setPutOnSaleType("FixedPrice");
          }
        }
        else {
          setPutOnSale(false);
          setPutOnSaleType("UnLimitedAuction");
          setTokenBid(true);
          SetPutOnSaleQuantity(0);
          setTokenPrice(0);
          setCoinNames("");
        }
      }
      else if (e.target.name == "ListNFT") {
        if (ListNFT === false) {
          SetListNFT(true);
          setPutOnSale(true);
          SetListedQuantity(1);
          SetPutOnSaleQuantity(1);
          if (PutOnSaleType == "UnLimitedAuction") {
            setPutOnSaleType("FixedPrice");
          }
        }
        else {
          SetListNFT(false);
          setPutOnSale(false);
          setPutOnSaleType("UnLimitedAuction");
          setTokenBid(true);
          SetPutOnSaleQuantity(0);
          setTokenPrice(0);
          setCoinNames("");
          SetListedQuantity(0);
        }
      }
    }
  };

  const AddField = (type) => {
    if (type == "whitelist") {
      if (WhiteListUser.length < WhiteListOptions?.length) {
        SetWhiteListUser([...WhiteListUser, ...[{ Address: '', Quantity: '' }]]);
      }
      else {
        toast.error("All Whitelist User Added", 1000);
      }
    } else if (type == "properties") {
      if (NFTProperties.length < 10) {
        setNFTProperties([...NFTProperties, ...[{ key: "", value: "" }]]);
      } else {
        toast.error("Already added 10 Properties", 1000);
      }
    } else {
      if (filterRows.length < 10) {
        setFilterRows([...filterRows, { filters: "" }]);
        SetSocialLinks([...socialLinks, { url: "" }]);
      } else {
        toast.error("Already added 10 links", 1000);
      }
    }
  };

  const RemoveField = (index, from) => {
    if (from == "whitelist") {
      SetWhiteListUser([
        ...WhiteListUser.slice(0, index),
        ...WhiteListUser.slice(index + 1, WhiteListUser?.length),
      ]);
    } else if (from == "properties") {
      setNFTProperties([
        ...NFTProperties.slice(0, index),
        ...NFTProperties.slice(index + 1, NFTProperties?.length),
      ]);
    } else {
      const list = [...filterRows];
      let currlink = socialLinks;
      list.splice(index, 1);
      currlink.splice(index, 1);
      setFilterRows(list);
      SetSocialLinks([...currlink]);
    }
  };

  const OnChange = (e, index, id) => {
    console.log("jdgdlgldgd", e, index);
    let currlinks = socialLinks;
    setValidateError({});
    if (e?.target?.id) {
      const { id, value, name, } = e.target;
      if (id == "Address") {
        SetWhiteListUser([
          ...WhiteListUser.slice(0, index),
          ...[
            {
              ...WhiteListUser?.[index],
              [name]: (name == 'Quantity' ? NumberOnly(value) : value),
            }
          ],
          ...WhiteListUser.slice(index + 1, WhiteListUser.length),
        ]);
      } else if (id == "key" || id == "value") {
        setNFTProperties([
          ...NFTProperties.slice(0, index),
          ...[
            {
              ...NFTProperties[index],
              ...{
                [id]: value,
              },
            },
          ],
          ...NFTProperties.slice(index + 1, NFTProperties.length),
        ]);
      } else {
        SetSocialLinks([
          ...socialLinks.slice(0, index),
          ...[
            {
              ...socialLinks[index],
              ...{
                [id]: value,
              },
            },
          ],
          ...socialLinks.slice(index + 1, socialLinks.length),
        ]);
      }
    }
    else if (e?.value) {
      if (id) {
        SetWhiteListUser([
          ...WhiteListUser.slice(0, index),
          ...[
            {
              ...WhiteListUser?.[index],
              [id]: (e.value),
            }
          ],
          ...WhiteListUser.slice(index + 1, WhiteListUser.length),
        ]);
      }
      else {
        const { classname, label, value } = e;

        // filterRows.map((val,ind)=>{
        //   if(ind == index){
        //     currlinks[ind].urlfor = value;
        //     currlinks[ind].classname = classname;
        //   }

        // })
        SetSocialLinks([
          ...socialLinks.slice(0, index),
          ...[
            {
              ...socialLinks[index],
              ...{
                urlfor: value,
                classname: classname,
              },
            },
          ],
          ...socialLinks.slice(index + 1, socialLinks.length),
        ]);
      }
    }
    console.log("kdlkkgdlkgd", currlinks);
    // SetSocialLinks([...currlinks])
  };

  const LazyMintFunc = async () => {
    setLazymintdone("processs");
    let senddata = {
      Name: TokenName,
      File: mp3File,
      Description:
        TokenDescription != ""
          ? TokenDescription
          : "This NFT Token From " + config.Fronturl,
      Creator: Wallet_Details.UserAccountAddr.toLowerCase(),
      TokenQuantity: TokenQuantity,
      Thumbfile:
        newlocation_pathname != "layer" && newlocation_pathname !== "image"
          ? files
          : "",
      type: newlocation_pathname,
      Price: TokenPrice,
      Royalities: TokenRoyalities,
      Category_label: CategorywithSubcategory.category,
      Bid: TokenBid,
      tokenProperty: TokenProperties,
      Owner: Wallet_Details.UserAccountAddr,
      CategoryId: CategorywithSubcategory.id,
      Quantity: Quantity,
      Balance: Quantity,
      ContractAddress: location_pathname == 'literature' ? Wallet_Details?.networkConfiguration?.literatureContract : Wallet_Details.networkConfiguration.multipleContract,
      Status: "true",
      HashValue: "notminted",
      Type: CollectibleType,
      MinimumBid: 0,
      Clocktime: "",
      EndClocktime: "",
      UnLockcontent: "",
      PutOnSale: PutOnSale,
      PutOnSaleType: PutOnSaleType,
      CoinName: CoinName,
      additionalImage: additionalImage,
      collection: UserCollection,
      SelectedNetwork: NetworkID,
      decimal: decimal,
      Subcategory: CategorywithSubcategory.subcategory,
      TermsandConditions: agree.termsandcondition,
      Randomname: Randomname,
      _nonce: _nonce,
      signature: signature,
      PutOnSaleQuantity: PutOnSaleQuantity,
      ListNFT: ListNFT,
      ListedQuantity: ListNFT ? ListedQuantity : 0,
      SocialLinks: JSON.stringify(socialLinks),
      TokenQuantity: TokenQuantity,
      SelectedNetworkName: NetworkName,
      PreviewFile: previewfile?.length > 0 ? previewfile : undefined,
      from: location_pathname == "literature" ? "preview" : "bulk",
      NFTNames: nftnames?.length > 0 ? JSON.stringify(nftnames) : [],
    };
    if (WhiteListUser?.length > 0) {
      senddata.WhiteListUser = JSON.stringify(WhiteListUser);
    }
    if (NFTProperties?.length > 0) {
      senddata.Properties = JSON.stringify(NFTProperties);
    }
    if (location_pathname == "literature") {
      senddata.ServiceType = newlocation_pathname == "all" ? "default" : newlocation_pathname
    }
    let Resp = await LazyMinting(senddata);
    if (Resp?.data?.success == "success") {
      toast.success(Resp?.data?.msg, 1000);
      setLazymintdone("true");
      window.$("#create_item_modal2").modal("hide");
      navigate("/my-items");
      // setLazymintdone('try')
    }
    else {
      toast.error(Resp?.data?.msg, 1000);
      setLazymintdone("try");
    }
    console.log("ksjlgljfgs", senddata);
  };

  useEffect(() => {
    if (choosencollection?._id) {
      selectChange(choosencollection);
    }
  }, [CollectionList]);

  const GetAdmin = async () => {
    if (!isEmpty(Wallet_Details?.UserAccountAddr && Wallet_Details.networkConfiguration.TradeContract && Wallet_Details.providerss)) {
      let web3 = new Web3(Wallet_Details.providerss);
      let CoursetroContract = new web3.eth.Contract(
        Market,
        Wallet_Details.networkConfiguration.TradeContract
      );
      try {
        let controwner = await CoursetroContract.methods.owner().call();
        console.log("ksjdkjsds", controwner);
        if (
          Wallet_Details?.UserAccountAddr.toLowerCase() ==
          controwner.toLowerCase()
        ) {
          SetAdmincheck(true);
        } else {
          SetAdmincheck(false);
        }
      } catch (err) {
        console.log("GetAdmin_error", err);
      }
    }
  };

  return (
    <div className="inner_header">
      <Header/>
      {console.log(
        "ldkgldkkgdkg",
        NFTProbability,
        MultipleImagePath,
        mp3File,
        files
      )}
      <ScrollToTopOnMount />
      {ViewMode ? (
        <ViewBulkFiles
          Files={mp3File}
          ThumbFiles={
            newlocation_pathname === "layer" ? MultipleImagePath : files
          }
          ChangeView={ChangeView}
          TokenQuantity={TokenQuantity}
          Pathname={newlocation_pathname}
          Layertype={LayerType?.value}
          Popup={PopupOpen}
          PreviewFile={previewfile}
          NFTNames={nftnames}
        />
      ) : (
        <div className={"inner_pageheader"}>
          <div className={"container"}>
            <AddCollectionRef
              ref={CreateCollectionForwardRef}
              GetCollection={getCollection}
              SetCurrCollection={SetChoosenCollection}
            />
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center">
                  <Link
                    to={
                      location_pathname == "bulkmint"
                        ? "/create-polymultiple"
                        : "/create-mintliterature"
                    }
                  >
                    <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                  </Link>
                  <h3 className="section-head mb-0 sec_he_sm">
                    Create {FirstCaps(location.pathname.split("/")[2])} NFTs
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
          <div className="container mt-5">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <form className="formCls">
                  {newlocation_pathname == "layer" && (
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="primary_label" htmlFor="name">
                          NFT Need To be Minted
                        </label>
                        <input
                          type="text"
                          className="form-control primary_inp"
                          name="MintQuantity"
                          id="MintQuantity"
                          onChange={(e) => GetRequiredLayers(e)}
                          placeholder="e.g. 10"
                          value={RequiredNFT || ""}
                        />
                      </div>
                      {console.log("lsflkslkfls", FileProbability)}
                      <div className="form-group col-md-6">
                        <label className="primary_label" htmlFor="desccription">
                          Layers Needed (approximately)
                        </label>
                        <textarea
                          type="text"
                          className="form-control primary_inp"
                          disabled={true}
                          placeholder=""
                          autoComplete="off"
                          value={FileProbability ? FileProbability : ""}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  <div className="form-row">
                    {newlocation_pathname !== "layer" && (
                      <div className="form-group col-md-12">
                        <div className="d-flex justify-content-between align-items-start">
                          <div>
                            <label
                              className="primary_label"
                              htmlFor="inputEmail4"
                            >
                              View
                            </label>
                            <p className="form_note">
                              Click here to View the Files Uploaded
                            </p>
                          </div>
                          <div
                            className="file_btn btn primary_btn"
                            onClick={() => Viewfunction()}
                          >
                            View
                          </div>
                        </div>
                      </div>
                    )}

                    {(newlocation_pathname != "image" ||
                      (newlocation_pathname == "image" &&
                        location_pathname == "literature")) && (
                        <div className="form-group col-md-12">
                          <div className="d-flex justify-content-between align-items-start flex-wrap ffff">
                            <div>
                              <label
                                className="primary_label"
                                htmlFor="inputEmail4"
                              >
                                {newlocation_pathname == "layer"
                                  ? "Upload layer"
                                  : location_pathname == "literature"
                                    ? "Upload Excel File"
                                    : "Upload Thumb File"}
                              </label>
                              <p className="form_note resform_note">
                                {newlocation_pathname == "layer"
                                  ? "The folder should contain at least two files.Note you can preview the uploaded files by clicking on the uploaded folder."
                                  : location_pathname == "literature"
                                    ? "Upload Excel File Which Contain NFT Info"
                                    : "As per metadata standard, while uploading " +
                                    newlocation_pathname +
                                    " file please add a image file too. Based no. of " + newlocation_pathname + " file uploaded, same quantity of image file also need to be uploaded"}
                              </p>
                              {newlocation_pathname != "layer" && (
                                <>
                                  {location_pathname != "literature" ? (
                                    <>
                                      <p className="form_note resform_note">
                                        Example: If you add one{" "}
                                        {newlocation_pathname} file simultaneously
                                        add one image file too
                                      </p>
                                      <p className="form_note resform_note">
                                        If you add ten {newlocation_pathname} file
                                        simultaneously add ten image file too
                                      </p>
                                      <p className="form_note resform_note">
                                        Thumb image name and {newlocation_pathname} file name must be same. e.x.(filename-test.{newlocation_pathname == 'audio' ? "mp3" : "mp4"},test.jpeg)
                                      </p>
                                    </>
                                  ) : newlocation_pathname == "image" ? (
                                    <p className="form_note resform_note">
                                      Example: The Excel Must Contain NFTName ,
                                      File-NFT Image Type-image{" "}
                                    </p>
                                  ) : newlocation_pathname.includes("video") ? (
                                    <p className="form_note resform_note">
                                      Example: The Excel Must Contain NFTName ,
                                      File-NFT Video , Preview- Preview Of the
                                      uploading nft, Type- video , Thumb- if not
                                      Provided default image will be Uploaded in
                                      Thumb, Properties- About the Uploading
                                      NFTs(Optional) e.x.-{`[{"colour":"red"}]`}
                                    </p>
                                  ) : newlocation_pathname.includes("audio") ? (
                                    <p className="form_note resform_note">
                                      Example: The Excel Must Contain NFTName ,
                                      File-NFT Audio , Preview- Preview Of the
                                      uploading nft, Type-audio, Thumb- if not
                                      Provided default image will be Uploaded in
                                      Thumb, Properties- About the Uploading
                                      NFTs(Optional) e.x.-{`[{"colour":"red"}]`}
                                    </p>
                                  ) : newlocation_pathname.includes("book") ? (
                                    <p className="form_note resform_note">
                                      Example: The Excel Must Contain NFTName ,
                                      File-NFT Pdf , Preview- Preview Of the
                                      uploading nft, Type-book , Thumb- if not
                                      Provided default image will be Uploaded in
                                      Thumb, Properties- About the Uploading
                                      NFTs(Optional) e.x.-{`[{"colour":"red"}]`}
                                    </p>
                                  ) : (
                                    newlocation_pathname.includes("all") && (
                                      <p className="form_note resform_note">
                                        Example: The Excel Must Contain NFTName ,
                                        File-NFT Image/Audio/Video/Pdf , Preview-
                                        Preview Of the uploading nft- Not Required
                                        for Image NFTs,
                                        Type-(book/image/video/audio){" "}
                                        {newlocation_pathname}, Thumb-(for
                                        video/Audio/Pdf) if not Provided default
                                        image will be Uploaded in Thumb- Not
                                        Required for Image NFTs, Properties- About
                                        the Uploading NFTs(Optional) e.x.-
                                        {`[{"colour":"red"}]`}
                                      </p>
                                    )
                                  )}
                                  {/* <p className="form_note resform_note">Example: The Excel Must Contain NFTName , File-NFT Image/Audio/Video/Pdf , Preview- Preview Of the uploading nft- Not Required for Image NFTs,
                            Type-(book/image/video/audio) {newlocation_pathname}, Thumb-(for video/Audio/Pdf) if not Provided default image will be Uploaded in Thumb- Not Required for Image NFTs</p> */}
                                </>
                              )}

                              {
                                location_pathname == "literature" &&
                                <button className="sample_btn">
                                  <a href="https://docs.google.com/spreadsheets/d/1cnMVdKA9T6KTvqsibrD-03kEhbkSrufBp9aXJXxpSYI/edit#gid=0" target="_blank">
                                    View sample here
                                  </a>
                                </button>
                              }
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <div className="file_btn btn primary_btn me-2">
                                Upload
                                {location_pathname == "literature" ? (
                                  <input
                                    className="inp_file"
                                    type="file"
                                    id="excelfile"
                                    // value={files}
                                    // type="file"
                                    // name="file"
                                    // accept="audio/*,video/*,image/*"
                                    onChange={selectFileChange}
                                  />
                                ) : (
                                  <input
                                    className="inp_file"
                                    type="file"
                                    id="fileInputControl"
                                    webkitdirectory={newlocation_pathname == "layer" ? "true" : false}
                                    multiple
                                    // directory={"true"}
                                    // value={files}
                                    // type="file"
                                    // name="file"
                                    // accept="audio/*,video/*,image/*"
                                    onChange={selectFileChange}
                                  />
                                )}
                              </div>
                              <div
                                className="file_btn btn primary_btn"
                                onClick={() =>
                                  Oncancel(
                                    location_pathname == "literature"
                                      ? "excel"
                                      : "fileInputControl"
                                  )
                                }
                              >
                                Cancel
                              </div>
                              {ValidateError.layer && (
                                <span className="text-danger img-file">
                                  {ValidateError.layer}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {(newlocation_pathname != "layer" ||
                      (newlocation_pathname == "layer" &&
                        LayerType?.value == "Imageaudio")) && (
                        <div className="form-group col-md-12">
                          <div className="d-flex justify-content-between align-items-start flex-wrap">
                            <div>
                              <label
                                className="primary_label"
                                htmlFor="inputEmail4"
                              >
                                {newlocation_pathname == "layer"
                                  ? "Upload audio file"
                                  : "Upload " +
                                  FirstCaps(
                                    location_pathname != "literature"
                                      ? newlocation_pathname
                                      : "All Files"
                                  )}
                              </label>
                              <p className="form_note">
                                {newlocation_pathname == "layer"
                                  ? "Supported audio format is: MP3,mp3,FLAC"
                                  : Validfile}
                              </p>
                              {
                                (location_pathname != 'literature' && newlocation_pathname != 'layer' && newlocation_pathname != 'image') &&
                                <p className="form_note">
                                  {FirstCaps(newlocation_pathname)} file name and Thumb image name must be same. e.x.(filename-test.{newlocation_pathname == 'audio' ? "mp3" : "mp4"},test.jpeg)
                                </p>
                              }
                            </div>
                            <div className="info_btn_kralign">
                              <div className="file_btn btn primary_btn me-2">
                                Upload
                                <input
                                  className="inp_file"
                                  type="file"
                                  id="audio"
                                  webkitdirectory={location_pathname == "literature" ? "true" : false}
                                  // directory={"true"}
                                  multiple
                                  // value={mp3File||''}
                                  // type="file"
                                  // name="file"
                                  // accept="audio/*,video/*,image/*"
                                  onChange={selectFileChange}
                                />
                              </div>
                              <div
                                className="file_btn btn primary_btn"
                                onClick={() =>
                                  Oncancel(
                                    location_pathname == "literature"
                                      ? "all"
                                      : "audio"
                                  )
                                }
                              >
                                Cancel
                              </div>
                              {ValidateError.photo && (
                                <span className="text-danger img-file">
                                  {ValidateError.photo}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="row mx-0 mt-4 fixed_price_sec">
                    <div className="form-row type">
                      <div className="form-group col-md-6 network">
                        <label className="primary_label" htmlFor="price_new">
                          Network
                        </label>
                        <div className="input-group input_grp_style_1 pt-2">
                          <Select
                            className="yes1 typecontrol3 form-control primary_inp typecontrol"
                            id="networkname"
                            name="networkname"
                            // onChange={networkoptionfunc}
                            onChange={selectChange}
                            options={networkoption}
                            label="Select Network"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            value={
                              isEmpty(Wallet_Details?.networkConfiguration?.label)
                                ?
                                {
                                  label: "Select Network",
                                  value: "Select Network",
                                }
                                :
                                Wallet_Details?.networkConfiguration
                            }
                          // formControlProps={{
                          //   fullWidth: true
                          // }}
                          />
                          {ValidateError.network && (
                            <span className="text-danger">
                              {ValidateError.network}
                            </span>
                          )}
                        </div>
                        <span className="">
                          Supported blockchains are BNB Smartchain and Ethereum
                          Smartchain
                        </span>
                      </div>
                      {newlocation_pathname == "layer" && (
                        <div className="form-group col-md-6 network">
                          <label className="primary_label" htmlFor="price_new">
                            Choose Type
                          </label>
                          <div className="input-group input_grp_style_1 pt-2">
                            <Select
                              className="yes1 typecontrol3 form-control primary_inp typecontrol"
                              id="layertype"
                              name="layertype"
                              // onChange={networkoptionfunc}
                              onChange={(e) => ChangeLayer(e)}
                              options={[
                                { label: "Image Only", value: "Image" },
                                {
                                  label: "Image With Audio",
                                  value: "Imageaudio",
                                },
                              ]}
                              label="Select price"
                              classNamePrefix="react-select"
                              value={LayerType}
                              isSearchable={false}
                            // formControlProps={{
                            //   fullWidth: true
                            // }}
                            />
                          </div>
                          <p className="form-note">
                            Select This To Upload Your Layer For NFT's
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control primary_inp"
                        name="TokenName"
                        id="TokenName"
                        onChange={inputChange}
                        placeholder="e.g. Redeemable"
                        value={TokenName || ""}
                      />
                      {ValidateError.TokenName && (
                        <span className="text-danger">
                          {ValidateError.TokenName}
                        </span>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">
                        Description{" "}
                        <span className="text-muted">(Optional)</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control primary_inp"
                        id="desccription"
                        name="TokenDescription"
                        onChange={inputChange}
                        placeholder="You have an option to give description of your NFT"
                        autoComplete="off"
                        value={TokenDescription || ""}
                      ></textarea>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="Collection">
                        Select Collection
                      </label>
                      <Select
                        className="yes1 typecontrol3 form-control primary_inp typecontrol"
                        id="userCollection"
                        name="userCollection"
                        onChange={selectChange}
                        maxMenuHeight={220}
                        options={CollectionList}
                        label="Select Collection"
                        classNamePrefix="react-select"
                        placeholder="Select Collection"
                        value={
                          UserCollection
                            ? {
                              label: UserCollection,
                              value: UserCollection,
                            }
                            : ""
                        }
                      // formControlProps={{
                      //   fullWidth: true
                      // }}
                      />
                      {console.log("jshjhfsg", ValidateError)}
                      {ValidateError.userCollection && (
                        <span className="text-danger">
                          {ValidateError.userCollection}
                        </span>
                      )}
                    </div>
                    <div className="form-group col-md-6 category">
                      <label className="primary_label mb-3" htmlFor="category">
                        Category
                      </label>

                      <input
                        type="text"
                        placeholder="Select the category and the subcategory of your NFT"
                        className="form-control"
                        value={
                          CategorywithSubcategory?.display
                            ? CategorywithSubcategory?.display
                            : ""
                        }
                        onClick={() => setCategory(!category)}
                        disabled={
                          CategorywithSubcategory?.disabecategory
                            ? CategorywithSubcategory.disabecategory
                            : false
                        }
                      />
                      {category == true ? (
                        <>
                          <div className="dropdown">
                            <ul className="dropdown-menu1 p-3">
                              {fullcategory?.length > 0 &&
                                fullcategory.map((val) => {
                                  return (
                                    <li className="dropdown-sub">
                                      {val.name}
                                      <ul className="sub-dropdown">
                                        {val.subcategory.map((sub) => {
                                          return (
                                            <li
                                              onClick={() =>
                                                SettokenCategory(val, sub)
                                              }
                                            >
                                              {sub}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {ValidateError.TokenCategory && (
                        <span className="text-danger">
                          <br />
                          {ValidateError.TokenCategory}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="primary_label" htmlFor="royalties">
                          Royalties
                        </label>
                        <div className="inp_grp">
                          <input
                            type="text"
                            className="form-control primary_inp"
                            name="TokenRoyalities"
                            onChange={inputChange}
                            id="royalties"
                            value={TokenRoyalities}
                            placeholder="10%"
                          />
                          <p className="inp_append">%</p>
                          <span>
                            Note that you may choose not to receive royalty. As
                            such, you type in 0% as royalty.
                          </span>
                          {ValidateError.TokenRoyalities && (
                            <span className="text-danger">
                              <br />
                              {ValidateError.TokenRoyalities}
                            </span>
                          )}
                        </div>
                      </div>

                      {newlocation_pathname == "layer" && (
                        <div className="form-group col-md-6">
                          <label className="primary_label" htmlFor="desccription">
                            NFT quantity
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="TokenQuantity"
                            name="TokenQuantity"
                            onChange={inputChange}
                            placeholder="e.g. 1"
                            value={TokenQuantity}
                            maxLength={5}
                            autoComplete="off"
                          />
                          <p className="form-note">
                            Please, indicate how many NFTs you are minting.
                          </p>
                          {ValidateError.TokenQuantity && (
                            <span className="text-danger">
                              <br />
                              {ValidateError.TokenQuantity}
                            </span>
                          )}
                        </div>
                      )}

                      <div className="d-flex justify-content-between align-items-start grid_toggle gap-2">
                        <div>
                          <label
                            className="primary_label"
                            htmlFor="inputEmail4"
                          >
                            LazyMint
                          </label>
                          <p className="form_note">
                            To Create NFT in Lazy-Minting by turning on the
                            button .
                          </p>
                        </div>
                        <label className="switch toggle_custom me-0">
                          <input
                            type="checkbox"
                            id="LazyMint"
                            name="LazyMint"
                            onChange={() => { SetLazyMint(!LazyMint); SetWhiteListUser([]); }}
                            checked={LazyMint}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>

                      <div className="d-flex justify-content-between align-items-start grid_toggle gap-2">
                        <div>
                          <label
                            className="primary_label"
                            htmlFor="inputEmail4"
                          >
                            List NFT
                          </label>
                          <p className="form_note">
                            To List your NFT on this MarketPlace by turning on
                            the button .
                          </p>
                        </div>
                        <label className="switch toggle_custom me-0">
                          <input
                            type="checkbox"
                            id="ListNFT"
                            name="ListNFT"
                            onChange={CheckedChange}
                            checked={ListNFT}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      {ListNFT && (
                        <div className="d-flex gap-2 justify-content-between align-items-start grid_toggle">
                          <div>
                            <label
                              className="primary_label"
                              htmlFor="inputEmail4"
                            >
                              Put on Sale
                            </label>
                            <p className="form_note">
                              Decide how you want to sell your minted NFT by
                              turning on the button.
                            </p>
                          </div>
                          <label className="switch toggle_custom me-0">
                            <input
                              type="checkbox"
                              id="putonsale"
                              name="putonsale"
                              onChange={CheckedChange}
                              checked={PutOnSale}
                            />
                            <span className="slider"></span>
                          </label>
                        </div>
                      )}



                      {PutOnSale && (
                        <div className="form-group">
                          <label className="primary_label" htmlFor="properties">
                            Price{" "}
                          </label>
                          <div className="input-group input_grp_style_1">
                            <input
                              type="text"
                              className="form-control primary_inp"
                              id="TokenPrice"
                              name="TokenPrice"
                              onChange={inputChange}
                              placeholder="0"
                            />
                            <div className="input-group-append">
                              <Select
                                className="yes1 form-control primary_inp select1 selxet_app"
                                id="basic-addon2"
                                name="coinname"
                                onChange={priceoptionfunc}
                                options={priceoption}
                                label="Select price"
                              // formControlProps={{
                              //   fullWidth: true
                              // }}
                              />
                            </div>
                          </div>
                          {ValidateError.TokenPrice && (
                            <span className="text-danger">
                              <br />
                              {ValidateError.TokenPrice}
                            </span>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">
                        Individual NFT Quantity
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Quantity"
                        name="Quantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={Quantity}
                        maxLength={3}
                        autoComplete="off"
                      />
                      <span className="">
                        Specify the number of copies you have to mint.
                      </span>
                      {ValidateError.Quantity && (
                        <span className="text-danger">
                          <br />
                          {ValidateError.Quantity}
                        </span>
                      )}
                    </div>

                    {ListNFT && (
                      <div className="form-group col-md-6">
                        <label className="primary_label" htmlFor="desccription">
                          Individual NFT List Quantity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="ListedQuantity"
                          name="ListedQuantity"
                          onChange={inputChange}
                          placeholder="e.g. 1"
                          value={ListedQuantity}
                          maxLength={3}
                          disabled={location_pathname === "create-single"}
                          autoComplete="off"
                        />
                        <span className="">
                          Specify the number of copies you have to list for sale.
                        </span>
                        {ValidateError.ListedQuantity && (
                          <span className="text-danger">
                            <br />
                            {ValidateError.ListedQuantity}
                          </span>
                        )}
                      </div>
                    )}

                    {PutOnSale && (
                      <div className="form-group col-md-6">
                        <label className="primary_label" htmlFor="desccription">
                          Individual NFT Quantity for Sale
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="PutOnSaleQuantity"
                          name="PutOnSaleQuantity"
                          onChange={inputChange}
                          placeholder="e.g. 1"
                          value={PutOnSaleQuantity}
                          maxLength={3}
                          disabled={location_pathname === "create-single"}
                          autoComplete="off"
                        />
                        <span className="">
                          Specify the number of copies you have to list for sale.
                        </span>
                        {ValidateError.PutOnSaleQuantity && (
                          <span className="text-danger">
                            <br />
                            {ValidateError.PutOnSaleQuantity}
                          </span>
                        )}
                      </div>
                    )}

                    <div className="form-row w-100">
                      <div className="form-group col-md-12">
                        <label className="primary_label" htmlFor="name">
                          Social Media Links
                        </label>
                      </div>
                    </div>
                    <div className="social_add_sec mb-3">
                      <p className="text_title">
                        Interactive media links, e.g. Facebook, Telegram,
                        Twitter, Instagram, etc.
                      </p>

                      <div className="add_social">
                        <div className="btn_sec">
                          <button
                            type="button"
                            className="btn btn-add-social"
                            onClick={AddField}
                          >
                            {" "}
                            <FaPlus />
                          </button>
                          <span className="add_txt">Click to Add</span>
                        </div>
                      </div>
                      {console.log("dgldklgkd", socialLinks)}
                      {filterRows.map((val, index) => (
                        <div className="url_card my-3" key={index}>
                          <label className="primary_label" htmlFor="name">
                            URL For
                          </label>
                          <div className="row first_row">
                            <div className="col-lg-3 mb-4 mb-lg-0">
                              <Select
                                id="urfor"
                                options={mappedData}
                                classNamePrefix="react-select"
                                className="yes1 typecontrol3 form-control primary_inp typecontrol"
                                onChange={(e) => OnChange(e, index)}
                                value={
                                  socialLinks[index]?.urlfor
                                    ? {
                                      label: socialLinks[index].urlfor,
                                      value: socialLinks[index].urlfor,
                                    }
                                    : ""
                                }
                              />
                              {ValidateError["urlfor" + index] && (
                                <span className="text-danger">
                                  {ValidateError["urlfor" + index]}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-9 mb-4 mb-lg-0">
                              <div className="row">
                                <div className="col-10">
                                  <form>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="url"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter url"
                                        onChange={(e) => OnChange(e, index)}
                                        value={socialLinks[index]?.url || ""}
                                      />
                                      {ValidateError["url" + index] && (
                                        <span className="text-danger">
                                          {ValidateError["url" + index]}
                                        </span>
                                      )}
                                    </div>
                                  </form>
                                </div>
                                <div className="col-2 d-flex align-items-center">
                                  <img
                                    src={delete_png}
                                    onClick={() => RemoveField(index)}
                                    alt="delete"
                                    className="img-fluid delete_png"
                                    width={30}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {
                      (LazyMint && location_pathname != "literature")
                        ?
                        <></>
                        :
                        <>
                          <div className="form-row w-100">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="name">
                                WhiteList Users
                              </label>
                            </div>
                          </div>
                          <div className="social_add_sec mb-3">
                            <p className="text_title">
                              You Can Add User WalletAddress Who can see your complete
                              nfts
                            </p>

                            <div className="add_social">
                              <div className="btn_sec">
                                <button
                                  type="button"
                                  className="btn btn-add-social"
                                  onClick={() => AddField("whitelist")}
                                >
                                  {" "}
                                  <FaPlus />
                                </button>
                                <span className="add_txt">Click to Add</span>
                              </div>
                            </div>
                            {console.log("dlsdjkshfsfks", WhiteListUser)}
                            {WhiteListUser.map((val, index) => (
                              <div className="url_card my-3" key={index}>
                                <label className="primary_label" htmlFor="name">
                                  Select Wallet Address
                                </label>
                                <div className="row first_row">
                                  <div className="col-10 mb-4 mb-lg-0">
                                    <form>
                                      <div class="form-group">
                                        <Select
                                          id="Address"
                                          options={WhiteListOptions?.filter((checkdata) => !WhiteListUser.some((selected) => selected?.Address == checkdata.label))}
                                          classNamePrefix="react-select"
                                          className="yes1 typecontrol3 form-control primary_inp typecontrol"
                                          onChange={(e) => OnChange(e, index, "Address")}
                                          value={
                                            WhiteListUser[index]?.Address
                                              ? {
                                                label: WhiteListUser[index].Address,
                                                value: WhiteListUser[index].Address,
                                              }
                                              : ""
                                          }
                                          name={"whitelist"}
                                        />
                                        {ValidateError["whitelistaddress" + index] && (
                                          <span className="text-danger">
                                            {ValidateError["whitelistaddress" + index]}
                                          </span>
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                  <div className="col-2 d-flex justify-content-center">
                                    <img
                                      src={delete_png}
                                      onClick={() => RemoveField(index, "whitelist")}
                                      alt="delete"
                                      className="img-fluid delete_png"
                                      width={30}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                    }
                  </div>
                  <div className="mb-3">
                    <div className="d-flex agreement mb-0">
                      <input
                        type="checkbox"
                        id="agreement1"
                        name="agreement1"
                        value="Agreement"
                        checked={agree.original}
                        onChange={() =>
                          SetAgree({
                            ...agree,
                            ...{ original: !agree.original },
                          })
                        }
                      />
                      <label for="vehicle1" className="ml-2">
                        I declare that this is an original artwork. I understand
                        that no plagiarism is allowed, and that the artwork can
                        be removed anytime if detected.
                      </label>
                    </div>
                    {ValidateError.original && (
                      <span className="text-danger ms-3">
                        {ValidateError.original}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="d-flex agreement mb-0">
                      <input
                        type="checkbox"
                        id="agreement2"
                        name="agreement2"
                        value="Car"
                        checked={agree.termsandcondition}
                        onChange={() =>
                          SetAgree({
                            ...agree,
                            ...{ termsandcondition: !agree.termsandcondition },
                          })
                        }
                      />
                      <label for="vehicle2" className="ml-2">
                        I have read and I agree to all the NFT{" "}
                        <Link to={"/terms-and-conditions"} target="_blank">
                          Terms & Conditions
                        </Link>{" "}
                        and all legal requirements of EDA
                      </label>
                    </div>
                    {ValidateError.termsandcondition && (
                      <span className="text-danger ms-3">
                        {ValidateError.termsandcondition}
                      </span>
                    )}
                  </div>
                  <div>
                    <button
                      className="create_btn"
                      type="button"
                      data-toggle="model"
                      data-target="#create_item_modal"
                      onClick={CreateItem}
                    >
                      Create item
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <Footer />

      <div
        className="modal fade primary_modal"
        id="create_item_modal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">
                Proceed to create multiple nfts
              </h5>

              <button
                type="button"
                onClick={() => {
                  setnftcreated("");
                  SetPopupOpen(false);
                }}
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {newlocation_pathname == "layer" && (
              <div className="modal-body">
                <div className="text-left">
                  <div className="d-flex justify-content-start">
                    <div className="tick">
                      <i
                        class="fas fa-check mr-3 pro_initial"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="content mb-2">
                      <p className="approve_text">
                        Create NFT{" "}
                        <span className="mint_freecost">
                          (It's Free of Cost)
                        </span>
                      </p>
                      <p className="approve_desc">
                        This will generate the various kinds of NFTs for
                        minting.
                      </p>
                    </div>
                  </div>
                  <button
                    className="create_btn w-100"
                    disabled={
                      nftcreated === "true" || nftcreated === "processs"
                    }
                    onClick={() => createnfts()}
                  >
                    {nftcreated === "" && "Create NFT"}
                    {nftcreated === "processs" && "processing"}
                    {nftcreated === "true" && "done"}
                  </button>
                </div>
              </div>
            )}
            {newlocation_pathname == "layer" && (
              <div className="modal-body">
                <div className="text-left">
                  <div className="d-flex justify-content-start">
                    <div className="tick">
                      <i
                        class="fas fa-check mr-3 pro_initial"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="content mb-2">
                      <p className="approve_text">
                        View NFT{" "}
                        <span className="mint_freecost">
                          (It's Free of Cost)
                        </span>
                      </p>
                      <p className="approve_desc">
                        This will generate the various kinds of NFTs for
                        minting.
                      </p>
                    </div>
                  </div>
                  <button
                    className="create_btn w-100"
                    disabled={
                      nftcreated === "processs" || nftcreated !== "true"
                    }
                    onClick={() => {
                      SetViewMode(true);
                      window.$("#create_item_modal2").modal("hide");
                    }}
                  >
                    View NFT
                  </button>
                </div>
              </div>
            )}
            {LazyMint ? (
              <>
                <div className="modal-body">
                  <div className="text-left">
                    <div className="d-flex justify-content-start">
                      <div className="tick">
                        <i
                          class="fas fa-check mr-3 pro_initial"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="content mb-2">
                        <p className="approve_text">
                          NFT Upload{" "}
                          <span className="mint_freecost">
                            (It's Free of Cost)
                          </span>
                        </p>
                        <p className="approve_desc">
                          NFT Will be Uploaded to Server
                        </p>
                      </div>
                    </div>
                    <button
                      className="create_btn w-100"
                      disabled={
                        lazymintdone === "true" ||
                        lazymintdone === "processs" ||
                        nftcreated === "processs" ||
                        nftcreated !== "true"
                      }
                      onClick={() => LazyMintFunc()}
                    >
                      {lazymintdone === "" && "Lazy-Mint"}
                      {lazymintdone === "processs" && "minting"}
                      {lazymintdone === "true" && "done"}
                      {lazymintdone === "try" && "try again"}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="modal-body">
                  <div className="text-left">
                    <div className="d-flex justify-content-start">
                      <div className="tick">
                        <i
                          class="fas fa-check mr-3 pro_initial"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="content mb-2">
                        <p className="approve_text">
                          IPFS Upload{" "}
                          <span className="mint_freecost">
                            (It's Free of Cost)
                          </span>
                        </p>
                        <p className="approve_desc">
                          IPFS refers to InterPlanetary File System. This is
                          where your NFT file will be stored for easy access
                        </p>
                      </div>
                    </div>
                    <button
                      className="create_btn w-100"
                      disabled={
                        ipfsdone === "true" ||
                        ipfsdone === "processs" ||
                        nftcreated === "processs" ||
                        nftcreated !== "true"
                      }
                      onClick={() => IpfsAndMint()}
                    >
                      {ipfsdone === "" && "IPFS Upload"}
                      {ipfsdone === "processs" && "processing"}
                      {ipfsdone === "true" && "done"}
                      {ipfsdone === "try" && "try again"}
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="text-left">
                    <div className="d-flex justify-content-start">
                      <div className="tick">
                        <i
                          class="fas fa-check mr-3 pro_initial"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="content mb-2">
                        <p className="approve_text">Mint NFT</p>
                        <p className="approve_desc">
                          This button allows you to mint the NFT on the selected
                          blockchain.{" "}
                        </p>
                      </div>
                    </div>
                    <button
                      className="create_btn w-100"
                      disabled={
                        mintdone === "processs" ||
                        mintdone === "true" ||
                        nftcreated === "processs" ||
                        nftcreated !== "true" ||
                        ipfsdone !== "true" ||
                        ipfsdone === "try" ||
                        ipfsdone === "processs"
                      }
                      onClick={() => Mintcallfun()}
                    >
                      {mintdone === "" && "Mint"}
                      {mintdone === "processs" && "processing"}
                      {mintdone === "true" && "done"}
                      {mintdone === "try" && "Try-again"}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
