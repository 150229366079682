import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from 'jquery';
import TopHeader from "../components/Header/TopHeader";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import Bannerimg from "../assets/images/bannerheade.png";
// import BSC from "../assets/images/BNB12.png";
import TokenCard from '../views/separate/TokenCard'

import Profile from "../assets/images/profile_img.png";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// mysid
import isEmpty from '../lib/isEmpty'
import '@metamask/legacy-web3'
import {
  CollectiblesList_Home,
  topCreatorsApi,
  getBuyerSeller,
  gallarycollection,
  Hotcollections
} from '../actions/v1/token';
import { getcmslistinhome, Bannerpromotion, gall } from '../actions/v1/report'
import axios from 'axios';
import config from '../lib/config';
import { LikeRef } from './separate/LikeRef';
import { useDispatch, useSelector } from "react-redux";
import { getnewpromotion } from "../actions/v1/user"
// import DynamicPDFViewer from "../components/DynamicPDFViewer";





export default function Home(props) {




  const hoursMinSecs = { hours: 1, minutes: 20, seconds: 40 }


  const state = {
    responsive: {
      0: {
        items: 1,
      },
      575: {
        items: 2,
      },
      767: {
        items: 3,
      },
      992: {
        items: 4,
      },
      1199: {
        items: 5,
      },
      1400: {
        items: 5
      }

    },

  };

  const liveauctions = {
    dots: false,
    infinite: true,
    autoplay: true,
    smartSpeed: 400,
    //loop:true,
    margin: 60,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    speed: 1500,

    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }

  };
  // const classes = useStyles();
  const { ...rest } = props;



  $(window).scroll(function () {
    if ($(this).scrollTop()) {
      $('header').addClass('fixedTop');
    } else {
      $('header').removeClass('fixedTop');
    }
  });




  const [responsiveone] = React.useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    }

  })


  var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {

      return <span>Waiting for Owner To Accept</span>
    } else {
      return <span> <span className="hourds">{formatTime(hours)}</span> : <span className="hourds">{formatTime(minutes)}</span> : <span className="hourds">{formatTime(seconds)}</span> </span>;
    }
  };

  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

  const options = {
    dots: false,
    infinite: true,
    autoplay: true,
    smartSpeed: 400,

    margin: 25,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    speed: 1500,

    responsive: {
      0: {
        items: 1.5
      },
      320: {
        items: 1.3
      },
      576: {
        items: 2.3
      },
      768: {
        items: 2.3
      },
      992: {
        items: 3.3
      },
      1300: {
        items: 3.3
      },
      1500: {
        items: 4.3
      },
      1700: {
        items: 4.3
      },
      1900: {
        items: 5.3
      },
    }

  }

  const location = useLocation();
  const Wallet_Details = useSelector(state => state.wallet_connect_context);


  var LikeForwardRef = useRef();


  const [topcreatorsection, settopcreatorsection] = React.useState([]);
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({
    'loader': false, 'All': {
      page: 1, list: [],
      onmore: true
    }
  })
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
  // const [Wen_Bln, set_Wen_Bln] = useState(0);
  const [Promotion, Set_Promotion_list] = useState([])
  const [Promotion_Banner, Set_Promotion_Banner] = useState({})
  const [gallarycoll, Setgallarycoll] = useState({})
  const [hotcol, Sethotcoll] = useState({})
  const [Creatorcount, setCreatorcount] = useState(0);
  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [buyerSeller, setBuyerSeller] = useState([]);
  const [Time, setTime] = useState(7);
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [imgfilename, Set_imgfilename] = useState("");
  const [imgpath, setimgpath] = useState("");
  const [promotiondetail, setpromotiondetail] = useState("");


  useEffect(() => {
    Galarycoll();
    Hotcoll();
    getInit();
    Timefun();
    if (CatBasedTokenList?.All?.list?.length == 0) {
      TokenListCall();
    }
    getBuyerSellerFun('buyer');
  }, [Time])



  async function getInit() {

    getcmslistinhomes();
    getcmslistinhomes1();
    promotionData();
    topCreatorsFUnc();
    BannerPro();
    timeAuctionFUnc();
    recentNftFUnc();

    // ;}

  }

  const getcmslistinhomes = async () => {
    let reqdata = {
      load: 'home'
    }
    let convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome1(convers.data)
    }
  }
  const getcmslistinhomes1 = async () => {
    let reqdata = {
      load: 'tophome'
    }
    //console.log("hkmgh,j.kjg.hkl",reqdata);

    let convers = await getcmslistinhome(reqdata);
    //console.log("frtjtfrjtey",convers);
    if (convers && convers.data) {
      setgetcmslistinhome12(convers.data)
    }
  }

  const promotionData = async () => {
    let promotion = await getnewpromotion()
    if (promotion?.data?.data?.promotion?.length === 1) {
      let file = promotion?.data?.data?.promotion[0]?.image
      Set_imgfilename(promotion?.data?.data?.promotion[0]?.image)
      let path = `${config.Back_URL}/newpromotion/${file}`
      setimgpath(path)
      setpromotiondetail(promotion?.data?.data?.promotion[0])
    }
  }

  const BannerPro = async () => {
    let test = await Bannerpromotion();
    //  //console.log("Dsajdashdjasgdaskdkagshdghasgkd", test)
    if (test && test.userValue) {
      // //console.log("hfijwedifghi", test.userValue);
      Set_Promotion_Banner(test.userValue)
    }
  }

  const topCreatorsFUnc = async () => {
    let topCraete = await topCreatorsApi();
    if (topCraete && topCraete.data) {

      settopcreatorsection(topCraete.data.data)
    }
  }
  const timeAuctionFUnc = async () => {
    let currAddr = Wallet_Details.UserAccountAddr;

    let payload = {
      limit: 6, from: 'Time', currAddr: currAddr
    }
    let resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from === 'time-auction-token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      Set_Time_Auction_List(resp.data.list)
    }
    else {
      Set_Time_Auction_List([])
    }
  }



  const recentNftFUnc = async () => {
    let currAddr = Wallet_Details.UserAccountAddr

    let payload = {
      limit: 10, from: 'recent', currAddr: currAddr
    }
    let resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from === 'recent-token-collectibles-list-home' && resp.data.list && ((resp.data.list).length !== 0)) {
      // //console.log("all come", resp.data)
      Set_Recent_Nft_List(resp.data.list)
    }
    else {
      Set_Recent_Nft_List([])
    }
  }


  const Galarycoll = async () => {
    let collectiongal = await gallarycollection();
    Setgallarycoll(collectiongal?.data?.data?.collections)
  }



  const Hotcoll = async () => {
    let Arg = {
      Timed: Time,
    };
    let collectiongal = await Hotcollections(Arg);
    Sethotcoll(collectiongal?.data?.data?.collections)
  }

  async function Timefun(timefunn) {
    if (timefunn > 0) {
      setTime(timefunn)
    }
  }


  async function TokenListCall(data = {}) {
    console.log("🚀 ~ TokenListCall ~ data:", data)

    let currAddr = Wallet_Details.UserAccountAddr
    let name = CatName;
    if (data.CatName) {
      name = data.CatName
    }
    let payload = {
      limit: 10,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
      currAddr: currAddr,
      CatName: name,
      from: 'Home',
    }
    Set_LoadDisable(true)
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);
    let resp = await CollectiblesList_Home(payload);
    console.log("🚀 ~ TokenListCall ~ resp:", resp)
    CatBasedTokenList.loader = false;
    CatBasedTokenList.onmore = resp?.data?.list ? resp?.data?.list?.length != 0 : true
    setCatBasedTokenList(CatBasedTokenList);
    Set_LoadDisable(false)
    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }

  const getBuyerSellerFun = async (filter) => {
    let filterobj = {}

    if (!isEmpty(filter)) {
      if (filter === 'seller')
        filterobj.buyerSellerFilter = filter
      if (filter === 'buyer')
        filterobj.buyerSellerFilter = filter;
    }
    const response = await getBuyerSeller(filterobj);
    if (response && response.data && response.data.success) {
      setBuyerSeller(response.data.list);
    }
  }


  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);
    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1
    });
  }



  return (


    // <div className="mt-5">
    //   <DynamicPDFViewer />
    // </div>
    <div>
      <div className="home_header">

        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
        />

        <Header />


        {
          <section className="banner_head home_banner_head">
            <div className="container-fluid container-theme container-lg">

              <div className="row rowing_height">
                <div className="col-md-6 col-12">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="homebnns">
                      {/* {ReactHtmlParser(getcmslistinhome12.answer)} */}
                      <p dangerouslySetInnerHTML={{ __html: getcmslistinhome12.answer }}></p>
                    </div>
                  </div>
                </div>

                {
                  <div className="col-md-6 col-12">
                    <div className="baner_image">

                      <div className={["mp4", "webm", "WEBM", "OGV", "ogv"].includes(imgfilename?.split('.')?.pop()) ? "ethactiosban ethaction_video ethactiosban_pos_rel_au" : "ethactiosban ethactiosban_pos_rel_au"}>

                        {
                          (imgpath === "")
                            ?
                            <img src={Bannerimg} className="img-fluid" />

                            :
                            <> {

                              imgfilename !== "" &&
                              (imgpath !== "" && ["mp4", "webm", "WEBM", "OGV", "ogv"].includes(imgfilename?.split('.')?.pop()) ?
                                (<video
                                  id="imgPreview"
                                  className="img-fluid"
                                  alt="img"
                                  autoPlay
                                  controls
                                  muted
                                  playsInline
                                  loop
                                  src={imgpath}
                                  type="video/mp4"
                                />)
                                :
                                null
                              )}

                              {imgpath != "" && (imgpath.split('.').pop() === 'mp3')
                                &&
                                <>
                                  <img src={config.AudioImg} className="img-fluid" />

                                  <audio id="banner_audio" controls className="audio_pos">
                                    <source src={imgpath} type="audio/ogg" />
                                    <source src={imgpath} type="audio/mpeg" />
                                  </audio>

                                </>

                              }

                              {
                                (imgpath !== "" && ["webp", "WEBP", "gif", "jpg", "GIF", "JPG", "JPEG", "jpeg", "png", "PNG"].includes(imgfilename?.split('.')?.pop()) ?
                                  (<img src={imgpath} id="imgPreview" alt="Collections" className="img-fluid" />)
                                  :
                                  null
                                )
                              }

                            </>




                        }

                      </div>






                      {

                        (Promotion.length > 0) ?
                          Promotion && Promotion.map((Promotion_List) => {
                            return (
                              (Promotion_List && Promotion_List.tokenName) &&



                              <div className="banercontent">



                                <Link to={Promotion_List?.userProfile?.customurl !== "" ? `/${Promotion_List?.userProfile?.customurl}` : `/user/${Promotion_List?.userProfile?.curraddress}`}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={`Creator : ${Promotion_List.userProfile.name !== "" ? Promotion_List.userProfile.name : Promotion_List.userProfile.curraddress}`}
                                >
                                  {Promotion_List.userProfile.image !== "" ? <img src={`${config.Back_URL}/images/${Promotion_List.userProfile._id}/${Promotion_List.userProfile.image}`} /> :
                                    <div >
                                      <img src={Profile} />

                                    </div>}

                                </Link>


                                <div className="banercontentDet">
                                  <h2> {Promotion_List.tokenName}</h2>
                                  <p>by {Promotion_List.userProfile.curraddress.slice(0, 6).concat("....")}</p>
                                  {/* <p>by John</p> */}
                                </div>
                              </div>

                            )
                          })
                          :
                          <div className="banercontent">



                            <Link to={""}
                              data-toggle="tooltip"
                              data-placement="top"
                            // title={`Creator `}
                            >

                              <div >
                                <img src={promotiondetail?.Profile ? `${config.Back_URL}/newpromotion/${promotiondetail?.Profile}` : Profile} />

                              </div>

                            </Link>


                            <div className="banercontentDet">
                              <h2> {promotiondetail?.TokenName ? promotiondetail?.TokenName : "Blue Studios NFT"}</h2>
                              <p>by {promotiondetail?.creator ? promotiondetail?.creator : "John"}</p>
                            </div>
                          </div>
                      }



                    </div>


                  </div>


                }
              </div>
            </div>
          </section>
        }


        {(gallarycoll && gallarycoll.length > 0) &&
          <section className="bid_section section bid_section_section_1 hotcollect">
            <div className="container-fluid container-theme container-lg">
              <h2 className="mb-5 title_text_white responsing_txt mb-5 pb-5">Hot Collections</h2>

              <OwlCarousel className='owl-theme hot_owlcar' {...options}>


                {gallarycoll.length > 0 &&
                  gallarycoll.map((itemgallary) => {
                    return (

                      <div className="hotcollect_body">
                        <Link to={'/collectiondetail/' + itemgallary.userAddress + '/' + itemgallary.customurl + '/' + itemgallary.collectionAddress}>

                          <>

                            <div className="hotcollectimgsoruce">
                              {
                                (itemgallary.imageName === "" || itemgallary.imageName === undefined) ?
                                  <img src={require("../assets/images/hotcollectionone.png")} class="hotcolectimg" alt="Shape" />
                                  :
                                  <img src={`${config.Back_URL}/collectionLogo/${itemgallary.userAddress}/${itemgallary.imageName}`} alt="Shape" className="hotcolectimg" />
                              }
                            </div>

                            <h2 className="hotcol_named">{itemgallary.collectionName}</h2>

                          </>
                        </Link>
                      </div>
                    )
                  })}



              </OwlCarousel>

            </div>
          </section>
        }


        <section className="topcreators">
          <div className="container-fluid container-theme container-lg">
            <h2 className="mb-5 responsing_txt pb-5">Top Creators</h2>
            <div className="mt-5">

              <div className="topgrid">
                {topcreatorsection.length > 0 && topcreatorsection.map((item) => {
                  return (
                    <div className="topgridban">
                      <div>
                        <Link to={'/user/' + item.curraddress}>
                          {
                            item.image === "" &&
                            <img src={Profile} />
                          }
                          {item.image !== "" &&

                            <img src={`${config.Back_URL}/images/${item._id}/${item.image}`} />
                          }

                        </Link>
                      </div>
                      <div>

                        {
                          item.name === "" && <h3> {(item?.curraddress)?.slice(0, 10)?.concat('.....')}</h3>}
                        {
                          item.name !== "" && <h3> {(item?.name)?.slice(0, 10)?.concat('.....')}</h3>}

                        <p>{item.count} items</p>
                      </div>
                    </div>
                  )
                })}
              </div>

            </div>
          </div>
        </section>


        <section className="liveauction carousel-nav pb-3">
          <div className="container-fluid container-theme container-lg">

            <div className="mt-3">
              <div className="">
                <div className="">
                  <div className="">
                    <div> <h2 className="section-head mb-5 responsing_txt pb-5">Live Auctions</h2></div>
                    <div className="m-0">

                      <div className="">
                        <OwlCarousel className='owl-theme' {...liveauctions}>

                          {(Time_Auction_List.map((item) => {
                            return (
                              (isEmpty(item.tokenowners_current) !== true) ?

                                <TokenCard

                                  item={item}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                  Bids={item.myBid}
                                  curraddress={Wallet_Details.UserAccountAddr != "" ? Wallet_Details.UserAccountAddr : ""}
                                />
                                : ("")
                            )
                          }))
                          }
                        </OwlCarousel>
                      </div>

                    </div>

                  </div>

                </div>
              </div>


            </div>

          </div>
        </section>



        <section className="liveauction carousel-nav pb-3">
          <div className="container-fluid container-theme container-lg">

            <div className="mt-3">
              <div className="">
                <div className="">
                  <div className="">
                    <div><h2 className="section-head mb-5 responsing_txt pb-5">Recently Added NFT</h2></div>
                    <div className="m-0">

                      <div className="auctions">

                        {
                          (CatBasedTokenList
                            && CatName
                            && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list
                            && CatBasedTokenList[CatName].list.length > 0)
                            ? (CatBasedTokenList[CatName].list.map((item) => {
                              return (
                                (isEmpty(item.tokenowners_current) !== true) ?
                                  <TokenCard
                                    item={item}
                                    LikedTokenList={LikedTokenList}
                                    setLikedTokenList={setLikedTokenList}
                                    hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                    Bids={item.myBid}
                                    curraddress={Wallet_Details.UserAccountAddr != "" ? Wallet_Details.UserAccountAddr : ""}
                                  />
                                  : <></>
                              )
                            })) : <></>
                        }
                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>


         {CatBasedTokenList[CatName].onmore && <div className="text-center mt-5 w-100 ">

            <div id="spinButs" className="overflow-hidden">
              <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={() => onLoadMore()} disabled={LoadDisable} style={{ display: "flex" }}>
                Load More <span>

                </span>

              </button>

            </div>
          </div>}

        </section>


        <div id="logo_overlay" className="logo_ovelay">

          <Footer />
        </div>

      </div>
    </div>
  )
}
