import React, { useEffect, useState } from "react";


// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"



const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Edafacepartnership(props) {

  const { ...rest } = props;


  return (
    <div className="inner_header howItWorks">
     <Header/>
      <ScrollToTopOnMount />
      <div className={"inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">EdaFace NFT Partnership</h2>
                </div>

              </Col>
            </Row>
          </div>
        </div>
        <div className="container mt-3">
          <div className="accordion" id="accordionExample">

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Introduction</button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>In line with its vision, EdaFace seeks to partner with creators and innovators to bring their creative
                    and innovative ideas into NFTs.</p>
                  <div className="accordionparalist">
                    <p>Hence, artists, scientists, sportsmen and women, musicians, authors, politicians, institutions, etc. are
                      all welcome on board of partnership with EdaFace NFT Team, which consists of experts and
                      marketers in the NFT industry.
                    </p>


                  </div>


                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Benefits of Partnership
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">

                  <div className="accordionparalist">
                    <h2>(1). EdaFace NFT Partners will enjoy guidance from our industry leading experts on NFT structuring and development.</h2>
                    <h2>(2). EdaFace NFT Partners will also enjoy guidance on branding of their NFTs.</h2>
                    <h2>(3). Once your NFT contents have been finalized, EdaFace experts will guide you on pricing and
                      marketing.</h2>
                    <h2>(4). Our dedicated NFT Team will provide all the support that will make you feel comfortable using
                      EdaFace NFT Marketplace and all other related EdaFace platforms and offer advise on best practices.</h2>
                    <p>If you are a newcomer to the NFT industry, be rest assured that you’ll never be left alone as our
                      team will be with you to help you navigate your way successfully and make your profits.
                    </p>

                  </div>

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  What Does It Cost to be a Partner?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">



                  <p>EdaFace NFT Team does not charge for partnerships or promotion. We only take our usual 1.5% fee
                    on every transaction both primary and secondary sales. This is the lowest in the industry.

                  </p>



                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Where to Start

                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>We are interested in multiple and collections of unique Fine Arts, Collectibles and Gamings as NFTs.</p>
                  <p>To get started, send us an email to <span><a href="admin@edaface.com">admin@edaface.com</a></span>, stating your details and what you plan to
                    do, and we will contact you.</p>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
