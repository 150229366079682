import React, { useEffect, useState } from "react";

// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { GetResource, faqlists } from '../actions/v1/report'
import { Container, Col, Row } from "react-bootstrap";



const dashboardRoutes = [];


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Resources(props) {
  // const classes = useStyles();
  const { ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate()
  const [Resource, SetResource] = useState({});


  useEffect(() => {
    Getresourcedetail()
  }, [location?.pathname])

  const Getresourcedetail = async () => {

    let Resp = await GetResource({ link: location?.pathname });
    if (Resp?.status) {
      SetResource(Resp?.data)
    }
    else {
      navigate('/')
    }
  }

  return (
    <div className="inner_header howItWorks">
   <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Container>
              <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">{Resource?.Heading}</h2>
                </div>
              </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="container mt-3">
          <div class="accordion resource_accordion" id="accordionExample">
            <div class="accordion-item">
              {Resource?.FaqArray?.length > 0 &&
                Resource?.FaqArray?.map((val, ind) => {
                  return (
                    <>
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed resource_accohead_btn" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + ind} aria-expanded="false" aria-controls="collapseOne">
                          {val?.Question}
                        </button>
                      </h2>
                      <div id={"collapse" + ind} class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div className="dangered_accord_dtls" dangerouslySetInnerHTML={{ __html: val.Answer }} ></div>

                        </div>
                      </div>

                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
