import React, { useEffect, useState } from "react"
import config from "../../lib/config"
import { Link, useLocation } from "react-router-dom"
import { getaccouncementurldata } from "../../actions/v1/user"

export default function TopHeader() {

    const location = useLocation();


    const [description, setDescription] = useState('')
    const [deleted, setDeleted] = useState('')
  
    const [showannounce,setShowAnnounce] = useState("true");

    useEffect(() => {
        getAccouncementData()
      }, [location?.pathname,]);

      const getAccouncementData = async () => {
        try {
      let data = {
        url : location.pathname
      }
         let respData = await getaccouncementurldata(data);
         console.log('djksjjlskjfs',respData)
         let description = respData?.data?.data?.Description;
         if(respData?.data?.status){
          setDescription(description)
          setDeleted(respData?.data?.data?.Deleted)
         }
         else{
          setDescription('')
          setDeleted(respData?.data?.data?.Deleted)
         }
         
        } catch (err) {
      // console.log("getAccouncementData_err", err)
        }
      }

    return (
        <>
        <div className="first_head">
            <div className="d-flex justify-content-end pt-2">
                {
                    localStorage.registedEmail == undefined
                    ?
                    <a className="me-5" href={config.loginurl+"/login"} target="_blank">Login</a>
                    :
                    <></>
                }
                
            </div>
        </div>

        {deleted == "0" && <div className={showannounce == "true" ? "announce_whole" :"announce_hide"}>
                  <div className="rolling_track">
                    <div className="rolling_cont">
           <p>
            
            {description}</p>
            </div>
            </div>

          <i onClick={() => setShowAnnounce("false")} class="fa-solid fa-xmark announce_close"/>
        </div> }

        </>
    )
}