import axios from 'axios';
import Config from './config'

export const NumberChange = (data) => {
    try{
      return (isNaN(Number(data)) ? 0 : Number(data))
    }
    catch(err){
      console.log('NumberChange_error',err)
      return 0;
    }
  }

  export const NumberOnly = (data) => {
    return data.toString().replace(Config.NumOnly,'')
  }

  export const GetToken = (key) => {
    if(key){
      let cookie = ("; " + document.cookie)
      .split(`; ${key}=`)
      .pop()
      .split(";")[0];
      return cookie;
    }
    else{
      return undefined;
    }
  }

  export const SetToken = (tokenkey,token) => {
    if(tokenkey&&token){
        document.cookie =  `${tokenkey}= ${token}`;
    }
    return true;
}

export const deleteToken = (tokenkey) => {
  if(tokenkey){
    document.cookie = `${tokenkey}= ; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
  return undefined;
}

