
import React, { useEffect, useState } from "react";




// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import { Link } from "react-router-dom";
import ActivityCard from './separate/activityTab'
import { ActivityCall } from '../actions/v1/report'

import { useSelector } from "react-redux";


import config from '../lib/config'
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "react-bootstrap";
import { IoCaretDown } from "react-icons/io5";




const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Activity(props) {


 
  const { ...rest } = props;
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  
  
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'purchase': { page: 1, list: [], onmore: true } });
  const [CatName, setCatName] = useState('purchase');
  const [expanded, setExpanded] = React.useState('panel1');
  const [Activity_Item, Set_Activity_Item] = useState([])


  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);
    ActivityTab({
      page: CatBasedTokenList[CatName].page + 1
    });
  }


  useEffect(() => {
    if (typeof CatBasedTokenList[CatName] === 'undefined') {
      CatBasedTokenList[CatName] = { page: 1, list: [], onmore: true };
      setCatBasedTokenList(CatBasedTokenList);
      ActivityTab({ CatName: CatName, page: 1 });
    }
  }, [CatName])

  useEffect(() => {
    ActivityTab({ CatName: CatName, page: 1 });
  }, [])

  const ActivityTab = async (data) => {
    let name = CatName
    let reqdata = {
      tabName: name,
      currAddr: Wallet_Details.UserAccountAddr,
      limit: 9,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
    }
    let activitys = await ActivityCall(reqdata)
    if (activitys && activitys.data && activitys.data.list && (activitys.data.list).length > 0) {
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(activitys.data.list);
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    else {
      Set_Activity_Item([])
    }
  }

  return (
    <div className="inner_header">
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader activity"}>
        <div className="bg_red_1">
          <div className={'container'}>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="">
                  <h2 className="inner_title">Activity</h2>

                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container-fluid container-theme container-lg">
          <div className="customDrop position-relative">
            <Dropdown className="position-relative">
              <DropdownToggle className="filter_btn">
              Filter <IoCaretDown/>
              </DropdownToggle>
              <DropdownMenu className="p-0">
              <div className="buernotir">

<div style={{ height: 250 }}>

  <ul className="explorebg btnbuyerss">
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0" >All</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0" >My Activity</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3"  >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0" >Mint</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0" >Bid</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0" >Bid Accept</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0" >Purchase</p>

          </div>

        </div>
      </div>

    </li>

    <li className="px-3"   >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0">Put on sale</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0">Cancel price</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0">Token Like</p>

          </div>

        </div>
      </div>

    </li>
    <li className="px-3" >
      <div className="media">
        <div className="media-body">
          <div>
            <p className="mt-0 mb-0">Follow</p>

          </div>

        </div>
      </div>

    </li>
  </ul>

</div>



</div>
              </DropdownMenu>
            </Dropdown>

            {/* <CustomDropdown
              noLiPadding
              buttonText={<div className="customDropdown">

                Filter</div>}
              dropdownList={[
                <div className="buernotir">

                  <div style={{ height: 250 }}>

                    <ul className="explorebg btnbuyerss">
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 " >All</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 " >My Activity</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3"  >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 " >Mint</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 " >Bid</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 " >Bid Accept</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 " >Purchase</p>

                            </div>

                          </div>
                        </div>

                      </li>

                      <li className="px-3"   >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 ">Put on sale</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 ">Cancel price</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 ">Token Like</p>

                            </div>

                          </div>
                        </div>

                      </li>
                      <li className="px-3" >
                        <div className="media">
                          <div className="media-body">
                            <div>
                              <p className="mt-0 ">Follow</p>

                            </div>

                          </div>
                        </div>

                      </li>
                    </ul>

                  </div>



                </div>
              ]}
            /> */}

          </div>



          <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
            <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
              <div className="proposal_panel_overall">

                <div className="followers_overall py-3">

                  {

                    <ActivityCard

                    />

                  }




                </div>


              </div>


            </div>


          </div>

        </div>

        <div className="text-center mt-4 w-100 ">

          <div id="spinButs" className="overflow-hidden">
            <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={onLoadMore} style={{ display: "flex" }}>
              Load More <span>

              </span>

            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}







