import React,
{ useEffect, useState, useRef } from "react";


// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link, useLocation } from "react-router-dom";
// myside

import { getSearchList } from "../actions/v1/user";



import { useSelector } from "react-redux";


import config from '../lib/config';
import isEmpty from "../lib/isEmpty";
import TokenCard from "./separate/TokenCard"
import UserCard from './separate/UserCard'
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';

import { Row, Col } from "react-bootstrap";


const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Search(props) {

  var LikeForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  const location = useLocation()
  console.log("🚀 ~ Search ~ location:", location)
  const { ...rest } = props;
  const [searchItem, setSearchItem] = useState([])
  const [UsersearchItem, setUserSearchItem] = useState([])
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [HitItem, Set_HitItem] = useState({});
  
  console.log('props?.location-->',props?.location)
  useEffect(() => {
    searchCall();
  }, [location?.search])

  const searchCall = async () => {
    if (isEmpty(location?.search))
      return false;
    const search = location?.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const keyword = params.get('search')

    let postData = {
      keyword: keyword,
      limit: parseInt(config.limitMax)
    }
    console.log('postData-->',postData)
    var data = await getSearchList(postData);

    if (data && data.searchlist && data.searchlist.items && data.searchlist.items.list) {
      setSearchItem(data.searchlist.items.list);
    }
    if (data && data.searchlist && data.searchlist.users) {
      setUserSearchItem(data.searchlist.users);
    }
  }


  return (
    <div className="inner_header">



      <WalletRef
        ref={WalletForwardRef}

      />

      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
      />
      <ReportNowRef

        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />




      <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader"}>

        <div className="container-fluid container-theme mt-3">
          <Row>
            <Col xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav items_tab_outer text-center">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab  items_tab_new pb-2 pl-2 text-center" id="nav-tab" role="tablist">
                  <a className="nav-link active" id="items-tab" data-toggle="tab" href="#items" role="tab" aria-controls="all" aria-selected="true">
                    <span className="tab_head p-2">Items</span>
                  </a>

                  <a className="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="following" aria-selected="false">
                    <span className="tab_head p-2">Users</span>
                  </a>

                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="items" role="tabpanel" aria-labelledby="items-tab">
                  <div className="proposal_panel_overall">
                    {searchItem.length === 0 ?
                      <div className="text-center py-5 ">
                        <p className="not_found_text">Loading</p>
                        <p className="not_found_text_sub">Please wait a moment</p>
                        <div className="mt-3">
                          {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                        </div>
                      </div>
                      :




                      <div className=" m-0 ma_no_gap myitemcards">
                        <>
                          <div className="row">

                            {
                              searchItem.map((item, index) => {
                                return (
                                  (isEmpty(item.tokenowners_current) !== true) ?
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                      <TokenCard
                                        item={item}
                                        LikedTokenList={LikedTokenList}
                                        setLikedTokenList={setLikedTokenList}
                                        hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                        Bids={item.myBid}
                                      />
                                    </div>
                                    : ("")
                                )
                              })}
                          </div>
                        </>

                      </div>






                    }
                  </div>
                </div>


                <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                  <div className="proposal_panel_overall">
                    {UsersearchItem.length === 0 ?
                      <div className="text-center py-5">
                        <p className="not_found_text">No users found</p>
                        <p className="not_found_text_sub">Please wait a moment</p>
                        <div className="mt-3">
                          <button className="create_btn">Browse Marketplace</button>
                        </div>
                      </div> :
                      <div className="followers_overall mt-5">
                        <div className="row">
                          {UsersearchItem.map((User) => {
                            return (
                              <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <UserCard
                                  User={User}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>}
                  </div>
                </div>

              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}
