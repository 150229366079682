import React, { useEffect, useState, useRef } from "react";
// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
// myside
import CopyToClipboard from "react-copy-to-clipboard";
import moment from 'moment'
import { notificationStatusChange } from '../actions/v1/report';
import {
  Link,
  useParams,
  useNavigate,
  useLocation
} from "react-router-dom";
import isEmpty from "../lib/isEmpty";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Avatars from './Avatar';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
import { toast } from 'react-toastify';
import Select from 'react-select'
import Banner from "../assets/images/banner.jpg";

import TokenCard from './separate/TokenCard';
import { useSelector, useDispatch } from 'react-redux'
import {
  getprofile,
  getFollowers,
  followUnfollow,
  checkFollower,
  ParamAccountAddr_Detail_Get,
  coverimagevalidations,
  coverImage,
  getAllNFTFromContract
} from '../actions/v1/user';
import {
  CollectiblesList_MyItems,
  getAllCounts,
  getUserCollectiondata

} from '../actions/v1/token';
import config from '../lib/config';
import { BurnRef } from './separate/BurnRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { LikeRef } from './separate/LikeRef';
import LazyLoad from 'react-lazyload';
import LazyLoader from "./lazyloader";
import { network } from "./network";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "react-bootstrap";

// toast.configure();
let toasterOption = config.toasterOption;



const dashboardRoutes = [];


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Myitems(props) {


  const { ...rest } = props;


  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const navigate = useNavigate();
  var { paramUsername, paramAddress } = useParams();

  
  var LikeForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();


  if (typeof paramUsername == 'undefined') { paramUsername = ''; }
  if (typeof paramAddress == 'undefined') { paramAddress = ''; }


  const [ParamAccountCustomUrl, Set_ParamAccountCustomUrl] = React.useState(paramUsername);
  const [ParamAccountAddr, Set_ParamAccountAddr] = React.useState(paramAddress);
  const [UserNotFound, Set_UserNotFound] = React.useState(false);
  const [disabled1, setdisabled] = useState()
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [chooseimage, setchooseimage] = React.useState(false);
  const [validateError, setvalidateError] = React.useState({});
  const [followButton, setFollowButton] = useState('Follow')
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = React.useState({});
  const [profile, setProfile] = useState({});
  const [coverimage, setcoverphoto] = React.useState(Banner);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [OnSale_Count, Set_OnSale_Count] = useState(0);
  const [OnSale_List, Set_OnSale_List] = useState([]);
  const [OnSale_Page, Set_OnSale_Page] = useState(1);
  const [followList, setFollowList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [Collectibles_Count, Set_Collectibles_Count] = useState(0);
  const [Collectibles_List, Set_Collectibles_List] = useState([]);
  const [Collectibles_List_Page, Set_Collectibles_List_Page] = useState(1);
  const [Sold_List_Page, Set_Sold_List_Page] = useState(1);
  const [Sold_Count, Set_Sold_Count] = useState(0);
  const [Sold_List, Set_Sold_List] = useState([]);
  const [Created_Count, Set_Created_Count] = useState(0);
  const [Created_List, Set_Created_List] = useState([]);
  const [Created_List_Page, Set_Created_List_Page] = useState(1);
  const [Liked_Count, Set_Liked_Count] = useState(0);
  const [Liked_List, Set_Liked_List] = useState([]);
  const [Liked_List_Page, Set_Liked_List_Page] = useState(1);
  const [LoadmoreStatus, Set_Loadmore] = useState(false);
  const [notFount, Set_NotFount] = useState(false);
  const [otherNftCollection, setotherNftCollection] = useState([])
  const [item, Set_item] = useState({});
  const [Activity_Item, Set_Activity_Item] = useState([])
  const [userCollection, setUserCollections] = useState({});
  const [UserCollectionCount, setUserCollectionCount] = useState(0)
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [AlldataCount, setAllDataCount] = useState({})
  const [UserCollectionCount2, setUserCollectionCount2] = useState(0)
  const [cursor, set_cursor] = useState(null)
  const [BNB_Count, Set_BNB_Count] = useState(0);
  const [BNB_List, Set_BNB_List] = useState([]);
  const [BNB_List_Page, Set_BNB_List_Page] = useState(1);
  const [ETH_Count, Set_ETH_Count] = useState(0);
  const [ETH_List, Set_ETH_List] = useState([]);
  const [ETH_List_Page, Set_ETH_List_Page] = useState(1);
  const [Myitems, setMyitems] = useState({ display: "More", count: '', navlink: '' });
  const [TabName, SetTabName] = useState('BNB')

  //loadmore
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [usercollectionloadmore, setUsercollectionloadmore] = useState(true);





  useEffect(() => {

    if (Wallet_Details.UserAccountAddr !== "" || ParamAccountCustomUrl !== '' || paramAddress !== '') {
      getInit();

    }

  }, [Wallet_Details.UserAccountAddr, followButton]);



  const getNftCollection = async (data1) => {
    setdisabled(true)


    let data = await getAllNFTFromContract(data1)
    console.log('data1data1', data1, data)
    if (data?.data?.list != '' && data?.data?.count > 0) {
      if (data1.click == 'tab') {
        setotherNftCollection(data.data.list);
        setMyitems({ 'display': "User Collectibles", 'count': data?.data?.list?.length })
      }
      else if (data1.click == 'loadmore') {
        setotherNftCollection(otherNftCollection.concat(data.data.list));
        setMyitems({ 'display': "User Collectibles", 'count': data?.data?.list?.length + otherNftCollection.length })
      }
    }
    if (data?.data?.list?.length > 0 && data?.data?.cursor !== null && data?.data?.count > 0) {
      set_cursor(data?.data?.cursor)
      setUsercollectionloadmore(true)
    } else {
      setUsercollectionloadmore(false)
    }
    if (data?.data?.count) {
      setUserCollectionCount(otherNftCollection?.length + 10)
      setUserCollectionCount2(data?.data?.count)
    }
    setdisabled(false)
  }

  const Get_MyItemAccountAddr_Details = async (payload) => {
    if (Wallet_Details.UserAccountAddr !== "" || paramAddress !== "" || ParamAccountCustomUrl !== "") {
      let Resp = await ParamAccountAddr_Detail_Get(payload);
      if (
        Resp
        && Resp.data
        && Resp.data.User
        && Resp.data.User.curraddress
      ) {
        Set_MyItemAccountAddr(Resp.data.User.curraddress);
        getAllCount(Resp.data.User.curraddress);
        if (Resp.data.User) {
          Set_MyItemAccountAddr_Details(Resp.data.User);
          getProfileData(Resp.data.User.curraddress);
        }
      }
      else {
        toast.warning('User not found', toasterOption);
        Set_UserNotFound(true);
      }
    }
  }

  const getInit = async () => {
    let currAddr = Wallet_Details.UserAccountAddr;
    let payload = {}
    if ((ParamAccountAddr !== undefined || ParamAccountCustomUrl !== undefined) && (ParamAccountAddr !== "" || ParamAccountCustomUrl !== "") && (ParamAccountAddr !== undefined || ParamAccountCustomUrl !== undefined)) {
      if (ParamAccountAddr && ParamAccountAddr.toString() === currAddr.toString()) {
        Set_MyItemAccountAddr(ParamAccountAddr);

        payload.addr = ParamAccountAddr
      }
      else {
        if (ParamAccountAddr !== "") {

          payload.addr = ParamAccountAddr;
        }
        else if (ParamAccountCustomUrl !== "") {
          payload.customurl = ParamAccountCustomUrl;
        }
      }
    }
    else {
      Set_MyItemAccountAddr(currAddr);
      payload.addr = currAddr
    }
    
    if(currAddr){
      await Get_MyItemAccountAddr_Details(payload);
    }

    window.$('#AfterWalletConnected_two').click();
  }

  const getAllCount = async (addr) => {
    let payload = {}
    payload.currAddr = Wallet_Details.UserAccountAddr;
    payload.address = addr
    payload.BNBnetwork = network?.BSC?.Chainid;
    payload.ETHnetwork = network?.ETH?.Chainid;
    let countdata = await getAllCounts(payload);
    console.log('sojfsklfs', countdata)
    if (countdata && countdata?.data && countdata?.data?.success) {
      setAllDataCount(countdata.data)
    }
  }

  const getProfileData = async (addrchk) => {
    const currAddr = isEmpty(paramAddress) ? addrchk : paramAddress;
    let reqData = {
      currAddr
    }
    let data = await getprofile(reqData);
    setProfile(data.userValue);
    if (data.userValue) {
      if (data.userValue.coverimage !== "") {
        let coverimage = `${config.Back_URL}/images/coverimages/${data.userValue._id}/${data.userValue.coverimage}`;
        setcoverphoto(coverimage);
      }
    }
  }

  async function AfterWalletConnected_two() {
    await checkFollowUser();
    await getfollowers1();//
    await getfollowing(); //
    await getuserCollections();
    await Tab_Data_Call('List', 'BNB', 'true', BNB_List_Page);
    Set_BNB_List_Page(BNB_List_Page + 1)
  }

  const checkFollowUser = async () => {
    const currAddr = MyItemAccountAddr_Details.curraddress;
    let reqData = {
      curraddress: currAddr,
      followeraddress: Wallet_Details.UserAccountAddr
    }
    let data = await checkFollower(reqData);
    if (data && data.follower && data.follower.success === true) {
      if (data.follower.message) {
        setFollowButton(data.follower.message)
      }
    }
  }


  const getfollowers1 = async () => {
    const currAddr = MyItemAccountAddr;
    let reqData = {

      curraddress: currAddr,
      tab: "follower"
    }
    let data = await getFollowers(reqData);

    if (data && data.follower && (data.follower.list).length !== 0) {
      setFollowList(data.follower.list);
    }
  }


  const getfollowing = async () => {
    const currAddr = MyItemAccountAddr;
    let reqData = {
      curraddress: currAddr,
      tab: "following"
    }
    let data = await getFollowers(reqData);
    if (data && data.follower && (data.follower.list).length !== 0) {
      setFollowingList(data.follower.list);
    }
    else {
      setFollowingList([])
    }
  }
  
  const getuserCollections = async () => {
    let reqData = {
      userAddress: MyItemAccountAddr
    }
    let userdata = await getUserCollectiondata(reqData)
    if (userdata && userdata.data && userdata.data.data && userdata.data.data.collections) {
      setUserCollections(userdata.data.data.collections)
    }
  }

  async function Tab_Data_Call(Target, TabName, init, pageno) {
    if (MyItemAccountAddr !== "") {
      let pagelimit = 10;
      let networkid
      if (TabName == "BNB") networkid = network?.BSC?.Chainid;
      else if (TabName == "ETH") networkid = network?.ETH?.Chainid;
      let ReqData = {
        Addr: MyItemAccountAddr,
        MyItemAccountAddr: MyItemAccountAddr,
        ParamAccountAddr: ParamAccountAddr,
        UserAccountAddr: Wallet_Details.UserAccountAddr,
        Target: Target,
        TabName: TabName,
        init: init,
        limit: pagelimit,
        from: 'My-Items',
        page: pageno,
        network: networkid,
      };
      Set_LoadDisable(true)
      let Resp = {};
      let RespNew = await CollectiblesList_MyItems(ReqData);
      console.log('CollectiblesList_MyItems_resp', RespNew, ReqData)
      if (
        (Target === 'Count' && typeof RespNew.count != 'undefined')
        ||
        (Target === 'List' && RespNew?.list)
      ) {
        Set_LoadDisable(false)
        if (TabName === 'collectibles') {
          if (Target === 'Count') { Set_Collectibles_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_Collectibles_List(RespNew.list)
            }
            else {
              Set_Collectibles_List(Collectibles_List.concat(RespNew.list));
            }
          }
        }
        else if (TabName === 'onsale') {
          if (Target === 'Count') { Set_OnSale_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_OnSale_List(RespNew.list);
            }
            else {
              Set_OnSale_List(OnSale_List.concat(RespNew.list));
            }
          }
        }
        else if (TabName === 'created') {
          if (Target === 'Count') { Set_Created_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_Created_List(RespNew.list);
            }
            else {
              Set_Created_List(Created_List.concat(RespNew.list));
            }
          }
        }
        else if (TabName === 'liked') {
          if (Target === 'Count') { Set_Liked_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_Liked_List(RespNew.list);
            }
            else {
              Set_Liked_List(Liked_List.concat(RespNew.list));
            }
          }
        }
        else if (TabName === 'BNB') {
          if (Target === 'Count') { Set_BNB_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_BNB_List(RespNew.list);
            }
            else {
              Set_BNB_List(BNB_List.concat(RespNew.list));
            }
          }
        }
        else if (TabName === 'ETH') {
          if (Target === 'Count') { Set_ETH_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_ETH_List(RespNew.list);
            }
            else {
              Set_ETH_List(ETH_List.concat(RespNew.list));
            }
          }
        }
        else if (TabName === 'sold') {
          if (Target === 'Count') { Set_Sold_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_Sold_List(RespNew.list);
            }
            else {
              Set_Sold_List(Sold_List.concat(RespNew.list));
            }
          }
        }
      }
    }
    return true;
  }


  async function CorrectDataGet(Resp, Target) {
    let RetData = { count: 0, list: [] }
    if (
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.list
      && Resp.data.list.length > 0
      && Resp.data.list[0]
    ) {
      if (Resp.data.Target === 'Count' && Resp.data.list[0].count) {
        RetData.count = Resp.data.list[0].count;
      }
      else if (Resp.data.Target === 'List' && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }
    return RetData;
  }


  const handleFile = async (e) => {

    let reader = new FileReader()
    let file = e.target.files[0];
    let url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setcoverphoto(reader.result)
    }.bind(this);


    let addr = MyItemAccountAddr;
    let reqdata = {
      coverimage: file,
      accounts: addr,
    }

    const { error } = await coverimagevalidations(reqdata)
    console.log("🚀 ~ handleFile ~ error:", error)

    if (error !== undefined) {
      setshowingLoader(true)
      if (isEmpty(error.errors)) {
        setchooseimage(true)

        setvalidateError('')
        let coverimg = await coverImage(reqdata)
        if (coverimg && coverimg.userValue !== undefined) {
          window.$('#edit_cover_modal').modal('hide');
          getProfileData();
          setshowingLoader(false)
          setchooseimage(false)
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setcoverphoto(Banner)
        }
      } else {
        setchooseimage(false)
        setshowingLoader(false)
        setvalidateError(error.errors)

      }
    } else {
      if (isEmpty(error)) {
        setshowingLoader(true)
        setchooseimage(true)
        setvalidateError('')
        let coverimg = await coverImage(reqdata)
        console.log("🚀 ~ handleFile ~ coverimg:", coverimg)
        if (coverimg && coverimg.userValue !== undefined) {
          window.$('#edit_cover_modal').modal('hide');
          setshowingLoader(false)
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
        else {
          setcoverphoto(Banner)
        }
      }
      else {
        setshowingLoader(false)
        setchooseimage(false)
        setvalidateError(error.errors)
      }
    }
  }


  const copyText = (a, b) => {
    toast.success('copied', toasterOption);
  }

  const followFun = async (followerAddress) => {
    const currAddr = MyItemAccountAddr_Details.curraddress;
    let reqData = {
      curraddress: currAddr,
      followeraddress: followerAddress
    }
    let data = await followUnfollow(reqData);
    if (data && data.follower && data.follower.success === true) {
      if (data.follower.message)
        toast.success(data.follower.message, toasterOption)
    }
    checkFollowUser();

  }

  async function Tab_Click(TabName, pageno) {
    Set_NotFount(false)
    if (pageno > 0) {
      await Tab_Data_Call('List', TabName, true, pageno);
    }
    else {
      Set_Loadmore(false)
      await Tab_Data_Call('List', TabName, true, 1);
    }
    if (TabName === 'collectibles') {
      Set_Collectibles_List_Page(Collectibles_List_Page + 1)
    }
    else if (TabName === 'onsale') {
      Set_OnSale_Page(OnSale_Page + 1)
    }
    else if (TabName === 'created') {
      Set_Created_List_Page(Created_List_Page + 1)
    }
    else if (TabName === 'liked') {
      Set_Liked_List_Page(Liked_List_Page + 1)
    }
    else if (TabName === 'BNB') {
      Set_BNB_List_Page(BNB_List_Page + 1)
    }
    else if (TabName === 'ETH') {
      Set_ETH_List_Page(ETH_List_Page + 1)
    }
    else if (TabName === 'sold') {
      Set_Sold_List_Page(Sold_List_Page + 1)
    }
    else if (TabName === 'usercollectibles') {
      const collAddr = isEmpty(paramAddress) ? window?.web3?.eth?.defaultAccount : paramAddress;
      getNftCollection({ click: "tab", collectionAddress: collAddr, limit: 10, cursor: null, from: "my-item", Chain: Wallet_Details?.networkConfiguration?.EvmName })
    }
  }


  const statusChangeactivity = async (data) => {
    let reqdata = {
      currAddr: (Wallet_Details.UserAccountAddr).toLowerCase(),
      tokenCounts: data.tokenCounts,
      _id: data._id
    }
    let noti = await notificationStatusChange(reqdata)
  }

  const onLoadMore = () => {
    const collAddr = isEmpty(paramAddress) ? window.web3.eth.defaultAccount : paramAddress;
    getNftCollection({ click: "loadmore", collectionAddress: collAddr, limit: 10, cursor: cursor, from: "my-item", Chain: Wallet_Details?.networkConfiguration?.EvmName })
  }

  const DropdownView = (disp, count) => {
    setMyitems({ ...Myitems, ...{ 'display': disp, 'count': count } })
  }

  return (
    <div className="inner_header">

      <WalletRef
        ref={WalletForwardRef}



      />
      <LikeRef

        ref={LikeForwardRef}


        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <ReportNowRef

        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />


      <div id="AfterWalletConnected_two" onClick={() => AfterWalletConnected_two()}></div>
    <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader items_header my_items"}>
        <div>
          <Row className="mx-0">
            <Col xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg">
                <img src={coverimage} alt="profile" className="img-fluid" />
                <div className="container edit_cover_container">
                  {
                    Wallet_Details.UserAccountAddr === MyItemAccountAddr_Details.curraddress &&
                    <p className="edit_cover_text" data-toggle="modal" data-target="#edit_cover_modal">Edit cover</p>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row className="mx-0">
            <Col xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg_pink">
                <div className="item_prof">
                  <div className="item_prof_img">
                    {MyItemAccountAddr_Details && MyItemAccountAddr_Details.image !== "" &&
                      <img src={`${config.Back_URL}/images/${MyItemAccountAddr_Details._id}/${MyItemAccountAddr_Details.image}`} alt="profile" className="img-fluid items_profile" />}

                    {
                      MyItemAccountAddr_Details && MyItemAccountAddr_Details.image === "" &&
                      <Avatars item="img-fluid items_profile" />
                      //  <Avatars classValue="img-fluid items_profile"/>
                    }

                  </div>

                </div>
                <p className="text-center">
                  <span className="address_text">{MyItemAccountAddr_Details && MyItemAccountAddr_Details.curraddress}</span>
                  <span>
                    <CopyToClipboard text={MyItemAccountAddr_Details.curraddress} onCopy={() => copyText('invite link', MyItemAccountAddr_Details.curraddress)}>

                      <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                    </CopyToClipboard>
                  </span>
                </p>


                {(MyItemAccountAddr_Details && MyItemAccountAddr_Details.name) && <p className="text-center bio_desc">
                  {MyItemAccountAddr_Details.name}
                </p>}
                <p className="text-center bio_desc">
                  {MyItemAccountAddr_Details && MyItemAccountAddr_Details.bio}
                </p>


                <div className="mt-3  ">

                  <p className="mb-1 text-center sociallinks">
                    {
                      MyItemAccountAddr_Details?.SocialLinks?.length > 0 &&
                      MyItemAccountAddr_Details?.SocialLinks?.map((val) => {
                        return (
                          <>
                            <a href={val?.url} target="_blank">
                              <span className="pl-3">
                                <i className={val?.classname + " notes_fa mr-2"}></i>
                                {val.url}</span> </a>
                          </>
                        )
                      })
                    }
                  </p>
                </div>




                <div className="mt-3 temp_more_details">
                  {
                    (MyItemAccountAddr_Details.curraddress === Wallet_Details.UserAccountAddr) ?
                      <button className='btn newbtn'>
                        <span class="gradbtn grad-bordbtn">
                          <Link className="" to="/edit-profile">Edit Profile</Link>
                        </span>
                      </button>
                      :
                      <button className='btn newbtn' onClick={() => followFun(Wallet_Details.UserAccountAddr)}>
                        <span class="gradbtn grad-bordbtn">{followButton}</span>
                      </button>
                  }
                  <span className="bg_red_icon cur_poinet" data-toggle="modal" data-target="#share_modal1">
                    <i className="fas fa-share-alt"></i>
                  </span>


                  <div className="ms-3">
                    <div
                      className="menu_dropdown button dropdown_header_ul noti_ul myitemsds position-relative">
                        <Dropdown className="position-relative"> 
                          <DropdownToggle>
                            {/* More */}
                            {Myitems.display}
                            </DropdownToggle>
                          <DropdownMenu>
                          <div  className="nto_scrol_div h_sets">
                              <ul className="explorebg">



                                <li className="px-3" id="liked-tab" data-toggle="tab" href="#liked" role="tab" aria-controls="liked" aria-selected="false">
                                  <div className="media">
                                    <div className="media-body">
                                      <div>
                                        <p className="mt-0 myitemscolelds mb-0" onClick={() => { DropdownView("Liked", AlldataCount.likeCount); Tab_Click('liked', 0); SetTabName('liked') }}>Liked</p>


                                      </div>

                                    </div>
                                  </div>

                                </li>
                                <li className="px-3" id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false">
                                  <div className="media">
                                    <div className="media-body">
                                      <div>
                                        <p className="mt-0 myitemscolelds mb-0" onClick={() => { DropdownView("Following", AlldataCount.followersCount); getfollowing(); SetTabName('following') }}>Following</p>


                                      </div>

                                    </div>
                                  </div>

                                </li>
                                <li className="px-3" id="followers-tab" data-toggle="tab" href="#followers" role="tab" aria-controls="followers" aria-selected="false">
                                  <div className="media">
                                    <div className="media-body">
                                      <div>
                                        <p className="mt-0 myitemscolelds mb-0" onClick={() => { DropdownView("Followers", AlldataCount.followersCount); getfollowers1(); SetTabName('followers') }}>Followers</p>


                                      </div>

                                    </div>
                                  </div>

                                </li>




                              </ul>

                            </div>
                          </DropdownMenu>
                        </Dropdown>
                    </div>
                  </div>



                </div>



              </div>
            </Col>
          </Row>
        </div>
        <div className="container-fluid container-theme">
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <nav className="masonry_tab_nav mt-4 mb-5 items_tab_outer">
                    <div className="nav nav-tabs masonry_tab kr_masonry items_tab items_tab_new pb-2 pl-2 d-flex justify-content-start" id="nav-tab" role="tablist">
                      <a className={TabName == 'BNB' ? "nav-link active" : "nav-link"} id="BNB-tab" data-toggle="tab" href="#BNB" role="tab" aria-controls="BNB" aria-selected="false" onClick={() => { Tab_Click('BNB', 0); SetTabName('BNB') }}>
                        <div className="tab_head">BNB Collections</div>
                        <div className="tab_count">({AlldataCount && AlldataCount.BNBCount})</div>
                      </a>
                      <a className={TabName == 'ETH' ? "nav-link active" : "nav-link"} id="ETH-tab" data-toggle="tab" href="#ETH" role="tab" aria-controls="ETH" aria-selected="false" onClick={() => { Tab_Click('ETH', 0); SetTabName('ETH') }}>
                        <div className="tab_head">ETH Collections</div>
                        <div className="tab_count">({AlldataCount && AlldataCount.ETHCount})</div>
                      </a>
                      <a className={TabName == 'created' ? "nav-link active" : "nav-link"} id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false" onClick={() => { Tab_Click('created', 0); SetTabName('created') }}>
                        <div className="tab_head">Created</div>
                        <div className="tab_count">({AlldataCount && AlldataCount.createdCount})</div>
                      </a>
                      <a className={TabName == 'sold' ? "nav-link active" : "nav-link"} id="sold-tab" data-toggle="tab" href="#sold" role="tab" aria-controls="sold" aria-selected="false" onClick={() => { Tab_Click('sold', 0); SetTabName('sold') }}>
                        <div className="tab_head">Sold</div>
                        <div className="tab_count">({AlldataCount && AlldataCount.SoldCount})</div>
                      </a>
                      <a className={TabName == 'onsale' ? "nav-link active" : "nav-link"} id="onsale-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="onsale" aria-selected="true" onClick={() => { Tab_Click('onsale', 0); SetTabName('onsale') }} >
                        <div className="tab_head">OnSale</div>
                        <div className="tab_count">({AlldataCount && AlldataCount.onsaleCount})</div>
                      </a>
                      <a className={TabName == 'userCollection' ? "nav-link active" : "nav-link"} id="UserCollection-tab" data-toggle="tab" href="#userCollection" role="tab" aria-controls="userCollection" aria-selected="true" onClick={() => { getuserCollections(); SetTabName('userCollection') }} >
                        <div className="tab_head">Collections</div>
                        <div className="tab_count">({AlldataCount && AlldataCount.CollectionCount})</div>
                      </a>
                      <a className={TabName == 'collectibles' ? "nav-link active" : "nav-link"} id="collectibles-tab" data-toggle="tab" href="#collectibles" role="tab" aria-controls="collectibles" aria-selected="true" onClick={() => { Tab_Click('collectibles', 0); SetTabName('collectibles') }} >
                        <div className="tab_head">Collectibles</div>
                        <div className="tab_count">({AlldataCount && AlldataCount.collectibleCount})</div>
                      </a>
                      <a className={TabName == 'usercollectibles' ? "nav-link active" : "nav-link"} id="NewCollectibles_tab" data-toggle="tab" href="#NewCollectibles" role="tab" aria-controls="NewCollectibles" aria-selected="true" onClick={() => { Tab_Click('usercollectibles', 0); SetTabName('usercollectibles') }}>
                        <div className="tab_head">User Collectibles</div>
                        <div className="tab_count">
                          {/* ({AlldataCount && AlldataCount.onsaleCount}) */}
                        </div>
                      </a>
                    </div>
                  </nav>


                </div>

              </div>


              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className={TabName == 'onsale' ? "tab-pane fade show active" : "tab-pane fade"} id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                  <div className="proposal_panel_overall">
                    {(OnSale_List.length === 0) ? (
                      <div className="text-center py-5">
                        {notFount === true && <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">OnSale NFT Not Found</p>
                        </>
                        }

                        <div className="mt-3">
                        </div>
                      </div>

                    ) :
                      (
                        <div className=" m-0 ma_no_gap myitemcards">
                          <>
                            <div className="row">

                              {
                                OnSale_List.map((item) => {

                                  return (
                                    (isEmpty(item.tokenowners_current) !== true) ?
                                      <div className="col-lg-3 col-md-4 col-sm-6">
                                        <TokenCard
                                          item={item}
                                          Set_item={Set_item}
                                          LikedTokenList={LikedTokenList}
                                          setLikedTokenList={setLikedTokenList}
                                          hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}

                                        />
                                      </div>
                                      : ("")
                                  )
                                })}
                            </div>
                          </>

                        </div>
                      )}
                    {LoadmoreStatus == true &&

                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }} onClick={() => { Tab_Click('onsale', OnSale_Page) }} disabled={LoadDisable} >
                            Load More <span>

                            </span>

                          </button>

                        </div>
                      </div>
                    }

                  </div>

                </div>

                <div className={TabName == 'collectibles' ? "tab-pane fade show active" : "tab-pane fade"} id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                  <div className="proposal_panel_overall">
                    {(Collectibles_List.length === 0) ? (

                      <div className="text-center py-5">
                        {notFount === true && <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">Collectibles NFT Not Found</p>
                        </>
                        }
                        <div className="mt-3">

                        </div>
                      </div>) :


                      (<div className=" myitemcards m-0 ma_no_gap">


                        <div className="row">
                          {
                            Collectibles_List.map((item) => {

                              return (
                                (isEmpty(item.tokenowners_current) !== true) ?
                                  <div className="col-lg-3 col-md-4 col-sm-6">
                                    <TokenCard
                                      item={item}
                                      Set_item={Set_item}
                                      LikedTokenList={LikedTokenList}
                                      setLikedTokenList={setLikedTokenList}
                                      hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}

                                    />

                                  </div>

                                  : ("")
                              )
                            })}
                        </div>


                      </div>
                      )}



                    {LoadmoreStatus == true &&
                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore"
                            onClick={() => { Tab_Click('collectibles', Collectibles_List_Page) }} disabled={LoadDisable} style={{ display: "flex" }}>
                            Load More <span>

                            </span>

                          </button>

                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className={TabName == 'created' ? "tab-pane fade show active" : "tab-pane fade"} id="created" role="tabpanel" aria-labelledby="created-tab">
                  <div className="proposal_panel_overall">
                    {(Created_List.length === 0) ? (

                      <div className="text-center py-5">
                        {notFount === true && <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">Created NFT Not Found</p>
                        </>
                        }
                        <div className="mt-3">

                        </div>
                      </div>) :
                      (
                        <div className=" myitemcards m-0 ma_no_gap">



                          <div className="row">
                            {
                              Created_List.map((item) => {

                                return (
                                  (isEmpty(item.tokenowners_current) !== true) ?
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                      <TokenCard
                                        item={item}
                                        Set_item={Set_item}
                                        LikedTokenList={LikedTokenList}
                                        setLikedTokenList={setLikedTokenList}
                                        hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                        

                                      />
                                    </div>
                                    : ("")
                                )
                              })}
                          </div>

                        </div>

                      )}
                    {LoadmoreStatus == true &&

                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore"
                            onClick={() => { Tab_Click('created', Created_List_Page) }} disabled={LoadDisable} style={{ display: "flex" }}>
                            Load More <span>

                            </span>

                          </button>

                        </div>
                      </div>
                    }

                  </div>
                </div>

                <div className={TabName == 'liked' ? "tab-pane fade show active" : "tab-pane fade"} id="liked" role="tabpanel" aria-labelledby="liked-tab">
                  <div className="proposal_panel_overall">
                    {(Liked_List.length === 0) ? (

                      <div className="text-center py-5">
                        {notFount === true && <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">Liked NFT Not Found</p>
                        </>
                        }
                        <div className="mt-3">

                        </div>
                      </div>) :


                      (<div className=" myitemcards">





                        <div className="row">


                          {
                            Liked_List.map((item) => {

                              return (
                                (isEmpty(item.tokenowners_current) !== true) ?

                                  <div className="col-lg-3 col-md-4 col-sm-6">
                                    <TokenCard
                                      item={item}
                                      Set_item={Set_item}
                                      LikedTokenList={LikedTokenList}
                                      setLikedTokenList={setLikedTokenList}
                                      hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}

                                    />
                                  </div>
                                  : ("")


                              )
                            })}
                        </div>



                      </div>

                      )}
                    {LoadmoreStatus == true &&
                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore"
                            onClick={() => { Tab_Click('liked', Liked_List_Page) }} disabled={LoadDisable} style={{ display: "flex" }}>
                            Load More <span>

                            </span>

                          </button>

                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className={TabName == 'activity' ? "tab-pane fade show active" : "tab-pane fade"} id="activity" role="tabpanel" aria-labelledby="activity-tab">
                  <div className="proposal_panel_overall">
                    {(Activity_Item.length === 0) ?

                      <div className="text-center py-5">
                        <p className="not_found_text">Item Not Found</p>
                        <p className="not_found_text_sub">Activity Not Found</p>
                        <div className="mt-3">

                        </div>
                      </div> :
                      <div className="followers_overall py-1" >

                        <div className="row" >
                          {
                            Activity_Item.map((item) => {
                              return (
                                <div className="col-12 col-md-6 col-xl-4 mb-4" onClick={() => statusChangeactivity(item)}>
                                  <div className="card my-0 h-100 acti_card">
                                    <div className="card-body px-3">
                                      <div className="media follow_media icon_img">
                                        <div className="icon-img-div">
                                          <div className="">
                                          </div>
                                          <div className="img_media_new  mr-3">



                                            {
                                              item
                                              && item.fromField
                                              && item.fromField.image
                                              && item.fromField.image !== ""
                                              && <img onClick={() => navigate(item.fromField.customurl !== "" ? `/:${item.fromField.customurl}` : `/user/${item.to}`)} src={`${config.Back_URL}/images/${item.fromField._id}/${item.fromField.image}`} alt="User" className="img-fluid" />
                                            }
                                            {
                                              (item.fromField === undefined
                                                || item.fromField.image === undefined
                                                || item.fromField.image === "")
                                              &&
                                              <div className="img-fluid">
                                                <Avatars onClick={() => navigate(item.fromField.customurl !== "" ? `/:${item.fromField.customurl}` : `/user/${item.to}`)} item="img-fluid" /></div>
                                            }




                                          </div>
                                        </div>
                                        <div className="media-body flex_body">
                                          <div>


                                            {item.to && <div onClick={() => navigate(`/user/${item.to}`)}>
                                              <p className="my-0 media_text" title={(item.touserField && item.touserField.name !== "") ? item.touserField.name : item.to}>{(item.touserField && item.touserField.name !== "") ? item.touserField.name : item.to && item.to !== "" && (String(item.to).slice(0, config.My_item_Limit).concat('...'))}</p></div>}
                                            {item.activity && <div onClick={() => navigate(`/info/${item.tokenOwner}/${item.contractAddress}/${item.tokenCounts}`)}><p className="mt-0 media_num mt-0">
                                              <b>{item.tokens_current && item.tokens_current.tokenName !== "" && item.tokens_current.tokenName}</b> {item.activity}</p></div>}
                                            {item.from && <div onClick={() => navigate(`/user/${item.from}`)}><p className="my-0 media_text" title={(item.userField && item.userField.name !== "") ? item.userField.name : (item.from)}>{(item.userField && item.userField.name !== "") ? item.userField.name : item.from !== "" && String(item.from).slice(0, config.My_item_Limit).concat('...')}</p></div>}


                                          </div>







                                        </div>
                                      </div>
                                      <div className="ml-2">
                                        <p className="mt-0 media_num mb-0 dur_text">		{moment(item.created).format('MM/DD/YYYY, h:mm a')}
                                        </p>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              )
                            })}

                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className={TabName == 'following' ? "tab-pane fade show active" : "tab-pane fade"} id="following" role="tabpanel" aria-labelledby="following-tab">
                  {followingList && followingList.length === 0 ?
                    <div className="text-center py-5">
                      <p className="not_found_text">following Not Found</p>
                      <div className="mt-3">

                      </div>
                    </div> :
                    <div className="followers_overall py-1">
                      <div className="row">
                        {followingList && followingList.map((User) => {
                          return (
                            <div className="col-12 col-md-6 col-xl-4 mb-4">
                              <div className="card my-0">
                                <div className="card-body">
                                  <div className="media follow_media">
                                    <div className="img_media_new  mr-3">
                                      {User && User.user && !isEmpty(User.user) && User.user.image && User.user.image !== "" ?
                                        <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>
                                          <img src={`${config.Back_URL}/images/${User.user._id}/${User.user.image}`} alt="User" className="img-fluid" onClick={User.user.customurl !== "" ? `/${User.user.customurl}` : `/user/${User.user.curraddress}`} />
                                        </Link>
                                        :
                                        <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                          <div className="img-fluid">
                                            <Avatars item="img-fluid" /></div>

                                        </Link>
                                      }
                                      {
                                        User && User.user && isEmpty(User.user) &&


                                        <div className="img-fluid">
                                          <Link to={'/user/' + User.followerAddress}>
                                            <Avatars item="img-fluid" />  </Link>
                                        </div>

                                      }

                                    </div>
                                    <div className="media-body flex_body"
                                    >
                                      <div>
                                        {

                                          User && User.user && !isEmpty(User.user) && <>
                                            <p className="mt-0 media_num font_14 mb-0">You Started Following</p>
                                            <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                              <p className="my-0 media_text" title={'User :' + (User.user.name !== "" ? User.user.name : User.user.curraddress)}>{(User.user.name !== "" ? User.user.name : User.user.curraddress !== "" && String(User.user.curraddress).slice(0, 10).concat("..."))}</p>
                                            </Link>
                                          </>

                                        }
                                        {
                                          User && User.user && isEmpty(User.user) && <>

                                            <p className="mt-0 media_num font_14 mb-0">You Started Following  </p>
                                            <Link to={'/user/' + User.followerAddress}>
                                              <p className="my-0 media_text" title={'User :' + User.followerAddress}>{(User.followerAddress).slice(0, 10).concat("...")}</p>

                                            </Link> </>}

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>

                    </div>
                  }
                </div>
                <div className={TabName == 'followers' ? "tab-pane fade show active" : "tab-pane fade"} id="followers" role="tabpanel" aria-labelledby="followers-tab">
                  <div className="proposal_panel_overall">
                    {followList && followList.length === 0 ?
                      <div className="text-center py-5">

                        <p className="not_found_text">followers Not Found</p>

                        <div className="mt-3">


                        </div>
                      </div> :
                      <div className="followers_overall py-1">
                        <div className="row">
                          {followList && followList.map((User) => {
                            return (

                              <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <div className="card my-0">
                                  <div className="card-body">
                                    <div className="media follow_media">
                                      <div className="img_media_new  mr-3">
                                        {User && User.user && !isEmpty(User.user) && User.user.image && User.user.image !== "" ?
                                          <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>
                                            <img src={`${config.Back_URL}/images/${User.user._id}/${User.user.image}`} alt="User" className="img-fluid" onClick={User.user.customurl !== "" ? `/${User.user.customurl}` : `/user/${User.user.curraddress}`} />
                                          </Link>
                                          :
                                          <Link href={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                            <div className="img-fluid">
                                              <Avatars item="img-fluid" /></div>

                                          </Link>
                                        }
                                        {
                                          User && User.user && isEmpty(User.user) &&


                                          <div className="img-fluid">
                                            <Link to={'/user/' + User.userAddress}>
                                              <Avatars item="img-fluid" />  </Link>
                                          </div>

                                        }


                                      </div>
                                      <div className="media-body flex_body"
                                      >
                                        <div>


                                          <>

                                            {

                                              User && User.user && !isEmpty(User.user) && <>
                                                <p className="mt-0 media_num font_14 mb-0">You Started Following</p>
                                                <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                                  <p className="my-0 media_text" title={'User :' + (User.user.name !== "" ? User.user.name : User.user.curraddress)}>{(User.user.name !== "" ? User.user.name : User.user.curraddress !== "" && String(User.user.curraddress).slice(0, 10).concat("..."))}</p>
                                                </Link>
                                              </>

                                            }
                                            {
                                              User && User.user && isEmpty(User.user) && <>

                                                <p className="mt-0 media_num font_14 mb-0">You Started Following  </p>
                                                <Link to={'/user/' + User.userAddress}>
                                                  <p className="my-0 media_text" title={'User :' + User.userAddress}>{(User.userAddress).slice(0, 10).concat("...")}</p>

                                                </Link> </>}

                                          </>

                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            )
                          })}

                        </div>

                      </div>
                    }
                  </div>
                </div>
                <div className={TabName == 'BNB' ? "tab-pane fade show active" : "tab-pane fade"} id="BNB" role="tabpanel" aria-labelledby="BNB-tab">
                  <div className="proposal_panel_overall">
                    {(BNB_List.length === 0) ? (
                      <div className="text-center py-5">
                        {notFount === true && <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">BNB NFT Not Found</p>
                        </>
                        }

                        <div className="mt-3">
                        </div>
                      </div>

                    ) :
                      (
                        <div className=" m-0 ma_no_gap myitemcards">
                          <>
                            <div className="row">

                              {
                                BNB_List.map((item) => {

                                  return (
                                    (isEmpty(item.tokenowners_current) !== true) ?
                                      <div className="col-lg-3 col-md-4 col-sm-6">
                                        <TokenCard
                                          item={item}
                                          Set_item={Set_item}
                                          LikedTokenList={LikedTokenList}
                                          setLikedTokenList={setLikedTokenList}
                                          hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}

                                        />
                                      </div>
                                      : ("")
                                  )
                                })}
                            </div>
                          </>

                        </div>
                      )}
                    {LoadmoreStatus == true &&

                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }} onClick={() => { Tab_Click('BNB', BNB_List_Page) }} disabled={LoadDisable} >
                            Load More <span>

                            </span>

                          </button>

                        </div>
                      </div>
                    }

                  </div>

                </div>
                <div className={(TabName == 'sold') ? "tab-pane fade show active" : "tab-pane fade"} id="sold" role="tabpanel" aria-labelledby="sold-tab">
                  <div className="proposal_panel_overall">
                    {(Sold_List.length === 0) ? (
                      <div className="text-center py-5">
                        {notFount === true && <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">ETH NFT Not Found</p>
                        </>
                        }

                        <div className="mt-3">
                        </div>
                      </div>

                    ) :
                      (
                        <div className=" m-0 ma_no_gap myitemcards">
                          <>
                            <div className="row">

                              {
                                Sold_List.map((item) => {

                                  return (
                                    (isEmpty(item.tokenowners_current) !== true) ?
                                      <div className="col-lg-3 col-md-4 col-sm-6">
                                        <TokenCard
                                          item={item}
                                          Set_item={Set_item}
                                          LikedTokenList={LikedTokenList}
                                          setLikedTokenList={setLikedTokenList}
                                          hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}

                                        />
                                      </div>
                                      : ("")
                                  )
                                })}
                            </div>
                          </>

                        </div>
                      )}
                    {LoadmoreStatus == true &&

                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }} onClick={() => { Tab_Click('sold', Sold_List_Page) }} disabled={LoadDisable} >
                            Load More <span>

                            </span>

                          </button>

                        </div>
                      </div>
                    }

                  </div>

                </div>

                <div className={(TabName == 'ETH') ? "tab-pane fade show active" : "tab-pane fade"} id="ETH" role="tabpanel" aria-labelledby="ETH-tab">
                  <div className="proposal_panel_overall">
                    {(ETH_List.length === 0) ? (
                      <div className="text-center py-5">
                        {notFount === true && <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">ETH NFT Not Found</p>
                        </>
                        }

                        <div className="mt-3">
                        </div>
                      </div>

                    ) :
                      (
                        <div className=" m-0 ma_no_gap myitemcards">
                          <>
                            <div className="row">

                              {
                                ETH_List.map((item) => {

                                  return (
                                    (isEmpty(item.tokenowners_current) !== true) ?
                                      <div className="col-lg-3 col-md-4 col-sm-6">
                                        <TokenCard
                                          item={item}
                                          Set_item={Set_item}
                                          LikedTokenList={LikedTokenList}
                                          setLikedTokenList={setLikedTokenList}
                                          hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}

                                        />
                                      </div>
                                      : ("")
                                  )
                                })}
                            </div>
                          </>

                        </div>
                      )}
                    {LoadmoreStatus == true &&

                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }} onClick={() => { Tab_Click('ETH', ETH_List_Page) }} disabled={LoadDisable} >
                            Load More <span>

                            </span>

                          </button>

                        </div>
                      </div>
                    }

                  </div>

                </div>





                <div className={TabName == 'usercollectibles' ? "tab-pane fade show active" : "tab-pane fade"} id="NewCollectibles" role="tabpanel" aria-labelledby="NewCollectibles-tab">
                  <div className="proposal_panel_overall">

                    {(UserCollectionCount == 0) ?
                      <>
                        <div className="text-center py-5">
                          <p className="not_found_text">No items found</p>
                          <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                          <div className="mt-3">
                          </div>
                        </div>
                      </>
                      :




                      <div className="row">
                        {console.log('otherNftCollectionotherNftCollection',otherNftCollection)}
                        {

                          ((otherNftCollection) || []).map((item, index) => {

                            var owner = item?.owner != '' ? item?.owner : Wallet_Details?.UserAccountAddr
                            console.log('itemitem',item)
                            return (
                              (item?.image != "" && item?.colladdress != network?.ETH?.singleContract && item?.colladdress != network?.ETH?.multipleContract && item?.colladdress != network?.BSC?.singleContract && item?.colladdress != network?.BSC?.multipleContract) ?

                                <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                  {console.log('itemitem',item)}
                                  <div className="tokens">

                                    <Link to={"/info/" + owner.toLowerCase() + "/" + item?.colladdress.toLowerCase() + "/" + item?.tokenCounts } state ={{ 'amount': item?.amount, 'imgurl': item?.image, 'title': item?.title, 'name': item?.name, 'type': item?.type, 'from': 'userCollection', 'metfile': item.metadata, 'ipfshash': item.ipfshash, 'meta': item.meta }}>
                                      <div className="baner_image">
                                        <div className="ethactios">





                                          <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>




                                            <>
                                              {
                                                (((otherNftCollection && item?.image)?.includes('ipfs://') === true || (item?.image)?.includes('ipfs/') === true || (item?.image)?.includes('ipfs:/') === true)
                                                  ? (

                                                    <object type="image/webp" data={config.IPFS_IMG + '/' + (((item?.image)?.split('ipfs://').pop()).split('ipfs/').pop()).split('ipfs:/').pop()}
                                                    >
                                                    </object>

                                                  )
                                                  :

                                                  (item?.image !== "" &&

                                                    <object type="image/webp" data={item?.image}
                                                    >
                                                    </object>
                                                  ))}


                                            </>

                                          </LazyLoad>


                                        </div>
                                        <div className="auctioncards">
                                          <div className="starreiaf">
                                            <h3 className="mb-3">{item?.name}</h3>


                                          </div>


                                          <div className="abaucdetail">

                                            <div className="banercontentauction">




                                              {(owner) &&


                                                <Link to={`/user/${(owner)}`} title={`Owner : ${(owner)}`}>
                                                  {MyItemAccountAddr_Details && MyItemAccountAddr_Details.image !== "" &&
                                                    <img src={`${config.Back_URL}/images/${MyItemAccountAddr_Details._id}/${MyItemAccountAddr_Details.image}`} alt="profile" className="img-fluid align-self-center" />}
                                                  {
                                                    MyItemAccountAddr_Details && MyItemAccountAddr_Details.image === "" &&
                                                    <Avatars item="img-fluid align-self-center" />
                                                  }
                                                </Link>
                                              }




                                              <div className="banercontentDet">
                                                <h2>@{(item?.colladdress)?.slice(0, 10).concat("....")}</h2>

                                              </div>
                                            </div>

                                          </div>
                                        </div>

                                      </div>

                                    </Link>
                                  </div>
                                </div>
                                :
                                ("")
                            )

                          })

                        }

                      </div>



                    }
                    {(usercollectionloadmore == true) &&
                      <div className="loadmorebtndssec">
                        <button className="loadmorebtnds" onClick={() => onLoadMore()}>
                          Load More
                        </button>
                      </div>
                    }
                  </div>
                </div>

                <div className={TabName == 'userCollection' ? "tab-pane fade show active" : "tab-pane fade"} id="userCollection" role="tabpanel" aria-labelledby="UserCollection-tab">
                  <div className="proposal_panel_overall">
                    {userCollection && userCollection.length === 0 ?
                      <div className="text-center py-5">
                        <p className="not_found_text">No items found</p>
                        <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                        <div className="mt-3">
                        </div>
                      </div> :

                      <div className="followers_overall py-1">
                        <div className="row">
                          {userCollection && userCollection.length > 0 && userCollection.map((User) => {
                            if (User && User.collectionAddress != null) {
                              var coll = User.collectionAddress
                            }
                            else {
                              var coll = ''
                            }
                            return (
                              <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <div className="card my-0">
                                  <div className="card-body">
                                    <div className="media follow_media">
                                      <div className="img_media_new  mr-3">


                                        {(User && User?.imageName !== "" || User?.imageName != undefined) ?
                                          <Link to={`/collectiondetail/${(User.userAddress)}/${(User.customurl)}/${coll}`} title={`Owner : ${(User.userAddress)}`}>

                                            <img src={`${config.Back_URL}/collectionLogo/${User.userAddress}/${User.imageName}`} alt="User" className="img-fluid" />
                                          </Link>
                                          :
                                          <Link to={`/collectiondetail/${(User.userAddress)}/${(User.customurl)}/${(coll)}`} title={`Owner : ${(User.userAddress)}`}>

                                            <div className="img-fluid">
                                              <Avatars item="img-fluid" /></div>

                                          </Link>
                                        }


                                      </div>
                                      <div className="media-body flex_body">
                                        <div>
                                          {
                                            User && !isEmpty(User) && <>
                                              <Link to={`/collectiondetail/${(User.userAddress)}/${(User.customurl)}/${(coll)}`} title={`Owner : ${(User.userAddress)}`}>
                                                <p className="mt-0 media_num font_14 mb-0">{(User.collectionName !== "" ? User.collectionName : User.collectionName !== "" && String(User.collectionName).slice(0, 10).concat("..."))}</p>


                                                <p className="my-0 media_text" >{(User.collectionType)}</p>
                                              </Link>
                                            </>
                                          }

                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            )
                          })}

                        </div>

                      </div>
                    }
                  </div>

                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
      {/* edit_cover Modal */}
      <div className="modal fade primary_modal" id="edit_cover_modal" tabIndex="-1" role="dialog" aria-labelledby="edit_cover_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">Update cover</h5>
              <h5 className="modal-title" id="edit_cover_modalLabel_2">Follow Steps</h5>


              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution and supported format PNG,GIF,WEPG,JPG</p>
                <form className="text-center">
                  <button type="button" className="file_btn btn primary_btn" disabled={chooseimage} >Choose image
                    <input className="inp_file"
                      type="file"
                      name="coverimage"
                      id="coverphoto"
                      onChange={(e) => handleFile(e)}
                    />
                  </button>


                </form>
                <div className="text-center mb-2">
                  {
                    validateError.file && <span className="text-danger">{validateError.file}</span>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}
      <div className="modal fade primary_modal" id="share_modal1" tabIndex="-1"
        role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-module">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="share_modalLabel">Share this NFT</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">

                  <span aria-hidden="true">&times;</span>
                </button>

              </div>
              <div className="modal-body px-0">
                <div className="row justify-content-center mx-0">
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">

                      <TwitterShareButton title={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}
                      >
                        <i className="fab fa-twitter"></i>
                        <p>Twitter</p>
                      </TwitterShareButton >

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">

                      <TelegramShareButton
                        title={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}
                      >

                        <i className="fab fa-telegram-plane"></i>
                        <p>Telegram</p>

                      </TelegramShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">
                      <FacebookShareButton
                        quote={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}

                      >
                        <i className="fab fa-facebook-f"></i>
                        <p>Facebook</p>
                      </FacebookShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">
                      <WhatsappShareButton
                        title={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}
                      >
                        <i className="fab fa-whatsapp"></i>
                        <p>Whatsapp</p>
                      </WhatsappShareButton>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
