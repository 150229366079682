import React, { useEffect, useState } from "react";



// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacypolicy(props) {

  const { ...rest } = props;


  return (
    <div className="inner_header">
     <Header/>
      <ScrollToTopOnMount/>
  
      <div className={" inner_pageheader"}>
      {/* {ReactHtmlParser(getcmslistinhome1.answer)}  */}
        <div className="container-fluid container-theme p-0">
        
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center"> 
                  
                <h3 className="section-head mb-0">Community Guidelines</h3>
              </div>
            </Col>
          </Row>
        </div>
        <div className="container-fluid container-theme mt-4">
          <Row>
         answer
            <Col xs={12} sm={12} md={12}>
            </Col>
          </Row>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
