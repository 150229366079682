//kslp
import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

import { Link, useNavigate, useParams } from "react-router-dom";
// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";


// Import css files

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// mysid
import TokenCard from './separate/TokenCard'
import {
  CollectiblesList_Home,
  GetCategoryAction,
  getUserCollectiondata
} from '../actions/v1/token';
import isEmpty from "../lib/isEmpty";
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';
import collectionimsg from "../assets/images/collections_02.png"




import Carouselimg from "../assets/images/artimage/caroo.png";
import proimg from "../assets/images/artimage/proimg.png";

import ReactReadMoreReadLess from "react-read-more-read-less";

import { getAllNFTFromContract, addUserCollection } from '../actions/v1/user';
import LazyLoad from 'react-lazyload';
import LazyLoader from "./lazyloader";
import config from '../lib/config';
import { useSelector } from "react-redux";
import { network } from "./network"
import { LikeRef } from "./separate/LikeRef";
import moment from "moment";

// toast.configure();
const dashboardRoutes = [];


export default function Art(props) {


  const { ...rest } = props;
  const navigate = useNavigate();
  var { param, customurl, collAddr } = useParams();
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  console.log('Wallet_DetailsWallet_Details',Wallet_Details)

  var LikeForwardRef = useRef();


  const [CollectionInfo, setCollectionInfo] = useState([]);
  const [disabled1, setdisabled1] = useState(false)
  const [CollectionList, Set_Collection_List] = useState([]);
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Collectiondata, setCollectiondata] = useState([])
  const [NFTCount, setNFTCount] = useState("")
  const [collectionName, setCollectionName] = useState("")
  const [UserCollectionCount2, setUserCollectionCount2] = useState(0)
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [cursor, set_cursor] = useState(null)
  const [UserCollectionCount, setUserCollectionCount] = useState(0)
  const [CollectionOwner, SetCollectionOwner] = useState({})
  const [Total, SetTotal] = useState({});



  const onLoadMore = () => {
    Get_All_Nft_From_Contract({ collectionAddress: collAddr, limit: 10, cursor: cursor, customurl: customurl })
  }


  useEffect(() => {
    //console.log("DBFHdfbnh", param, customurl, collAddr);
    if (collAddr !== '') {
      Get_All_Nft_From_Contract({ collectionAddress: collAddr, limit: 10, cursor: null, customurl: customurl })
    }
  }, [])

  useEffect(() => {
    getInit();
  }, [Wallet_Details?.UserAccountAddr])

  async function getInit() {
    TokenListCall();
    CollectionProfile()

  }

  async function TokenListCall() {
    let currAddr = Wallet_Details.UserAccountAddr
    let payload = {
      currAddr: currAddr,
      customurl: customurl,
      from: 'collection'
    }
    // //console.log("TokenList call function", payload)
    let resp = await CollectiblesList_Home(payload);
    // console.log("After NFT", resp, resp.data);
    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      //console.log("After NFT", resp.data);
      Set_Collection_List(resp.data.list)
    }
    else {
      Set_Collection_List([])
    }
  }



  async function CollectionProfile() {
    let currAddr = Wallet_Details.UserAccountAddr
    let payload = {
      userAddress: param,
      customurl: customurl,
      currAddr: currAddr,
    }
    console.log("fgjnhfgjm", payload);
    let collectionInfo = await getUserCollectiondata(payload);
    console.log('lsgkdgaasgdss', collectionInfo)
    if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections) {

      let datas = collectionInfo.data.data;
      console.log("Collection Informations", datas, Wallet_Details);

      setCollectionInfo(collectionInfo?.data?.data?.collections)
      SetCollectionOwner(collectionInfo?.data?.data?.userdata)
      SetTotal({ TotalNfts: datas?.TotalNfts, Listed: ((datas?.ListedNfts ? datas?.ListedNfts : 0) / (datas?.TotalNfts ? datas?.TotalNfts : 1)) * 100, TotalOwners: datas?.TotalOwners ? datas?.TotalOwners : 0, TotalVolume: datas?.TotalVolume?.[0]?.totalprice ? datas?.TotalVolume?.[0]?.totalprice : 0, HighestBid: (datas?.HighestBid?.CoinName ? datas?.HighestBid?.tokenBidAmt + " " + datas?.HighestBid?.CoinName : 0), FloorPrice: datas?.Floor_Price })
    }
  }

  const Get_All_Nft_From_Contract = async (data) => {
    console.log("🚀 ~ constGet_All_Nft_From_Contract= ~ data:", data)
    setdisabled1(true)
    let allNft = await getAllNFTFromContract(data)
    if (allNft && allNft.data && allNft.data.list) {
      setCollectiondata(Collectiondata.concat(allNft.data.list))
      if (allNft?.data?.data?.owner) {
        setNFTCount(allNft.data.data.NFTCount)
      }
      if (allNft && allNft.data && allNft.data.list[0] && allNft.data.list[0].title) {
        setCollectionName(allNft.data.list[0].title)
      }

      if (allNft?.data?.cursor)
        set_cursor(allNft.data.cursor)

      if (allNft?.data?.count && allNft?.data?.list != '') {
        setUserCollectionCount(Collectiondata.length + 10)
        setUserCollectionCount2(allNft.data.count)

      }
      setdisabled1(false)
    }
  }


  async function FindOwner(item) {
    if (Collectiondata && Collectiondata.length > 0) {
      let reqdata = {
        counts: item.tokenCounts,
        type: item.type,
        colladdr: item.colladdress,
        from: 'FindOwner',
        myaddress: Wallet_Details.UserAccountAddr

      }
      let tokenOwner = await addUserCollection(reqdata)
      console.log("🚀 ~ FindOwner ~ tokenOwner:", tokenOwner)
      
      if (tokenOwner?.message?.data?.owner) {
        let owner = (tokenOwner.message.data.owner).toString().toLowerCase()
        let TokenOwner = owner.toLowerCase()
        const state = {
          'amount': item?.amount, 'imgurl': item?.image, 'title': item?.title, 'name': item?.name, 'type': item?.type, 'from': 'userCollection', 'metfile': item.metadata, 'ipfshash': item.ipfshash, 'meta': item.meta
        }
        const url = { 'pathname': "/info/" + TokenOwner.toLowerCase() + "/" + item?.colladdress.toLowerCase() + "/" + item?.tokenCounts }
        navigate(url, {state})
      }
      else {
        toast.warn('There are no owners in this collection');
      }
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (

    <div>
      <div className="home_header">
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
        />
      <Header/>
        <section>
          <div className="container-fluid container-theme container-lg mt-5 mt-md-0">

            <div className="section-carousel pt-5">
              {/* <OwlCarousel className='owl-theme' {...optionlist}>
                                {carousels.map((e, i) =>
                                    <div class="item">
                                        <div class="card artdetail">
                                            <img class="card-img-top" src={Carouselimg} alt="Card image cap" />
                                            <button type="button" class="btn btn-primary">View Collection</button>
                                        </div>
                                    </div>
                                )}
                            </OwlCarousel> */}
              <div class="item">
                <div class="card artdetail">
                  {!isEmpty(CollectionInfo) && CollectionInfo[0].coverimage ?
                    <img src={`${config.Back_URL}/coverphoto/${param}/${CollectionInfo[0].coverimage}`} alt="profile" className="card-img-top" />
                    :
                    <img class="card-img-top" src={Carouselimg} alt="Card image cap" />
                  }

                </div>
              </div>
            </div>

            <div className="profileimg">
              {!isEmpty(CollectionInfo) && CollectionInfo[0].imageName ?
                <img src={`${config.Back_URL}/collectionLogo/${param}/${CollectionInfo[0].imageName}`} alt="DitmaxEye" />
                :
                <img src={proimg} alt="profileicon" />
              }
            </div>
            {console.log('kjgsjjsglsg', CollectionInfo, Wallet_Details)}
            <div className="contents">
              <h3 className="mb-2">{(CollectionInfo && CollectionInfo.length > 0) ? CollectionInfo[0].collectionName : "no name"}</h3>
              <p className="mb-0">By {CollectionOwner?.name ? CollectionOwner?.name : CollectionOwner?.curraddress?.substring(0, 5) + '...' + CollectionOwner?.curraddress?.substring(CollectionOwner?.curraddress?.length - 5, CollectionOwner?.curraddress?.length)}</p>
              <p className="font-weight-bold">{Total?.TotalNfts} items . {(Total?.FloorPrice?.tokenPrice ? Total?.FloorPrice?.tokenPrice : 0) + " " + (Total?.FloorPrice?.CoinName ? Total?.FloorPrice?.CoinName : 'ETH')}</p>
              {Wallet_Details?.UserAccountAddr == CollectionInfo?.[0]?.userAddress &&
                <div className="loadmorebtndssec mb-4">
                  <button className="loadmorebtnds" disabled={disabled1} onClick={() => navigate("/addCollectionss" + '/' + CollectionInfo[0].collectionName + "/" + "usercollection")}>
                    Edit Collection
                    {/* <i class="fa fa-spinner ml-2 spinner_icon spin_sm" aria-hidden="true"></i> */}
                  </button>
                </div>}
              <ul>
                <li>Items <span>{Total?.TotalNfts}</span></li>
                {/* new Date(CollectionInfo[0].timestamp).getMonth()+" "+new Date(CollectionInfo[0].timestamp).getDate()+" "+new Date(CollectionInfo[0].timestamp).getFullYear() */}
                <li>Created <span>{CollectionInfo?.length > 0 ? moment(new Date(CollectionInfo[0].timestamp)).format('MMMM DD yyyy') : "May 29 2023"}</span> </li>
                <li>Chain <span>{Wallet_Details?.networkConfiguration?.name ? Wallet_Details?.networkConfiguration?.name : config?.NetworkName}</span></li>
                <li>Category <span>{CollectionInfo?.[0]?.Category}</span> </li>
              </ul>
              {console.log('fkjhfsjkfhksfs', CollectionInfo?.[0]?.Description, CollectionInfo)}
              {
                (CollectionInfo?.[0]?.Description && CollectionInfo?.[0]?.Description !== "undefined") &&
                <p className="text">
                  <ReactReadMoreReadLess
                    charLimit={4}
                    readMoreText={"See More ▼"}
                    readLessText={"See Less ▲"}
                    readMoreClassName="read-more-less--more"
                    readLessClassName="read-more-less--less"
                  >
                    {CollectionInfo?.length > 0 ? CollectionInfo[0]?.Description : ""}
                  </ReactReadMoreReadLess>
                </p>}
              {/* <p className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
                            <p>See More <i class="fa fa-angle-down"></i></p> */}
              {console.log('dlksjfkslkfjlsf', CollectionInfo, Collectiondata, Total, isEmpty(Total?.Listed))}
              <div className="columss pt-3">
                <div><h5 className="mb-1">{CollectionInfo?.[0]?.volume ? CollectionInfo?.[0]?.volume : 0} </h5><p>Total Volume</p></div>
                <div><h5 className="mb-1">{Total?.FloorPrice?.tokenPrice ? Total?.FloorPrice?.tokenPrice + " " + Total?.FloorPrice?.CoinName : 0 + " ETH"}</h5><p>Floor Price</p></div>
                <div><h5 className="mb-1">{Total?.HighestBid}</h5><p>Best Offer</p></div>
                <div><h5 className="mb-1">{isEmpty(Total?.Listed) ? 0 : (Total?.Listed?.toFixed(2))}%</h5><p>Listed</p></div>
                <div><h5 className="mb-1">{Total?.TotalOwners}</h5><p>Owners</p></div>
              </div>
            </div>
            <div className="artcard pt-5">
              {console.log('Wallet_Details?.Allcontract?.length-->',Wallet_Details?.Allcontract?.length,Wallet_Details?.Allcontract?.includes(collAddr),Collectiondata)}
              {(Wallet_Details?.Allcontract?.length > 0 && !(Wallet_Details?.Allcontract?.includes(collAddr))) ?
                <>
                  {(Collectiondata && Collectiondata.length > 0) ? (
                    <div className="row">
                      {
                        Collectiondata.map((item, index) => {

                          return (
                            (item?.image != '' &&
                              collAddr != network?.ETH?.singleContract &&
                              collAddr != network?.ETH?.multipleContract &&
                              collAddr != network?.ETH?.literatureContract &&
                              collAddr != network?.BSC?.singleContract &&
                              collAddr != network?.BSC?.multipleContract &&
                              collAddr != network?.BSC?.literatureContract
                            ) ?
                              <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="tokens">

                                  <div className="baner_image">
                                    <div className="ethactios">
                                      <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
                                        {(item && item.image) &&
                                          (((item.image).includes('ipfs://') == true || (item.image).includes('ipfs/') == true || (item.image).includes('ipfs:/') == true)
                                            ? (

                                              <object type="image/webp" data={config.IPFS_IMG + "/" + (((item.image).split('ipfs://').pop()).split('ipfs/').pop()).split('ipfs:/').pop()} onClick={() => FindOwner(item)}
                                              >
                                              </object>

                                            )
                                            :

                                            (item.image != "" &&

                                              <object type="image/webp" data={item.image}
                                                onClick={() => FindOwner(item)}

                                              >
                                              </object>

                                            ))}
                                      </LazyLoad>
                                    </div>
                                    <div className="auctioncards">
                                      <div className="starreiaf">
                                        <h3 className="mb-3" onClick={() => FindOwner(item)}>{item.name}</h3>
                                      </div>


                                      <div className="abaucdetail">

                                        <div className="banercontentauction">
                                          <img src={collectionimsg} />
                                          <img src={collectionimsg} />
                                          <div className="banercontentDet">
                                            <h2>@{(item.colladdress).slice(0, 10).concat("....")}</h2>

                                          </div>
                                        </div>

                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                              :
                              ("")
                          )
                        })
                      }

                    </div>

                  ) : ("")}

                  {
                    (UserCollectionCount < UserCollectionCount2 || UserCollectionCount == UserCollectionCount2) &&
                    <div className="loadmorebtndssec">
                      <button className="loadmorebtnds" disabled={disabled1} onClick={() => onLoadMore()}>
                        Load More
                      </button>
                    </div>
                  }
                </>
                :
                <div className="row">
                  {/* token card */}
                  {console.log('kljfjdskljfkdsjkfds', CollectionList)}
                  {
                    (CollectionList
                      && CollectionList.length > 0)
                      ? (CollectionList.map((item) => {
                        return (
                          <>
                            {console.log('kjdjkfddsljkflds', item)}
                            {(isEmpty(item.tokenowners_current) !== true) ?


                              <div className="col-lg-3 col-md-4 col-sm-6 pb-5">


                                <TokenCard
                                  item={item}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                />
                              </div>
                              : ("")}
                          </>
                        )
                      })) : ('')
                  }
                </div>
              }
            </div>
          </div>
          {(CatBasedTokenList && CatBasedTokenList.loader === false && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list.length >= 10 && CatBasedTokenList[CatName].onmore === true) ? (
            <div className="text-center mt-4 w-100 ">

              <div id="spinButs" className="overflow-hidden">
                <button className="loadmorebtnds" id="onmore" style={{ display: "flex" }} onClick={onLoadMore} >
                  Load More <span>

                  </span>

                </button>
              </div>
            </div>) : ('')}
        </section>
        <div id="logo_overlay" className="logo_ovelay">
          <Footer />
        </div>
      </div>
    </div>

  );
}
