import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Configdata from "../../lib/config";
import DETH_ABI from "../../ABI/DETH_ABI";

import SINGLE from "../../ABI/SINGLE";
import { network } from "../../views/network";
import market from "../../ABI/market";
import isEmpty from "../../lib/isEmpty";

export const connectWallet = async (type, configchainid) => {
  let Config = await configChange(configchainid);
  console.log("dsbgdtneh", type, configchainid);
  let accountDetails = {};
  let web3Obj = {};
  if (type == "MetaMask" || type == "BinanceWallet" || type == "Coinbase") {
    web3Obj = await MetamaskWallet(type, configchainid);
  }
  if (type == "WalletConnect") {
    web3Obj = await WalletConnect(type, configchainid);
  }
  if (web3Obj) {
    try {
      //console.log("accounts try");
      const accounts = await web3Obj?.eth?.getAccounts();
      console.log("accounts", accounts);
      accountDetails.accountAddress = accounts[0].toString().toLowerCase();
      //console.log("accountDetails.accountAddress",accountDetails.accountAddress);
      try {
        //console.log("accountDetails.accountAddress",web3Obj.eth,accountDetails.accountAddress);

        accountDetails.coinBalance = await web3Obj.eth.getBalance(
          accountDetails.accountAddress
        );
        //console.log("DETH_ABI, Config.tokenAddr",DETH_ABI, Config.tokenAddr);
        var conObj = new web3Obj.eth.Contract(
          DETH_ABI,
          Config.tokenAddr[Config.tokenSymbol]
        );

        accountDetails.tokenBalance = await conObj.methods
          .balanceOf(accountDetails.accountAddress)
          .call();
        //console.log("accountDetails.tokenBalance",accountDetails.tokenBalance);
      } catch (err) {
        //console.log("sdfbgadsgf",err);
      }
      accountDetails.web3 = web3Obj;
      //console.log("accountDetails..fgdrtfhjutedyjedt........",accountDetails.web3._provider);
      return accountDetails;
    } catch (e) {
      //console.log("errrrrrr",e);
      return accountDetails;
    }
  }
};
export const MetamaskWallet = async (type, configchainid, config) => {
  //console.log("typeeeeeee",type,configchainid)
  let web3;
  let Config = config;
  try {
    //console.log("dfghdfbhdsfhb",window ,type == 'MetaMask');
    if (window.ethereum && type == "MetaMask") {
      web3 = new Web3(window.ethereum);
      //console.log("dfghrtfh",web3);
      if (window.ethereum.isMetaMask == true) {
        const chainId = await web3.eth.getChainId();
        //console.log("accountDetails type id@che",chainId,configchainid)
        let checkchain;
        if (isEmpty(configchainid)) {
          if (Configdata.chainids.includes(chainId)) {
            checkchain = true;
          } else {
            checkchain = await chainIdCheck(Configdata?.defaultchainid);
          }
        } else {
          if (parseInt(chainId) != parseInt(configchainid)) {
            checkchain = await chainIdCheck(configchainid);
            // return web3
          } else {
            checkchain = true;
          }
        }
        console.log("checkchaincheckchain", checkchain);
        if (!checkchain) {
          return false;
        }
        localStorage.setItem("walletConnectType", type);
        await window.ethereum
          .enable()
          .then(async () => {
            const accounts = await web3.eth.getAccounts();
            //console.log("accountssss",accounts);
            const account = accounts[0].toString().toLowerCase();
            //console.log("bfsdgbnsdfgndfgndghn",account,type);
            localStorage.setItem("accountInfo", account);
            localStorage.setItem("walletConnectType", type);
          })
          .catch((err) => {
            //console.log("ghfnfghmjghjm",err);
          });
      } else {
        alert("Please Uninstall CoinbaseWallet or Connect to Coinbase");
        return false;
      }
    } else if (window.BinanceChain && type == "BinanceWallet") {
      web3 = new Web3(window.BinanceChain);
      const chainId = await web3.eth.getChainId();
      ////console("accountDetails type id",chainId,web3)
      let checkchain;
      if (isEmpty(configchainid)) {
        if (Configdata.chainids.includes(chainId)) {
        } else {
          checkchain = await chainIdCheck(Configdata?.defaultchainid);
        }
      } else {
        if (parseInt(chainId) != parseInt(configchainid)) {
          checkchain = await chainIdCheck(configchainid);
          // return web3
        }
      }
      if (!checkchain) {
        return false;
      }
      // if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
      //   await chainIdCheck()
      //   // return true
      // }
      await window.BinanceChain.request({}).then(async () => {
        // User has allowed account access to DApp...
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0].toString().toLowerCase();
        localStorage.setItem("accountInfo", account);
        localStorage.setItem("walletConnectType", type);
      });
    } else if (window.ethereum && type == "Coinbase") {
      web3 = new Web3(window.ethereum);
      const chainId = await web3.eth.getChainId();
      console("accountDetails type id", chainId, web3);
      if (parseInt(chainId) != parseInt(configchainid)) {
        await chainIdCheck();
        // return true
      }
      await window.ethereum.enable().then(async () => {
        // User has allowed account access to DApp...
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0].toString().toLowerCase();
        localStorage.setItem("accountInfo", account);
        localStorage.setItem("walletConnectType", type);
      });
    }
    // Legacy DApp Browsers
    else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
      const chainId = await web3.eth.getChainId();
      // if (parseInt(chainId) != parseInt(configchainid)) {
      //   await chainIdCheck()
      //   // return true
      // }
      let checkchain;
      if (isEmpty(configchainid)) {
        if (Configdata.chainids.includes(chainId)) {
        } else {
          checkchain = await chainIdCheck(Configdata?.defaultchainid);
        }
      } else {
        if (parseInt(chainId) != parseInt(configchainid)) {
          checkchain = await chainIdCheck(configchainid);
          // return web3
        }
      }
      if (!checkchain) {
        return false;
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account);
      localStorage.setItem("walletConnectType", type);
    }
    // Non-DApp Browsers
    else {
      //alert('No Dapp Supported Wallet Found');
      ////console("No Dapp Supported Wallet Found")
    }
  } catch (e) {
    ////console("accountDetails type id1 last",e)
  }
  //console.log("web3................................",web3);
  return web3;
};

export const WalletConnect = async (type, configchainid) => {
  let web3;
  //Create WalletConnect Provider
  ////console("Wallet connect");
  const provider = new WalletConnectProvider({
    rpc: {
      56: "https://bsc-dataseed1.ninicoin.io",
    },
    network: "binance",
    chainId: 56,
  });
  await provider
    .enable()
    .then(function (error, result) {
      // //console('error: ' + error);
      // //console("accountInfo", result);
    })
    .catch((e) => {
      //try again
    });
  web3 = new Web3(provider);
  const accounts = await web3.eth.getAccounts();
  ////console("Account : ", accounts[0]);
  const account = accounts[0].toString().toLowerCase();
  localStorage.setItem("accountInfo", account);
  localStorage.setItem("walletConnectType", type);
  ////console("accountInfo", account);
  // localStorage.setItem("provider",JSON.stringify(provider))
  return web3;
};

const configChange = async (configchainid) => {
  let Config = {};

  if (configchainid === network.ETH.Chainid) {
    Config = network.ETH;
  } else if (configchainid === network.BSC.Chainid) {
    Config = network.BSC;
  }
  return Config;
};

const chainIdCheck = async (configchainid) => {
  let Config = await configChange(configchainid);
  //console.log("inside chainIdCheck",configchainid);
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  const hexString = configchainid && configchainid.toString(16);
  //console.log("hexString","0x" + hexString);
  if (window.ethereum) {
    try {
      // check if the chain to connect to is installed
      //console.log("ifffffffffff");

      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
      });
      return true;
    } catch (error) {
      //console.log("error............",error);
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x" + hexString,
                rpcUrl: Config.RPC_URL,
              },
            ],
          });
          return true;
        } catch (addError) {
          //console.log("err in nested catch",addError);
          console.error(addError);
        }
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    return false;
  }
};

export const getServiceFees = async (configchainid) => {
  try {
    let Config = await configChange(configchainid);
    let rpcObj = new Web3(Config.BNBPROVIDER);
    if (rpcObj) {
      let marketObj = new rpcObj.eth.Contract(market, Config.TradeContract);
      let servicefees = await marketObj.methods.FeeType(1).call();

      //  _fees[0] - serviceValue, _fees[1] - sellervalue, _fees[2] - Minting Fees, _fees[3] -  Listing Fee, _fees[4] - Withdrawal Fee

      let buyerFee = servicefees?.buyerFees;
      let sellerFee = servicefees?.sellerFees;
      let mintingfee = servicefees?.mintFees;
      let listingfee = servicefees?.listFees;
      let withdrawfee = servicefees?.withdrawFees;

      let fee = {
        buyerFee,
        sellerFee,
        mintingfee,
        withdrawfee,
        listingfee,
      };
      return fee;
    }
  } catch (e) {
    console.log("getServiceFees_error", e);
  }
};
