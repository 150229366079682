import React, { useEffect, useState, useRef } from "react";



// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";




const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {





  const { ...rest } = props;


  return (
    <div className="inner_header">
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center">
                  <h2 className="inner_title">Following</h2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container-fluid container-theme mt-3">
          <div className=" masonry myitemcards m-0 ma_no_gap">
            {/* {
              <div className="row">

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <TokenCard
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <TokenCard
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <TokenCard
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <TokenCard
                  />
                </div>
              </div>
            } */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
