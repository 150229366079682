import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Cookies, useCookies } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

//css
import "./index.css";
import "./styles.css";
import "./App.css";

import config from "./lib/config";
import { isEmpty } from "./actions/commonfunction";
import { NumberChange, SetToken } from "./lib/common";
import { Signon } from "./actions/v1/user";
import { Account_Connect } from "./actions/redux/action";
import "@metamask/legacy-web3";

// component
import Home from "./views/Home";
import Art1 from "./views/art1";
import Artdetail from "./views/artdetail";
import Info from "./views/info";
import Resources from "./views/resources";
import Faq from "./views/faq";
import Terms from "./views/Termsofservice";
import Myitems from "./views/my-items";

import Create from "./views/Create";
import CreateSingle from "./views/Create-single";
import EditProfile from "./views/edit-profile";

import AddCollection from "./views/addcollection";
import UserCollection from "./views/usercollections";
import List from "./views/List";

import Bulkmint from "./views/bulkmint";
import SupportTicket from "./views/SupportTicket";
// cms
import Privacypolicy from "./views/Privacypolicy";
import Nfttc from "./views/nfttc";
import Howtocreate from "./views/howtocreate";
import Howtobuy from "./views/howtobuy";
import Howtosell from "./views/howtosell";
import Edafacepartnership from "./views/edafacepartnership";
import Investnft from "./views/investnft";
import Nftusecase from "./views/nftusecase";
import Probabilitynft from "./views/probabilitynft";
import Nftmarketplaces from "./views/nftmarketplaces";
import Conclusion from "./views/conclusion";
import Terminology from "./views/terminology";
import Globalrush from "./views/globalrush";
import Typesofnft from "./views/typesofnft";
import Transaction from "./views/transaction";
import Community from "./views/communty";
import About from "./views/About";
import Following from "./views/following";
import Comingsoon from "./views/comingsoon";

import Search from "./views/search";
import Activity from "./views/activity";

import PDFReader from "./views/PdfReaders/PDFReader";
import { ToastContainer } from "react-toastify";

// toast.configure();

export default function Indexs(props) {
  const dispatch = useDispatch();
  const cookies = new Cookies();

  function getCookie(cookieName) {
    let name = cookieName + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  async function checkCookie(cookieName) {
    let cookie = getCookie(cookieName);
    console.log("cookiecookie", cookie);
    if (cookie != "") {
      let getusercookie = getCookie(config.userCookieName);
      if (isEmpty(localStorage.registedEmail) || isEmpty(getusercookie)) {
        // SetTokendata(cookie)
        let Resp = await Signon({ data: cookie });
        console.log("RespResp", Resp);
        if (Resp.status) {
          cookies.removeChangeListener(cookieChangeListener);
          toast.success("Login Successfully");
          localStorage.setItem("registedEmail", Resp.data.Regemail);
          SetToken(config.userCookieName, Resp?.token);
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              Regname: Resp.data.Regname,
              Profile: Resp.data.Profile,
              Customurl: Resp.data.Customurl,
              _id: Resp.data._id,
              MaxExpense:
                Resp?.data?.MaxExpense == "nolimit"
                  ? Resp?.data?.MaxExpense
                  : NumberChange(Resp?.data?.MaxExpense),
            },
          });
        } else {
          if (Resp.data == "noerror") {
          } else {
            toast.error(Resp.msg, 1000);
          }
        }
      } else {
      }
    } else {
      if (!isEmpty(localStorage.registedEmail)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      console.log("cookie doesnot exist set the cookie again");
    }
  }
  const cookieChangeListener = (name, value) => {
    checkCookie(config.cookiename);
    // SetTokendata(value)
  };

  useEffect(() => {
    cookies.addChangeListener(cookieChangeListener);
    let cookie = getCookie(config.cookiename);
    let getusercookie = getCookie(config.userCookieName);
    console.log("getusercookie", getusercookie);
    if (
      (isEmpty(localStorage.registedEmail) || isEmpty(getusercookie)) &&
      !isEmpty(cookie)
    ) {
      cookieChangeListener();
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV == "production") {
      console.log = () => { };
      console.warning = () => { };
      console.info = () => { };
      console.error = () => { };
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            className: "fw-600",
            style: {
              textAlign: "center",
              background: "rgb(58 183 27)",
              color: "white",
            },
          },
          error: {
            className: "fw-600",
            style: {
              textAlign: "center",
              background: "#cd1212",
              color: "white",
            },
          },
        }}
      />

      <BrowserRouter basename="/">
        <Routes>
          {/* Home */}
          <Route path="/" element={<Home />} />

          {/* Detail page */}
          <Route path="/explore/:category" element={<Art1 />} />
          <Route
            path="/collectiondetail/:param/:customurl/:collAddr"
            element={<Artdetail />}
          />
          <Route
            path="/collection/:param/:customurl/:collAddr"
            element={<UserCollection />}
          />
          <Route
            path="/info/:owneraddress/:collectionaddress/:tokenidval"
            element={<Info />}
          />
          <Route path="/resource/:resourcelink" element={<Resources />} />

          {/* my items */}
          <Route path="/my-items" element={<Myitems />} />
          <Route path="/my-items/:activity?" element={<Myitems />} />
          <Route path="/user/:paramAddress" element={<Myitems />} />
          <Route path="/:paramUsername" element={<Myitems />} />
          <Route path="/edit-profile" element={<EditProfile />} />

          {/* pdf viewer */}
          <Route path="/pdfview/:owner/:id" element={<PDFReader />} />

          {/* create */}
          <Route path="/create" element={<Create />} />
          <Route path="/create-single" element={<CreateSingle />} />
          <Route path="/create-multiple" element={<CreateSingle />} />
          <Route path={"/create-polymultiple"} element={<Create />} />
          <Route path={"/create-mintliterature"} element={<Create />} />

          {/* addcollection */}
          <Route path="/addCollections" element={<AddCollection />} />
          <Route
            path="/addCollectionss/:name/:form"
            element={<AddCollection />}
          />
          <Route path="/list/:address/:id" element={<List />} />

          {/* bulkmint */}
          <Route path="/bulkmint" element={<Bulkmint />} />
          <Route path="/bulkmint/:type" element={<Bulkmint />} />
          <Route path="/literature/:type" element={<Bulkmint />} />

          <Route path="/search" element={<Search />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/supportticket" element={<SupportTicket />} />

          {/* cms */}
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/faq" element={<Faq />} />
          <Route path={"/nfttc"} element={<Nfttc />} />
          <Route path="/howtocreate" element={<Howtocreate />} />
          <Route path="/howtobuy" element={<Howtobuy />} />
          <Route path="/howtosell" element={<Howtosell />} />
          <Route path="/edafacepartnership" element={<Edafacepartnership />} />
          <Route path="/investnft" element={<Investnft />} />
          <Route path="/nftusecase" element={<Nftusecase />} />
          <Route path="/probabilitynft" element={<Probabilitynft />} />
          <Route path="/globalrushof_nft" element={<Globalrush />} />
          <Route path="/nftmarketplaces" element={<Nftmarketplaces />} />
          <Route path="/terminology" element={<Terminology />} />
          <Route path="/conclusion" element={<Conclusion />} />
          <Route path="/typesofnft" element={<Typesofnft />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/community" element={<Community />} />
          <Route path="/about" element={<About />} />
          <Route path="/comingsoon" element={<Comingsoon />} />
          <Route path="/Following" element={<Following />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
