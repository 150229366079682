import React, { useEffect } from "react";




// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Create(props) {

  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header/>
      <ScrollToTopOnMount/>
      <div className={" inner_pageheader"}>
      
        <div className="container mt-4 coming_soon_sec">
          <Row>
            <Col xs={12} sm={12} md={12}>
                <div className="text-center">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />
            </div>
            <p className="mb-2 com_text">Coming Soon!</p>
            <div className="text-center">
            <button className="btn coming_soon_btn" tabIndex="0" type="button"><span className="text_back"><Link to="/">
          Back to home</Link></span></button>
            </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
