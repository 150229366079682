import React, { useEffect, useState } from "react";


// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const dashboardRoutes = [];


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Create(props) {


  const { ...rest } = props;
  const location = useLocation()


  const [location_pathname, Set_location_pathname] = useState(location.pathname.split('/')[1]);


  useEffect(() => {
    if (location !== location_pathname) {
      Set_location_pathname(location.pathname.split('/')[1]);
    }
  }, [location.pathname])

  return (
    <div className="inner_header">
      {/* <TopHeader/> */}
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader"}>
        <div className={"container"}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to={(location_pathname == 'create-polymultiple' || location_pathname?.includes('create-mintliterature')) ? "/create" : "/"}>
                  <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0">Create NFT</h3>
              </div>
            </Col>
          </Row> 
        </div>
        <div className="container mt-4 create_block">
          <Row>
            {location_pathname == 'create-polymultiple' ?
              <Col xs={12} sm={12} md={12}>
                <p className="create_para">Here, you can mint your bulk NFTs either as image alone, image with audio, video, or in layers. </p>
                <div className="create_box_panel">
                  <Link to="/bulkmint/image" className="create_box">
                    <h2>Image</h2>
                    <img src={require("../assets/images/single_icon.png")} alt="logo" className="img-fluid" />
                  </Link>
                  <Link to="/bulkmint/audio" className="create_box">
                    <h2>Audio</h2>
                    <img src={require("../assets/images/multiple_icon.png")} alt="logo" className="img-fluid" />
                  </Link>
                  <Link to="/bulkmint/video" className="create_box createCollection">
                    <h2>Video</h2>
                    <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid" />
                  </Link>
                  <Link to="/bulkmint/layer" className="create_box createCollection">
                    <h2>Layer By Layer</h2>
                    <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid" />
                  </Link>
                </div>
                <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation</p>
                <p className="create_para mt-3 fs_19">We have implemented Lazy Minting in Minting Standards.</p>
              </Col>
              :
              location_pathname == 'create-mintliterature' ?
                <Col xs={12} sm={12} md={12}>
                  <p className="create_para">Here, you can mint your Literature NFTs either as Songs, video, or Books. </p>
                  <div className="create_box_panel">
                    <Link to="/literature/song" className="create_box">
                      <h2>Songs</h2>
                      <img src={require("../assets/images/multiple_icon.png")} alt="logo" className="img-fluid" />
                    </Link>
                    <Link to="/literature/video" className="create_box createCollection">
                      <h2>Videos</h2>
                      <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid" />
                    </Link>
                    <Link to="/literature/book" className="create_box createCollection">
                      <h2>Books</h2>
                      <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid" />
                    </Link>
                  </div>
                  <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation</p>
                  <p className="create_para mt-3 fs_19">We have implemented Lazy Minting in Minting Standards.</p>
                </Col>
                :
                <Col xs={12} sm={12} md={12}>
                  <p className="create_para">Choose “Single” if you want your collectible to be one of a kind or “Multiple” if you want to sell one collectible multiple times</p>
                  <div className="create_box_panel">
                    <Link to="/create-single" className="create_box">
                      <h2>Single</h2>
                      <img src={require("../assets/images/single_icon.png")} alt="logo" className="img-fluid" />
                    </Link>
                    <Link to="/create-multiple" className="create_box">
                      <h2>UniMultiple</h2>
                      <img src={require("../assets/images/multiple_icon.png")} alt="logo" className="img-fluid" />
                    </Link>
                    <Link to="/create-polymultiple" className="create_box createCollection">
                      <h2>PolyMultiple</h2>
                      <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid" />
                    </Link>
                    <Link to="/create-mintliterature" className="create_box createCollection">
                      <h2>Mint Literature</h2>
                      <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid" />
                    </Link>
                  </div>
                  <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation</p>
                  <p className="create_para mt-3 fs_19">We have implemented Lazy Minting in Minting Standards.</p>
                </Col>
            }
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}
