import React, {
    useEffect,
    forwardRef,
    useState,
    useImperativeHandle
} from 'react';
import {
    useNavigate, useLocation
} from "react-router-dom";
// import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'

// import EXCHANGE from '../../ABI/EXCHANGE.json'
// import Audios from 'assets/images/audio.png'
import config from '../../lib/config';
import Select from 'react-select';
import isEmpty from '../../lib/isEmpty';
import Trade from '../../ABI/Trade.json'
import {
    TokenPriceChange_update_Action,
    checkOtherPlatformDetais1155
} from '../../actions/v1/token';
import SINGLE from '../../ABI/SINGLE.json';
import MULTIPLE from '../../ABI/MULTIPLE.json'
import LITERATURE from '../../ABI/Literature.json'

// import {
//     getCurAddr,
// } from '../../actions/v1/user';
import { getReceipt } from '../../actions/v1/getReceiptFunc';
// import ReactPlayer from 'react-player';
import Convert from '../separate/Convert'
import { toast } from 'react-toastify';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "../../actions/redux/action";
import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network"
import Market from "../../ABI/market"
import randomInteger from 'random-int';
import { UserCheck } from '../../actions/v1/user';
import { GetGasFees } from '../../actions/contractProvider';
import { NewGetGasFees } from '../../actions/contractProvider';
import { GetServiceFee } from '../../actions/contractProvider';
import { NumberChange } from '../../lib/common';

// toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.exchangeAddress;

export const PutOnSaleRef = forwardRef((props, ref) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const Wallet_Details = useSelector(state => state.wallet_connect_context);

    let pathVal = '';
    const location = useLocation();
    if (location.pathname) {
        if (location.pathname.split('/').length >= 2) {
            pathVal = location.pathname.split('/')[1];
        }
    }


    const [location_pathname, Set_location_pathname] = useState(pathVal);
    const [FormSubmitLoading, Set_FormSubmitLoading] = useState('start');
    const [CoinName, setCoinNames] = useState('');
    const [CoinName_Initial, set_CoinName_Initial] = useState('');
    const [Items, Set_Items] = useState({});
    const [YouWillGet, Set_YouWillGet] = useState(0);
    const [owner_Detail, set_owner_Detail] = useState({})
    const [TokenPrice, Set_TokenPrice] = useState(0);
    const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);
    const [token_onwers_info, set_token_onwers_info] = useState({})
    const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
    const [MintCallStatus, setMintCallStatus] = React.useState('init');
    const [PutOnSale, setPutOnSale] = useState(false);
    const [PutOnSaleType, setPutOnSaleType] = useState('UnLimitedAuction');
    const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);
    const [MinimumBid, Set_MinimumBid] = useState(0);
    const [Clocktime, set_Clocktime] = useState('');
    const [EndClocktime, set_EndClocktime] = useState("");
    const [StartDate, Set_StartDate] = useState('Select Start Date');
    const [EndDate, Set_EndDate] = useState('Select End Date');
    const [quantyputonsale, Setquantyputonsale] = useState(1)
    const [stateforOwnerdetails, SetstateforOwnerdetails] = useState({})
    const [ValidateError, Set_ValidateError] = useState({ TokenPrice: '' });
    const [priceoption, Set_priceoption] = useState({ TokenPrice: '' });
    const [ListNFT,SetListNFT] = useState(true);
    const [ListedQuantity,SetListedQuantity] = useState(0);
    const [currentFees,SetCurrentFees] = useState({})


    useEffect(()=>{
        if(props?.item?.tokenowners_current?.length > 0){
            SetListedQuantity((Number(stateforOwnerdetails.balance)-Number(stateforOwnerdetails.ListedQuantity)));
            if(!props?.item?.tokenowners_current[0]?.ListNFT){
                setPutOnSale(false)
                setPutOnSaleType('UnLimitedAuction')
            }
        }
    },[props?.item?.tokenowners_current])

    const priceoptionfunc = (e) => {
        set_CoinName_Initial(e.value)
        // ItemValidation({ CoinName: e.value })
        Set_FormSubmitLoading('start')
    };
    const inputChange = (e) => {
        if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            Set_FormSubmitLoading('start')
            let value = e.target.value;
            switch (e.target.name) {
                case 'TokenPrice':
                    Set_TokenPrice(value);
                    // ItemValidation({ TokenPrice: value });
                    if (value !== '' && isNaN(value) === false && value > 0) {
                        let weii = value * config.decimalvalues;
                        let per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
                        let royal = weii * item.tokenRoyality * config.decimalvalues / 1e20
                        Set_YouWillGet(parseFloat((weii - (per)) / config.decimalvalues).toFixed(4));
                    }
                    break;
                case 'MinimumBid':
                    Set_MinimumBid(value);
                    if (value !== '' && isNaN(value) === false && value > 0) {
                        let weii = value * config.decimalvalues;
                        let per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
                        let royal = weii * item.tokenRoyality * config.decimalvalues / 1e20
                        if ((String(CoinName_Initial).toLowerCase()) != (String(Wallet_Details.networkConfiguration.currencySymbol).toLowerCase())) {
                            Set_YouWillGet(parseFloat((weii - (per)) / config.decimalvalues).toFixed(2));
                        }
                        else {
                            // Set_YouWillGet(parseFloat((weii - (per+royal)) / config.decimalvalues).toFixed(4));
                            Set_YouWillGet(parseFloat((weii - (per)) / config.decimalvalues).toFixed(4));
                        }
                    }
                    // ItemValidation({ TokenPrice: value })
                    break;
                default:
            }
        }
    }

    const ItemValidation = async (data = {}) => {
        let ValidateError = {};
        let Chk_TokenPrice = (typeof data.TokenPrice !== undefined && typeof data.TokenPrice !== "undefined") ? (data.TokenPrice) : TokenPrice;
        let CoinName_Initial1 = (typeof data.CoinName !== undefined && typeof data.CoinName !== "undefined" && data.CoinName !== "") ? data.CoinName : CoinName_Initial
        if(ListedQuantity || !stateforOwnerdetails?.ListNFT)
        {
            if((Number(ListedQuantity) + Number(stateforOwnerdetails.ListedQuantity)) > Number(stateforOwnerdetails.balance)){
                ValidateError.ListedQuantity = '"List Quantity" Must be less than '+(Number(stateforOwnerdetails.balance)-Number(stateforOwnerdetails.ListedQuantity));
            }
        }
        if (PutOnSale){
            if (Chk_TokenPrice === '') {
                ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
            }
            else if (Chk_TokenPrice === 0) {
                ValidateError.TokenPrice = '"Token Price" must be greater than 0';
            }
            else if (isNaN(Chk_TokenPrice) === true) {
                ValidateError.TokenPrice = '"Token Price" must be a number';
            }
            else if (TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
                ValidateError.TokenPrice = '"Token Price" must be less than ' + TokenPrice_Initial;
            }
            else if (CoinName_Initial1 === "") {
                ValidateError.CoinName = '"Currency or Token Symbol" is required';
            }
            else if (CoinName_Initial1 === undefined) {
                ValidateError.CoinName = '"Currency or Token Symbol" is required';
            }
            if (YouWillGet === 0) {
                ValidateError.TokenPrice = 'Only  allowed  4 digits after decimal point';
            }
            if (quantyputonsale < 1) {
                ValidateError.quantyputonsale = '"Quantity" is not allowed to be empty';
            }
            if (Number(quantyputonsale) > (Number(stateforOwnerdetails.ListedQuantity) + Number(ListedQuantity)) ) {
                ValidateError.quantyputonsale = 'Quantity must be less than '+(Number(stateforOwnerdetails.ListedQuantity) + Number(ListedQuantity));
            }
            else {
                delete ValidateError.TokenPrice;
                Set_FormSubmitLoading('start')
            }
        }
        
        if (PutOnSale === true && PutOnSaleType === 'TimedAuction') {
            if (MinimumBid === '' || MinimumBid ===0) {
              ValidateError.MinimumBid = '"Bid Price" cant be empty';
            }
            if (Clocktime === '') {
              ValidateError.Clocktime = '"Start Clock Time " cant be a number';
            }
            if (EndClocktime === '') {
              ValidateError.EndClocktime = '"End Clock Time " cant be a number';
            }
            if (Clocktime === 'Select Start Date') {
              ValidateError.Clocktime = '"Start Clock Time " cant be a number';
            }
            if (EndClocktime === 'Select End Date') {
              ValidateError.EndClocktime = '"End Clock Time " cant be a number';
            }
            if (Clocktime === 'Invalid Date') {
              ValidateError.Clocktime = '"Start Clock Time " cant be a number';
            }
            if (EndClocktime === 'Invalid Date') {
              ValidateError.EndClocktime = '"End Clock Time " cant be a number';
            }
          }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    async function FormSubmit() {
        Set_FormSubmitLoading('start');
        let errors = await ItemValidation();
        let errorsSize = Object.keys(errors).length;
        if (errorsSize !== 0 || isEmpty(ValidateError) === false) {
            Set_FormSubmitLoading('error');
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }
        let receipt = null;
        let handle = null;
        let signature = null;
        let _nonce = null;
        let Randomname = null;
        let web3 = new Web3(Wallet_Details.providerss)
        Set_FormSubmitLoading('processing');
        if (web3) {
            try {
                let generator = require('generate-password');
                let randomNum = randomInteger(10000000, 100000000);
                let password = generator.generate({
                    length: 10,
                    numbers: true
                });
                if (web3) {
                    let web3RpcPro = new Web3(web3.providers);
                    const to = Wallet_Details.UserAccountAddr
                    const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3RpcPro.utils.toWei(String(TokenPrice));
                    const time = Date.now();
                    Randomname = password;
                    let tot = time + Number(randomNum);

                    _nonce = tot;
                    const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                    let decode = web3RpcPro.utils.soliditySha3Raw(result,)
                    const signhash = await web3.eth.personal.sign(result, to);
                    if (signhash) {
                        signature = signhash;
                    }
                }
            }
            catch(err){
                console.log('signature errror',err)
                toast.error("Try-Again",1000)
                return Set_FormSubmitLoading("try");
            }
        }
        if(!isEmpty(ListedQuantity) && ListedQuantity){
            if (Wallet_Details.providerss) {
                // console.log("bfjkbvhcjigvhfji",props.providerss);
                if (
                    web3
                ) {
                    
                    let CoursetroContract = new web3.eth.Contract(
                        Market,
                        Wallet_Details.networkConfiguration.TradeContract
                    );
                    try {
                        Set_FormSubmitLoading('processing');
                        console.log("qweqweqwewqeqweqwezxccvc", Wallet_Details,);
                        if(item?.hashValue == 'notminted'){
                            receipt={blockHash:'testwithdraw',transactionHash:'testwithdraw'}
                        }
                        else {
                            let encoded = await CoursetroContract.methods
                            .listNFT(
                                Items.tokenCounts,
                                owner_Detail.contractAddress,
                                owner_Detail.type,
                                ListedQuantity,
                                currentFees?.key,
                            );
                            let gasfeecalculated = await NewGetGasFees({ 
                                web3: web3, 
                                contractcall: encoded, 
                                accountAddress: owner_Detail.tokenOwner, 
                                value: (currentFees?.Listing_fee?currentFees?.Listing_fee:Wallet_Details?.Listing_fee)
                            });
                            await CoursetroContract.methods
                                .listNFT(
                                    Items.tokenCounts,
                                    owner_Detail.contractAddress,
                                    owner_Detail.type,
                                    ListedQuantity,
                                    currentFees?.key,
                                )
                                .send({
                                    from: Wallet_Details.UserAccountAddr,
                                    value: (currentFees?.Listing_fee?currentFees?.Listing_fee:Wallet_Details?.Listing_fee),
                                    // gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10),
                                    // gasPrice: gasfeecalculated?.gasPrice,
                                })
                                .on('transactionHash', async (transactionHash) => {
                                    // handle = setInterval(async () => {
                                    receipt = await getReceipt(web3, transactionHash)
                                    // }, 8000)
                                })
                        }
                    }

                    catch (error) {
                        console.log('lskflsklfs',error)
                        Set_FormSubmitLoading('try');
                        toast.error('Order not placed', toasterOption)
                        return false
                    }

                }
            }
        }
        if(PutOnSale){
            if (PutOnSaleType === 'FixedPrice') {
                let errors = await ItemValidation();
                let errorsSize = Object.keys(errors).length;
                if (errorsSize !== 0 ||isEmpty(ValidateError)===false) {
                    Set_FormSubmitLoading('error');
                    toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
                    return false;
                }
                Set_FormSubmitLoading('done');
                let postData = {
                    tokenOwner: Wallet_Details.UserAccountAddr,
                    tokenCounts: Items.tokenCounts,
                    tokenPrice: Number(TokenPrice),
                    owner: owner_Detail.tokenOwner,
                    CoinName: CoinName_Initial,
                    MinimumBid: 0,
                    Clocktime: null,
                    EndClocktime: null,
                    PutOnSaleType: PutOnSaleType,
                    putonsalequantity: quantyputonsale,
                    Randomname: Randomname,
                    _nonce: _nonce,
                    signature: signature,
                    ListNFT: ListNFT,
                    ListedQuantity: ListNFT ? (ListedQuantity ? (Number(ListedQuantity)+Number(owner_Detail?.ListedQuantity?owner_Detail?.ListedQuantity:0)) : owner_Detail?.ListedQuantity ) : 0,
                }
                if(receipt){
                    postData.blockHash = receipt.blockHash;
                    postData.transactionHash = receipt.transactionHash;
                }
                // console.log("sfgdgvdfgvfd", postData);
                let Resp = await TokenPriceChange_update_Action(postData)
                // console.log("dfghjsfgj",Resp);
                if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                    toast.success("Token price changed successfully", toasterOption)
                    window.$('#PutOnSale_modal').modal('hide');
                    setTimeout(() => {
                        // navigate("/my-items")
                        props.GetUpdatas();
                        // navigate('/');
                    }, 3000);
                }
            }
            else {
    
    
                let postData = {
                    tokenOwner: Wallet_Details.UserAccountAddr,
                    tokenCounts: Items.tokenCounts,
                    tokenPrice: Number(TokenPrice),
                    owner: owner_Detail.tokenOwner,
                    CoinName: CoinName_Initial,
                    MinimumBid: MinimumBid,
                    Clocktime: Clocktime,
                    EndClocktime: EndClocktime,
                    PutOnSaleType: PutOnSaleType,
                    putonsalequantity: 1,
                    Randomname: Randomname,
                    _nonce: _nonce,
                    signature: signature,
                    ListNFT: ListNFT,
                    ListedQuantity: ListNFT ? (ListedQuantity ? (Number(ListedQuantity)+Number(owner_Detail?.ListedQuantity?owner_Detail?.ListedQuantity:0)) : owner_Detail?.ListedQuantity ) : 0,
    
                }
                console.log("🚀 ~ FormSubmit ~ postData:", postData)
                if(receipt){
                    postData.blockHash = receipt.blockHash;
                    postData.transactionHash = receipt.transactionHash;
                }
    
    
                let Resp = await TokenPriceChange_update_Action(postData)
                if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                    toast.success("Token price changed successfully", toasterOption)
                    window.$('#PutOnSale_modal').modal('hide');
                    Set_FormSubmitLoading('done');
                    setTimeout(() => {
                        props.GetUpdatas();
                        // navigate("/my-items")
                        // navigate('/');
                    }, 2000);
                }
    
    
    
            }
        }
        else{
            let postData = {
                tokenOwner: Wallet_Details.UserAccountAddr,
                tokenCounts: Items.tokenCounts,
                tokenPrice: Number(TokenPrice),
                owner: owner_Detail.tokenOwner,
                CoinName: CoinName_Initial,
                MinimumBid: 0,
                Clocktime: Clocktime ? Clocktime : null,
                EndClocktime: EndClocktime ? EndClocktime : null,
                PutOnSaleType: PutOnSaleType,
                putonsalequantity: quantyputonsale,
                Randomname: Randomname,
                _nonce: _nonce,
                signature: signature,
                ListNFT: ListNFT,
                ListedQuantity: ListNFT ? (ListedQuantity ? (Number(ListedQuantity)+Number(owner_Detail?.ListedQuantity?owner_Detail?.ListedQuantity:0)) : owner_Detail?.ListedQuantity ) : 0,
            }
            let Resp = await TokenPriceChange_update_Action(postData)
            if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                toast.success("Token price changed successfully", toasterOption)
                window.$('#PutOnSale_modal').modal('hide');
                setTimeout(() => {
                    props.GetUpdatas();
                    // navigate("/my-items")
                    // navigate('/');
                }, 1000);
            }
            else{
                toast.error("Error Occured", toasterOption);
                Set_FormSubmitLoading('try');
            }
        }
        
        
    }

    var {
        item,
        UserAccountAddr,
        UserAccountBal,
        Service_Fee
    } = props;


console.log('Wallet_Details?.tokenAddress-->',Wallet_Details?.tokenAddress)
    useEffect(() => {
        if (PutOnSale && PutOnSaleType == 'TimedAuction') {
            let bnbs = (Wallet_Details?.tokenAddress).filter((item) => item?.label != Wallet_Details?.networkConfiguration?.currencySymbol)
            Set_priceoption(bnbs)
        }
        else {
            Set_priceoption(Wallet_Details.tokenAddress)
        }
        Set_ValidateError({});
    }, [PutOnSaleType]);

    const changePutOnSaleType = (type) => {
        setPutOnSaleType(type);
        // console.log("dfbgzdbdfbdf", type);
        if (type === 'FixedPrice') {
        }
    };


    async function DateChange(from, val) {
        let newdat;
        if (from === 'StartDateDropDown') {
            if (val === 'PickStartDate') {
                ModalAction('calendar_modal', 'show');
            }
            else {
                
                Set_StartDate(val);
                let myDate = new Date();
                if (val === 'RightAfterListing') {
                    newdat = myDate.setDate(myDate.getDate());
                }
                else {
                    newdat = myDate.setDate(myDate.getDate() + parseInt(val));
                }
                console.log("🚀 ~ DateChange ~ newdat:", new Date(newdat))
                set_Clocktime(new Date(newdat));
            }

        }
        else if (from === 'EndDateDropDown') {
            if (val === 'PickEndDate') {
                ModalAction('calendar_modal_expire', 'show');
            }
            else {
                Set_EndDate(val);
                let myDate = new Date();
                 newdat = myDate.setDate(myDate.getDate() + parseInt(val));
                console.log("🚀 ~ DateChange ~ newdat:", new Date(newdat))
                set_EndClocktime(new Date(newdat))
            }
        }
    }


    async function ModalAction(id, type) {
        window.$('#' + id).modal(type);
        if (id === 'calendar_modal') {
            if (Clocktime) {
                let dt = new Date(Clocktime);
                let dt1 = dt.toLocaleString();
                Set_StartDate(dt1);
            }
        }
        else if (id === 'calendar_modal_expire') {
            if (EndClocktime) {
                let dt = new Date(EndClocktime);
                let dt1 = dt.toLocaleString();
                Set_EndDate(dt1);
            }
        }
    }
    const CheckedChange = (e) => {
        // console.log("checked change");
        if (e && e.target && e.target.name) {
            if (e.target.name === 'putonsale') {
                if (PutOnSale === false) {
                    setPutOnSale(true);
                    setPutOnSaleType('FixedPrice')
                }
                else {
                    setPutOnSale(false);
                    setPutOnSaleType('UnLimitedAuction')
                }
            }
            else if (e.target.name === 'unlockoncepurchased') {
                if (Unlockoncepurchased === false) {
                    Set_Unlockoncepurchased(true);
                }
                else {
                    Set_Unlockoncepurchased(false);
                }
            }
            else if(e.target.name === 'ListNFT'){
                if (ListNFT === false) {
                    SetListedQuantity(props?.item?.tokenowners_current[0]?.balance);
                    SetListNFT(true);
                    setPutOnSale(true);
                    setPutOnSaleType('FixedPrice')
                }
                else {
                    SetListedQuantity(0)
                    SetListNFT(false);
                    setPutOnSale(false);
                    setPutOnSaleType('UnLimitedAuction')
                }
            }
        }
    };
    const onChange = (e) => {
        Set_ValidateError({})
        Set_FormSubmitLoading('start');
        e.preventDefault();
        const { id, value } = e.target;

        let formData = { ...quantyputonsale, ...{ [id]: value } }
        Setquantyputonsale(formData.putonsalequantity)
    }


    useImperativeHandle(
        ref,
        () => ({
            async PutOnSale_Click(item, tokenOwnerInfo, ownerdetail) {
                let useractive = await UserCheck({ currAddr: Wallet_Details?.UserAccountAddr });
                if (useractive?.userValue?.activate) {
                }
                else {
                    toast.error("User banned by the admin")
                    return false;
                }
                if (Wallet_Details.UserAccountAddr !== "" || Wallet_Details.UserAccountAddr !== null) {

                    setPutOnSaleType(ownerdetail.PutOnSaleType == 'UnLimitedAuction' ? 'FixedPrice' : 'UnLimitedAuction')

                    let chainid = item?.tokenowners_current?.[0]?.SelectedNetwork || item?.tokenowners_current?.SelectedNetwork;
                    let accountDetailsss = ''

                    if (Wallet_Details?.networkConfiguration?.Chainid == item?.tokenowners_current?.[0]?.SelectedNetwork) {
                        accountDetailsss = true
                    }
                    else {
                        if (item?.tokenowners_current?.[0]?.SelectedNetwork) {
                            let chainid = item?.tokenowners_current[0].SelectedNetwork
                        }
                        accountDetailsss = await switchNetwork(chainid)
                    }
                    if (chainid === Wallet_Details?.networkConfiguration?.Chainid) {
                        SetstateforOwnerdetails(ownerdetail)
                        let web3 = new Web3(Wallet_Details.providerss)
                        let data = {
                            Singleaddress: Wallet_Details.networkConfiguration.singleContract,
                            multipleContract: Wallet_Details.networkConfiguration.multipleContract
                        }
                        let CoursetroContract = new web3.eth.Contract(
                            Market,
                            Wallet_Details.networkConfiguration.TradeContract,
                        );
                        let balance = await checkOtherPlatformDetais1155(item, ownerdetail, item.type, web3, data, CoursetroContract);
                        if (balance == 0) {
                            toast.warning("You won't buy at this moment please refresh you data", toasterOption);
                            setTimeout(() => {
                                // window.location.href="/"
                            }, 1000);
                            return false;
                        }
                        else {
                            let check = await orderApprovecheck(item, ownerdetail);
                            if (check) {
                                window.$('#PutOnSale_modal').modal('show');
                                setApproveCallStatus('done')
                            }
                            else {
                                window.$('#price_item_modal').modal('show');
                            }
                            props.Set_HitItem(item);
                            Set_Items(item)
                            set_owner_Detail(ownerdetail)
                            // Set_TokenPrice(ownerdetail.tokenPrice);
                            Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                            set_CoinName_Initial(ownerdetail.CoinName)
                            setCoinNames(ownerdetail.CoinName)
                            if (!isEmpty(tokenOwnerInfo)) {
                                set_token_onwers_info(tokenOwnerInfo)
                            }
                            else {
                                set_token_onwers_info({})
                            }
                            Set_ValidateError({});
                            let CoursetroContract = new web3.eth.Contract(
                                Market,
                                Wallet_Details.networkConfiguration.TradeContract,
                            )
                            let CurrServiceFee = await GetServiceFee(item?.ServiceType, CoursetroContract)
                            console.log('slkfklsjflksfs', CurrServiceFee, item?.ServiceType)
                            SetCurrentFees(CurrServiceFee)

                        }
                    }
                    else {
                        if (Wallet_Details.UserAccountAddr === "") {
                            window.$('#connect_modal').modal('show');
                        }
                    }

                }
            }
        }),
    )





    async function switchNetwork(configdata) {
        let type = ""
        let networkConfiguration = {}
        let accountDetails;
        if (configdata) {
            if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                type = "MetaMask"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                type = "WalletConnect"
            }
            accountDetails = await connectWallet(type, configdata)
            let web3 = new Web3(window.ethereum);
            if (window.ethereum.isMetaMask == true) {
                const chainId = await web3.eth.getChainId();
                if (chainId === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (chainId === network.BSC.Chainid) {
                    networkConfiguration = network.BSC
                }


            }
            if (accountDetails != '') {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: accountDetails.accountAddress,
                        UserAccountBal: accountDetails.coinBalance,
                        WalletConnected: "true",
                        Wen_Bln: accountDetails.tokenBalance,
                        Accounts: accountDetails.accountAddress,
                        providerss: accountDetails.web3._provider,
                        networkConfiguration: networkConfiguration
                    }
                })
            }
        }
        return accountDetails
    }

    async function orderApprovecheck(item, tokenOwnerInfo) {
        if (Wallet_Details.providerss == null) {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        let web3 = new Web3(Wallet_Details.providerss);
        try {

            //   console.log("OrderApprove Check", item,tokenOwnerInfo);
            let MultiContract = new web3.eth.Contract(
                (item.type === 721 ? SINGLE : MULTIPLE),
                item.contractAddress
            );
            let status = await MultiContract.methods.isApprovedForAll(
                tokenOwnerInfo.tokenOwner,
                Wallet_Details.networkConfiguration.TradeContract
            ).call();
            return status;
        }
        catch (e) {
            // console.log("OrderApprove Check", e);
            return false

        }
    }
    async function ApproveCall() {
        let receiptt = null;
        let handlee = null;
        if (Wallet_Details.providerss == null) {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        let web3 = new Web3(Wallet_Details.providerss);
        let currAddr = Wallet_Details.UserAccountAddr

        if (currAddr === "") {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        setApproveCallStatus('processing');
        try {
            let MultiContract = new web3.eth.Contract(
                (Items.type === 721 ? SINGLE : (Items.type == 1155 ? MULTIPLE : LITERATURE )),
                Items.contractAddress
            );
            let encoded = await MultiContract.methods.setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract,true).encodeABI();
            let gasfeecalculated = await GetGasFees({web3: web3, encoded: encoded, contractaddress: Items.contractAddress, accountAddress: owner_Detail.tokenOwner});

            await MultiContract.methods.setApprovalForAll(
                Wallet_Details.networkConfiguration.TradeContract,
                true
            ).send({
                from: owner_Detail.tokenOwner,
                // gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10),
                // gasPrice: gasfeecalculated?.gasPrice,
            }).on('transactionHash', async (transactionHash) => {
                if (transactionHash !== "") {
                    handlee = setInterval(async () => {
                        receiptt = await getReceipt(web3, transactionHash)
                        clr();
                    }, 8000)
                }
            })
        }
        catch (error) {
            console.log("FSnhbsfgnsfh", error);
            toast.error("Approve failed", toasterOption);
            setApproveCallStatus('tryagain');
        }
        async function clr() {
            if (receiptt != null) {
                clearInterval(handlee)
                toast.success("Approve Successfully", toasterOption);
                setApproveCallStatus('done');
            }
        }
    }


    async function priceListFunction() {
        //console.log("POPup starting")
        window.$('#price_item_modal').modal('hide');
        window.$('#PutOnSale_modal').modal('show');
    }

    const SettoInitalValueFuc = () => {
        Set_FormSubmitLoading('start');
        setCoinNames('');
        set_CoinName_Initial('');
        Set_Items('');
        Set_YouWillGet(0);
        set_owner_Detail('');
        Set_TokenPrice('');
        Set_TokenPrice_Initial('');
        set_token_onwers_info('');
        setApproveCallStatus('');
        Set_MinimumBid(0);
        set_Clocktime('');
        set_EndClocktime('');
        Set_StartDate('Select Start Date');
        Set_EndDate('Select End Date');
        Setquantyputonsale(1);
        SetstateforOwnerdetails({});
        Set_ValidateError({});
        Set_priceoption({ TokenPrice: '' });
        SetListNFT(true);
        if(props?.item?.tokenowners_current?.length > 0){
            SetListedQuantity((Number(stateforOwnerdetails.balance)-Number(stateforOwnerdetails.ListedQuantity)));
            if(!props?.item?.tokenowners_current[0]?.ListNFT){
                setPutOnSale(false)
                setPutOnSaleType('UnLimitedAuction')
            }
        }
    }

    return (
        <>

            <div className="modal fade primary_modal" id="PutOnSale_modal" tabIndex="-1" role="dialog" data-bs-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true"  >
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="accept_modalLabel">{(TokenPrice_Initial === 0 || TokenPrice_Initial === null) ? 'Put On Sale' : 'Lower Price'}</h5>
                            <p className="text-gray font_we_600 font_12">You are about to sale for
                                <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{Items.tokenName} </span>
                                for
                                <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                    {
                                        token_onwers_info
                                        && (token_onwers_info)
                                        && (token_onwers_info.name !== "" ?
                                            <span >{token_onwers_info.name}</span>
                                            :
                                            <span className="word_brak_text_inline_new" title={"Owner : " + owner_Detail.tokenOwner}>{String(owner_Detail.tokenOwner).slice(0, 5).concat('...')} <span>Quantity {owner_Detail.balance}</span></span>)

                                    }
                                </span>
                            </p>


                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" id="close9" onClick={()=>SettoInitalValueFuc()}>

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0 pt-0">
                            <form className="px-4 bid_form">
                                {console.log('TokenPrice_InitialTokenPrice_Initial',TokenPrice_Initial)}
                                {TokenPrice_Initial > 0 ?
                                    <>
                                        <div className="input-group mb-2 input_grp_style_1">
                                            <input type="text"

                                                maxLength={config.maxLength}
                                                name="TokenPrice"
                                                id="TokenPrice"
                                                onChange={inputChange}
                                                placeholder="e.g. 10"
                                                autoComplete="off"

                                                className="form-control"
                                                aria-describedby="basic-addon2" />
                                            {(TokenPrice_Initial === 0 || TokenPrice_Initial === null) &&
                                                <div className="input-group-append">
                                                    <Select
                                                        className="yes1 form-control primary_inp select1 selxet_app"
                                                        id="basic-addon2"
                                                        name="coinname"

                                                        onChange={priceoptionfunc}
                                                        options={priceoption}
                                                        label="Select price"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                </div>}



                                        </div>
                                        {ValidateError.CoinName && <span className="text-danger">{ValidateError.CoinName}</span>}
                                        {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                        {ValidateError.CoinName && <span className="text-danger">{ValidateError.CoinName}</span>}

                                        {TokenPrice_Initial > 0 && <p className="form_note">Price must be less than the actual price</p>}

                                    </>

                                    :
                                    <>

                                        <div className="d-flex justify-content-between align-items-end">
                                            <div>
                                                <h3 className="my-2 ">
                                                    <span className="">
                                                        {(PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice > 0) && <span className="mr-1 putforsale">For Sale</span>}
                                                        {(PutOnSale === true && PutOnSaleType === 'TimedAuction') && <span className="mr-1 putforsale">Minimum Bid </span>}

                                                    </span> </h3>

                                                {(PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice > 0) && <button type="button" className="undefinedline" data-toggle="modal" data-target="#place_bid_multiple_modal"><span>
                                                    <Convert
                                                        item={Number(TokenPrice)}
                                                        coinName={CoinName_Initial}
                                                        convertVal={1}
                                                    /> {isEmpty(CoinName_Initial)?"":CoinName_Initial}</span></button>}
                                                {(PutOnSale === true && PutOnSaleType === 'TimedAuction') && <button type="button" className="undefinedline" data-toggle="modal" data-target="#place_bid_multiple_modal"> <span>{MinimumBid === '' ? 0 :
                                                    <Convert
                                                        item={Number(MinimumBid)}
                                                        coinName={CoinName_Initial}
                                                        convertVal={1}
                                                    />} {isEmpty(CoinName_Initial)?"":CoinName_Initial} </span></button>}

                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                            <div className="d-flex justify-content-between align-items-start grid_toggle">
                                                    <div>
                                                        <label className="primary_label" htmlFor="inputEmail4">Deposit NFT</label>
                                                        <p className="form_note">You’ll receive bids on this item</p>
                                                    </div>
                                                    <label className="switch toggle_model">
                                                        <input type="checkbox"
                                                            id="ListNFT"
                                                            name="ListNFT"
                                                            onChange={CheckedChange}
                                                            checked={ListNFT}
                                                            disabled={true}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </div>

                                                {
                                    Number(owner_Detail?.ListedQuantity) > 0 &&
                                    <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm_bold">Deposited NFT's</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{owner_Detail?.ListedQuantity}</p>
                                    </div>
                                </div>
                                }
                                                <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm_bold">Total NFT's</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{owner_Detail?.balance}</p>
                                    </div>
                                </div>
                                                {
                                                    (Number(owner_Detail?.ListedQuantity) < Number(owner_Detail?.balance) && (item.type) !== 721) &&
                                                    <div className="row mx-0 mt-3 fixed_price_sec">
                                                        <label className="primary_label" htmlFor="price_new">List Quantity</label>

                                                        <div className="quantity_wirh">

                                                            <input type="text"
                                                                value={ListedQuantity}
                                                                className="form-control primary_inp"
                                                                id="ListedQuantity"
                                                                placeholder="Enter your quantity"
                                                                onChange={(e) => { Set_ValidateError({}); Set_FormSubmitLoading('start'); if(/^\d+$/.test(e.target.value) || e.target.value == '' ) SetListedQuantity(e.target.value) }}
                                                                disabled={Number(item.type) === 721}
                                                            />
                                                            {ValidateError.ListedQuantity && <span className="text-danger">{ValidateError.ListedQuantity}</span>}

                                                        </div>
                                                    </div>
                                                }
                                                {  (item.type) !== 721 && <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm_bold">Total Deposit</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{Number(owner_Detail?.ListedQuantity) + Number(ListedQuantity)}</p>
                                    </div>
                                </div> }
                                                { ListNFT &&
                                                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                                                    <div>
                                                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                                                        <p className="form_note">You’ll receive bids on this item</p>
                                                    </div>
                                                    <label className="switch toggle_model">
                                                        <input type="checkbox"
                                                            id="putonsale"
                                                            name="putonsale"
                                                            onChange={CheckedChange}
                                                            checked={PutOnSale}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </div>}
                                                {
                                                    (PutOnSale === false) ? ('') : (

                                                        <>
                                                            <div className="row connect_row putonsale_sec justify-content-center">
                                                                <div className="col-12 col-sm-4 col-md-12 col-lg-6">
                                                                    <div className={"create_box create_sing_bx" + ((PutOnSaleType === 'FixedPrice') ? 'active' : 'inactive')} onClick={() => { changePutOnSaleType('FixedPrice') }} id="fixedprice" >
                                                                        <img src={require("../../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />
                                                                        <p>Fixed Price</p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (item.type === 721) &&
                                                                    <div className="col-12 col-sm-4 col-md-12 col-lg-6">
                                                                        <div className={"create_box create_sing_bx" + ((PutOnSaleType === 'TimedAuction') ? 'active' : 'inactive')} onClick={() => { changePutOnSaleType('TimedAuction') }} >
                                                                            <img src={require("../../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                                                                            <p>Timed Auction</p>
                                                                        </div>
                                                                    </div>}

                                                            </div>

                                                        </>)}
                                                {(PutOnSale === true && PutOnSaleType === 'FixedPrice') &&
                                                    <div className="row mx-0 mt-3 fixed_price_sec">
                                                        {(item.type === 1155) &&
                                                            <>
                                                                <label className="primary_label" htmlFor="price_new">Quantity</label>

                                                                <div className="quantity_wirh">

                                                                    <input type="text"
                                                                        // value={collectionName}
                                                                        className="form-control primary_inp"
                                                                        id="putonsalequantity"
                                                                        //    value ={putonsalequantity}
                                                                        placeholder="Enter your quantity"
                                                                        onChange={onChange}
                                                                    />
                                                                    {ValidateError.quantyputonsale && <span className="text-danger">{ValidateError.quantyputonsale}</span>}

                                                                </div></>
                                                        }
                                                        <label className="primary_label" htmlFor="price_new">Price</label>

                                                        <div className="form-row w-100">

                                                            <div className="form-group col-md-12 col-lg-12">

                                                                <div className="input-group input_grp_style_1 bordbot">

                                                                    <input
                                                                        type="text"
                                                                        className="form-control selct_form_input_h"
                                                                        placeholder="Enter price"
                                                                        aria-label="Recipient's username"
                                                                        aria-describedby="basic-addon3"
                                                                        name="TokenPrice"
                                                                        id="TokenPrice1"
                                                                        maxLength={config.maxLength}
                                                                        onChange={(e) => inputChange(e)}
                                                                        autoComplete="off"
                                                                    />

                                                                    <div className="input-group-append">
                                                                        <Select
                                                                            className="yes1 form-control primary_inp select1 selxet_app"
                                                                            id="basic-addon2"
                                                                            name="coinname"
                                                                            onChange={priceoptionfunc}
                                                                            options={priceoption}
                                                                            label="Select price"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                {ValidateError.CoinName && <span className="text-danger">{ValidateError.CoinName}</span>}

                                                                {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                                            </div>
                                                        </div>
                                                        <p className="form_note">Service fee
                                                            <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee_seller / config.decimalvalues}% </span><br />
                                                            You will receive <span className="font_we_700_note_txt">
                                                                {/* {console.log('testtttt',YouWillGet,CoinName_Initial)} */}
                                                                <Convert
                                                                    item={NumberChange(YouWillGet)}
                                                                    coinName={CoinName_Initial}
                                                                    convertVal={1}
                                                                />
                                                                {/* {
                                                                    YouWillGet
                                                                } */}
                                                                {isEmpty(CoinName_Initial)?"":CoinName_Initial}</span>
                                                            <span className="font_we_700_note_txt">
                                                                <br />
                                                            </span>
                                                        </p>
                                                    </div>
                                                }
                                                {(PutOnSale === true && PutOnSaleType === 'TimedAuction') &&
                                                    <div className="row mt-3 timed_sec">
                                                        <div className="col-12 mb-3">
                                                            <label className="primary_label" htmlFor="price_new">Minimum bid</label>
                                                            <div className="input-group mb-1 align-items-baseline">

                                                                <input
                                                                    type="text"
                                                                    className="form-control selct_form_input_h"
                                                                    // placeholder="0"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                    name="MinimumBid"
                                                                    id="MinimumBid"
                                                                    step="0.01"
                                                                    maxLength={15}
                                                                    onChange={(e) => inputChange(e)}
                                                                    placeholder="e.g. 10"
                                                                    autoComplete="off"
                                                                />

                                                                <div className="input-group-append">

                                                                    <Select
                                                                        className="yes1 form-control primary_inp select1 selxet_app"
                                                                        id="basic-addon2"
                                                                        isSearchable={false}
                                                                        name="coinname"
                                                                        // value={{ label: CoinName == config.currencySymbol ? priceoption&&priceoption[1].label : CoinName }}
                                                                        onChange={priceoptionfunc}
                                                                        options={priceoption}
                                                                        label="Select price"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                    />
                                                                </div>
                                                                {ValidateError.CoinName && <span className="text-danger">{ValidateError.CoinName}</span>}



                                                                {/* <div className="input-group-append">
                                                                    <p className="yes1 form-control primary_inp select1 selxet_app mb-0" >symbol</p>
                                                                </div> */}

                                                            </div>
                                                            {ValidateError.MinimumBid && <span className="text-danger">{ValidateError.MinimumBid}</span>}

                                                            <p className="form_note">   Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                                                        </div>

                                                        <div className="col-12 col-lg-6  mb-3">
                                                            <div className="single_dd_1">
                                                                <label className="primary_label" htmlFor="start_date">Starting Date</label>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                                        {StartDate}<i className="fas fa-chevron-down"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                                                        {/* <div >Select Date</div> */}
                                                                        <div id="RightAfterListing" onClick={() => { DateChange('StartDateDropDown', 'RightAfterListing') }} >Right after listing</div>
                                                                        <div id="PickStart" onClick={() => { DateChange('StartDateDropDown', 'PickStartDate') }}>Pick specific date</div>
                                                                    </div>
                                                                </div>
                                                                {ValidateError.Clocktime && <span className="text-danger">{ValidateError.Clocktime}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 mb-3">
                                                            <div className="single_dd_1">
                                                                <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        {EndDate}<i className="fas fa-chevron-down"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                                                        {/* <div>Select Date</div> */}
                                                                        <div onClick={() => { DateChange('EndDateDropDown', '1 Day') }}>1 day</div>
                                                                        <div onClick={() => { DateChange('EndDateDropDown', '3 Day') }}>3 days</div>
                                                                        <div onClick={() => { DateChange('EndDateDropDown', 'PickEndDate') }}>Pick specific date</div>

                                                                    </div>
                                                                </div>

                                                                {ValidateError.EndClocktime && <span className="text-danger">{ValidateError.EndClocktime}</span>}
                                                            </div>

                                                        </div>
                                                        <div className="col-12">
                                                            <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal">Learn more how timed auctions work</p>

                                                        </div>

                                                    </div>


                                                }</div>

                                        </div>


                                    </>

                                }
                                {
                                    ( PutOnSale && ListNFT && (Number(owner_Detail?.ListedQuantity) < Number(owner_Detail?.balance))) &&
                                    <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm_bold">Listing Fee</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{(currentFees?.Listing_fee?currentFees?.Listing_fee:Wallet_Details?.Listing_fee) / config.decimalvalues} <span>{Wallet_Details?.tokenAddress?.length > 0 && 
                                        Wallet_Details?.tokenAddress?.map((val)=>{
                                            return(
                                                <>{val?.Address?.includes('000000000000') && val.value}</>
                                            )
                                        })
                                        }</span></p>
                                    </div>
                                </div>
                                }
                                {
                                    PutOnSale &&
                                    <>
                                    <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm">Service fee</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee_seller / config.decimalvalues}% <span>{TokenPrice_Initial > 0 && CoinName_Initial}{(TokenPrice_Initial === 0 || TokenPrice_Initial === null) && (isEmpty(CoinName_Initial)?"":CoinName_Initial)}</span></p>
                                    </div>
                                </div>

                                <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm">You will get</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">
                                            <Convert
                                                item={NumberChange(YouWillGet)}

                                                coinName={CoinName_Initial}
                                                convertVal={1}
                                            />
                                            {/* {
                                                YouWillGet
                                            } */}
                                            <span>{TokenPrice_Initial > 0 && CoinName_Initial}{(TokenPrice_Initial === 0 || TokenPrice_Initial === null) && (isEmpty(CoinName_Initial)?"":CoinName_Initial)}</span></p>
                                    </div>
                                </div>
                                </>}

                                <div className="text-center">

                                    <button
                                        className="create_btn btn-block"
                                        type="button"
                                        disabled={(FormSubmitLoading === 'processing' || FormSubmitLoading === 'done' || FormSubmitLoading === 'init' || FormSubmitLoading === 'error' || !ListNFT)}
                                        onClick={(FormSubmitLoading === 'start' || FormSubmitLoading === 'try') ? (() => FormSubmit()) : ''}

                                    >
                                        {FormSubmitLoading === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {FormSubmitLoading === 'processing' && 'In-Progress'}
                                        {FormSubmitLoading === 'init' && 'Start'}
                                        {FormSubmitLoading === 'start' && 'Start'}
                                        {FormSubmitLoading === 'done' && 'Done'}
                                        {FormSubmitLoading === 'try' && 'Try-Again'}
                                        {FormSubmitLoading === 'error' && 'Error in Entered Price'}
                                    </button>
                                    <button className="btn_outline_red btn-block"


                                         data-bs-dismiss="modal" aria-label="Close" disabled={(FormSubmitLoading === 'processing')} onClick={()=>SettoInitalValueFuc()}>Cancel</button>


                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade primary_modal" id="price_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" onClick={()=>SettoInitalValueFuc()}>

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="media approve_media">
                                    {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}

                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Approve</p>
                                        <p className="mt-0 approve_desc">Checking balance and approving</p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <button className={"primary_btn btn-block"}
                                    type='button'
                                        disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')}
                                        onClick={ApproveCall}
                                    >
                                        {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus === 'init' && 'Approve'}
                                        {ApproveCallStatus === 'processing' && 'In-progress...'}
                                        {ApproveCallStatus === 'done' && 'Done'}
                                        {ApproveCallStatus === 'tryagain' && 'Try Again'}

                                    </button>
                                </div>
                                <div className="media approve_media">
                                    {MintCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                                    {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                                    {MintCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                                    {MintCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Listing the NFT</p>
                                        <p className="mt-0 approve_desc">Call contract method</p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <button className={"primary_btn btn-block"}
                                    type="button"
                                        disabled={(ApproveCallStatus !== 'done' || MintCallStatus === 'processing' || MintCallStatus === 'done')}
                                        onClick={priceListFunction}
                                    >
                                        {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {MintCallStatus === 'init' && 'Start'}
                                        {MintCallStatus === 'processing' && 'In-progress...'}
                                        {MintCallStatus === 'done' && 'Done'}
                                        {MintCallStatus === 'tryagain' && 'Try Again'}
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            {/* calendar Modal */}
            <div className="modal fade primary_modal" id="calendar_modal" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="pb-3">

                                <Datetime
                                    input={false}
                                    value={Clocktime}
                                    onChange={(value) => set_Clocktime(value)} />
                            </div>
                            <div className="text-center pb-3">
                                <button  type="button" className="btn btn_blue" id="doneStartDate" onClick={() => ModalAction('calendar_modal', 'hide')} >Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end calendar modal */}
            {/* calendar Modal */}
            <div className="modal fade primary_modal" id="calendar_modal_expire" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="pb-3">
                                <Datetime
                                    input={false}
                                    value={EndClocktime}
                                    onChange={(value) => set_EndClocktime(value)}
                                />
                            </div>
                        </div>
                        <div className="text-center">
                            <button  type="button" className="primary_btn" id="doneEndDate" onClick={() => ModalAction('calendar_modal_expire', 'hide')}>Done</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* end calendar modal */}
        </>
    )
})
