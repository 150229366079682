
import React from "react";
import {useNavigate} from "react-router-dom";
import config from '../../lib/config';
import Avatars from "../../views/Avatar";
import Profile from "../../assets/images/profile_img.png";


export default function ActivityCard(props) {
  let navigate=useNavigate();
  let {
    item
    } = props;

    return (
    
          
    <div className="topgrid atcs">
    {[...Array(8)].map(()=>(
    <div className="topgridban">
      <div>
      <img src={Profile} />
      </div>
      <div className="activity">
          <h3 className="activityhead">lenin quies</h3>
          <p className="activitypara">Lorem ipsum dolor sit amet,<span>EdaFace NFT.</span></p>
      </div>
      <h5>6/5/2022,5:36 PM</h5>
    </div>
    ))}
  </div>
    )
}
