import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import config from "../../lib/config";
// import Audios from '../../assets/images/masonary_02.png'
import { useSelector } from "react-redux";
import BSC from "../../assets/images/BNB12.png";
import ETH from '../../assets/images/eth.png'
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import LazyLoader from "../lazyloader";
import Avatars from "../../views/Avatar";
import isEmpty from "../../lib/isEmpty";
import { getrating, likes } from "../../actions/v1/token";
import { network } from "../../views/network";

export default function TokenCard(props) {


  const Wallet_Details = useSelector((state) => state.wallet_connect_context);


  const [starRate, setstarRate] = useState(0);
  const [likeList, setlikeList] = useState(LikedTokenList);


  var {
    item,
    LikedTokenList,
    setLikedTokenList,
    hitLike,
    curraddress
  } = props;


  useEffect(() => {
    getstarRating(item.tokenCounts);
    getLikes(item.tokenCounts)
  }, [item?.tokenCounts]);

  const getstarRating = async (count) => {
    let data = {
      tokenCounts: count,
    };
    let rating = await getrating(data);
    if (rating?.data?.data?.data[0]?.avgQuantity !== null) {
      let starrate = Math.round(rating?.data?.data?.data[0]?.avgQuantity);
      setstarRate(starrate);
    }


  };


  const getLikes = async (count) => {
    if (Wallet_Details.UserAccountAddr != '' || curraddress != '') {
      let data =
      {
        currAdd: Wallet_Details.UserAccountAddr != '' ? Wallet_Details.UserAccountAddr : curraddress,
        tokenCount: count
      }
      let likesCount = await likes(data)
      if (!isEmpty(likesCount?.data?.data)) {
        setlikeList(likesCount.data.data.data)
        setLikedTokenList(likesCount?.data?.data?.data)
      }
    }

  }


  const onlikeClick = async () => {
    let likeupdate = hitLike(item)
    setTimeout(() => {
      getLikes(item.tokenCounts)
    }, 100);
  }



  const checkerror = (e, item) => {
    e.target.src = `${config.IPFS_IMG}/${item.ipfsimage}`;
  };

  const hoursMinSecs = { hours: 1, minutes: 20, seconds: 40 };
  var renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <span>Waiting for Owner To Accept</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(hours)}</span> :{" "}
          <span className="hourds">{formatTime(minutes)}</span> :{" "}
          <span className="hourds">{formatTime(seconds)}</span>{" "}
        </span>
      );
    }
  };

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  const TokenDisplayUrl = () => {
    if (item?.Preview) {
      if (item?.tokenowners_current?.WhiteListUser?.includes(Wallet_Details.UserAccountAddr) || item?.tokenowners_current?.tokenOwner == Wallet_Details.UserAccountAddr) {
        return item && item?.additionalImage
          ? item?.additionalImage === ""
            ? `${config.IPFS_IMG}/${item?.ipfsimage}`
            : `${config.Back_URL}/compressedImage/${item?.tokenCreator}/${item.additionalImage}`
          : `${config.IPFS_IMG}/${item?.ipfsimage}`
      }
      else {
        return item && item?.Preview
          ? `${config.Back_URL}/preview/${item?.tokenCreator}/${item.Preview}`
          : `${config.IPFS_IMG}/${item?.ipfsimage}`
      }

    }
    else {
      return item && item?.additionalImage
        ? item?.additionalImage === ""
          ? `${config.IPFS_IMG}/${item?.ipfsimage}`
          : `${config.Back_URL}/compressedImage/${item?.tokenCreator}/${item.additionalImage}`
        : `${config.IPFS_IMG}/${item?.ipfsimage}`
    }

  }

  return (
    <div className="tokens tokencard_tok">
      <div className="baner_image">
        <div className="ethactios">
          {/* <img src="" className="img-fluid"/> */}

          <Link
            to={
              "/info/" +
              item?.tokenOwner.toLowerCase() +
              "/" +
              item?.contractAddress.toLowerCase() +
              "/" +
              item?.tokenCounts
            }
          >
            <LazyLoad
              height={200}
              placeholder={<LazyLoader />}
              offset={[-200, 0]}
              debounce={500}
            >
              <>

                {item?.image !== "" &&
                  (String(item?.image).split(".").pop() === "mp4" ||
                    String(item?.image).split(".").pop() === "webm" ||
                    String(item?.image).split(".").pop() === "WEBM" ||
                    String(item?.image).split(".").pop() === "ogv" ||
                    String(item?.image).split(".").pop() === "OGV") && (
                    <video
                      id="my-video"
                      className="img-fluid"
                      autoPlay
                      playsInline
                      loop
                      muted
                      preload="auto"
                      alt="video"
                    >
                      <source
                        src={
                          TokenDisplayUrl()
                        }
                        type="video/mp4"
                      />
                    </video>
                  )}

                {item.image !== "" &&
                  (String(item.image).split(".").pop() === "mp3" ||
                    String(item.image).split(".").pop() === "MP3" ||
                    String(item.image).split(".").pop() === "aac" ||
                    String(item.image).split(".").pop() === "AAC" ||
                    String(item.image).split(".").pop() === "FLAC" ||
                    String(item.image).split(".").pop() === "flac") && item.thumb != "" ?


                  <>
                    <img src={`${config.Back_URL}/Thumb_compressedImage/${item?.tokenCreator}/${item.thumb}`} className="img-fluid" loading="lazy"></img>
                    <audio
                      controls
                      controlsList="nodownload"
                      className="audio_align"
                      alt="audio"
                      playsInline
                      loop
                      muted
                      type="audio/*"
                      autostart="off"
                      src={
                        TokenDisplayUrl()
                      }
                    ></audio>
                  </>
                  :
                  String(item.image).split(".").pop() === "mp3" &&
                  (
                    <>
                      <img
                        src={config.AudioImg}
                        className="img-fluid"
                        alt="text"
                      />
                      <audio
                        controls
                        controlsList="nodownload"

                        alt="audio"
                        playsInline
                        loop
                        muted
                        type="audio/*"
                        autostart="off"
                        src={
                          TokenDisplayUrl()
                        }
                      ></audio>
                    </>
                  )}

                {/* Book */}
                {item.image !== "" &&
                  (String(item.image).split(".").pop() === "pdf" ||
                    String(item.image).split(".").pop() === "PDF") && item.thumb != "" ?


                  <>
                    <img src={`${config.Back_URL}/Thumb_compressedImage/${item?.tokenCreator}/${item.thumb}`} className="img-fluid" loading="lazy"></img>
                  </>
                  :
                  String(item.image).split(".").pop() === "pdf" &&
                  (
                    <>
                      <img
                        src={config.AudioImg}
                        className="img-fluid"
                        alt="text"
                      />
                    </>
                  )}
                {/* Book */}

                {item &&
                  item?.image !== "" &&
                  (String(item?.image).split(".").pop() === "webp" ||
                    String(item.image).split(".").pop() === "WEBP" ||
                    String(item?.image).split(".").pop() === "gif" ||
                    String(item.image).split(".").pop() === "jpg" ||
                    String(item?.image).split(".").pop() === "GIF" ||
                    String(item.image).split(".").pop() === "JPG" ||
                    String(item?.image).split(".").pop() === "JPEG" ||
                    String(item.image).split(".").pop() === "jpeg" ||
                    String(item?.image).split(".").pop() === "png" ||
                    String(item.image).split(".").pop() === "PNG")
                  &&
                  (item?.ipfsimage?.split("/").includes("ipfs") === false) ?

                  <img src={item?.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item?.tokenCreator}/${item?.additionalImage}` : `${config.IPFS_IMG}/${item?.ipfsimage}`}
                    onError={(e) => checkerror(e, item)}
                    alt="Collections" className="img-fluid" /> :
                  (isEmpty(item?.additionalImage) &&
                    <img src={item?.ipfsimage?.split("/").includes("ipfs") && `${item?.ipfsimage}`}
                      onError={(e) => checkerror(e, item)}
                      alt="Collections" className="img-fluid " />)


                }
              </>
            </LazyLoad>
          </Link>
        </div>
        <div className="auctioncards">
          <div className="starreiaf">
            <Link
              to={
                "/info/" +
                item.tokenOwner +
                "/" +
                item.contractAddress +
                "/" +
                item.tokenCounts
              } //className="nameimg"
            >
              <h3>
                {item?.tokenName && (item.tokenName.length > 16 ? item.tokenName.slice(0, 10).concat("...") : item.tokenName)}
              </h3>
              <p className="cat_name"><span>Type : </span>
                {
                  item?.additionalImage?.includes('.pdf') ? "Book" :
                    (
                      item?.additionalImage?.includes(".mp3") ? "Audio" :
                        (
                          item?.additionalImage?.includes(".webp") ? "Image" :
                            "Video"
                        )
                    )
                }

              </p>
            </Link>

            <div className={"star-rating star" + starRate}>
              <input id="star-5" type="radio" name="rating" value="star-5" />
              <label for="star-5" title="5 stars" className="five">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-4" type="radio" name="rating" value="star-4" />
              <label for="star-4" title="4 stars" className="four">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-3" type="radio" name="rating" value="star-3" />
              <label for="star-3" title="3 stars" className="three">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-2" type="radio" name="rating" value="star-2" />
              <label for="star-2" title="2 stars" className="two">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
              <input id="star-1" type="radio" name="rating" value="star-1" />
              <label for="star-1" title="1 star" className="one">
                <i className="active fa fa-star" aria-hidden="true"></i>
              </label>
            </div>
            {/* <Rating name="read-only" value={starRate} readOnly /> */}
          </div>

          <div className="highestbid">
            {item &&
              item.higheBd != null &&
              item.tokenowners_current.tokenPrice !== null &&
              item.higheBd.tokenBidAmt != null ?
              <p>Highest Bid</p>
              :
              <p>Token Price</p>
            }
            <h4>
              {item &&
                item.higheBd != null &&
                item.tokenowners_current.tokenPrice !== null &&
                item.higheBd.tokenBidAmt != null ? (
                <span>

                  {item.higheBd.tokenBidAmt}

                  {item.higheBd.CoinName}

                  <></>
                </span>
              ) : (
                <span>{item.tokenowners_current.tokenPrice}

                </span>
              )}
              <img src={String(item?.SelectedNetwork) == String(network.BSC.Chainid) ? BSC : ETH} />
            </h4>
          </div>
          <div className="abaucdetail">
            <div className="banercontentauction">
              {/* <img src={Profile} /> */}
              {item && item.tokenCreatorInfo && (
                <Link
                  to={
                    item.tokenCreatorInfo.customurl !== ""
                      ? `/${item.tokenCreatorInfo.customurl}`
                      : `/user/${item.tokenCreatorInfo.curraddress}`
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  title={`Creator : ${item.tokenCreatorInfo.name !== ""
                      ? item.tokenCreatorInfo.name
                      : item.tokenCreatorInfo.curraddress
                    }`}
                >
                  {item.tokenCreatorInfo.image !== "" ? (
                    <img
                      src={`${config.Back_URL}/images/${item.tokenCreatorInfo._id}/${item.tokenCreatorInfo.image}`}
                    />
                  ) : (
                    <div>
                      <Avatars />
                    </div>
                  )}
                </Link>
              )}

              {/* <img src={Profile} className="profikdds"/> */}

              {item &&
                // && item.type == 1155
                item.tokenuser &&
                !isEmpty(item.tokenuser) &&
                item.tokenowners_current_count &&
                item.tokenowners_current_count.count &&
                (item.tokenuser.image && item.tokenuser.image !== "" ? (
                  <Link
                    to={
                      item.tokenuser.customurl !== ""
                        ? `/${item.tokenuser.customurl}`
                        : `/${item.tokenuser.curraddress}`
                    }
                    title={`Owner : ${item.tokenuser.name}`}
                  >
                    <img
                      src={`${config.Back_URL}/images/${item.tokenuser._id}/${item.tokenuser.image}`}
                    />
                  </Link>
                ) : (
                  <Link
                    to={`/user/${item.tokenowners_current.tokenOwner}`}
                    title={`Owner : ${item.tokenowners_current.tokenOwner}`}
                  >
                    <div>
                      <Avatars />
                    </div>
                  </Link>
                ))}

              {item?.tokenuser?.name === "" ? (
                <div className="banercontentDet">
                  <h2>
                    @{item?.tokenuser?.curraddress.slice(0, 8).concat("...")}
                  </h2>
                </div>
              ) : (
                <div className="banercontentDet">
                  <h2>@{item?.tokenuser?.name}</h2>
                </div>
              )}
            </div>
            <div className="hearting" onClick={() => onlikeClick(item)}>
              {/* <p><i class="fa fa-heart" aria-hidden="true"></i> <span>246</span></p> */}
              {likeList?.findIndex(
                (tokenCounts) => tokenCounts.tokenCounts === item.tokenCounts
              ) > -1 ? (
                <i className="fas fa-heart mr-1 liked"></i>
              ) : (
                <i className="far fa-heart mr-1"></i>
              )}
            </div>
          </div>
        </div>
        <div className="timers">
          {item &&
            item.tokenowners_current &&
            item.tokenowners_current.clocktime &&
            item.tokenowners_current.clocktime != null &&
            item.tokenowners_current.endclocktime &&
            item.tokenowners_current.endclocktime != null ? (
            <p>
              <span>
                {new Date(item.tokenowners_current.endclocktime) >
                  Date.now() ? (
                  <Countdown
                    // date={Date.now() + 8000000}
                    // autoStart={true}
                    // renderer={renderer}
                    date={new Date(item.tokenowners_current.endclocktime)}
                    autoStart={true}
                    onStart={() => new Date(item.tokenowners_current.clocktime)}
                    renderer={renderer}
                  ></Countdown>
                ) : (
                  <span>Auction Completed</span>
                )}
              </span>
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
