import React, { useEffect, useState } from "react";


// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { faqlists } from '../actions/v1/report'
import { Row, Col } from "react-bootstrap";



const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Nftusecase(props) {

  const { ...rest } = props;


  return (
    <div className="inner_header howItWorks">
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">NFT Use Cases</h2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container mt-3">
          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Entertainment
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>NFTs are featuring strongly in the entertainment industry, especially in games.</p>
                  <div className="accordionparalist">

                    <p>You may be wondering, “so NFTs are only for entertainment?” Partly right. Entertainment has grown
                      to be a huge business. In fact, the entertainment industry is one of the fastest growing, if not the
                      biggest, financial industries in the world.

                    </p>
                    <p>For instance, the global movies and entertainment market was valued at 80.98 billion USD in 2016
                      and grew to 91.83 billion USD in 2020. This market is expected to grow at a compound annual
                      growth rate of 4.1% to reach 114.93 billion USD by 2025.</p>
                    <p>With the rise of NFTs, this growth rate may be far exceeded.</p>
                  </div>

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Tokenisation
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Besides entertainment, another force that drives NFTs is the monetary aspect. Since NFTs are
                    cryptocurrencies, the currency for their transactions are cryptos.

                  </p>
                  <p>For instance, NFTs that are minted on the EdaFace Marketplace are bought and sold with EDA, the
                    crypto token for EdaFace ecosystem. </p>
                  <p>Because the tokens are tied to the NFTs, as more of the NFTs are traded, you expect the value of
                    EDA to keep rising. This means if you are not interested in acquiring NFTs, you may choose to trade
                    on EDA and still make good profits.
                  </p>

                  <div className="accordionparalist">
                    <i>Hurray!!!</i>
                    <p>NFTs are digital representations of the physical world! Meaning the days of wealth from artistic
                      works have arrived! Don’t be left behind!

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  Future use cases
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>As the Crypto Market evolves, NFTs will gradually find other use cases for their existence such as:
                  </p>

                  <div className="accordionparalist">
                    <h2>(a). Licensing and Certification</h2>
                    <p>NFTs can be used to verify licenses and certifications.</p>
                    <p>Imagine if a university crafts an NFT into each certificate it issues! What that means is that the
                      originality of each certificate issued by the institution can easily be ascertained by checking its
                      embedded NFT unique signatory features.</p>
                    <p>In that way, certificate forgery will be a thing of the past, and the university administration could
                      save a lot of time and resources required for certificate verification by simply accessing such licenses
                      with the functionalities of their NFTs.</p>
                  </div>
                  <div className="accordionparalist">
                    <h2>(b). Control of Counterfeit tickets and Corruption in Sports</h2>
                    <p>One common headache in public (and even some private) events such as world cup soccer is
                      ascertaining the authenticity of entry tickets. Counterfeit tickets and corrupt merchandise are some
                      of the critical issues that plague the sports industry.
                    </p>
                    <p>However, NFTs can bring a solution to these plagues.</p>
                    <p>Imagine if Fédération Internationale de Football Association (FIFA), which is the world governing
                      body of football, will issue an NFT for each world cup with its tokens! The fans acquire the tickets by
                      simply buying the world cup NFT tokens. Imagine if each of the tickets sold carry the unique
                      signature of identification!</p>
                    <p>This will not only be a source of revenue for the event, but it will also be a means for checking
                      duplicated or forged tickets. It means the issue of counterfeit tickets and manipulations of tickets to
                      favour some privileged people to the detriment of the soccer game will be a thing of the past.</p>
                    <p>Remember, the blockchain cannot be manipulated, hence such world cup NFT-inscribed tickets will
                      be free from undue manipulations by any corrupt official.
                    </p>
                  </div>
                  <div className="accordionparalist">
                    <h2>(c). Family Legacy</h2>
                    <p>Each NFT carries specific crypto traits such as fur patterns or eye colour for crypto cats. A user can
                      purchase two different crypto cats and breed them by simply clicking on a button. The resulting new
                      breed of kitten carry features of its own identity and Genetic Algorithm (GA).

                    </p>
                    <p>In the same manner, parents and grandparents can breed new collectibles specifically for their
                      family and such collectibles with specific genetic algorithm can be passed onwards through
                      generations as family legacies.
                    </p>
                  </div>
                  <div className="accordionparalist">
                    <h2>Summary</h2>
                    <p>Indeed, NFTs are in their early stage of growth and development. As innovations pour in, more use
                      cases for them will emerge.</p>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
