import DETH_ABI from '../ABI/DETH_ABI.json';

export const network =
{
    ETH: {

        // currencySymbol: 'ETH',
        // tokenSymbol: 'WETH',
        // tokenABI: { WETH: DETH_ABI },
        // tokenAddr: { WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase() },
        // singleContract: "0x45aF8d34e9bd4bCd283A9dad54941A11fdb1d81E".toLowerCase(),
        // multipleContract: "0xCD3eBeC243dFC28A3918cD3DCA7Ef56A20426a9d".toLowerCase(),
        // TradeContract: "0rxc3b7719CF89af4700BA4c303186bF0222F6b9184",
        // BNBPROVIDER: "https://mainnet.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2",
        // networkVersion: '1',
        // chainId: '0x1',
        // Chainid: 1,
        // trade: ("0x1081E292e0Bda2446a2773C0549d2d1D6bCecFdD").toLowerCase(),
        // multipleContract: "0x689B803Ae597A35CfDBe1f753E249857F99e05de".toLowerCase(),
        // EvmName:  "ETHEREUM",


        //              Sepolia

        currencySymbol: 'ETH',
        tokenSymbol: 'CAKE',
        tokenAddr: { CAKE: "0xc6aBa068A91d327B259327523f71f51221943186".toLowerCase() },
        tokenABI: { CAKE: DETH_ABI },
        TradeContract: "0x57b412D7a1623A72dC9b0FFCd9aaB7C10d21d19a".toLowerCase(),

        singleContract: "0xb56828a7cc046dc27411f8ba52933f543cdde6ae".toLowerCase(),////////
        multipleContract: "0x3c50e5949fb322e7212f88f14237485d09f2344f".toLowerCase(),////////
        literatureContract: "0x4C27C310504c429f95d4575e30f78Fea420f8371".toLowerCase(),

        BNBPROVIDER: "https://sepolia.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2",
        networkVersion: '11155111',
        chainId: '11155111',
        Chainid: 11155111,
        name:"Sepolia",
        EvmName:  "SEPOLIA",
        label: "Sepolia",
        value: "Etherium",
    },
    BSC: {



////////////////////////////binance/////////////////////////////

        currencySymbol: 'BNB',
        tokenSymbol: 'CAKE',
        tokenAddr: { CAKE: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase() },
        tokenABI: { CAKE: DETH_ABI },
        TradeContract: "0xa55979D24dA9718e0B4CB2F5706Bdb0A76eB9778".toLowerCase(),
        singleContract: "0x0811927f25199FCa99548b05734Aa51AF3ff110F".toLowerCase(),
        multipleContract: "0xF9b44507944E7A765E18de2C6cafA087153367e1".toLowerCase(),
        literatureContract: "0xaDAdF4F1525EAA58A74Aa9F4e1214c69624e284e".toLowerCase(),


        //                  OLD
        // TradeContract: "0xE7D77dD9F2511d814EE72817556B7C864C869f79".toLowerCase(),
        // singleContract: "0x8526A6853a6a6ef0a2f1B4d438a6Fc60Ac179971".toLowerCase(),////////polygon
        // multipleContract: "0xd48fa845B28f3587701834Cc3644daDD1935DCce".toLowerCase(),////////polygon
        //                  OLD

        BNBPROVIDER: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        networkVersion: '97',
        chainId: '0x97',
        Chainid: 97,
        name:"Binance",
        EvmName : 'BSC_TESTNET',
        label: "binance",
        value: "Binance",

///////////////////////////avax(onlyy for mint testing)////////////////////////

// avax: {

// currencySymbol: 'avax',
//         tokenSymbol: 'CAKE',
//         tokenAddr: { CAKE: "0xeb4ffed6b324b5fdc4d72ad3da75bf1fb2bb004a".toLowerCase() },
//         tokenABI: { CAKE: DETH_ABI },
//         TradeContract: "0xc6E0aaf9973b441361c1fC049D8dFfB1394ff142".toLowerCase(),

//         singleContract: "0xc6E0aaf9973b441361c1fC049D8dFfB1394ff142".toLowerCase(),////////polygon
//         multipleContract: "0xc6E0aaf9973b441361c1fC049D8dFfB1394ff142".toLowerCase(),////////polygon
//         BNBPROVIDER: "https://api.avax-test.network/ext/bc/C/rpc",
//         networkVersion: '43113',
//         chainId: '0x43113',
//         Chainid: 43113,
     
    // }

//////////////////////new binance//////////////////

        // currencySymbol: 'BNB',
        // tokenSymbol: 'WBNB',
        // tokenAddr: { WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c".toLowerCase() },
        // tokenABI: { WBNB: DETH_ABI },
        // TradeContract: "0xc3b7719CF89af4700BA4c303186bF0222F6b9184".toLowerCase(),
        // singleContract: "0xCD3eBeC243dFC28A3918cD3DCA7Ef56A20426a9d".toLowerCase(),////////polygon
        // multipleContract: "0x80dc293cd13F9d5f2f516A4BeaD02AF839c534b5".toLowerCase(),////////polygon
        // BNBPROVIDER: "https://bsc-dataseed1.binance.org/",
        // networkVersion: '56',
        // chainId: '0x38',
        // Chainid: 56,

        // BNBPROVIDER: "https://bsc-dataseed1.binance.org/",
        // networkVersion: '56',
        // chainId: '0x38',
        // Chainid: 56,
        // EvmName:  "BSC",
    }

}