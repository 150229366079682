import React, { useEffect, useState, useRef } from "react";
import TopHeader from "./TopHeader";
import logo from "../../assets/images/logo.png";
import nftlogo from "../../assets/images/nft-marketplace.png";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { Nav, NavDropdown, Navbar, Offcanvas, Container, Dropdown, DropdownToggle, DropdownMenu } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import config from '../../lib/config';
import Avatars from "../../views/Avatar";
import Registerbtn from "../../assets/images/registericon.png";
import Wallet from "../../assets/images/walletconnect.png";

import { Connect } from '../../views/separate/connectWallet'
import { getServiceFees, connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { useSelector, useDispatch } from 'react-redux'
import { Account_Connect, Account_disConnect } from "../../actions/redux/action";
import Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { AddressUserDetails_GetOrSave_Action, getaccouncementurldata, submitdata, userget } from "../../actions/v1/user";
import isEmpty from "../../lib/isEmpty";
import { getListOfToken, networklist, WenlamboConvert } from '../../actions/v1/token'
import { network } from "../../views/network"
import {
    GetCategoryAction
} from '../../actions/v1/token';
import { getSearchList } from "../../actions/v1/user";
import { getCmsInFooter, notifications, notificationStatusChange } from '../../actions/v1/report';
import { NumberChange } from "../../lib/common";


// toast.configure();
let toasterOption = config.toasterOption


export default function Header(props) {

    const navigate = useNavigate();
    const timerRef = useRef(null);

    const Wallet_Details = useSelector(state => state.wallet_connect_context);


    const dispatch = useDispatch();
    const connectMadalRef = useRef();

    const [TokenImages, setTokenImage] = useState({});
    const [CategoryOption, setCategoryOption] = useState([]);
    const [searchItem, setSearchItem] = useState([])
    const [keyword, setKeyword] = useState();
    const [UsersearchItem, setUserSearchItem] = useState([])
    const [userdata, setuserdata] = useState([])
    const [notificationss, setnotificationss] = useState([])
    const [cms, setCms] = useState([]);
    const [description, setDescription] = useState('')
    const [deleted, setDeleted] = useState('')

    const [showannounce, setShowAnnounce] = useState("true");

    useEffect(() => {
        getCmsData()
    }, []);
    var location = useLocation();
    const [locations, setLocation] = useState(location)


    useEffect(() => {
        // getAccouncementData()
    }, [location]);


    const getCmsData = async () => {
        const getData = await getCmsInFooter();
        const filter = getData?.data?.filter((el) => el.identifier == "Resources");
        setCms(filter)
    }
    const toggletheme = () => {

        document.getElementById("root").classList.toggle('dark_theme');
        var usebody = document.getElementsByClassName("mobile_nav");
        for (var j = 0; j < usebody?.length; j++) {
            usebody[j].classList.toggle('dark_theme')
        }


    };
    const toggleUsermenu = () => {
        var useclass = document.getElementsByClassName("usemneu_dd");
        for (var i = 0; i < useclass?.length; i++) {
            useclass[i].classList.toggle('d-none')
        }
    }

    var tab = 'activity';
    const toggleSearchmenu = async (event) => {

        var useclass = document.getElementsByClassName("searchmneu_dd");
        if (event?.target?.value?.length == 1) {
            for (var c = 0; c < useclass.length; c++) {
                useclass[c].classList.remove('d-none');

            }
        }
        if (event?.target?.value?.length == 0) {
            for (var c = 0; c < useclass.length; c++) {
                useclass[c].classList.add('d-none');

            }
        }
        let keywordVal = event.target.value;
        setKeyword(keywordVal)
        let postData = {
            limit: 6,
            keyword: keywordVal
        }
        var data = await getSearchList(postData);
        if (data && data.searchlist && data.searchlist.items && data.searchlist.items.list) {
            setSearchItem(data.searchlist.items.list);
        }
        if (data && data.searchlist && data.searchlist.users) {
            setUserSearchItem(data.searchlist.users);
        }
    }

    useEffect(() => {

        if (window.ethereum) {
            window.ethereum.on('chainChanged', (networkId) => {
                var message = ''

                let allnetwork = Object.values(network);
                let getchain = allnetwork.find((val) => val?.Chainid == parseInt(networkId, 16))
                // if (String(networkId) === "0x61" || String(networkId) === "0x38") {
                //   message = "successfuly connected to binance network"

                // }
                // else if (String(networkId) === "0x1") {
                //   message = "successfuly connected to ethereum network"
                // }
                // else {

                //   message = ""
                // }
                if (!isEmpty(getchain) && getchain?.label) {
                    message = "successfully connected to " + getchain?.label + " network"
                }
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                timerRef.current = setTimeout(() => {

                    if (message !== '') {
                        toast.success(message, toasterOption);
                    }

                }, 1000);
            })

            window.ethereum.on('accountsChanged', function (accounts) {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                timerRef.current = setTimeout(() => {
                    walletConnect('MetaMask');
                }, 1000);
            })
        }
        GetCategoryCall();
        if (Wallet_Details?.UserAccountAddr != '') {
            notification(Wallet_Details?.UserAccountAddr)
        }
    }, [Wallet_Details.UserAccountAddr])

    useEffect(() => {
        if (localStorage.registered === true) {
            walletConnect('MetaMask', 'register');
        }

        else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            walletConnect('MetaMask', 'register');
        }
        else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {

            walletConnect('WalletConnect', 'register')
        }
        if (localStorage.getItem('registedEmail')) {
            GetUsername()
        }

    }, [localStorage.walletConnectType, Wallet_Details.UserAccountAddr]);

    useEffect(() => {


        networkoption()
    }, [Wallet_Details.UserAccountAddr]);


    const walletConnect = async (type, register, accountchanged) => {
        try {
            if (window.ethereum.isMetaMask == true) {
                var web3 = new Web3(window.ethereum);
                var ChainID = await web3.eth.getChainId();
                window.$('#connect_modal').modal('hide');
                var type = "MetaMask"/////////////////////////////need tobe change
                var networkConfiguration = ""

                var accountDetails = await connectWallet(type, ChainID)

                const IDcheck = await web3.eth.getChainId();
                if (accountDetails != '') {
                    if (IDcheck === network.ETH.Chainid) {
                        networkConfiguration = network.ETH
                    }
                    else if (IDcheck === network.BSC.Chainid) {
                        networkConfiguration = network.BSC
                    }
                    else {
                        toast.warning("please switch network to binance or ethereum")
                        localStorage.setItem("accountInfo", '')
                        localStorage.setItem('walletConnectType', '')
                        return false
                    }

                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            UserAccountAddr: accountDetails.accountAddress,
                            UserAccountBal: accountDetails.coinBalance,
                            WalletConnected: "true",
                            Wen_Bln: accountDetails.tokenBalance,
                            Accounts: accountDetails.accountAddress,
                            providerss: accountDetails.web3._provider,
                            networkConfiguration: networkConfiguration
                        }
                    })
                }


                getInitialSeviceFee(networkConfiguration.Chainid)
                getBuyTokensList(networkConfiguration.Chainid)

                var data1 = {
                    curradd: accountDetails.accountAddress, email: localStorage.registedEmail

                }
                var users = await userget(data1)
                var alreadyuser = users?.data?.data?.users
                console.log("🚀 ~ walletConnect ~ alreadyuser:", alreadyuser)
                if (accountchanged === undefined) {
                    if (alreadyuser != null && (alreadyuser?.curraddress === '' || alreadyuser?.curraddress === accountDetails?.accountAddress || localStorage?.userAddress === accountDetails?.accountAddress)) {
                        if (String(localStorage?.registedEmail) === String(alreadyuser?.Regemail) || localStorage?.registedEmail === undefined) {
                            var data = {
                                addr: String(accountDetails.accountAddress).toLowerCase(),
                                from: register ? register : '',
                                email: localStorage.registedEmail
                            }

                            var Resp = await AddressUserDetails_GetOrSave_Action(data);
                            console.log("🚀 ~ walletConnect ~ Resp:", Resp)

                            if (Resp.data.data.Message === "already registered") {
                                toast.error(`Email invalid `)
                                setTimeout(() => {
                                    signout()
                                }, 3000);
                            }
                            else if (Resp.data.data.Message === "not registered") {
                                toast.error(`please register to login`)
                                setTimeout(() => {
                                    signout()
                                }, 3000);
                            }
                            else {
                                localStorage.setItem("userAddress", Resp?.data?.data?.User?.curraddress)
                                dispatch({
                                    type: Account_Connect,
                                    Account_Detail: {
                                        userid : Resp.data.data.User?._id
                                    }
                                })
                            }
                            if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
                                return Resp.data.data.User
                            } else {
                                return null
                            }
                            // }
                        }
                        else {
                            toast.error(" to login")
                            signout()
                        }
                    }
                    else if (alreadyuser === null || alreadyuser?.curraddress !== accountDetails?.accountAddress) {
                        toast.error("please register to login")
                        signout()
                    }
                    else {

                    }
                }

            }
        }
        catch (err) {
            console.log('walletConnect_error', err)
        }
    }












    const getInitialSeviceFee = async (Chainid) => {
        if (Chainid != 0) {
            var fees = await getServiceFees(Chainid)
            if (fees) {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        Service_Fee_buyer: fees.buyerFee,
                        Service_Fee_seller: fees.sellerFee,
                        Minting_fee: fees.mintingfee,
                        Listing_fee: fees.listingfee,
                        Withdraw_fee: fees.withdrawfee,
                        baseRoyalty: fees.baseRoyalty,
                    }
                })
            }
        }
    }

    window.addEventListener('load', async (event) => {
        if (localStorage.getItem('walletConnectType') == 'MetaMask') {
            if (window.ethereum) {

                window.ethereum.on('chainChanged', async function (networkId) {
                    var message = ''

                    if (timerRef.current) {
                        clearTimeout(timerRef.current);
                    }
                    timerRef.current = setTimeout(() => {
                        walletConnect('MetaMask', '', 'accountChanged');

                    }, 1000);
                    //  setTimeout(() => {
                    //     window.location="/"
                    //   }, 1000);
                    // timerRef.current = setTimeout(() => {
                    //   if(message!=='')
                    // {
                    // toast.success(message, toasterOption);

                    // }
                    // },100);
                })
            }
            else {
                window.location = "/"
            }
        }
        else if (localStorage.walletConnectType == "WalletConnect") {
            var provider3 = null
            if (provider3 == null) {
                provider3 = await walletConnect("WalletConnect");
            }
            else if (provider3 != null) {
                (provider3).on("connect", () => {
                    walletConnect('WalletConnect')
                });
                (provider3).on("disconnect", () => {
                    localStorage.removeItem('walletConnectType')
                });
            }
        }
    })

    async function getBuyTokensList(Chainid) {
        var id = { "Chainid": Chainid }
        if (id) {
            var TokenList = await getListOfToken(id)
console.log('TokenList?.data?.data-->',TokenList?.data?.data,"dd")
            if (TokenList?.data?.data?.[0]?.Token) {
                var get_token_details = await Promise.all(TokenList.data.data[0].Token.filter(item=>item.deleted == false || item.deleted == 0).map(async (item) => {
                    return ({
                        label: item.tokenSymbol,
                        value: item.tokenSymbol,
                        Address: item.tokenAddress,
                        // currency:item.currency,
                        Decimal: Number(item.decimal),
                        network: TokenList?.data?.data?.[0]?.network,
                        // tokenETH:bnbsETH,
                        // tokenBSC:bnbsBNB
                    }
                    )
                }))
console.log('get_token_details-->',get_token_details)

                let allcurrencydata = await getListOfToken();
                let allcurrency = [];
                if (allcurrencydata?.data?.data?.length > 0) {
                    await Promise.all(allcurrencydata?.data?.data?.map(async (val) => {
                        if (val?.Token?.length > 0) {
                            await Promise.all(val?.Token?.map(async (item) => {
                                let ans = await WenlamboConvert(item.tokenSymbol);
                                if (ans && ans.data && !isEmpty(ans.data)) {
                                    let finddind = allcurrency.findIndex((check) => check.label == item.tokenSymbol);
                                    allcurrency.push({
                                        label: item.tokenSymbol,
                                        value: item.tokenSymbol,
                                        Address: item.tokenAddress,
                                        Decimal: NumberChange(item.decimal),
                                        network: val?.network,
                                        USD: ans.data.USD,
                                        [val?.network + item?.tokenSymbol + String(val?.chainId)]: ans.data.USD,
                                        ChainId: val?.chainId,
                                    })
                                }
                            }))
                        }
                    }))
                }
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        tokenAddress: get_token_details,
                        allcurrency: allcurrency,
                    }
                })
            }

            var cover = get_token_details?.priceoption && get_token_details.priceoption;
            var TokenUSDArray = []
            //console("sdegadsgr",cover)
            if (cover != null || cover != undefined) {
                var newArr = cover.filter(item => item.label !== Wallet_Details.networkConfiguration.currencySymbol);
            }
            if (newArr?.length > 0) {
                for (let i = 0; i < newArr.length; i++) {
                    var ans = await WenlamboConvert(newArr[i].label);
                    if (ans && ans.data && !isEmpty(ans.data)) {
                        TokenUSDArray.push({ USD: ans.data.USD, label: newArr[i].label })
                    }
                }
            }
            dispatch({

                type: Account_Connect,
                Account_Detail: {
                    Token_convertion: TokenUSDArray


                }
            })
        }
    }


    async function networkoption() {
        var networklistss = await networklist()

        var networkOption = networklistss?.data?.data?.data?.map((item) => {
            return ({
                label: item.networkname,
                value: item.networkname,
                name: "networkname"
            })
        })

        let allcontract = [];
        let currencyprice = {};
        await Promise.all(Object.keys(network).map(async (val) => {
            allcontract.push(network[val]?.singleContract);
            allcontract.push(network[val]?.multipleContract);
            allcontract.push(network[val]?.literatureContract);
            let usd = await WenlamboConvert(network[val].currencySymbol);
            let tokenusd = await WenlamboConvert(network[val].tokenSymbol)
            currencyprice[network[val].currencySymbol.toUpperCase()] = usd?.data?.USD ? usd?.data?.USD : 0;
            currencyprice[network[val].tokenSymbol.toUpperCase()] = tokenusd?.data?.USD ? tokenusd?.data?.USD : 0;
        }))
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                networkoption: networkOption,
                Allcontract: allcontract,
                currency_price: currencyprice,
            }
        })



    }


    async function GetCategoryCall() {
        var resp = await GetCategoryAction()
        if (resp && resp.data && resp.data.list) {
            var CategoryOption = [];
            var ind = null
            resp.data.list.map((item, index) => {
                ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
                CategoryOption.push({
                    name: 'TokenCategory',
                    value: item._id,
                    label: item.name
                })
            })
            if (ind > -1) {
                delete CategoryOption[ind]
            }
            setCategoryOption(CategoryOption)
        }
    }




    const Disconnect = async () => {
        // alert(1)
        dispatch({ type: Account_disConnect })

        if (localStorage.walletconnect != null && localStorage.walletConnectType == 'wc') {
            await (Wallet_Details.providerss).disconnect()
            localStorage.removeItem('walletconnect')
            localStorage.removeItem('walletConnectType');

        }
        // localStorage.clear()
        localStorage.removeItem('accountInfo');

        localStorage.removeItem('walletConnectType');
        localStorage.removeItem('userAddress');
        localStorage.removeItem("registered")
        setTimeout(() => {
            navigate("/")

        }, 1000);
        config.providercon = null
    }

    async function signout() {
        localStorage.clear();
        if (config.domainname == 'localhost') {
            document.cookie = config.cookiename + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
        else {
            document.cookie = config.cookiename + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" + config.domainname + ";";
        }
        setTimeout(() => {
            navigate("/")
        }, 1000);
    }


    const seachByKeyword = () => {
        navigate({ pathname: '/search', search: `?search=${keyword}`, key: Math.floor((Math.random() * 100) + 1) })
        if (window.location.pathname === '/search')
            window.location.reload();
    }






    const copyText = (a, b) => {
        toast.success('copied', toasterOption);

    }



    const notification = async (currAddr) => {
        var reqdata = {
            currAddr: currAddr,
            limit: 12
        }
        if (currAddr) {
            var noti = await notifications(reqdata)
            if (noti && noti.data && noti.data.data) {
                setnotificationss(noti.data.data)
            }
        }
    }


    const statusChangeactivity = async (data) => {
        var reqdata = {
            currAddr: (Wallet_Details.UserAccountAddr).toLowerCase(),
            tokenCounts: data.tokenCounts,
            _id: data._id
        }
        var noti = await notificationStatusChange(reqdata)
        //////console..log("qweiqwueiqwueiouqeuqw", noti)
    }

    var GetUsername = async () => {
        let token = ("; " + document.cookie)
            .split(`; ${config.cookiename}=`)
            .pop()
            .split(";")?.[0];
        var data = { from: "getname", name: localStorage.getItem('registedEmail'), token: token };
        var username = await submitdata(data);
        if (username?.message?.data?.status === true) {
            dispatch({
                type: Account_Connect,
                Account_Detail: {
                    Regname: username.message.data.data.Regname,
                    Profile: username.message.data.data.Profile,
                    Customurl: username.message.data.data.Customurl,
                    _id: username.message.data.data._id,
                    alldata: username.message.data.data,
                    MaxExpense: (username?.message?.data?.data?.MaxExpense == 'nolimit' ? "nolimit" : NumberChange(username?.message?.data?.data?.MaxExpense)),
                    SpentAmount: NumberChange(username?.message?.data?.data?.SpentAmount),
                    AvailableAmount: (username?.message?.data?.data?.AvailableAmount == 'nolimit' ? "nolimit" : NumberChange(username?.message?.data?.data?.AvailableAmount))
                },
            })
        }
    }


    const [menuOpen, setMenuOpen] = useState(false);  // initially closed
    const toggleMenu = () => {
        // alert(1)
        const getWindowWidth = window.innerWidth
        if (getWindowWidth < 992) {

            setMenuOpen(false)
        } else { setMenuOpen(false) }

        // this handler is "regular"
        // setMenuOpen(!menuOpen);    // open and close...
    };

    return (
        <>

            <Connect
                ref={connectMadalRef} />

            <div className="header">
                <TopHeader />

                <Navbar key="xl" expand="xl" className="bg-body-tertiary">
                    <Container fluid>
                        <Navbar.Brand className="ps-0 ps-xl-0">
                            <Link to="/"><img src={logo} alt="logo" className="img-fluid logoimg" /></Link>
                            <Link to="/"><img src={nftlogo} alt="logo" className="img-fluid logoimg ms-2" /></Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} onClick={() => setMenuOpen(!menuOpen)}/>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-xl`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                            placement="end"
                            restoreFocus={false}
                            show={menuOpen}
                            onHide={() => setMenuOpen(!menuOpen)}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                    <Link to="/"><img src={nftlogo} alt="logo" className="img-fluid logomobimg ms-2" /></Link>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="main_navbar">
                                    {CategoryOption.map((val) => {
                                        return (
                                            <>
                                                <Link to={`/explore/` + val.label} onClick={() => toggleMenu()}>{val.label}</Link>
                                            </>

                                        )
                                    })}
                                </div>

                                <div className="position-relative">
                                    <div className="search_inp_group">
                                        <input type="text" className="search_inp" placeholder="Search EdaFace NFT" onChange={(e) => toggleSearchmenu(e)} />
                                        <div className="search_inp_group_append">
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                    <div className="noti_parent noti_parnt_user searchmneu_dd searchmneu_dd_1 d-none" id="searchmneu_dd">
                                        <div className="nto_scrol_div scrollbars" >
                                            {searchItem.length != 0 && <p className="font_we_700">Items</p>}
                                            <ul className="noti_ul_dd">
                                                {
                                                    searchItem && searchItem.map((item) =>
                                                        <li className="px-3">
                                                            <div className="media" onClick={() => { navigate(`/info/${item.tokenOwner}/${item.contractAddress}/${item.tokenCounts}`) }}>

                                                                {(item.image != "" && (
                                                                    String(item.image).split('.').pop() == "mp4" ||
                                                                    (String(item.image).split('.').pop() == "webm") ||
                                                                    (String(item.image).split('.').pop() == "WEBM") ||
                                                                    (String(item.image).split('.').pop() == "ogv") ||
                                                                    (String(item.image).split('.').pop() == "OGV")
                                                                )) &&
                                                                    <video
                                                                        id="my-video"
                                                                        className="img-fluid mr-2 user_ul_new align-self-center"
                                                                        autoPlay playsInline loop muted
                                                                        preload="auto"
                                                                    >
                                                                        <source src={item.additionalImage ? (item.additionalImage == "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`} />


                                                                    </video>

                                                                }
                                                                {(item.image != "" &&
                                                                    (String(item.image).split('.').pop() == "mp3" || String(item.image).split('.').pop() == "aac" || String(item.image).split('.').pop() == "AAC" || String(item.image).split('.').pop() == "FLAC" || String(item.image).split('.').pop() == "flac")) &&
                                                                    <>
                                                                        <img src={config.AudioImg} className="img-fluid" />
                                                                        <audio
                                                                            muted
                                                                            className="img-fluid mr-2 user_ul_new align-self-center"
                                                                            playsInline loop
                                                                            //type="audio/mp3"
                                                                            autostart="off"
                                                                            src={item.additionalImage ? (item.additionalImage == "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}


                                                                        >

                                                                        </audio>
                                                                    </>


                                                                }



                                                                {
                                                                    (item.image != "" && (
                                                                        String(item.image).split('.').pop() == "webp"
                                                                        || String(item.image).split('.').pop() == "WEBP"
                                                                        || String(item.image).split('.').pop() == "gif"
                                                                        || String(item.image).split('.').pop() == "jpg"
                                                                        || String(item.image).split('.').pop() == "GIF"
                                                                        || String(item.image).split('.').pop() == "JPG"
                                                                        || String(item.image).split('.').pop() == "JPEG"
                                                                        || String(item.image).split('.').pop() == "jpeg"
                                                                        || String(item.image).split('.').pop() == "png"
                                                                        || String(item.image).split('.').pop() == "PNG") &&
                                                                        <img
                                                                            src={item.additionalImage ? (item.additionalImage == "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}

                                                                            //  src={item.ipfsimage!=""? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                                                            alt="Collections" className="img-fluid mr-2 user_ul_new align-self-center" />
                                                                    )}

                                                                {/* <img
                              src={config.AudioImg}

                          
                              alt="Collections" className="img-fluid mr-2 user_ul_new align-self-center" /> */}

                                                                <div className="media-body flex_body">
                                                                    <div>
                                                                        <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc mr-1">{item.tokenName}</p>
                                                                        <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc mr-1">

                                                                            {(
                                                                                item
                                                                                && item.tokenowners_current
                                                                                && item.tokenowners_current.tokenPrice > 0)
                                                                                && <span>{item.tokenowners_current.tokenPrice} {item.tokenowners_current.coinName}   </span>}
                                                                            {(

                                                                                item
                                                                                && item.clocktime == null
                                                                                && item.endclocktime == null
                                                                                && item.tokenowners_current
                                                                                && (item.tokenowners_current.tokenPrice == 0 || item.tokenowners_current.tokenPrice == null)
                                                                                && <span> Open for Bid </span>)}

                                                                            {
                                                                                item
                                                                                && item.clocktime != null
                                                                                && item.endclocktime != null
                                                                                && item.minimumBid
                                                                                && item.minimumBid != 0
                                                                                && <span>{item.minimumBid} {config.tokenSymbol}   </span>}


                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </li>

                                                    )}



                                            </ul>

                                            {UsersearchItem.length != 0 && <p className="font_we_700">Users</p>}

                                            <ul className="noti_ul_dd">

                                                {
                                                    UsersearchItem && UsersearchItem.map((searchUser) =>
                                                        <li className="px-3" onClick={() => navigate(searchUser.customurl != "" ? `/${searchUser.customurl}` : `/user/${searchUser.curraddress}`)}>
                                                            <div className="media">


                                                                {searchUser && searchUser.image != "" ?
                                                                    <img src={`${config.Back_URL}/images/${searchUser._id}/${searchUser.image}`} alt="User" className="img-fluid mr-2 user_ul_new align-self-center" />
                                                                    :
                                                                    <Avatars item="img-fluid mr-2 user_ul_new align-self-center nnnnnnn" />

                                                                }

                                                                <div className="media-body flex_body">
                                                                    <div>
                                                                        <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc mr-1">{searchUser.name}</p>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </li>
                                                    )}
                                            </ul>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn new_btn_grad" tabIndex="0" type="button" onClick={() => { seachByKeyword() }}><span><Link to='/search'>Search</Link></span></button>
                                        </div>
                                    </div>
                                </div>

                                <Nav className="justify-content-end main_navbar pe-3">
                                    <Link to="/explore/All" onClick={() => toggleMenu()}>Explore</Link>
                               
                                    {(localStorage.registedEmail !== undefined) ?
                                        <Link to="/create"> <button className="create_btn  createbtn_color">
                                            Create
                                        </button></Link>
                                        :
                                        <Link to={config.loginurl + "/register"} target="_blank" className="p-0">  <button className="create_btn  createbtn_color"
                                        >
                                            Create
                                        </button></Link>
                                    }


                                    <div className="position-relative">

                                        <div class="dropdown megamenu">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Resources
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <div className="dropdwongrod">
                                                    <div>
                                                        {cms && cms[0]?.content?.slice(0, 5)?.map((value, index) => (
                                                            <>
                                                                <Link class="dropdown-item" to={value.link}><span className="mr-2 resourcesimgs"><img src={`${config.Back_URL}/cmsImages/${value.logo}`} /></span>{value.lable}</Link>
                                                            </>
                                                        ))}
                                                    </div>
                                                    <div>
                                                        {cms && cms[0]?.content?.slice(5, 10)?.map((value, index) => (
                                                            <Link class="dropdown-item" to={value.link}><span className="mr-2 resourcesimgs"><img src={`${config.Back_URL}/cmsImages/${value.logo}`} /></span>{value.lable}</Link>
                                                        ))}
                                                    </div>
                                                    <div>
                                                        {cms && cms[0]?.content?.slice(10, 15)?.map((value, index) => (
                                                            <Link class="dropdown-item" to={value.link}><span className="mr-2 resourcesimgs"><img src={`${config.Back_URL}/cmsImages/${value.logo}`} /></span>{value.lable}</Link>
                                                        ))}
                                                        {/* <Link class="dropdown-item" to="/howtobuy"><span className="mr-2 resourcesimgs"><img src={Buy} alt="What is NFT" /></span>How to Buy NFTs</Link>
                      <Link class="dropdown-item" to="/howtosell"><span className="mr-2 resourcesimgs"><img src={Sell} alt="What is NFT" /></span>How to Sell NFTs</Link>
                      <Link class="dropdown-item" to="/edafacepartnership"><span className="mr-2 resourcesimgs"><img src={Partnership} alt="What is NFT" /></span>EdaFace NFT Partnership</Link>
                      <Link class="dropdown-item" to="/conclusion"><span className="mr-2 resourcesimgs"><img src={Conclusion} alt="What is NFT" /></span>Miscellany</Link> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        Wallet_Details.UserAccountAddr == "" &&
                                        <div className="position-relative">

                                        <div class="megamenu regitersfd">
                                            <button class="btn btn-secondary btnlinksd onmousehovers">
                                                <img src={Registerbtn} />
                                            </button>
                                            <div class="p-2 reginonehover">


                                                <Link className={" pl-0 registerfrone"} to={config.loginurl + "/register"} target="_blank">Register</Link>
                                                {/* <Link className={" pl-0 registerfrone"} to="/Register">Register</Link> */}
                                                <Link className={" pl-0 registerfrone"} to={config.loginurl + "/login"} target="_blank">Login</Link>

                                                <Link className={" pl-0 registerfrone"} to="https://launchpad.edaface.com/login" target="_blank"> Buy Eda Token</Link>

                                            </div>
                                        </div>
                                    </div>}





                                    {
                                        (localStorage.registedEmail !== undefined) ?

                                            (isEmpty(localStorage.walletConnectType) || isEmpty(Wallet_Details.UserAccountAddr)) ?


                                                <li>


                                                    <button onClick={() => connectMadalRef.current.walletConnectfunction()} className="create_btn">connect Wallet

                                                    </button>


                                                </li>

                                                :
                                                (<li className={" menu_dropdown dropdown_header_ul user_dd_ul onmousehovers"}>
                                                    <button color="transparent" className={" pl-cust-wal ml-0"}>
                                                        {/* <AccountBalanceWallet className="menu_icons" /> */}
                                                        <img src={Wallet} />

                                                    </button>
                                                    <div className="noti_parent noti_parnt_user usemneu_dd nonehover" id="usemneu_dd">
                                                        <p className="noti_head pt-4 mb-0">
                                                            <span title={Wallet_Details.UserAccountAddr && Wallet_Details.UserAccountAddr != "" && (Wallet_Details.UserAccountAddr)}>{Wallet_Details.UserAccountAddr && Wallet_Details.UserAccountAddr != "" && (Wallet_Details.UserAccountAddr).substring(0, 8).concat('.....')}
                                                                <CopyToClipboard text={Wallet_Details.UserAccountAddr} onCopy={() => copyText('invite link', Wallet_Details.UserAccountAddr)}>

                                                                    <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                                                                </CopyToClipboard>
                                                            </span>

                                                        </p>
                                                        <div className="px-2">
                                                            {/* <p className="info_des">oxc4c16ab5ac7d...b21a<i className="fas fa-sticky-note notes_fa cur_pointer"></i></p> */}
                                                            <div className="media header_media pt-3">
                                                                <img src={Wallet_Details?.networkConfiguration?.currencySymbol == 'BNB' ? require("../../assets/images/bnbdesign.png") : require("../../assets/images/eth_desing.png")} alt="User" className="img-fluid mr-3 coin_header" />
                                                                <div className="media-body flex_body">
                                                                    <div>
                                                                        <p className="mt-0 media_num">{Wallet_Details.networkConfiguration.currencySymbol} Balance</p>
                                                                        <p className="balance_txt_header pt-0 mb-0">

                                                                            <span>
                                                                                <p class="tokendetaiss det wallet_amount">{(Wallet_Details.UserAccountBal) / 1e18} </p>
                                                                            </span>
                                                                        </p>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <ul className="user_prof_ul mt-4">
                                                                <li>
                                                                    <Link to="/edit-profile"><span><i className="fas fa-user mr-2"></i>My profile</span></Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/my-items"><span><i className="fas fa-file-image mr-2"></i>My items</span></Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/supportticket"><span><i className="fas fa-headset mr-2"></i>Support</span></Link>
                                                                </li>
                                                                {/*
              <li>
                <a href={config.loginurl + "/verification"} target="_blank">
                  <span><i className="fas fa-user mr-2"></i>KYC-Verification</span>
                </a>
              </li>
              */}
                                                                {/* <li>
<Link to="/addCollections"><span><i className="fas fa-file-image mr-2"></i>Create Collection</span></Link>
</li> */}

                                                                <li>
                                                                    <div onClick={() => Disconnect()}><Link to="/"><span><i className="fas fa-sign-out-alt mr-2"></i>Disconnect</span></Link></div>
                                                                </li>
                                                                {/* <li>
<div onClick={signout}><Link to="/"><span><i className="fas fa-sign-out-alt mr-2"></i>Signout</span></Link></div>
</li> */}
                                                            </ul>

                                                        </div>




                                                    </div>

                                                </li>
                                                )
                                            :
                                            ("")

                                    }







                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>

        </>
    );
}


