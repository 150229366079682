import { NumberChange } from "../../lib/common";
import { useDispatch, useSelector } from "react-redux";


export default function CommonFunction() {

    const dispatch = useDispatch();

    const Wallet_Details = useSelector(state => state.wallet_connect_context);

    const ExpenseCheck = (data) => {
        try{
            if(data){
                let { Price, CoinName, ChainId } = data;
                if(Price){
                    let usdval = CurrencyUSDConversion({ Price, CoinName, ChainId });
                    console.log('usdvalusdval',usdval,Wallet_Details?.AvailableAmount)
                    if(usdval){
                        if(Wallet_Details?.AvailableAmount=='nolimit'){
                            return true;
                        }
                        else if(NumberChange(usdval)<NumberChange(Wallet_Details?.AvailableAmount)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return false
                }
            }
            else{
                return false
            }
        }
        catch(err){
            console.log('ExpenseCheck_error',err);
            return false
        }
    }

    const CurrencyUSDConversion = (data) => {
        try{
            if(data){
                let { Price, CoinName, ChainId } = data;
                let finddata = Wallet_Details?.allcurrency?.find((val)=>(val?.ChainId==ChainId&&val?.label==CoinName))
                console.log('CurrencyUSDConversion',Wallet_Details?.allcurrency,Wallet_Details,finddata,data)
                return ( NumberChange(Price) * NumberChange(finddata?.USD) ? NumberChange(Price) * NumberChange(finddata?.USD) : false );
            }
            else{
                return false
            }
        }
        catch(err){
            console.log('CurrencyUSDConversion',err);
            return false
        }
        // return (price * )
    }

    return{
        ExpenseCheck,
        CurrencyUSDConversion,
    }
}
