import axios from "axios";
import config from '../../lib/config';
import { isEmpty, DecryptData, EncryptData } from "../../actions/commonfunction";
import { GetToken } from "../../lib/common";


const axiosFunc    =   async   (data)  =>  {
        
  try{
    let Resp    =   {};
      // if(false){
        if(data?.params){
          data.params = { data: EncryptData(data.params)  }
        }
        else if(data?.data){
          if(!(data?.data instanceof FormData)){
            data.data = { data: EncryptData(data.data)  }
          }
        }      
    // }
    if(data?.headers){
      data.headers.Authorization = "Bearer "+GetToken(config.userCookieName)
    }
    else{
      data.headers = {
        Authorization: "Bearer "+GetToken(config.userCookieName)
      }
    }
      Resp    =   await axios(data)
      
      if(Resp?.data){
          Resp.data = isEmpty(DecryptData(Resp.data)) ? Resp.data : DecryptData(Resp.data)
      }
    

      return Resp
  }
  catch (e) {
      
      return { success: 'error', msg: null }
  }
}

export const reportFunc = async (postdata) => {
  ////console..log("check allsssss",postdata)
    try {
      let resp = await axiosFunc({
        'method': 'post',
        'url'   : `${config.vUrl}/token/report/reportFunc`,
        data    : postdata,
      });
      return {
        data: resp.data
      }
    }
    catch (err) {
    }
  }

  
export const sociallinksfunction = async (postdata) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url'  :`${config.vUrl}/token/social/sociallinksfunction`,
     
     
    });
   
    return {
      data: resp.data
    }
  }
  catch (err) {
  }

}

export const faqlists = async (postdata) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url'  :`${config.vUrl}/token/social/faqlists`,
     
     
    });
   
    return {
      data: resp.data
    }
  }
  catch (err) {
  }

}


export const getPrivacyVal = async (postdata) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url'  :`${config.vUrl}/admin/panel/getPrivacyVal`,
      data:postdata   
    });
   
    return {
      data: resp.data
    }
  }
  catch (err) {
  }

}


export const notifications = async (postdata) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url'  :`${config.vUrl}/token/notifications`,
      data:postdata   
    });
   
    return {
      data: resp.data
    }
  }
  catch (err) {
  }

}

export const notificationStatusChange = async (postdata) => {
  try {
    let resp = await axiosFunc({
      'method': 'post',
      'url'  :`${config.vUrl}/token/notificationStatusChange`,
      data:postdata   
    });
   
    return {
      data: resp.data.data
    }
  }
  catch (err) {
  }

}

export const ActivityCall = async (postdata) => {
  //console.log("postdata for activity call",postdata);
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url'  :`${config.vUrl}/token/ActivityCall`,
      params: postdata   
    });
    //console.log("soci1ACtivity",resp.data)
    return {
      data: resp.data
    }
  }
  catch (err) {
  }

}


export const getcmslistinhome = async (postdata) => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url'  :`${config.vUrl}/token/use/getcmslistinhome`,
      params:postdata   
    });
    ////console..log("soci1",resp.data)
    return {
      data: resp.data.data
    }
  }
  catch (err) {
  }

}
export const getCmsInFooter= async () => {
  try {
    let resp = await axiosFunc({
      'method': 'get',
      'url'  :`${config.vUrl}/token/use/getCmsListInFooter`,
    });
    console.log("soci1",resp.data)
    return {
      data: resp.data.data
    }
  }
  catch (err) {
  }

}

export const getpromotion = async (filterData, dispatch) => {
  ////console.log('yes')
  try {
      let respData = await axiosFunc({
          'method': 'get',
          'url': `${config.vUrl}/admin/panel/getpromotion`,
          params: filterData
      });
      return {
          loading: false,
          userValue: respData.data.userValue

      }
      ////console.log("ok")
  }

  catch (err) {

      return {
          loading: false,
          // error: err.response.data
      }
      ////console.log("not ok")
  }
}

export const Bannerpromotion = async (filterData, dispatch) => {
  ////console.log('yes')
  try {
      let respData = await axiosFunc({
          'method': 'get',
          'url': `${config.vUrl}/admin/panel/Bannerpromotion`,
          params: filterData
      });
      return {
          loading: false,
          userValue: respData.data.userValue

      }
      ////console.log("ok")
  }

  catch (err) {

      return {
          loading: false,
          // error: err.response.data
      }
      ////console.log("not ok")
  }
}

export const GetResource = async (filterData, dispatch) => {
  ////console.log('yes')
  try {
      let respData = await axiosFunc({
          'method': 'post',
          'url': `${config.vUrl}/admin/panel/getresource`,
          data: filterData
      });
      return respData.data
      ////console.log("ok")
  }

  catch (err) {

      return {
          loading: false,
          // error: err.response.data
      }
      ////console.log("not ok")
  }
}

export const ServiceFeeaction = async (data) => {
  ////console.log('yes')
  try {
      let respData = await axiosFunc({
          'method': 'post',
          'url': `${config.vUrl}/admin/panel/servicefeeaction`,
          data:data,
      });
      console.log('lksdjfklsjgslgs',respData)
      return respData.data
  }
  catch (err) {
      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}
