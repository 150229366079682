import React, { useState, useEffect, useRef } from "react";
import DETH_ABI from '../ABI/DETH_ABI.json';

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { getUserCollection } from '../actions/v1/user'
import { Link, useNavigate, useLocation } from "react-router-dom";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import randomInteger from 'random-int';
// import AddIcon from '@mui/icons-material/Add';
import { FaPlus } from "react-icons/fa";
import delete_png from '../assets/images/del.png'

// import masonary1 from 'assets/images/masonary_04.png'
// import Loader from 'assets/images/loader.png';
import $ from 'jquery';
import '@metamask/legacy-web3'
import Select from 'react-select'
import CONFIG from '../lib/config'
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Convert from '../views/separate/Convert';
import isEmpty from "../lib/isEmpty";
import Web3 from 'web3';
import Web3Utils from 'web3-utils'
import MULTIPLE from '../ABI/MULTIPLE.json';
import SINGLE from '../ABI/SINGLE.json';
import { getReceipt } from '../actions/v1/getReceiptFunc';
import {
  ipfsImageHashGet,
} from '../actions/v1/token';
import { connectWallet, WalletConnect } from '../views/hooks/useWallet';
import {
  GetCategoryAction,
  CreateTokenValidationAction,
  TokenAddItemAction,
  TokenAddOwnerAction,
  WenlamboConvert,
} from '../actions/v1/token';
import { Account_Connect, Account_disConnect } from "../actions/redux/action";
//redux
import { AddressUserDetails_GetOrSave_Action } from "../actions/v1/user";
import { network } from "./network"
import Market from "../ABI/market"
import LazyLoader from "./lazyloader";
import LazyLoad from "react-lazyload";
import { AddCollectionRef } from "./separate/create_collection";
import { Row, Col } from "react-bootstrap";

// toast.configure();
const dashboardRoutes = [];
let toasterOption = CONFIG.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {


  const dispatch = useDispatch();
  const Wallet_Details = useSelector(state => state.wallet_connect_context);


  const { ...rest } = props;
  const navigate = useNavigate();


  const mapdata = [
    { value: 'Discord', label: 'Discord', classname: "fab fa-discord" },
    { value: 'Instagram', label: 'Instagram', classname: "fab fa-instagram" },
    { value: 'Linkedin', label: 'Linkedin', classname: 'fab fa-linkedin' },
    { value: 'Whatsapp', label: 'Whatsapp', classname: "fab fa-whatsapp" },
    { value: 'Twitter', label: 'Twitter', classname: "fab fa-twitter" },
    { value: 'Slack', label: 'Slack', classname: "fab fa-slack" },
    { value: 'Telegram', label: 'Telegram', classname: "fab fa-telegram" },
    { value: 'Github', label: 'Github', classname: "fab fa-github" },
    { value: 'Facebook', label: 'Facebook', classname: "fab fa-facebook-f" },
    { value: 'Others', label: 'Others', classname: "fas fa-hashtag" },
    // Add more data items here as needed
  ];


  const location = useLocation();
  const inststate = location?.state
  console.log("🚀 ~ CreateSingle ~ inststate:", inststate,"fff",location)



  const [state, SetState] = useState(inststate)
  const [location_pathname, Set_location_pathname] = useState(state?.type);
  const [CollectibleType, Set_CollectibleType] = useState(state?.type);
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [imgfilename, setimgfilename] = useState('');
  const [fileSizes, setfilesize] = useState(0)
  const [TokenFile, setTokenFile] = useState(state?.image);
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [ValidateError, setValidateError] = useState({ TokenPrice: '' });
  const [PutOnSale, setPutOnSale] = useState(false);
  const [PutOnSaleType, setPutOnSaleType] = useState('UnLimitedAuction');
  const [TokenBid, setTokenBid] = useState(true);
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);
  const [MinimumBid, Set_MinimumBid] = useState(0);
  const [Clocktime, set_Clocktime] = useState('');
  const [MintHashVal, Set_MintHashVal] = useState('');
  const [EndClocktime, set_EndClocktime] = useState("");
  const [TokenPrice, setTokenPrice] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [TokenName, setTokenName] = useState(state?.name);
  const [TokenDescription, setTokenDescription] = useState('');
  const [TokenRoyalities, setTokenRoyalities] = useState('0');
  const [TokenProperties, setTokenProperties] = useState('');
  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [TokenQuantity, Set_TokenQuantity] = useState(state?.amount);
  const [CoinName, setCoinNames] = useState("");
  const [ApproveCallStatus, setApproveCallStatus] = useState('init');
  const [ApproveTrade, setApproveTrade] = useState('init');
  const [StartDate, Set_StartDate] = useState('Select Start Date');
  const [EndDate, Set_EndDate] = useState('Select End Date');
  const [priceoption, setPriceoption] = React.useState([]);
  const [FormSubmitUserClicked, Set_FormSubmitUserClicked] = useState(false);
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [TokenCategory, setTokenCategory] = useState({ label: '' });
  const [UserCollection, setUserCollection] = useState('');
  const [CollectionList, setCollectionList] = useState([]);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [TokenImages, setTokenImage] = useState({});
  const [ipfshash, setIpfsHash] = useState(state?.ipfshash);
  const [additionalImage, setAdditionalImage] = useState('')
  const [MintCallStatus, setMintCallStatus] = useState('init');
  const [networkoption, setnetworkoption] = React.useState([]);
  const [NetworkID, setNetworkID] = React.useState(Wallet_Details.networkConfiguration.Chainid);
  var networkinit;
  const [config, setconfig] = React.useState(Wallet_Details.networkConfiguration);
  const [accountDetailssss, setaccountDetailssss] = useState({})
  const [decimal, setdecimal] = React.useState();
  const [originalImage, setoriginalImagee] = useState('')
  const [approvecheck, setapprovecheck] = useState(false);
  const [metfile, setMetfile] = useState(state?.metadata);
  const [fullcategory, setFullcategory] = useState({});
  const [CategorywithSubcategory, setCategorywithSubcategory] = useState({})
  const [category, setCategory] = React.useState(false);
  const [ListNFT, SetListNFT] = useState(false);
  const [ListedQuantity, SetListedQuantity] = useState(0)
  const [PutOnSaleQuantity, SetPutOnSaleQuantity] = useState(0)
  const [socialLinks, SetSocialLinks] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [choosencollection, SetChoosenCollection] = useState({})


  const CreateCollectionForwardRef = useRef();

  const mappedData = mapdata.map(item => ({
    value: item.value,
    label: item.label,
    classname: item.classname,
  }));




  var ContractAddressUser_val = (location_pathname === '721') ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract;


  useEffect(() => {
    if ((Wallet_Details.networkConfiguration.Chainid == network.BSC.Chainid) && config?.Chainid === 0) {
      setconfig(network.BSC);
      setNetworkID(Wallet_Details.networkConfiguration.Chainid)
    }
    else if ((Wallet_Details.networkConfiguration.Chainid == network.ETH.chainId) && config.Chainid === 0) {
      setconfig(network.ETH);
      setNetworkID(Wallet_Details.networkConfiguration.Chainid);
    }
  })

  useEffect(() => {
    getCollection()
  }, [Wallet_Details.UserAccountAddr]);


  async function getCollection() {
    let Singlee = Wallet_Details.networkConfiguration.singleContract
    let Multiplee = Wallet_Details.networkConfiguration.multipleContract
    let payload = {
      userAddress: Wallet_Details.UserAccountAddr
    }
    if (location_pathname == '1155') {
      payload.NFTType = 'MULTIPLE'
      payload.Type = Multiplee
    }
    else {
      payload.NFTType = 'SINGLE'
      payload.Type = Singlee
    }
    let userCollectiondata = await getUserCollection(payload)

    let Collectionlist = [];
    if (userCollectiondata && userCollectiondata.message && userCollectiondata.message.data && isEmpty(userCollectiondata.message.data.collections) === false) {

      // //console.log("setUserCollection",userCollectiondata.data);
      userCollectiondata.message.data.collections.map((item, index) => {
        Collectionlist.push({
          ...item,
          ...{
            name: 'userCollection',
            value: item._id,
            label: item.collectionName,
            // custon:item.customurl
          }
        })
      })
      setCollectionList(Collectionlist)
    }
    Collectionlist.push({

      name: 'userCollection',
      value: 'create',
      label: <div className="drop_static_create"><Link to={{ state: state }} onClick={() => CreateCollectionForwardRef.current.AddCollection_Click()}>Create Collections</Link></div>,

    })
    setCollectionList(Collectionlist)
  }

  useEffect(() => {
    if (Wallet_Details && Wallet_Details.tokenAddress.length > 0) {
      setPriceoption(Wallet_Details.tokenAddress)
    }
  }, [Wallet_Details.tokenAddress])

  useEffect(() => {

    if (Wallet_Details && Wallet_Details.networkoption.length > 0) {
      let staticnetwork = [];
      Wallet_Details.networkoption.map((val) => {
        if (Wallet_Details.networkConfiguration.Chainid == 97 || Wallet_Details.networkConfiguration.Chainid == 56) {
          if (val.value == 'binance') {
            staticnetwork.push(val)
          }
        }
        else if (Wallet_Details.networkConfiguration.Chainid == 1 || Wallet_Details.networkConfiguration.Chainid == 5) {
          if (val.vaue == 'ethereum') {
            staticnetwork.push(val)
          }
        }
      })
      setnetworkoption(staticnetwork)
    }


    GetCategoryCall();
    CreateItemValidation(FormSubmitUserClicked);
  }, [
    Wallet_Details.UserAccountAddr, TokenFilePreReader, CoinName]);


  const selectChange = async (e) => {
    let accountDetails;
    if (e.name == "networkname") {
      setCoinNames("")
      if (e.name == "networkname" && e.value == CONFIG.binance) {
        let configdata = network.BSC
        accountDetails = await switchNetwork(configdata)
        setconfig(configdata)
      }
      else if (e.name == "networkname" && e.value == CONFIG.etherium) {
        let configdata = network.ETH
        accountDetails = await switchNetwork(configdata)
        setconfig(configdata)
      }
    }
    setaccountDetailssss(accountDetails)
    if (e && e.name && e.label && e.value) {
      switch (e.name) {
        case 'TokenCategory':
          setTokenCategory(e);
          break;
        case 'userCollection':
          {
            if (e.value == 'create') {
              setUserCollection("")
            }
            else {
              setUserCollection(e.label)

              if (e?.SubCategory && e?.CategoryId && e?.Category) {
                setCategorywithSubcategory({ category: e.Category, subcategory: e.SubCategory, id: e.CategoryId, display: e.Category + '-' + e.SubCategory, disabecategory: true, collection: e.label })
              }
              else if (CategorywithSubcategory?.collection == UserCollection) {
                setCategorywithSubcategory({});
              }
            }

          }
        default:
      }
    }
    else {
      toast.warning("please connect your wallet", toasterOption)
    }
  }


  async function GetCategoryCall() {
    let resp = await GetCategoryAction()
    if (resp && resp.data && isEmpty(resp.data.list) === false) {
      let CategoryOption = [];
      let ind = null
      if (resp && resp.data && isEmpty(resp.data.list) === false) {
        resp.data.list.map((item, index) => {
          ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
          CategoryOption.push({
            name: 'TokenCategory',
            value: item._id,
            label: item.name
          })
        })
      }
      if (ind > -1) {
        delete CategoryOption[ind]
      }
      setCategoryOption(CategoryOption)
      setFullcategory(resp.data.list)
    }
  }


  async function CreateItemValidation(chk) {

    let approvedata = {
      curradd: Wallet_Details.UserAccountAddr,
      tradeadd: Wallet_Details.networkConfiguration.TradeContract
    }
    let check = await orderApprovecheck(approvedata);
    if (chk) {
      let ValidateError = {};
      if (config.Chainid === 0) {
        ValidateError.network = '"network" is not allowed to be empty';
      }
      if (CoinName === "" && PutOnSaleType !== "UnLimitedAuction") {
        ValidateError.TokenPrice = 'Currency or Token" must be required';
      }
      if (TokenName === '') {
        ValidateError.TokenName = '"Name" is not allowed to be empty';
      }
      if (TokenName !== "") {
        if ((CONFIG.nameFormat).test(TokenName)) {
          ValidateError.TokenName = '"Emoji" is not allowed';
        }
      }
      if (isEmpty(CategorywithSubcategory)) {
        ValidateError.TokenCategory = '"Category" is required';
      }

      if (Unlockoncepurchased === true && UnLockcontent === '') {
        ValidateError.UnLockcontent = '"Locked content" is required';
      }
      if (PutOnSale === true && PutOnSaleType === 'FixedPrice') {
        if (TokenPrice === '' || isNaN(TokenPrice) === true && Number(TokenPrice) === 0) {
          ValidateError.TokenPrice = '"Price" must be a number';
        }
        else if (Number(TokenPrice) === 0) {
          ValidateError.TokenPrice = '"Price" must be greater than zero';
        }
        else if (CoinName === "") {
          ValidateError.TokenPrice = 'Currency or Token" must be required';
        }
        if (YouWillGet === 0) {
          ValidateError.TokenPrice = 'only allowed 4 digits  after decimal point';
        }

      }
      if (PutOnSale === true && PutOnSaleType === 'TimedAuction') {
        if (MinimumBid === '') {
          ValidateError.MinimumBid = '"Bid Price" must be a number';
        }
        if (Clocktime === '') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === '') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Select Start Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Select End Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Invalid Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Invalid Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
      }
      if (TokenQuantity === '' || isNaN(TokenQuantity) === true && TokenQuantity === 0) {
        ValidateError.TokenQuantity = '"Number of copies" must be a number';
      }
      setValidateError(ValidateError);
      return ValidateError;
    }
    else {
      return {};
    }
  }






  const PriceCalculate = async (data = {}) => {
    let price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
    let weii = price * CONFIG.decimalvalues;
    let per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
    let mulWei = parseFloat(weii - per);
    let getVal = (mulWei / CONFIG.decimalvalues);
    Set_YouWillGet(getVal.toFixed(4));
  }




  const selectFileChange = (e) => {
    if (Wallet_Details.UserAccountAddr == '') {
      toast.warning(" connect your wallet", toasterOption)
    }
    let validExtensions = ["png", 'gif', 'webp', 'mp4', 'PNG', 'jpg', 'JPEG', 'jpeg', 'JPG', 'mp3', 'aac', 'AAC', 'flac', 'FLAC', 'WEBM', 'webm', 'ogv', 'OGV']; //array of valid extensions
    if (e.target && e.target.files) {
      let reader = new FileReader()
      let file = e.target.files[0];
      setimgfilename(file.name)

      let fileName = file.name;
      let fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error("Only these file types are accepted : " + validExtensions.join(', '), toasterOption);
        return false;
      }
      const fileSize = file.size;
      if (30000000 < fileSize) {
        toast.error("File size exceeds 30 MB", toasterOption);
        return false;
      }
      else {
        setfilesize(fileSize)
        setTokenFile(file);
        let url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          if (reader.result) {
            setTokenFilePreReader(reader.result);
          }
        }.bind(this);
        setTokenFilePreUrl(e.target.value);
      }
    }
  }


  const CheckedChange = (e) => {

    if (e && e.target && e.target.name) {
      if (e.target.name === 'putonsale') {
        if (PutOnSale === false) {
          setPutOnSale(true);
          if (PutOnSaleType === '') {
            setPutOnSaleType('FixedPrice')
          }
          SetPutOnSaleQuantity(1)
        }
        else {
          setPutOnSale(false);
          setPutOnSaleType("UnLimitedAuction")
          setTokenBid(true)
          SetPutOnSaleQuantity(0)
          setTokenPrice(0)
        }
      }
      else if (e.target.name === 'ListNFT') {
        if (ListNFT === false) {
          SetListedQuantity(1);
          SetListNFT(true);
          setPutOnSale(true);
          setPutOnSaleType('FixedPrice')
          SetPutOnSaleQuantity(1)
        }
        else {
          SetListedQuantity(0)
          SetListNFT(false);
          setPutOnSale(false);
          setPutOnSaleType('UnLimitedAuction')
          setTokenBid(true)
          SetPutOnSaleQuantity(0)
        }
      }
      else if (e.target.name === 'unlockoncepurchased') {
        if (Unlockoncepurchased === false) {
          Set_Unlockoncepurchased(true);
        }
        else {
          Set_Unlockoncepurchased(false);
        }
      }
    }
  };



  const changePutOnSaleType = (type) => {

    if (type == 'TimedAuction') {
      if (Wallet_Details && isEmpty(Wallet_Details.tokenAddress) === false) {
        let bnbs = (Wallet_Details.tokenAddress).filter((item) => item.label != Wallet_Details.networkConfiguration.currencySymbol)
        setPriceoption(bnbs)
      }

    }
    else if (Wallet_Details?.tokenAddress && isEmpty(Wallet_Details.tokenAddress) === false) {
      setPriceoption(Wallet_Details && Wallet_Details.tokenAddress && Wallet_Details.tokenAddress)
    }
    setPutOnSaleType(type);
    if (type === 'FixedPrice') {
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
    }
    else if (type == 'TimedAuction') {
      setTokenPrice(0)
    }
    else if (type == 'UnLimitedAuction') {
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
      setTokenPrice(0)
    }
  };
  const priceoptionfunc = (e) => {
    setCoinNames(e.label)
    setdecimal(e.Decimal)
  };

  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      let value = e.target.value;
      const re = /^[0-9]+([.][0-9]+)?$/; //with decimal
      const re1 = /^[0-9\b]+$/; // only [0-9]
      switch (e.target.name) {
        case 'TokenPrice':
          if (value !== '' && isNaN(value) === false && value > 0) {
            setTokenPrice(value);
            PriceCalculate({ price: value });
          }
          else {
            setValidateError(ValidateError);
            setTokenPrice('0');
            PriceCalculate({ price: 0 });
          }
          break;
        case 'TokenName':
          setTokenName(value);
          break;
        case 'TokenDescription':
          setTokenDescription(value);
          break;
        case 'TokenRoyalities':
          setTokenRoyalities(value);
          break;
        case 'TokenProperties':
          setTokenProperties(value);
          break;
        case 'UnLockcontent':
          Set_UnLockcontent(value);
          break;
        case 'MinimumBid':
          Set_MinimumBid(value);
          break;
        case 'TokenQuantity':
          Set_TokenQuantity(value);
          break;
        case 'ListedQuantity':
          SetListedQuantity(value);
          break;
        case 'PutOnSaleQuantity':
          SetPutOnSaleQuantity(value);
          break;
        default:
        // code block
      }
    }
  }




  // const networkoptionfunc = (e) => {

  //   setNetworkName(e.value)
  // };



  async function DateChange(from, val) {
    if (from === 'StartDateDropDown') {
      if (val === 'PickStartDate') {
        ModalAction('calendar_modal1', 'show');
      }
      else {
        Set_StartDate(val);
        let myDate = new Date();
        let newdat
        if (val === 'RightAfterListing') {
          newdat = myDate.setDate(myDate.getDate());
        }
        else {
          newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        }
        let newdate = new Date(newdat);
        set_Clocktime(newdate);
      }
    }
    else if (from === 'EndDateDropDown') {
      if (val === 'PickEndDate') {
        ModalAction('calendar_modal_expire', 'show');
      }
      else {
        Set_EndDate(val);
        let myDate = new Date();
        let newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        let newdate = new Date(newdat)
        set_EndClocktime(newdate)
      }
    }
  }



  async function ModalAction(id, type) {
    window.$('#' + id).modal(type);
    if (id === 'calendar_modal') {
      if (Clocktime) {
        let dt = new Date(Clocktime);
        let dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    }
    else if (id === 'calendar_modal_expire') {
      if (EndClocktime) {
        let dt = new Date(EndClocktime);
        let dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }


  async function CreateItem() {
    if (Wallet_Details.UserAccountAddr !== "") {
      let check = await orderApprovecheck();
      setapprovecheck(check)
      Set_FormSubmitUserClicked(true);
      let errors = await CreateItemValidation(true);
      let errorsSize = Object.keys(errors).length;
      if (errorsSize !== 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false
      }
      else if (Wallet_Details.providerss) {
        if (config.Chainid === Wallet_Details.networkConfiguration.Chainid) {
          if (Wallet_Details.providerss == null) {
            toast.error("Please Connect to BINANCE Network", toasterOption)
          }
          else {
            if (typeof Wallet_Details.UserAccountBal === 'undefined' || Wallet_Details.UserAccountBal === null || Wallet_Details.UserAccountBal === 0) {
              Set_UserAccountBal(0);
            }
            else {
              Set_UserAccountBal(Wallet_Details.UserAccountBal);
            }
            if (Wallet_Details.UserAccountBal === 0) {
              toast.error("Insufficient balance", toasterOption);
              return false;
            }
            let TokenCategory_label = TokenCategory.label;
            let payload = {
              TokenName,
              TokenRoyalities,
              image: TokenFile,
              TokenCategory_label,
              PutOnSaleType,
              TokenPrice,
            }
            const resp = await CreateTokenValidationAction(payload);
            if (resp && resp.data) {
              if (resp.data.errors) {
                let errors = resp.data.errors;
                let errorsSize = Object.keys(errors).length;
                if (errorsSize !== 0) {
                  setValidateError(errors);
                  toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
                }
                else {
                  setValidateError({});
                  if (Wallet_Details.networkConfiguration.Chainid === config.Chainid) {
                    window.$('#create_item_modal1').modal('show');
                  }
                  else {
                    toast.warn("CoiName is not Valid")
                  }
                }
              }
            }
          }
        }
      }
      else {
        if (config.Chainid === 0) {
          toast.error("Please select network ");
        }
        else {
          toast.error("Please switch network", toasterOption);
          setTimeout(() => {
            window.location = "/"
          }, 1000);
        }
      }
    }
    else {
      toast.warning("please connect your wallet")
    }
  }

  async function switchNetwork(configdata) {
    let type = ""
    let networkConfiguration = {};
    let accountDetails
    if (configdata) {
      if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

        type = "MetaMask"
      }
      else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
        type = "WalletConnect"
      }


      window.$('#connect_modal').modal('hide');

      accountDetails = await connectWallet(type, configdata.Chainid)

      let web3 = new Web3(window.ethereum);
      if (window.ethereum.isMetaMask == true) {
        const chainId = await web3.eth.getChainId();

        if (chainId === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (chainId === network.BSC.Chainid) {
          networkConfiguration = network.BSC
        }


      }
      //setPriceoption()

      if (accountDetails != '' && accountDetails?.web3?._provider != '') {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: accountDetails.accountAddress,
            UserAccountBal: accountDetails.coinBalance,
            WalletConnected: "true",
            Wen_Bln: accountDetails.tokenBalance,
            Accounts: accountDetails.accountAddress,
            providerss: accountDetails.web3._provider,
            networkConfiguration: networkConfiguration
          }
        })
      }
      else {
        toast.warning("please connect your wallet")
      }
    }
    return accountDetails
  }

  async function ApproveCall() {
    let ipfsimghashget;
    try {
      if (Wallet_Details.UserAccountAddr === "") {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
      else {
        setApproveCallStatus('processing');
        let senddata = {
          Image: metfile.image,
          name: TokenName,
          tokenCounts: state.tokenCounts,
          contractAddress: state.contractAddress,
          desc: JSON.stringify(metfile),
          Creator: Wallet_Details.UserAccountAddr.toLowerCase(),
          type: "list"
        }
        try {
          ipfsimghashget = await ipfsImageHashGet(senddata);
          console.log('ipfsimghashgetipfsimghashget', ipfsimghashget)
        } catch (err) {
          console.log("err in approve", err);
        }
        if (ipfsimghashget && ipfsimghashget.data && ipfsimghashget.data.medadata) {
          if (ipfsimghashget.data.medadata.image) {
            setoriginalImagee(ipfsimghashget.data.medadata.image)
            setAdditionalImage(ipfsimghashget.data.medadata.additionalImage)
            if (ipfsimghashget.data.medadata.MetFile) {
              let ipfsurls = ipfsimghashget.data.medadata.ipfsmetadata
              toast.success("Approve Successfully", toasterOption);
              setApproveCallStatus('done');
            }
            else {
              setApproveCallStatus('tryagain');
              toast.error("Try Again", CONFIG.toasterOption)
            }
          }
          else {
            setApproveCallStatus('tryagain');
            toast.error("Try Again", CONFIG.toasterOption)
          }

        }
        else {
          setApproveCallStatus('tryagain');
          toast.error("Try Again", CONFIG.toasterOption)
        }
      }
    }
    catch (error) {
      setApproveCallStatus('tryagain');
      toast.error("Try Again", CONFIG.toasterOption)
    }
  }


  const MintCall = async () => {

    let web3 = new Web3(Wallet_Details.providerss);
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    let CoursetroContract = null;
    let contractCall = null;
    let TokenPriceInStr = (PutOnSaleType === "FixedPrice") ? (window.web3.toWei(TokenPrice)).toString() : '0'

    let mintCall = null;
    let receipt = null;
    let handle = null;
    let signature = null;
    let _nonce = null;
    let Randomname = null;
    if (PutOnSaleType === "FixedPrice") {
      let generator = require('generate-password');
      let web3 = new Web3(Wallet_Details.providerss);
      let randomNum = randomInteger(10000000, 100000000);
      let password = generator.generate({
        length: 10,
        numbers: true
      });
      let web3RpcPro = new Web3(web3.providers);
      const to = Wallet_Details.UserAccountAddr
      const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3RpcPro.utils.toWei(String(TokenPrice));
      const time = Date.now();

      Randomname = password
      let tot = time + Number(randomNum);
      _nonce = tot;

      const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);

      const signhash = await web3.eth.personal.sign(result, to);

      if (signhash) {
        signature = signhash
      }
      let gas;
      await web3.eth.getGasPrice().then(async (result) => {
        gas = result;
      });
      //console.log("gas", gas);
      CoursetroContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
      try {
        contractCall = await CoursetroContract.methods.listNFT(
          state.tokenCounts,
          state.contractAddress,
          state.type,
          ListedQuantity,

        )
      } catch (err) {
        //console.log("adfsbdhbdfgtnh", err);
      }
      try {
        setMintCallStatus('processing')
        if (contractCall != null) {
          await contractCall
            .send({
              from: Wallet_Details.UserAccountAddr,
              value: Wallet_Details?.Listing_fee,
            })
            .on('transactionHash', (transactionHash) => {
              mintCall = transactionHash
              if (mintCall) {
                handle = setInterval(async () => {
                  receipt = await getReceipt(web3, transactionHash)
                  //console.log("XGFnbfgnfxdgn",receipt);

                  clr1();
                }, 8000)
              }
            })
        }
      }
      catch (e) {
        //console.log("dfsfdsfdsef", e)
        toast.error("Mint not Successfully", toasterOption);
        setMintCallStatus('tryagain');
      }
    }
    else {
      //console.log('elseeeee')
      clr1();
    }

    async function clr1() {
      if (receipt != null || PutOnSaleType !== "FixedPrice") {
        clearInterval(handle);
        if (receipt?.status === true || PutOnSaleType !== "FixedPrice") {
          Set_MintHashVal(mintCall);
          toast.success("Your Token Added Successfully", toasterOption);
          let Status = "true";


          let TokenAddItemPayload = {
            Image: originalImage,
            ipfsimage: ipfshash,
            Name: TokenName,
            Count: state.tokenCounts,
            Description: TokenDescription,
            Price: TokenPrice,
            Royalities: TokenRoyalities,
            Category_label: CategorywithSubcategory.category,
            Bid: TokenBid,
            Properties: TokenProperties,
            Owner: Wallet_Details.UserAccountAddr,
            Creator: Wallet_Details.UserAccountAddr,
            CategoryId: CategorywithSubcategory.id,
            Quantity: TokenQuantity,
            Balance: TokenQuantity,
            // ContractAddress:Wallet_Details.networkConfiguration.TradeContract,
            ContractAddress: state.contractAddress,
            Status: Status,
            HashValue: PutOnSaleType === "FixedPrice" ? mintCall : "",
            Type: CollectibleType,
            MinimumBid: 0,
            Clocktime: '',
            EndClocktime: '',
            UnLockcontent: '',
            PutOnSale: PutOnSale,
            PutOnSaleType: PutOnSaleType,
            CoinName: CoinName,
            additionalImage: additionalImage,
            collection: UserCollection,
            SelectedNetwork: NetworkID,
            decimal: decimal,
            action: "list",
            Subcategory: CategorywithSubcategory.subcategory,
            // TermsandConditions: agree.termsandcondition,
            Randomname: Randomname,
            _nonce: _nonce,
            signature: signature,
            SocialLinks: JSON.stringify(socialLinks),
            SelectedNetworkName: Wallet_Details.networkConfiguration.name,
          };


          if (Unlockoncepurchased === true) {
            TokenAddItemPayload.UnLockcontent = UnLockcontent;
          }

          if (PutOnSale === true) {
            if (PutOnSaleType === 'FixedPrice') {
              TokenAddItemPayload.Price = TokenPrice;
            }
            else if (PutOnSaleType === 'TimedAuction') {
              TokenAddItemPayload.MinimumBid = MinimumBid;
              TokenAddItemPayload.Clocktime = Clocktime;
              TokenAddItemPayload.EndClocktime = EndClocktime;
              //TokenAddItemPayload.CoinName = Wallet_Details.networkConfiguration.tokenSymbol;
            }
          }

          await TokenAddItemAction(TokenAddItemPayload);

          let TokenAddOwnerPayload = {
            'Count': state.tokenCounts,
            'Price': TokenPrice,
            'Owner': Wallet_Details.UserAccountAddr,
            'Balance': TokenQuantity,
            'Quantity': TokenQuantity,
            'ContractAddress': state.contractAddress,
            // "ContractAddress":Wallet_Details.networkConfiguration.TradeContract,
            'Type': CollectibleType,
            'tokenCreator': Wallet_Details.UserAccountAddr,
            // 'previousPrice':TokenPrice,
            'HashValue': PutOnSaleType === "FixedPrice" ? mintCall : "",
            'CoinName': CoinName,
            'Status': Status,
            'collection': UserCollection,
            'SelectedNetwork': NetworkID,
            "decimal": decimal,
            "PutOnSaleType": PutOnSaleType,
            "action": "list",
            Randomname: Randomname ? Randomname : '',
            _nonce: _nonce ? _nonce : '',
            signature: signature ? signature : '',
            ListedQuantity: ListedQuantity,
            ListNFT: ListNFT,
            putonsalequantity: PutOnSaleQuantity,
          };
          if (PutOnSaleType === 'TimedAuction') {
            TokenAddOwnerPayload.MinimumBid = MinimumBid;
            TokenAddOwnerPayload.Clocktime = Clocktime;
            TokenAddOwnerPayload.EndClocktime = EndClocktime;
          }

          await TokenAddOwnerAction(TokenAddOwnerPayload);

          setMintCallStatus('done');
          setTimeout(() => window.$('#create_item_modal1').modal('hide'), 1000);
          setTimeout(() => navigate("/my-items"), 1200);
        }
      }

    }
  }



  async function getapproveFun() {
    setApproveTrade("processing")
    let web3 = new Web3(Wallet_Details.providerss);

    let address = (location_pathname === '721') ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract


    try {
      let ConnectContract = await new web3.eth.Contract(CollectibleType == '721' ? SINGLE : MULTIPLE, state.contractAddress);
      let contract_Method_Hash = await
        ConnectContract
          .methods
          .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
          .send({
            from: Wallet_Details.UserAccountAddr
          }).on('transactionHash', (transactionHash) => {
            return transactionHash
          })
      let HashValue = contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash
      let receipt = await web3.eth.getTransactionReceipt(HashValue);

      let need_data = {
        status: receipt.status,
        HashValue: receipt.transactionHash,
      }
      if (need_data.status === true) {
        setApproveTrade("done")

      }
      else {
        setApproveTrade("tryagain")
      }
      return need_data;

    }
    catch (e) {
      //console(data, e)
      return false
    }
  }

  async function orderApprovecheck(item) {
    if (Wallet_Details.providerss == null) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    let web3 = new Web3(Wallet_Details.providerss);
    try {
      let MultiContract = new web3.eth.Contract(
        (item.type === 721 ? SINGLE : MULTIPLE),
        state.contractAddress
      )
      let status = await MultiContract.methods.isApprovedForAll(
        Wallet_Details.UserAccountAddr,
        Wallet_Details.networkConfiguration.TradeContract
      ).call();
      return status
    }
    catch (e) {
      //console.log("OrderApprove Check", e);

      return false

    }

  }

  async function orderApprovecheck(item, tokenOwnerInfo) {
    if (Wallet_Details.providerss == null) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    let web3 = new Web3(Wallet_Details.providerss);
    try {

      let MultiContract = new web3.eth.Contract(
        (item.type === 721 ? SINGLE : MULTIPLE),
        item.contractAddress
      );
      let status = await MultiContract.methods.isApprovedForAll(
        tokenOwnerInfo.tokenOwner,
        Wallet_Details.networkConfiguration.TradeContract
      ).call();
      return status;
    }
    catch (e) {
      // //console.log("OrderApprove Check", e);
      return false

    }
  }

  const SettokenCategory = (cat, subcat) => {
    setCategorywithSubcategory({ category: cat.name, subcategory: subcat, id: cat._id, display: cat.name + '-' + subcat })
    setCategory(!category)
  }

  const addFilters = () => {
    if (filterRows.length < 10) {
      setFilterRows([...filterRows, { filters: "" }])
      SetSocialLinks([...socialLinks, { url: "" }])
    }
    else {
      toast.error('Already added 10 links', 1000)
    }
  };

  const removeFilters = (index) => {
    const list = [...filterRows]
    let currlink = socialLinks;
    list.splice(index, 1)
    currlink.splice(index, 1)
    setFilterRows(list)
    SetSocialLinks([...currlink])
  };

  const OnChange = (e, index) => {
    let currlinks = socialLinks;
    setValidateError({})
    if (e?.target?.id) {
      const { id, value } = e.target;
      filterRows.map((val, ind) => {
        if (ind == index) {
          currlinks[ind][id] = value
        }
      })
    }
    else if (e?.value) {
      const { classname, label, value } = e;
      filterRows.map((val, ind) => {
        if (ind == index) {
          currlinks[ind].urlfor = value;
          currlinks[ind].classname = classname;
        }
      })
    }
    SetSocialLinks([...currlinks])
  }

  return (
    <div className="inner_header">
      <Header/>
      <ScrollToTopOnMount />
      <AddCollectionRef
        ref={CreateCollectionForwardRef}
        GetCollection={getCollection}
        SetCurrCollection={SetChoosenCollection}
        hideliteratue={true}
      />
      <div className={" inner_pageheader"}>
        <div className={"container"}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/create">
                  <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">{location_pathname === "721" && 'Create Single NFT'}{location_pathname === "create-multiple" && 'Create Multiple NFTs'}</h3>
              </div>
            </Col>
          </Row>
        </div>
        <div className="container mt-5">
          <Row>
            <Col xs={12} sm={4} md={4}>
              <label className="primary_label">Preview</label>
              <div className="single_collectible masonry mx-0">
                <div className="item">
                  <div className="card_inner_item">
                    <div className="d-flex justify-content-between">

                      <div>

                      </div>
                    </div>
                    <div className="remaintime mt-3">
                      <div className="item_inner_img">

                        <div className="ethactios">
                          <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
                            {
                              (((state?.image && TokenFile)?.includes('ipfs://') === true || (TokenFile)?.includes('ipfs/') === true || (TokenFile)?.includes('ipfs:/') === true)
                                ? (

                                  <object type="image/webp" data={'https://ipfs.io/ipfs/' + (((TokenFile)?.split('ipfs://').pop()).split('ipfs/').pop()).split('ipfs:/').pop()}
                                  >
                                  </object>

                                )
                                :

                                (state?.image &&

                                  <object type="image/webp" data={TokenFile}
                                  >
                                  </object>
                                ))}
                          </LazyLoad>
                        </div>

                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-end">
                      <div>
                        <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal"> <span>
                          Open for Bids 1 of 1</span></button>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </Col>
            <Col xs={12} sm={8} md={8}>
              <form className="formCls">
                {
                  <div className="form-row">
                    <label className="primary_label" htmlFor="price_new">Network</label>

                    <div className="form-row w-100">
                      <div className="form-group col-md-12">
                        <div className="input-group input_grp_style_1">


                          <Select
                            className="yes1 typecontrol3 form-control primary_inp typecontrol"
                            id="networkname1"
                            name="networkname"
                            // onChange={networkoptionfunc}
                            onChange={selectChange}
                            // options={networkoption}
                            label="Select price"
                            classNamePrefix="react-select"
                            value={config}
                            isDisabled={true}
                          // formControlProps={{
                          //   fullWidth: true
                          // }}
                          />
                          {ValidateError.network && <span className="text-danger">{ValidateError.network}</span>}


                        </div>

                      </div>
                    </div>
                  </div>


                }
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">List NFT</label>
                        <p className="form_note">List Your NFT in the MarketPlace</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"
                          id="ListNFT"
                          name="ListNFT"
                          onChange={CheckedChange}
                          checked={ListNFT}

                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {ListNFT &&
                      <div className="d-flex justify-content-between align-items-start grid_toggle">
                        <div>
                          <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                          <p className="form_note">You’ll receive bids on this item</p>
                        </div>
                        <label className="switch toggle_custom">
                          <input type="checkbox"
                            id="putonsale1"
                            name="putonsale"
                            onChange={CheckedChange}
                            checked={PutOnSale}

                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                    }
                    {
                      (PutOnSale === false) ? ('') : (
                        <>
                          <div className="row connect_row mt-3 putonsale_sec">
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                              <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'FixedPrice') ? 'active' : 'inactive')} id="fixedprice1" onClick={() => { changePutOnSaleType('FixedPrice') }}>
                                <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />
                                <p>Fixed Price</p>
                              </div>
                            </div>
                            {(CollectibleType === 721) &&
                              <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                                <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'TimedAuction') ? 'active' : 'inactive')} onClick={() => { changePutOnSaleType('TimedAuction') }}>
                                  <img src={require("../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                                  <p>Timed Auction</p>
                                </div>
                              </div>}
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3" onClick={() => { changePutOnSaleType('UnLimitedAuction') }}>
                              <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'UnLimitedAuction') ? 'active' : 'inactive')}>
                                <img src={require("../assets/images/unlimited_svg.svg")} alt="Unlimited Auction" className="img-fluid" />

                                <p>Unlimited Auction</p>
                              </div>
                            </div>

                          </div>

                        </>)}
                    {(PutOnSale === true && PutOnSaleType === 'FixedPrice') &&
                      <div className="row mx-0 mt-3 fixed_price_sec">
                        <label className="primary_label" htmlFor="price_new">Price</label>

                        <div className="form-row w-100">
                          <div className="form-group col-md-6">
                            <div className="input-group input_grp_style_1">

                              <input
                                type="text"
                                className="form-control selct_form_input_h"
                                placeholder="0"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                name="TokenPrice"
                                id="TokenPrice1"
                                step="0.01"
                                maxLength={15}
                                autoComplete="off"
                                onChange={inputChange}
                              />
                              <div className="input-group-append">
                                <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon21"
                                  name="coinname"
                                  onChange={priceoptionfunc}
                                  options={priceoption}
                                  label="Select price"
                                // formControlProps={{
                                //   fullWidth: true
                                // }}
                                />

                              </div>
                            </div>
                            {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                          </div>

                        </div>
                        <p className="form_note">Service fee
                          <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee_seller / 1e18}% </span><br />
                          You will receive <span className="font_we_700_note_txt">
                            {/* {YouWillGet} */}
                            <Convert
                              item={Number(YouWillGet)}

                              coinName={CoinName}
                              convertVal={1}
                            />
                            {CoinName}
                          </span>
                          <span className="font_we_700_note_txt">
                            <br />


                            {/* $( {CoinName == "CAKE" ? Wallet_Details.Token_convertion[0].USD * TokenPrice : Wallet_Details.Token_convertion[0].USD * TokenPrice}) */}
                          </span>
                        </p>
                      </div>
                    }
                    {(PutOnSale === true && PutOnSaleType === 'TimedAuction') &&
                      <div className="row mt-3 timed_sec">
                        <div className="col-12 mb-3">
                          <label className="primary_label" htmlFor="price_new">Minimum bid</label>
                          <div className="input-group mb-1">
                            <input
                              type="text"
                              className="form-control selct_form_input_h"
                              placeholder="Enter minimum bid"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon3"
                              name="MinimumBid"
                              id="MinimumBid1"
                              maxLength={CONFIG.maxLength}
                              onChange={inputChange}
                              autoComplete="off"
                            />
                            {ValidateError.MinimumBid && <span className="text-danger">{ValidateError.MinimumBid}</span>}
                            <div className="input-group-append">


                              <Select
                                className="yes1 form-control primary_inp select1 selxet_app"
                                id="basic-addon21"
                                isSearchable={false}
                                name="coinname"
                                //  value={{ label: CoinName == config.currencySymbol ? priceoption[1].label : CoinName }}
                                onChange={priceoptionfunc}
                                options={priceoption && priceoption.filter(item => item?.label != Wallet_Details?.networkConfiguration?.currencySymbol)}
                                label="Select price"
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />

                              {/* <p className="yes1 form-control primary_inp selxet_app symboldbo">{Wallet_Details.networkConfiguration.tokenSymbol
                              // NetworkName == 'binance' ? network.BSC.tokenSymbol : network.ETH.tokenSymbol
                              }</p> */}

                            </div>
                          </div>
                          {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}

                          <p className="form_note">   Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                        </div>
                        <div className="col-12 col-lg-6  mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Starting Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {StartDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div >Select Date</div>
                                <div id="RightAfterListing1" onClick={() => { DateChange('StartDateDropDown', 'RightAfterListing') }}>Right after listing</div>
                                <div id="PickStart1" onClick={() => { DateChange('StartDateDropDown', 'PickStartDate') }}>Pick specific date</div>
                              </div>
                            </div>
                            {ValidateError.Clocktime && <span className="text-danger">{ValidateError.Clocktime}</span>}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {EndDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div>Select Date</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '1 Day') }}>1 day</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '3 Day') }}>3 days</div>
                                <div onClick={() => { DateChange('EndDateDropDown', 'PickEndDate') }}>Pick specific date</div>

                              </div>
                            </div>
                            {ValidateError.EndClocktime && <span className="text-danger">{ValidateError.EndClocktime}</span>}
                          </div>

                        </div>
                        <div className="col-12">
                          <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal1">Learn more how timed auctions work</p>

                        </div>

                      </div>


                    }</div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      name="TokenName"
                      id="TokenName1"
                      onChange={inputChange}
                      value={TokenName}
                      disabled
                      placeholder="e.g. Redeemable" />
                    {ValidateError.TokenName && <span className="text-danger">{ValidateError.TokenName}</span>}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <textarea type="text"
                      className="form-control primary_inp"
                      id="desccription1"
                      name="TokenDescription"
                      onChange={inputChange}
                      placeholder="Description about your NFTs..."
                      autoComplete="off"
                      value={TokenDescription}
                      disabled
                    ></textarea>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="Collection">Select Collection</label>
                    <Select
                      className="yes1 typecontrol3 form-control primary_inp typecontrol"
                      id="userCollection1"
                      name="userCollection"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CollectionList}
                      label="Select Collection"
                      classNamePrefix="react-select"
                      placeholder="Select Collection"
                      value={
                        UserCollection ? {
                          label: UserCollection,
                          value: UserCollection
                        } : ""
                      }
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />


                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="category">Category</label>

                    <input type="text" placeholder="Select Category" className="form-control"
                      value={CategorywithSubcategory?.display ? CategorywithSubcategory?.display : ""}
                      onClick={() => setCategory(!category)}
                      disabled={CategorywithSubcategory?.disabecategory ? CategorywithSubcategory.disabecategory : false}
                    />
                    {category == true ?
                      (
                        <><div className="dropdown">

                          <ul className="dropdown-menu1 p-3">
                            {fullcategory?.length > 0 &&
                              fullcategory.map((val) => {
                                return (
                                  <li className="dropdown-sub">
                                    {val.name}
                                    <ul className="sub-dropdown">
                                      {val.subcategory.map((sub) => {
                                        return (
                                          <li onClick={() => SettokenCategory(val, sub)}>{sub}</li>
                                        )
                                      })}
                                    </ul>
                                  </li>)
                              })
                            }
                          </ul>
                        </div></>) : (<></>)}
                    {ValidateError.TokenCategory && <span className="text-danger"><br />{ValidateError.TokenCategory}</span>}

                  </div>
                  {location_pathname === 'create-multiple' &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">Number of copies</label>
                      <input
                        type="text"
                        className="form-control"
                        id="TokenQuantity"
                        name="TokenQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={TokenQuantity}
                        maxLength={3}
                        autoComplete="off"
                      />
                      <p className="form-note">Specify the number of copies of the NFT you want to mint.</p>
                      {ValidateError.TokenQuantity && <span className="text-danger"><br />{ValidateError.TokenQuantity}</span>}
                    </div>
                  }

                  {ListNFT &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">List Quantity</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ListedQuantity"
                        name="ListedQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={ListedQuantity}
                        maxLength={3}
                        disabled={location_pathname === 'create-single'}
                        autoComplete="off"
                      />
                      <p className="form-note">Specify the number of copies you have to list for sale.</p>
                      {ValidateError.ListedQuantity && <span className="text-danger"><br />{ValidateError.ListedQuantity}</span>}
                    </div>
                  }

                  {PutOnSale &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">Quantity for Sale</label>
                      <input
                        type="text"
                        className="form-control"
                        id="PutOnSaleQuantity"
                        name="PutOnSaleQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={PutOnSaleQuantity}
                        maxLength={3}
                        disabled={location_pathname === 'create-single'}
                        autoComplete="off"
                      />
                      <p className="form-note">Specify the number of copies you have to list for sale.</p>
                      {ValidateError.PutOnSaleQuantity && <span className="text-danger"><br />{ValidateError.PutOnSaleQuantity}</span>}
                    </div>
                  }

                  <div className="form-row w-100">
                    <div className="form-group col-md-12">
                      <label className="primary_label" htmlFor="name">
                        Social Media Links
                      </label>
                    </div>
                  </div>
                  <div className="social_add_sec">
                    <p className="text_title">Interactive media links, e.g. Facebook, Telegram, Twitter, Instagram, etc.</p>

                    <div className="add_social">
                      <div className="btn_sec">
                        <button type="button" className="btn btn-add-social" onClick={addFilters}> <FaPlus /></button><span className="add_txt">Click to Add</span>
                      </div>

                    </div>
                    {filterRows.map((val, index) => (
                      <div className="url_card my-3" key={index}>
                        <label className="primary_label" htmlFor="name">
                          URL For
                        </label>
                        <div className="row first_row">
                          <div className="col-lg-3 mb-4 mb-lg-0">
                            <Select
                              id="urfor"
                              options={mappedData}
                              classNamePrefix="react-select"
                              className="yes1 typecontrol3 form-control primary_inp typecontrol"
                              onChange={(e) => OnChange(e, index)}
                              value={
                                socialLinks[index]?.urlfor ?
                                  {
                                    label: socialLinks[index].urlfor,
                                    value: socialLinks[index].urlfor,
                                  }
                                  : ''
                              }
                            />
                            {ValidateError['urlfor' + index] && (
                              <span className="text-danger">{ValidateError['urlfor' + index]}</span>
                            )}
                          </div>
                          <div className="col-lg-9 mb-4 mb-lg-0">
                            <div className="row">
                              <div className="col-10">
                                <form>
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="url" aria-describedby="emailHelp" placeholder="Enter url" onChange={(e) => OnChange(e, index)} value={socialLinks[index]?.url || ''} />
                                    {ValidateError['url' + index] && (
                                      <span className="text-danger">{ValidateError['url' + index]}</span>
                                    )}
                                  </div>
                                </form>
                              </div>
                              <div className="col-2"><img src={delete_png} onClick={() => removeFilters(index)} alt="delete" className="img-fluid delete_png" width={30} /></div>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>

                  <button type="button" className="create_btn" data-toggle="model" data-target="#create_item_modal1" onClick={CreateItem} >List</button>

                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
      {/* Choose Collection Modal */}
      <div className="modal fade primary_modal" id="choose_collection_modal1" tabIndex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="choose_collection_modalLabel1">Collection</h5>

              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center flex_block_mob">
                <div className="hex center">
                  <div className="hex__shape">
                    <div className="hex__shape">
                      <div className="hex__shape">
                        <img src={require("../assets/images/img_01.png")} alt="logo" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name1" placeholder="Enter token name" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription1" placeholder="Enter token symbol" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name1" placeholder="Spread some words about token collection" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription1" defaultValue="Fayre.io/" />
                  </div>
                </div>
                <div className="text-center">
                  <button className="create_btn btn-block">Create Collection</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ens choose collection modal */}
      {/* create_item Modal */}
      <div className="modal fade primary_modal" id="create_item_modal1" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel1">Follow Steps</h5>

              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">

                  {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


                  <div className="media-body">
                    <p className="mt-0 approve_text">Download Image From Other Source</p>
                    <p className="mt-0 approve_desc">Download meta contents to Marketplace</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <button className="create_btn btn-block" disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')} onClick={ApproveCall}>     {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {ApproveCallStatus === 'init' && 'Approve'}
                    {ApproveCallStatus === 'processing' && 'In-progress...'}
                    {ApproveCallStatus === 'done' && 'Done'}
                    {ApproveCallStatus === 'tryagain' && 'Try Again'}
                  </button>



                </div>



                {approvecheck &&
                  <div className="media approve_media">

                    {ApproveTrade === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                    {ApproveTrade === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                    {ApproveTrade === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                    {ApproveTrade === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


                    <div className="media-body">
                      <p className="mt-0 approve_text">Get Approve </p>
                      {/* <p className="mt-0 approve_desc">Upload meta contents to ipfs</p> */}
                    </div>
                  </div>
                }
                {approvecheck &&
                  <div className="text-center my-3">
                    <button className="create_btn btn-block" disabled={(ApproveCallStatus !== 'done' || ApproveTrade === 'processing' || ApproveTrade === 'done')} onClick={getapproveFun}>
                      {ApproveTrade === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                      {ApproveTrade === 'init' && 'Approve'}
                      {ApproveTrade === 'processing' && 'In-progress...'}
                      {ApproveTrade === 'done' && 'Done'}
                      {ApproveTrade === 'tryagain' && 'Try Again'}
                    </button>



                  </div>
                }










                <div className="media approve_media">


                  {MintCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {MintCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {MintCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


                  <div className="media-body">
                    <p className="mt-0 approve_text">Listing token</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <button type="button" className="create_btn btn-block" disabled={
                    (ApproveCallStatus !== 'done' && 
                    (ApproveTrade !== 'tryagain') 
                    // && (MintCallStatus === 'processing' || MintCallStatus === 'done')
                    )} onClick={MintCall}>


                    {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {MintCallStatus === 'init' && 'Start'}
                    {MintCallStatus === 'processing' && 'In-progress...'}
                    {MintCallStatus === 'done' && 'Done'}
                    {MintCallStatus === 'tryagain' && 'Try Again'}



                  </button>
                </div>

                <div className="media approve_media">

                </div>
                <div className="text-center my-3">

                </div>

                <div className="media approve_media">

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}


      {/* learn Modal */}
      <div className="modal fade primary_modal" id="learn_modal1" tabIndex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="learn_modalLabel1">How timed auction work</h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

              <p>The bidder can only place a bid which satisfies the following conditions:</p>
              <ol>
                <li>
                  It is at least minimum bid set for the auction
                </li>
                <li>
                  It is at least 5% higher than the current highest bid

                </li>
              </ol>

              <p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

              <p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

              <p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>


            </div>
          </div>
        </div>
      </div>
      {/* end learn modal */}

      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal1" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">

                <Datetime
                  input={false}
                  value={Clocktime}
                  onChange={(value) => set_Clocktime(value)} />
              </div>
              <div className="text-center pb-3">
                <button className="btn btn_blue" onClick={() => ModalAction('calendar_modal1', 'hide')} id="doneStartDate">Done</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}
      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal_expire1" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">
                <Datetime
                  input={false}
                  value={EndClocktime}
                  onChange={(value) => set_EndClocktime(value)}
                />
              </div>
            </div>
            <div className="text-center">
              <button className="primary_btn" id="doneEndDate" onClick={() => ModalAction('calendar_modal_expire1', 'hide')}>Done</button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
