import React, { useEffect, useState,Fragment } from "react";



// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import ReactDatatable from '@ashvin27/react-datatable';
import { isCallChain } from "typescript";
import { toast } from "react-toastify";
import { getSupportTicket,createSupportTicket } from "../actions/v1/user";
import { closeSupportTicket } from "../actions/v1/user";

import DataTable from 'react-data-table-component';


const dashboardRoutes = [];

export default function SupportTicket(props) {

  const [responsive, setresponsive] = useState(true);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const [error, setError] = useState({});
  const [ticketList, setTicketList] = useState([
    {
      createtAt: "usgaw",
      _id: "usgaw",
      ticketStatus: "usgaw",
      subject: "usgaw"
    }
  ]);

   

 
    
    const { ...rest } = props;

    function ScrollToTopOnMount() {
        
        return null;
      }

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


      const columns  = [
        {
            name: "Created Date",
            text: "Created Date",
            className: "name",
            align: "left",
            sortable: false,
            cell: (record)=> { return record.createtAt?new Date(record.createtAt).toLocaleDateString():""}
        },
        {
            name: "TicketID", 
            text: "TicketID",
            className: "address",
            align: "left",
            sortable: false,
            cell: (record)=> { return record._id?record._id:""}
        },
        {
            name: "Ticket Status",
            text: "Ticket Status",
            className: "postcode",
            sortable: false,
            cell: (record)=> { return record.ticketStatus?record.ticketStatus:""}
        },
        {
            name: "Subject",
            text: "Subject",
            className: "rating",
            align: "left",
            sortable: false,
            cell: (record)=> { return record.subject?record.subject:""}
        },
        {
            name: "Action",
            text: "Action",
            className: "type_of_food",
            sortable: false,
            align: "left",
          cell: record => {
            return (
              <Fragment>
                {
                  record.ticketStatus == 'closed' ? <span className="me-2">Closed</span>
                    :
                    <button
                      className="btn sprttckt_editbtn btn-sm"
                      onClick={() => { closeTicket(record._id) }}>
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                }
              </Fragment>
            );
          }
        }
      ]

      const configdata = {
        page_size: 5,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: 'No Data found!',
      
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last"
          }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
      };

      const supportValidation = async()=> {
        let err= {}
        if(subject == "" || subject== null || subject == undefined){
          err.subject = "Subject Field is required"
        }
        if(message == "" || message== null || message == undefined){
          err.message = "Message Field is required"
        }
        if(attachment == "" || attachment== null || attachment == undefined){
          err.attachment = "Attachment Field is required"
        }
        setError(err);
        return {
          isvalid : Object.keys(err).length == 0 ? true : false
        }
      }
      const handleSubmit = async ()=> {
        try {
          const {isvalid} = await supportValidation();
          if(isvalid == true){
            // const formData = new FormData();
            // formData.append("subject", subject)
            // formData.append("description", message)
            // formData.append("file", attachment)
            // formData.append("email", localStorage.getItem("registedEmail"))
            let sendData = {
              subject: subject,
              description: message,
              file: attachment,
              email: localStorage.getItem("registedEmail"),
            }
            const createTicket = await createSupportTicket(sendData);
            if (createTicket.data.status == true) {
              toast.success(createTicket.data.message);
              getRisedTickedDatas()
              setMessage('');
              setSubject('');
            }
          }
        } catch (e) {
          console.log('handleSubmit_supportTicket',e)
        }
      }

  useEffect(() => {
    getRisedTickedDatas()
  }, []);

 
  const getRisedTickedDatas = async () => {
    const getData = await getSupportTicket({email: localStorage.getItem("registedEmail") });
    console.log('getDatagetData',getData)
    setTicketList(getData.data.data)
  }
  const closeTicket = async (data)=> {
    const close = await closeSupportTicket({id: data});
    if(close.data.status == true){
      getRisedTickedDatas()
    }
  }
  console.log("ticketList",ticketList);
  
  return (
    <div className="inner_header">
  <Header/>
    <ScrollToTopOnMount/>
    <div className={" inner_pageheader"}>
      <div className={"container"}>

        <div className="row mt-5 justify-content-center">
            <h4 className="supporrt_ttl">Support Ticket</h4>
            <div className="col-12 col-md-6 ">
            <div className="form-group ">
                    <label className="primary_label" htmlFor="name">Subject</label>
                    <input type="text"
                      className="form-control primary_inp"
                      name="TokenName"
                      id="TokenName"
                      onChange={(e)=> {setSubject(e.target.value)}}
                      placeholder="e.g. Redeemable" 
                      value={subject}
                      />
                   

                  </div>
                  <span className="text-danger">{error.subject}</span>
                  </div>
                  <div className="col-12 col-md-6">

                  <div className="form-group ">
                    <label className="primary_label" htmlFor="name">Message</label>
                    <input type="text"
                      className="form-control primary_inp"
                      name="TokenName"
                      id="TokenName"
                      onChange={(e)=> {setMessage(e.target.value)}}
                      placeholder="e.g. Redeemable" 
                      value={message}
                      />
                    
                 

                  </div>
                  <span className="text-danger">{error.message}</span>
                  </div>
                  <div className="col-12 col-md-6 ">
                  <div className="form-group ">
                    <label className="primary_label" htmlFor="name">Attachment if any <span className="spaning_txt">image should allowed only pdf/docx/jpg/png</span></label>
                    <div className="uploadfile_whole">
                        <div className="uploadfile_left">
                        <input className="abs_uploadfile" type="file" onChange={(e)=> {setAttachment(e.target.files[0])}}/>
                        </div>
                        {/* <button className="fileupload_browsebtn">Browse</button> */}
                        <button className="suporttkt_addfilebtn"><i class="fa-solid fa-circle-plus"></i></button>
                        
                    </div>
                 
                    

                  </div>
                  <span className="text-danger">{error.attachment}</span>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
                  <div className="text-center ">

                  <button className="create_btn" onClick={()=> {handleSubmit()}}>Submit</button>
                  </div>

            </div>
        </div>
        <div className="mt-5">
        <DataTable
        className="alltables"
        columns={columns}
        data={ticketList}
        pagination
        // highlightOnHover
      />
        
        {/* <ReactDatatable
     className="table table-striped table-bordered table-responsive supporttkt_tabled"
          responsive={responsive}
          config={configdata}
          records={ticketList}
          columns={columns}
        /> */}




        </div>
       
      </div>
      
    </div>
    <Footer/>
  </div>
  )
}
