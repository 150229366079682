
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import config from '../../lib/config';


export default function Convert(props) {
  const [val, set_val] = useState('')
  const navigate = useNavigate();
  var {
    item, convertVal, coinName
  } = props;

  useEffect(() => {
    let nev = item * convertVal
    nFormatter(nev, coinName)
    // //alert('1')
  });

  function nFormatter(num, coinName) {
    let digits;
    // //console.log("num",num,coinName)
    let cn = String(coinName).toLowerCase()
    if (cn === String(config.tokenSymbol).toLowerCase()) {
      digits = 2
    }
    else {
      digits = 5
    }
    let si = [
      { value: 1, symbol: "   " },
      { value: 1E3, symbol: " k " },
      { value: 1E6, symbol: " M " },
      { value: 1E9, symbol: " B " },
      { value: 1E12, symbol: " T " },
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    let bl = (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
    // console.log('blblbl',bl)
    set_val(bl)
    return bl;
  }


  return (
    <>
    {/* {console.log('testttttvalllllll',item,convertVal,val,item > 0
        ? (val !== 0 ? val : "")
        : (item / convertVal))} */}
      <> {item > 0
        ? (val !== 0 ? val : "")
        : (item / convertVal)}</>
    </>
  )
}
