import React, { useEffect, useState } from "react";


import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Web3 from 'web3';
import Profile from '../assets/images/no_profile2.png'
import Select from 'react-select'
import { useSelector } from "react-redux";
import isEmpty from '../lib/isEmpty';
import '@metamask/legacy-web3';
import config from '../lib/config';
import { addUserCollection, CollectionValidationAction } from '../actions/v1/user'
import {
  GetCategoryAction,
  getUserCollectiondata
} from '../actions/v1/token';
import { toast } from 'react-toastify';
import ERC721 from "../ABI/SINGLE"
import ERC1155 from "../ABI/MULTIPLE"
import cvr_img from '../assets/images/cover_img.png';
import editpng from '../assets/images/edit_pencil.png';
import { Row, Col } from "react-bootstrap";


// toast.configure();
let toasterOption = config.toasterOption;
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


const initialFormValue = {
  'userAddress': "",
  'collectionAddress': "",
  'collectionName': "",
  'customurl': "",
  'collectionType': '',
  'profileImg': '',
  'coverImg': ''
}



export default function EditProfile(props) {


  const { ...rest } = props;



  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const navigate = useNavigate();
  var { name, form } = useParams();


  const [validateError, setValidateError] = useState({});
  const [formValue, setFormValue] = useState(initialFormValue);
  const [disablebtn, setDisablebtn] = useState(0)
  const [CollectionOption, setCollectionOption] = useState([{ value: 'SINGLE', label: 'Single / Poly-Multiple' }, { value: 'MULTIPLE', label: 'Multiple' }]);
  const [NFTType, setNFTType] = useState('')
  const [fullcategory, setFullcategory] = useState({});
  const [CategorywithSubcategory, setCategorywithSubcategory] = useState({})
  const [category, setCategory] = React.useState(false);


  const {
    collectionName,
    customurl,
    collectionType,
    profileImg,
    coverImg
  } = formValue


  useEffect(() => {
    CollectionProfile();
  }, [Wallet_Details.UserAccountAddr])

  async function CollectionProfile() {
    let formData = {};
    let payload = {
      collectionName: name,
      from: "addCollection"
    }
    let collectionInfo = await getUserCollectiondata(payload);
    if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections) {

      //console.log("Collection Informations", collectionInfo.data.data.collections);
      let collectionData = collectionInfo.data.data.collections
      collectionData.map((item) => {
        formData['collectionName'] = item.collectionName;
        formData['customurl'] = item.customurl;
        formData['collectionType'] = item.collectionType;
        formData['profileImg'] = item.imageName;
        formData['coverImg'] = item.coverimage;
        formData['userAddress'] = Wallet_Details.UserAccountAddr;
        setNFTType(item.collectionType)
        if (item.imageName != '' || item.imageName !== undefined) {
          let profileimage = config.Back_URL + '/collectionLogo/' + item.userAddress + '/' + item.imageName;
        }
        if (item.coverimage != '' || item.coverimage != undefined) {
          let coverimage = config.Back_URL + '/coverphoto/' + item.userAddress + '/' + item.coverimage;
        }
        setFormValue(formData)

      })
    }
  }

  const handleFile1 = (event) => {
    event.preventDefault();
    let reader = new FileReader()
    const { id, files } = event.target;
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
        }
      }
    }
    //console.log("Dfbhrsfrtgjh11", id, files[0])
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
    //console.log("Dfbhrsfrtgjh22", formData)
  }


  const handleFile = (event) => {
    setDisablebtn(0)
    event.preventDefault();
    let reader = new FileReader()
    const { id, files } = event.target;
    setDisablebtn(0)
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
        }
      }
    }
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
  }

  const onChange = (e) => {
    setDisablebtn(0)
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }

  const selectChange = (e) => {
    if (e && e.label && e.value) {
      const { id, value } = e
      let formData = { ...formValue, ...{ ['collectionType']: value } }
      setFormValue(formData)
    }
  }


  const handleFormSubmit = async (e) => {
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warn("please connect your wallet")
    }
    else {
      try {

        const userAddress = Wallet_Details.UserAccountAddr
        const collectionType = NFTType
        let reqdata = {}
        if (form != "usercollection") {
          reqdata = {
            formValue,
            userAddress,
            Single: Wallet_Details.networkConfiguration.singleContract,
            Multiple: Wallet_Details.networkConfiguration.multipleContract,
            CategoryId: CategorywithSubcategory.id,
            Category: CategorywithSubcategory.category,
            SubCategory: CategorywithSubcategory.subcategory,
            chainId : Wallet_Details?.networkConfiguration?.Chainid,
            evmChain : Wallet_Details?.networkConfiguration?.EvmName
          }
        }
        else {
          reqdata = {
            formValue,
            userAddress,
            chainId : Wallet_Details?.networkConfiguration?.Chainid,
            evmChain : Wallet_Details?.networkConfiguration?.EvmName
          }
        }
        console.log("gdfnjdfj", reqdata, form);
        let validate = await Validate(reqdata)
        console.log("ghnjgfhkmjghj", validate, formValue, reqdata);

        if (isEmpty(validate) == false) {
          setDisablebtn(1)
          toast.error("please fill the required field", toasterOption);
        }
        else if (form != "usercollection") {

          const resp = await CollectionValidationAction(reqdata)
          let errors = resp.data;
          let errorsSize = Object.keys(errors).length;
          if (errorsSize != 0 || errorsSize > 0) {
            setDisablebtn(1)
            setValidateError(errors);
          }
          else {
            setDisablebtn(3)
            console.log("GJrgtykhjyuk", reqdata);
            reqdata = { ...reqdata, ...reqdata.formValue, ...{ coverImage: formValue?.coverImg, Image: formValue?.profileImg } }
            console.log("GJrgtykhjyuk11111", reqdata);
            let responsedata = await addUserCollection(reqdata)
            //console.log("dfbgvsdfbsdfb",responsedata);
            if (responsedata) {
              setValidateError("");
              toast.success("Collection Added successfully", toasterOption)
              navigate("/my-items")
            }
          }
        }
        else if (form == "usercollection") {
          setDisablebtn(3)
          reqdata = { ...reqdata, ...reqdata.formValue, ...{ coverImage: formValue?.coverImg, Image: formValue?.profileImg } }
          console.log("GJrgtykhjyuk11111222222222", reqdata);

          let responsedata = await addUserCollection(reqdata)
          if (responsedata) {
            setValidateError("");
            toast.success("collection Updated successfully", toasterOption)
            // history.push("/collectiondetail/" + formValue.userAddress + '/' + formValue.customurl + '/' + collectionAddress)
          }
        }
      }
      catch (err) {
        //console.log("gfnjfghnfghm",err);
      }
    }
  }
  console.log("Ghmjfghkjmfhj", formValue);
  const Validate = async (data) => {
    try {
      let validateError = {};

      let imageSize = 5000000;

      // let urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;


      if (formValue?.profileImg != "" && typeof (formValue?.profileImg) == 'object') {
        //console.log("epwopeopwope", data.formValue.coverImg, onchangeimg, onchangeimg.split('.').pop())
        if ((data?.formValue?.profileImg != "" && data?.formValue?.profileImg != undefined)) {
          if (imageSize < (data?.formValue?.profileImg)?.size) {
            validateError.image = "File size must be below 5mb"
          }
          if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data?.formValue?.profileImg)?.name)) {
            validateError.image = "file is invalid. only allowed JPG,PNG,WEBP,gif";
          }
        }

      }
      if (formValue?.coverImg != "" && typeof (formValue?.coverImg) == 'object') {
        if (data?.formValue?.coverImg != "" && data?.formValue?.coverImg != undefined) {
          if (imageSize < (data?.formValue?.coverImg)?.size) {
            validateError.image1 = "File size must be below 5mb"
          }
          if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.formValue.coverImg).name)) {
            validateError.image1 = "file is invalid. only allowed JPG,PNG,WEBP,gif";
          }
        }

      }
      if ((data.formValue.collectionName) === '') {
        validateError.names = "Collection Name is Required"
      }
      if ((data?.formValue?.collectionName) === '') {
        validateError.names = "Collection Name is Required"
      }
      if (data?.formValue?.customurl === '') {
        validateError.customurl = "Custom url is Required"
      }

      if (data?.formValue?.collectionType === '') {
        validateError.Collection = "Collection Type is Required"
      }
      if (isEmpty(CategorywithSubcategory) && form != "usercollection") {
        validateError.Category = '"Category" is required';
      }
      console.log("ghmjghkmghjkm,hg", data?.formValue?.collectionAddress, Wallet_Details, data?.formValue?.collectionType);
      if (data?.formValue?.collectionAddress) {
        try {
          const web3 = new Web3(Wallet_Details.networkConfiguration.BNBPROVIDER)
          let ConnectContract = await new web3.eth.Contract(
            Number(data?.formValue?.collectionType) == "SINGLE" ? ERC721 : ERC1155, data?.formValue?.collectionAddress
          );
          let contractowner = await ConnectContract.methods.owner().call()

          if (String(contractowner.toLowerCase()) !== String(Wallet_Details.UserAccountAddr.toLowerCase())) {
            validateError.CollectionContractAddress = "Owner Address is InValid"
          }
        } catch (err) {
          console.log("fgbnhdfgnhbdf", err);
        }
      }
      console.log("validateError", validateError);
      setValidateError(validateError)
      return validateError;
    }
    catch (error) {
      console.log("fgbnhdfgnhbdferr", error);
    }
  }


  useEffect(() => {
    GetCategoryCall();
  }, [Wallet_Details.UserAccountAddr]);

  async function GetCategoryCall() {
    let resp = await GetCategoryAction()
    if (resp && resp.data && isEmpty(resp.data.list) === false) {
      let CategoryOption = [];
      let ind = null
      if (resp && resp.data && isEmpty(resp.data.list) === false) {
        resp.data.list.map((item, index) => {
          CategoryOption.push({
            name: 'TokenCategory',
            value: item._id,
            label: item.name
          })
        })
      }
      setFullcategory(resp.data.list)
    }
  }

  const SettokenCategory = (cat, subcat) => {
    setCategorywithSubcategory({ category: cat.name, subcategory: subcat, id: cat._id, display: cat.name + '-' + subcat })
    setCategory(!category)
  }

  return (
    <div className="inner_header collection">
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader"}>
        <div className={"container"}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h3 className="section-head mb-0">Edit Collections</h3>
              </div>
            </Col>
          </Row>
        </div>
        <div className="container mt-5">
          <Row>
            <Col>

            </Col>
            <Col xs={12} sm={12} md={12}>
              <div className="banner_sec_collection">
                <div className="cover_coll_sec">
                  <img
                    src={
                      typeof (formValue?.coverImg) == 'string'
                        ? (isEmpty(formValue?.coverImg) ? cvr_img : (config.Back_URL + '/coverphoto/' + formValue.userAddress + '/' + formValue.coverImg))
                        : (formValue?.coverImg?.size ? URL.createObjectURL(formValue?.coverImg) : cvr_img)
                    }
                    alt="No Image"
                    id="imgPreview"
                    className="img-fluid cover_image"
                  />
                  <div className="cover_edit_banner">
                    <img src={editpng} alt="edit" className="img-fluid" width={30} />
                    <input
                      type="file"
                      name="photo"
                      id="coverImg"
                      className="photo"
                      onChange={(e) => handleFile1(e)}
                    />
                    {validateError.image1 && <span className="text-danger">{validateError.image1}</span>}
                  </div>
                </div>
                <div className="banner_pf_sec">
                  <img
                    src={
                      typeof (formValue?.profileImg) == 'string'
                        ? (isEmpty(formValue?.profileImg) ? cvr_img : (config.Back_URL + '/collectionLogo/' + formValue.userAddress + '/' + formValue.profileImg))
                        : (formValue?.profileImg?.size ? URL.createObjectURL(formValue?.profileImg) : cvr_img)
                    }
                    alt="logo"
                    id="imgPreview"
                    className="img-fluid profile_img"
                  />
                  <div className="pf_edit_banner">
                    <img src={editpng} alt="edit" className="img-fluid" width={30} />
                    <input
                      type="file"
                      name="photo"
                      id="profileImg"
                      required="true"
                      className="photo"
                      onChange={(e) => handleFile(e)}
                    />
                    {validateError.image && <span className="text-danger">{validateError.image}</span>}
                  </div>

                </div>
              </div>

            </Col>
            <Col xs={12} sm={12} md={12}>
              <form>
                <div className="form-row mt-5">
                  {
                    (form != "usercollection") &&

                    <div className="form-group col-md-5 mb-4">
                      <label className="primary_label" htmlFor="name">Collection Name</label>
                      <input type="text"

                        className="form-control primary_inp"
                        id="collectionName"
                        placeholder="Enter your Collection Name"
                        onChange={onChange}
                      />
                      {validateError.names && <span className="text-danger">{validateError.names}</span>}
                      {validateError.collectionName && <span className="text-danger">{validateError.collectionName}</span>}
                    </div>

                  }
                  {(form != "usercollection") &&
                    <div className="form-group offset-md-2 col-md-5 mb-4">

                      <label className="primary_label" htmlFor="collectionType">Type</label>
                      <Select

                        className="form-control primary_inp typecontrol"
                        onChange={selectChange}
                        name="collectionType"
                        options={CollectionOption}
                        maxMenuHeight={220}

                        label="Select or type name"
                        placeholder="Select or type name"
                      />
                      {validateError.Collection && <span className="text-danger"><br />{validateError.Collection}</span>}

                    </div>

                  }


                  {
                    (form != "usercollection") &&
                    <div className="form-group col-md-5 mb-4">
                      <label className="primary_label" htmlFor="collectionAddress">Collection Address   (optional)</label>
                      <input type="text"
                        className="form-control primary_inp"
                        id="collectionAddress"
                        placeholder="Enter your Collection Address"
                        onChange={onChange} />
                      {validateError.CollectionContractAddress && <span className="text-danger">{validateError.CollectionContractAddress}</span>}

                    </div>
                  }

                  {(form != "usercollection") &&
                    <div className="form-group  offset-md-2  col-md-5">
                      <label className="primary_label" htmlFor="customurl">Custom Url</label>
                      <input type="text"
                        value={customurl}
                        className="form-control primary_inp"
                        id="customurl"
                        onChange={onChange}
                        placeholder="Enter your Custom Url"
                      />
                      {validateError.customurl && <span className="text-danger">{validateError.customurl}</span>}


                    </div>

                  }

                  {(form != "usercollection") &&
                    <div className="form-group col-md-5 mb-4 category">
                      <label className="primary_label" htmlFor="category">Category</label>
                      <input type="text" placeholder="Select Category" className="form-control"
                        value={CategorywithSubcategory?.display ? CategorywithSubcategory?.display : ""}
                        onClick={() => setCategory(!category)} />
                      {category == true ?
                        (
                          <><div className="dropdown">

                            <ul className="dropdown-menu1 p-3">
                              {fullcategory?.length > 0 &&
                                fullcategory.map((val) => {
                                  return (
                                    <li className="dropdown-sub">
                                      {val.name}
                                      <ul className="sub-dropdown">
                                        {val.subcategory.map((sub) => {
                                          return (
                                            <li onClick={() => SettokenCategory(val, sub)}>{sub}</li>
                                          )
                                        })}
                                      </ul>
                                    </li>)
                                })
                              }
                            </ul>
                          </div></>) : (<></>)}

                      {validateError.Category && <span className="text-danger"><br />{validateError.Category}</span>}

                    </div>

                  }

                  <Col xs={12} sm={3} md={3}>
                  </Col>
                </div>
                <div className="text-end mt-3">

                  {/* <Button className="create_btn" >Add Collections</Button> */}
                  {
                    (form != "usercollection") &&
                    <button className="create_btn" onClick={handleFormSubmit}>Add Collections</button>
                  }
                  {
                    (form == "usercollection") &&
                    <button className="create_btn" onClick={handleFormSubmit}>Update
                    </button>
                  }
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}
