import React, { useEffect, useState } from "react";



// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { faqlists } from '../actions/v1/report'
import { Row, Col } from "react-bootstrap"


const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Howtosell(props) {

  const { ...rest } = props;


  return (
    <div className="inner_header howItWorks">
    <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">How to Sell NFTs on EdaFace Marketplace</h2>
                </div>

              </Col>
            </Row>
          </div>
        </div>
        <div className="container mt-3">
          <div className="accordion" id="accordionExample">

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Introduction</button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>To be able to sell NFTs on EdaFace Marketplace, you will need EDA. Hence, you will need to fund
                    your crypto wallet with EDA

                  </p>
                  <div className="accordionparalist">
                    <p>(For guidance on how to fund your non-custodial crypto wallet, check the following  <span>short videos
                      here….).</span></p>
                    <p>To start selling, click on the EdaFace NFT Marketplace <span> <a href="www.nft.edaface.com"> (www.nft.edaface.com)</a></span> and connect your
                      crypto wallet.
                    </p>

                  </div>


                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Step-by-Step Process
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>Once you have connected your wallet, you can follow these simple steps:
                  </p>

                  <div className="accordionparalist">

                    <p>Step 1: Click on your Profile and select the NFT you would like to sell from your wallet</p>

                  </div>
                  <div className="accordionparalist">
                    <p>Step 2: Click the “Sell” button in the upper right-hand corner of the NFT page
                    </p>

                  </div>
                  <div className="accordionparalist">
                    <p>Step 3: You’ll be prompted to choose the mode of sell – fixed-price sale or auction sale.
                    </p>
                    <p>If you choose a fixed-price sale, you’ll have to put in the asking price.</p>
                    <p>If you choose an auction sale, you’ll also have to set the start price, price threshold, and expiration
                      deadline.</p>


                  </div>
                  <div className="accordionparalist">
                    <p>Step 4: When you’ve completed all the required pricing information, you can then click the “Post
                      Your Listing” button.</p>
                    <p>There are no direct fees for listing your NFTs for sale on EdaFace Marketplace. However, there are
                      some fees that will be deducted by Edaface once the sale goes through.
                    </p>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 5: If this is your first time selling on the EdaFace Marketplace, you’ll also be prompted to
                      “initialize your wallet,” which involves completing two one-time transactions:

                    </p>
                    <h2>(a). To initialize your account for making sell orders</h2>
                    <h2>(b). To allow EdaFace Marketplace to access your item (or all items in the collection, if the collection
                      supports it) when a sale occurs. This may be needed if the item you are listing was not minted on
                      EdaFace but through a custom NFT contract.
                    </h2>
                    <p>Since it is your first-time auction, you will also need to approve EDA for trading.</p>
                    <p>Remember, after this one-time initializing of your wallet, you will not be prompted to do so again.
                      Hence, no need to be scared of a lengthy procedure. But if you have participated in a traditional
                      auction, you will realize that EdaFace Marketplace’s procedures are not lengthy at all.</p>
                  </div>
                  <div className="accordionparalist">
                    <p>Step 6: After initializing your wallet, you’ll be asked to finalize the listing, confirm all the details, and
                      post your NFT on the marketplace.</p>
                    <p>Congrats on listing your NFT on EdaFace Marketplace for sale.</p>
                    <p>You can view all the NFTs you have put up for sale by returning to your profile and selecting the
                      “Activity” tab on the left-hand sidebar.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
