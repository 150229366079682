import DETH_ABI from "../ABI/DETH_ABI.json";
import Loder from "../assets/images/loader.png";
import "@metamask/legacy-web3";
import AudioImg from "../assets/images/AudioImg.jpg";
import axios from "axios";
import { network } from "../views/network";
import { axiosFunc } from '../actions/commonfunction'

let fee1 = "";
var chainIds = 1;
const singleType = 721;
const multipleType = 1155;
const literatureType = 11551;
let maxLength = 13;
var WenlamboConvertVal = 0;
var erc20Address = "";
let PurchaseTransferType = "currency";
let Front_URL = "https://nft.bimaticz.com";
let Back_URL = "";
let Users_URL = "";
let v1Url = "";
let limit = "8";
var My_item_Limit = 6;
let Lod = Loder;
let limitMax = 3000;
let decimalvalues = 1000000000000000000;
let toFixed = 6;
var tokenFee = 0;
let IPFS_IMG = "https://edaface.infura-ipfs.io/ipfs";
let IPFS_Infura = "https://edaface.infura-ipfs.io/ipfs";
var nameFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
var trade = "";
var shareTag = [];
var toasterOption = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  limit: 1,
};
var Network = "";
var providercon = null;
var buyerfee = 0;
var sellerfee = 0;
// live

let EnvName = "local";
let fee = fee1;
var currAdrress = "";
var binance = "binance";
var etherium = "Sepolia";
var loginurl = "";
var cookiename = "";
var domainname = "";
var SingleSignonUrl = "";
let literaturcategory = '';
let NumOnly = /[^0-9]/g;
let chainids = [];
let defaultchainid = 0;

// const priceoptionETH = [
//     { value: 'ETH', label: 'ETH',address: "0x000000000000000000000000000",currency:true},
//     { value: 'CAKE', label: 'CAKE',address: "0x565654383Ec9F2d6184b0907cAE7AF150Ead2658",currency:false },
//   ];

//   const priceoptionBNB = [
//     { value: 'BNB', label: 'BNB',address: "0x000000000000000000000000000",currency:true},
//     { value: 'CAKE', label: 'CAKE',address: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11",currency:false },
//   ];

if (EnvName === "clientdemo") {
  Front_URL = "http://localhost:3000/";
  Users_URL = "http://localhost:3000/user";
  Back_URL = "http://localhost:2001";
  v1Url = "http://localhost:2001/v1";
  Network = network.ETH;
  loginurl = "http://localhost:3000";
  cookiename = "ssoToken";
  domainname = "localhost";
  literaturcategory = 'subcatadd';
  chainids = [11155111, 97,];
  defaultchainid = 97;
}
else {
  Front_URL = "http://localhost:3000/";
  Users_URL = "http://localhost:3000/user";
  Back_URL = "http://localhost:2001";
  v1Url = "http://localhost:2001/v1";
  Network = network.ETH;
  loginurl = "http://localhost:3000";
  cookiename = "ssoToken";
  domainname = "localhost";
  literaturcategory = 'subcatadd';
  chainids = [11155111, 97,];
  defaultchainid = 97;
}

async function tag() {
  let id = "0000";
  let data = await axiosFunc({
    method: 'get',
    url: `${v1Url}/admin/panel/getnfttag`,
    params: { id },
  })
  if (data && data.data && data.data.userValue) {
    data.data.userValue.map((item) => {
      shareTag.push(item.nfttag);
      return item;
    });
  }
}
tag();

var My_item_Page = 1;
let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  v1Url: v1Url,
  vUrl: v1Url,
  fee: fee,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  NumOnly: NumOnly,
  // networkVersion: Network.networkVersion,
  // currencySymbol: Network.currencySymbol,
  // tokenSymbol: Network.tokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  // singleContract: Network.singleContract,
  // multipleContract: Network.multipleContract,

  tokenAddr: Network.tokenAddr,
  singleType: singleType,
  multipleType: multipleType,
  literatureType: literatureType,


  IPFS_IMG: IPFS_IMG,
  // BNBPROVIDER: Network.BNBPROVIDER,
  tokenABI: Network.tokenABI,
  PurchaseTransferType: PurchaseTransferType,
  maxLength: maxLength,
  Users_URL: Users_URL,
  Lod: Lod,
  chainId: Network.chainId,
  Chainid: Network.Chainid,
  WenlamboConvertVal: WenlamboConvertVal,
  currAdrress: currAdrress,
  tokenFee: tokenFee,
  sellerfee: sellerfee,
  buyerfee: buyerfee,
  shareTag: shareTag,
  providercon: providercon,
  AudioImg: AudioImg,
  nameFormat: nameFormat,
  chainIds: chainIds,
  trade: trade,
  binance: binance,
  etherium: etherium,
  My_item_Limit: My_item_Limit,
  My_item_Page: My_item_Page,
  IPFS_Infura: IPFS_Infura,
  erc20Address: erc20Address,
  NetworkName: network.ETH.name,
  KEY: "EDAFACENFTMarketPlace#%^09276!!",
  loginurl: loginurl,
  cookiename: cookiename,
  domainname: domainname,
  // priceoptionETH      :   priceoptionETH,
  // priceoptionBNB      :  priceoptionBNB
  defaultservicekey: 0,
  literaturcategory: literaturcategory,
  chainids: chainids,
  defaultchainid: defaultchainid,
  userCookieName: "userkey",
  EnvName: EnvName
};

export default key;
