import React, { useEffect, useState } from "react";

// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import { Link } from "react-router-dom";
import { faqlists } from '../actions/v1/report'

import { Row, Col } from "react-bootstrap";


const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Typesofnft(props) {

  const { ...rest } = props;


  return (
    <div className="inner_header howItWorks">
      <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">Three Types of EdaFace NFTs</h2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container mt-3">
          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Divisions
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Based on what they represent, EdaFace has divided all NFTs into three types:</p>
                  <div className="accordionparalist">
                    <h2>(1). Fine Art NFTs</h2>
                    <p>These are digital images of visual arts considered to have been created primarily for aesthetic
                      purposes and judged for their beauty and meaningfulness. They include:
                    </p>
                  </div>
                  <div className="accordionparalist">
                    <ul>
                      <li>Paintings</li>
                      <li>Sculptures</li>
                      <li>Drawings</li>
                      <li>Watercolors</li>
                      <li>Graphics</li>
                      <li>Architecture</li>
                    </ul>
                  </div>
                  <div className="accordionparalist">
                    <h2>(2). Collectible NFTs</h2>
                    <p>A collectible is typically a manufactured item designed for people to acquire as possession.</p>
                    <p>Note that a collectible can be an item made without the intention of special interest but due to
                      production fault, human error, nostalgia, or any event. In other words, a collectible does not
                      necessarily have to be valuable or antique; its rarity or uniqueness kindle desire for the collectible to
                      be sought after.</p>
                    <p>The digital image of a collectible can be traded as an NFT.</p>
                    <p>Popular collectible items include:</p>
                    <ul>
                      <li>Stamps</li>
                      <li>Rocks</li>
                      <li>Ancient coins</li>
                      <li>Family artifacts</li>
                      <li>Religious relics, etc.</li>

                    </ul>
                  </div>
                  <div className="accordionparalist">
                    <h2>(3). Gaming NFTs</h2>
                    <p>These are digitalised games that can be traded as NFTs.</p>
                    <p>They include:</p>
                    <ul>
                      <li>Games, e.g. in-game accessories, in-game attires, rare-game assets, etc</li>
                      <li>Sports, e.g. player’s cards, player’s stats, player’s best moves, player’s collectibles like their wearables of jerseys, T-shirts, shoes, equipment, etc</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Metaverse
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Metaverse is the conversion and iteration of the physical world into a virtual world that is facilitated
                    by the use of virtual reality (VR) and augmented reality (AR) headsets.

                  </p>
                  <p>In other words, a metaverse is a network of 3D virtual worlds focused on social connection.</p>
                  <p>Gaming NFTs are already prominent as part of the metaverse technology, which has rapidly grown in
                    value to be more than 10 billion USD market capitalization.
                  </p>
                  <p>EdaFace plans to develop its metaverse that will have a broad range of functionalities other gaming</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
