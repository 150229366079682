import React, { useEffect, useState } from "react";


// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { faqlists } from '../actions/v1/report'

import { Row, Col } from "react-bootstrap";


const dashboardRoutes = [];


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Nftmarketplaces(props) {

  const { ...rest } = props;

  return (
    <div className="inner_header howItWorks">
     <Header/>
      <ScrollToTopOnMount />
      <div className={"inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">NFT Marketplaces</h2>
                </div>

              </Col>
            </Row>
          </div>
        </div>
        <div className="container mt-3">
          <div className="accordion" id="accordionExample">

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Introduction</button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>NFTs are special types of cryptos. Not only do they have monetary value, but they also have
                    aesthetic value. NFTs add beauty to the Crypto Market.
                  </p>
                  <p>In a regular wallet, NFTs appear as tokens. Therefore, to be able to see the aesthetic side of an NFT,
                    a different type of platform will be required. These platforms are known as NFT Marketplaces.
                  </p>
                  <p>EdaFace NFT Marketplace is rapidly growing to be the most popular marketplace in the global crypto
                    industry.</p>


                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Six Characteristics of EdaFace NFT Marketplace
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>EdaFace NFT Marketplaces have the following unique characteristics:</p>

                  <div className="accordionparalist">
                    <h2>(a). Offers Audiovisual Perception</h2>
                    <p>It offers audiovisual perception of NFTs. That is, on EdaFace NFT Marketplace, you can see an NFT in
                      its beauty and hear any sounds crafted into it</p>

                  </div>
                  <div className="accordionparalist">
                    <h2>(b). Does Not Store NFTs</h2>
                    <p>It does not have wallets and therefore does not store your NFTs. Hence, you will need to connect
                      your wallet to EdaFace Marketplace to be able to see and hear the NFTs that are stored in your
                      wallet.</p>

                  </div>
                  <div className="accordionparalist">
                    <h2>(c). A Peer-to-Peer Platform</h2>
                    <p>It is a peer-to-peer platform. Meaning that EdaFace NFT Marketplace is a platform where the seller
                      and buyer meet and transact their NFTs.</p>

                  </div>
                  <div className="accordionparalist">
                    <h2>(d). Several Transactional Services</h2>
                    <p>Besides buying and selling, EdaFace NFT marketplace offers other transactional services like minting,
                      auctioning, etcetera.</p>

                  </div>
                  <div className="accordionparalist">
                    <h2>(e). A Multichain Platform </h2>
                    <p>EdaFace Marketplace is a multichain platform, hence, accepts NFTs from different blockchains</p>

                  </div>
                  <div className="accordionparalist">
                    <h2>(f). Three Market Arenas</h2>
                    <p>There are three market arenas on EdaFace Marketplace – Art, Collectible, and Gaming arenas.
                    </p>

                  </div>

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  NFT Bridges
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>The question you may like to ask is: “Can I keep my NFTs in my regular wallets?”</p>
                  <p>Yes, you can. Ethereum-based NFTs can be held in Ethereum wallets, BNB-based NFTs can be held in
                    BNB wallets, etc.
                  </p>
                  <p>You cannot send an Ethereum-based NFT to a BNB wallet, and vice versa.
                  </p>
                  <p>However, note that the NFTs held in these conventional wallets will appear as tokens in the wallets
                    just like any other cryptocurrency.
                  </p>
                  <p>For you to view the NFTs in your wallet, your crypto wallet will have to be connected to EdaFace NFT
                    marketplace
                  </p>
                  <p>The digital connector that connects your wallet to an NFT marketplace is referred to as an NFT
                    Bridge.
                  </p>



                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingfour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                  EdaFace NFT Bridges
                </button>
              </h2>
              <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>EdaFace NFT Marketplace is revolutionizing the NFT industry. EdaFace Marketplace has constructed
                    NFT Bridges.</p>
                  <p>Thus, you can send your ETH-based NFT as well as BNB-based NFT from any Ethereum or BNB wallet
                    to EdaFace Marketplace for advertisement and marketing.</p>
                  <p>Other bridges that will allow NFTs minted on any other blockchain to be sent to EdaFace
                    Marketplace are being constructed.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Multichain Unique Platform
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>The unique thing about EdaFace NFT Marketplace is that it is a multichain platform. This means it
                    can accept NFTs from different blockchains such as Ethereum-based platform and BNB-based NFTs. </p>
                  <p>In fact, it should handle all the EVM (Ethereum Virtual Machine) supported chains as well as nonEVM supported chains, which are still in construction.
                  </p>
                  <p>Examples of the EVM-supported chains that are part of EdaFace NFT Marketplace are</p>
                  <div className="d-flex g-30">
                    <ul>
                      <li>Ethereum</li>
                      <li>Solana</li>
                      <li>Polkadot</li>
                      <li>Algorand</li>
                      <li>Fantom</li>
                      <li>OKEx Chain </li>
                    </ul>
                    <ul>
                      <li>BNB Smart Chain</li>
                      <li>Polygon</li>
                      <li>Avalanche</li>
                      <li>Huobi ECO Chain</li>
                      <li>Arbitrum</li>
                      <li>Cardano</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  The Three Arenas
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>EdaFace Marketplace is uniquely divided into three arenas. This division allows users easy access and
                    navigation across EdaFace Marketplace.</p>
                  <p>The three arenas of EdaFace Marketplace are:
                  </p>
                  <div className="accordionparalist">
                    <h2>(1). Art Arena </h2>
                    <p>This platform holds all the Fine Art NFTs.
                    </p>
                    <h2>(2). Collectible Arena</h2>
                    <p>This platform holds all the Collectible NFTs
                    </p>
                    <h2>(3). Game Arena</h2>
                    <p>This platform holds all the Gaming NFTs.
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
