import React, { useEffect, useState } from "react";

// core components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { faqlists } from '../actions/v1/report'
import { Row, Col } from "react-bootstrap";



const dashboardRoutes = [];



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Investnft(props) {

  const { ...rest } = props;


  return (
    <div className="inner_header howItWorks">
     <Header/>
      <ScrollToTopOnMount />
      <div className={" inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">

            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <h2 className="inner_title">How to Invest in NFTs</h2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container mt-3">
          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Introduction
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Three generations of crypto millionaires have emerged in the Crypto Market. </p>
                  <div className="accordionparalist">

                    <p>The first generation is the Bitcoin Millionaires, followed by the DeFi Millionaires, and the third wave
                      of millionaires that are being born in the Crypto Market is the NFT Millionaires.</p>
                    <p>NFT is at its early stage of growth. Meaning, if you position yourself correctly, you can be among this
                      third wave of millionaires.</p>
                    <p>How do you position yourself and invest profitably in NFTs?</p>
                  </div>

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Five Ways to Invest in NFTs
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>Presently, there are four ways you can invest in NFTs:</p>


                  <div className="accordionparalist">
                    <h2>(1). Minting NFTs</h2>
                    <p>To mint an NFT is to create or produce it from the blockchain. This is also known as “mining NFTs.”
                      Just as you mine any other cryptocurrency from its blockchain, NFTs are also mined from
                      blockchains.</p>
                    <p>EdaFace NFT Marketplace is a platform that you can utilise to mint your NFTs.</p>
                    <p>Thus, if you are an artist, a politician, a scientist, or in any profession, you can mint your unique NFTs
                      on EdaFace NFT Marketplace.</p>
                  </div>
                  <div className="accordionparalist">
                    <h2>(2). Merchandising NFTs</h2>
                    <p>There are various ways you can merchandise NFTs:</p>
                    <h2>(a). Day Trading</h2>
                    <p>This involves buying and selling NFTs.</p>
                    <h2>(b). Hodling </h2>
                    <p>This is buying an NFT and holding onto it until its price appreciates before selling it at a profit.
                      Presently, this is regarded to be the most lucrative form of NFT investment. Indeed, time is money.
                    </p>

                  </div>
                  <div className="accordionparalist">
                    <h2>(3). Token acquisition</h2>
                    <p>Some NFTs come with their tokens such as EDA. You use these cryptos to buy and sell the NFTs.</p>

                    <p>That means you can buy these cryptos and wait for their prices to appreciate. In fact, most people
                      who get involved in NFTs usually do so by buying their cryptos. You can use such tokens, e.g. EDA,
                      for yield farming or stake them to acquire more.</p>


                  </div>
                  <div className="accordionparalist">
                    <h2>(4). Royalties</h2>
                    <p>On EdaFace NFT marketplace, you have the option to program your NFT to be receiving royalties for
                      all sales made just as you obtain royalties for publishing a book. This can create a lifelong passive
                      income for the owner of the NFT.</p>


                  </div>
                  <div className="accordionparalist">
                    <h2>(5). DeFi activities</h2>
                    <p>As their use cases evolve, NFTs will undoubtedly feature in most decentralised financial activities
                      such as lending and yield farming.</p>

                    <p>In fact, the use of NFT in collateralisation will be a strong feature of DeFi in the future. Some
                      protocols have started to accept NFTs in liquidity pools. There is no doubt that NFTs will play
                      significant roles in the rapidly growing DeFi activities.</p>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
