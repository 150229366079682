import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Icon2 from "../../assets/images/wallet_03.png";
import Icon1 from "../../assets/images/icon1.jpg";
import { connectWallet, WalletConnect } from '../hooks/useWallet';
import { Account_Connect, Account_disConnect } from "../../actions/redux/action";
//redux
import { useDispatch, useSelector } from 'react-redux';
import { AddressUserDetails_GetOrSave_Action } from "../../actions/v1/user";
import Config from "../../lib/config";
// import { Account_Section } from '../../redux/reducers'
//import {Account_Section} from '../../redux/constants'
//import useAxiosFile from '../../actions/useaxios';
import { toast } from 'react-toastify';
import Icon4 from "../..//assets/images/wallet_05.png";


export const Connect = forwardRef((props, ref) => {


    const dispatch = useDispatch();


    useImperativeHandle(
        ref,
        () => ({
            async walletConnectfunction() {
                window.$('#connect_modal').modal('show');
            }
        })
    )

    const initialConnectWallet = async (type) => {
        window.$('#connect_modal').modal('hide');
        let accountDetails = await connectWallet(type,)
        if (accountDetails != '') {
            dispatch({
                type: Account_Connect,
                Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    providerss: accountDetails?.web3?._provider
                }
            })
        }

        let ReqData = {
            addr: String(accountDetails?.accountAddress).toLowerCase()
        }
        let Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
            return Resp.data.data.User
        } else {
            return null
        }
    }

    return (
        <div className="modal fade primary_modal" id="connect_modal" role="dialog" aria-labelledby="connect_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title" id="connect_modalLabel">Connect Your Wallet</h5>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ul className="connect_ul">
                            {
                                <li>
                                    <div className=" connect_card" >
                                        <div className="card-body-mod">
                                            <div className="media follow_media">


                                                <div className="media-body flex_body">
                                                    <div className="w-100">
                                                        <div className="wallet-lists"
                                                            onClick={() => initialConnectWallet("MetaMask")} >
                                                            <p className="my-0 media_text"> <img src={Icon1} />Metamask</p>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </li>
                            }

                            {
                                <li>
                                    <div className=" connect_card" >
                                        <div className="card-body-mod">
                                            <div className="media follow_media">


                                                <div className="media-body flex_body">
                                                    <div className="w-100">
                                                        <div className="wallet-lists"
                                                            onClick={() => initialConnectWallet("WalletConnect")}
                                                        >
                                                            <p className="my-0 media_text"> <img src={Icon2} />Wallet Connect</p>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </li>
                            }

                            {
                                <li>
                                    <div className=" connect_card" >
                                        <div className="card-body-mod">
                                            <div className="media follow_media">
                                                <div className="media-body flex_body">
                                                    <div className="w-100">
                                                        <div className="wallet-lists"
                                                            onClick={() => initialConnectWallet("WalletConnect")}
                                                        >
                                                            <p className="my-0 media_text"> <img src={Icon4} />Trust wallet</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
})