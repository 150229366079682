import React, { useState, useEffect } from "react";

import FooterLogo from "../../assets/images/footer_logo.png";
import { getCmsInFooter, sociallinksfunction } from '../../actions/v1/report';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import { subscriberAction } from "../../actions/v1/token";
import config from '././../../lib/config';




export default function Footer(props) {


    const Wallet_Details = useSelector(state => state.wallet_connect_context);



    const [cms, setCms] = useState([])
    const [mail, setmail] = useState('');
  
  
    useEffect(() => {
  
  
      sociallinksfunct()
    }, [Wallet_Details.WalletConnected, Wallet_Details.UserAccountAddr, localStorage.walletConnectType, mail, config.providercon])
  
    const mailstate = (e) => {
      ////console.log("Mialllll",e.target.value)
      setmail(e.target.value)
    }
  
    const sociallinksfunct = async () => {
      let soci = await getCmsInFooter()
      if (soci !== undefined && soci?.data !== undefined) {
        setCms(soci?.data)
      }
      else {
        setCms([])
      }
    }
  
  
    const newmailadd = async () => {
      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
      if (!mail) {
        return toast.error("Enter Email-Id")
      }
      if (mail != "") {
        if (!emailRegex.test(mail)) {
          return toast.error("Enter valid email")
        }
      }
      //console.log("mail>>>>",mail);
      let newmailData = await subscriberAction({ email: mail });
      console.log("🚀 ~ newmailadd ~ newmailData:", newmailData)
      if (newmailData && newmailData.data && newmailData.data && newmailData.data.success && newmailData.data.success == true) {
        toast.success(newmailData.data.message);
        setmail('');
      }
      // //console.log(">>>>newmailData",newmailData);
    }



    return (
        <>

            <footer >

                <div className="container-fluid container-theme container-lg">
                    <div className="footergrid">
                        <div className="foote1">
                            <div className="footone">
                                <h4>Company</h4>
                                <ul>
                                    {
                                        cms && cms[0]?.content?.map((value, index) => (
                                            <li><a href={value.link} target="_blank">{value.lable}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="foottwo">
                                <h4>Products</h4>
                                <ul>
                                    {
                                        cms && cms[1]?.content?.map((value, index) => (
                                            <li><a href={value.link} target="_blank">{value.lable}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="footthree">
                            <img src={FooterLogo} alt="Edaface" />
                            <h3 className="subxcribe">Subscribe to Our Newsletter</h3>
                            <div className="newsletter_grp">
                                <input type="email" className="newsletter_inp" placeholder="Your Email Address" value={mail} onChange={mailstate}
                                />
                                <div className="newsletter_grp_append">
                                    <button className="btn m-0" type="button" id="news_submit" onClick={() => { newmailadd() }}>Subscribe</button>
                                </div>

                            </div>
                        </div>
                        <div className="foote">

                            <div className="footfour">
                                <h4>Legal</h4>
                                <ul>
                                    {
                                        cms && cms[2]?.content?.map((value, index) => (
                                            <li><a href={value.link} target="_blank">{value.lable}</a></li>
                                        ))
                                    }

                                </ul>
                            </div>
                            <div className="footfive">
                                <h4>Community</h4>
                                <ul>
                                    {
                                        cms && cms[3]?.content?.map((value, index) => (
                                            <li><a href={value.link} target="_blank">{value.lable}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="bordrline"></div>
                    <div className="mt-4">
                        <p className="copyright_txt">{`Copyright © ${new Date().getFullYear()}. EdaFace NFT Marketplace. All Rights Reserved.`}</p>
                    </div>
                </div>
            </footer>






        </>
    );
}